<div style="display: flex; width: 100%; gap: 30px">
  <h2 class="fw-medium">Manage URLs segments</h2>
  <button mat-button color="primary" (click)="handleShowAddSegmentForm()">
    <mat-icon>add</mat-icon> Add new
  </button>
</div>

<div>
  @if(showAddSegmentForm) {
  <form
    [formGroup]="segmentFormData"
    (ngSubmit)="segmentFormData.valid && createURLSegment()"
    novalidate
  >
    <div class="row">
      <div class="col-3">
        <mat-form-field appearance="outline">
          <mat-label>Segment Name</mat-label>
          <input type="text" matInput formControlName="segmentName" />
          @if (segmentFormData.controls['segmentName'].hasError('required')) {
          <mat-error> Segment name is <strong>required</strong> </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field appearance="outline">
          <mat-label>eg /blog </mat-label>
          <input type="text" matInput formControlName="segmentPath" />
          @if (segmentFormData.controls['segmentPath'].hasError('required')) {
          <mat-error> Segment path is <strong>required</strong> </mat-error>
          } @if (segmentFormData.controls['segmentPath'].hasError('pattern')) {
          <mat-error>
          Segment path should start with /
          </mat-error>

          }
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field appearance="outline">
          <mat-label>Select Site</mat-label>
          <mat-select formControlName="segmentOwnerSite">
            @for(site of rumSites; track site) {
            <mat-option [value]="site.id">
              {{ site.title }}
            </mat-option>
            }
          </mat-select>
          @if
          (segmentFormData.controls['segmentOwnerSite'].hasError('required')) {
          <mat-error> Site is <strong>required</strong> </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-3 d-flex justify-content-center">
        <mat-checkbox formControlName="execludeRumData"
          >Exclude RUM data collection</mat-checkbox
        >
      </div>
      <div class="col-3 mt-1" style="display: flex; gap: 10px">
        <button
          class="btn btn-light"
          (click)="handleShowAddSegmentForm()"
        >
          Cancel
        </button>
        <button class="btn btn-primary" type="submit">Save</button>
      </div>
    </div>
  </form>
  }
  @if(urlBySegmentDatasource?.data?.length) {
  <div class="mt-2">
    <mat-table [dataSource]="urlBySegmentDatasource">
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <ng-container matColumnDef="segmentName">
        <mat-header-cell *matHeaderCellDef> Segment name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.segmentName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="path">
        <mat-header-cell *matHeaderCellDef>Segment path</mat-header-cell>
        <mat-cell *matCellDef="let element">
          @if(!element?.execludeRumData) {
          <a
            (click)="goToInternalPagesDetail(element)"
            style="text-decoration: none; cursor: pointer"
            >{{ element?.segmentPath }}</a
          >
          } @if (element?.execludeRumData) {
          <p>{{ element?.segmentPath }}</p>
          }
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="site">
        <mat-header-cell *matHeaderCellDef>Website</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a
            routerLink="/home/rum/{{ element?.segmentOwnerSite }}/overview"
            style="text-decoration: none"
            >{{ element?.segmentOwnerSiteTitle }}</a
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="rumDataExcluded">
        <mat-header-cell *matHeaderCellDef>RUM data excluded</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox
            [checked]="element?.execludeRumData"
            (change)="updateSegmentData($event, element)"
          ></mat-checkbox
        ></mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element"> </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>
  </div>
  }
</div>
