<app-help-widget
  [currentURL]="currentUrl"
  [userId]="userId"
  [projectID]="projectID"
  [Url] = "Url"
  >
</app-help-widget>
<nav class="mx-4 mt-3" aria-label="breadcrumb">
  <ol style="height: 50px; width: 52px;" class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center">
    @defer{
      @if (estAlloue(country)) {
        <span class="mx-1">
            <mat-icon [svgIcon]="country"></mat-icon>
        </span>
       }@else{
        <mat-icon  class="mx-1">language</mat-icon>
       }
    }
    <li>
      <span class="fw-bold">CRUX > </span>
    </li>
    <li class="breadcrumb-item">
      <a class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center " [href]="'/home/page/' + this.senderVarService.id + '/overview'">
       <mat-icon class="mx-2 ">desktop_windows</mat-icon>
       <span>{{WebSiteTitle}} Overview</span>
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
    Competitive benchmark
    </li>
  </ol>
</nav>

<div  class="d-flex justify-content-center align-content-center w-100">
  <div class="m-3 w-75">
  @if (loading) {
    <div class="d-flex justify-content-center align-items-center" style="height:-1000px;">
      <mat-spinner class="spinner" diameter="150"></mat-spinner>
    </div>
  }
  @if (!loading) {
    <div class="text-center">
      <h2 class="m-0">See how your website is performing against the competition</h2>
      <h4>Compare Web Vitals and Web performance metrics</h4>
    </div>
    <div class="mx-5 my-3 d-flex justify-content-end align-items-center">
      <app-device-button  (deviceEvent)='setDevice($event)'></app-device-button>
    </div>
    <div>
      <mat-tab-group mat-align-tabs="center" class="mx-5">
        <mat-tab label="UXR ranking"  class="bg-primary">
          <ng-template matTabContent>
            <mat-card>
              <mat-card-content>
                <mat-table [dataSource]="tableDataSource" class="mat-elevation-z8">
                  <ng-container matColumnDef="rank">
                    <mat-header-cell  *matHeaderCellDef>
                      Rank
                    </mat-header-cell>
                    <mat-cell class="box" *matCellDef="let element;">
                      @if (isGold(element.uxr)) {
                        <div style="text-align: center; color: goldenrod;">
                          1st <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M12,7.09L14.45,8.58L13.8,5.77L16,3.89L13.11,3.64L12,1L10.87,3.64L8,3.89L10.18,5.77L9.5,8.58L12,7.09M15,23H9V10H15V23M1,17V23H7V17H1M5,21H3V19H5V21M17,13V23H23V13H17M21,21H19V15H21V21Z" />
                        </svg>
                      </div>
                    }
                    @if (isSilver(element.uxr)) {
                      <div style="text-align: center; color: cornflowerblue;">
                        2nd <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20,10.09L22.45,11.58L21.8,8.77L24,6.89L21.11,6.64L20,4L18.87,6.64L16,6.89L18.18,8.77L17.5,11.58L20,10.09M23,23H17V13H23V23M1,17V23H7V17H1M5,21H3V19H5V21M9,10V23H15V10H9M13,21H11V12H13V21Z" />
                      </svg>
                    </div>
                  }
                  @if (isBronze(element.uxr)) {
                    <div style="text-align: center; color: brown;">
                      3rd <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M4,13.09L6.45,14.58L5.8,11.77L8,9.89L5.11,9.64L4,7L2.87,9.64L0,9.89L2.18,11.77L1.5,14.58L4,13.09M7,23H1V17H7V23M9,10V23H15V10H9M13,21H11V12H13V21M17,13V23H23V13H17M21,21H19V15H21V21Z" />
                    </svg>
                  </div>
                }
                @if (isFourth(element.uxr)) {
                  <div style="text-align: center;">
                    4th
                  </div>
                }
                @if (isFifth(element.uxr)) {
                  <div style="text-align: center;">
                    5th
                  </div>
                }
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="url">
              <mat-header-cell  *matHeaderCellDef>
                Domain name
              </mat-header-cell>
              <mat-cell class="box" *matCellDef="let element">
                {{ element.title }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="TTFB">
              <mat-header-cell  *matHeaderCellDef>
                TTFB
              </mat-header-cell>
              <mat-cell class="box" *matCellDef="let element">
                {{ element.ttfb}} ms
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="LCP">
              <mat-header-cell  *matHeaderCellDef>
                LCP
              </mat-header-cell>
              <mat-cell class="box" *matCellDef="let element">
                {{ element.lcp}} ms
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="INP">
              <mat-header-cell  *matHeaderCellDef>
                INP
              </mat-header-cell>
              <mat-cell class="box" *matCellDef="let element">
                {{ element.inp}} ms
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="CLS">
              <mat-header-cell  *matHeaderCellDef>
                CLS
              </mat-header-cell>
              <mat-cell class="box" *matCellDef="let element">
                {{ element.cls}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="uxrScore">
              <mat-header-cell  *matHeaderCellDef>
                UXR score
              </mat-header-cell>
              <mat-cell class="box" *matCellDef="let element">
                {{ element.uxr }}, diff:
                @if (element.uxrDiff < 0) {
                  <span style="color: red; padding-left: 1%;">{{
                    element.uxrDiff
                    }}</span
                    >
                    }@if (element.uxrDiff > 0) {
                    <span style="color: green; padding-left: 1%;">+{{
                      element.uxrDiff
                    }}</span>
                  }
                  @if (element.uxrDiff === '0.00') {
                    <span>{{ element.uxrDiff || '0.00'}}</span>
                  }
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true"></mat-header-row>
              <mat-row [ngClass]="{'highlighted-row': isUserDomain(row?.url)}" *matRowDef="let row; columns : tableDisplayedColumns"></mat-row>
            </mat-table>
            <div class="row m-3">
              <apx-chart [colors]="colors" [series]="chartOptionsUXRRank.series" [chart]="chartOptionsUXRRank.chart"
                [xaxis]="chartOptionsUXRRank.xaxis" [stroke]="chartOptionsUXRRank.stroke"
                [tooltip]="chartOptionsUXRRank.tooltip" [dataLabels]="chartOptionsUXRRank.dataLabels"
                [legend]="chartOptionsUXRRank.legend" [markers]="chartOptionsUXRRank.markers"
                [grid]="chartOptionsUXRRank.grid" [yaxis]="chartOptionsUXRRank.yaxis"
              [title]="chartOptionsUXRRank.title"></apx-chart>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </mat-tab>
    <mat-tab label="On last month">
      <div class="m-2">
        <div class="row m-2">
          <apx-chart [series]="chartOptionsCRUXLastMonthCompetitorTTFB.series" [chart]="chartOptionsCRUXLastMonthCompetitorTTFB.chart"
            [dataLabels]="chartOptionsCRUXLastMonthCompetitorTTFB.dataLabels"
            [plotOptions]="chartOptionsCRUXLastMonthCompetitorTTFB.plotOptions" [xaxis]="chartOptionsCRUXLastMonthCompetitorTTFB.xaxis"
            [yaxis]="chartOptionsCRUXLastMonthCompetitorTTFB.yaxis" [stroke]="chartOptionsCRUXLastMonthCompetitorTTFB.stroke"
            [fill]="chartOptionsCRUXLastMonthCompetitorTTFB.fill" [title]="chartOptionsCRUXLastMonthCompetitorTTFB.title"
          [tooltip]="chartOptionsCRUXLastMonthCompetitorTTFB.tooltip" [legend]="chartOptionsCRUXLastMonthCompetitorTTFB.legend"></apx-chart>
        </div>
        <div class="row m-2">
          <apx-chart [series]="chartOptionsCRUXLastMonthCompetitorFCP.series" [chart]="chartOptionsCRUXLastMonthCompetitorFCP.chart"
            [dataLabels]="chartOptionsCRUXLastMonthCompetitorFCP.dataLabels"
            [plotOptions]="chartOptionsCRUXLastMonthCompetitorFCP.plotOptions" [xaxis]="chartOptionsCRUXLastMonthCompetitorFCP.xaxis"
            [yaxis]="chartOptionsCRUXLastMonthCompetitorFCP.yaxis" [stroke]="chartOptionsCRUXLastMonthCompetitorFCP.stroke"
            [fill]="chartOptionsCRUXLastMonthCompetitorFCP.fill" [title]="chartOptionsCRUXLastMonthCompetitorFCP.title"
          [tooltip]="chartOptionsCRUXLastMonthCompetitorFCP.tooltip" [legend]="chartOptionsCRUXLastMonthCompetitorFCP.legend"></apx-chart>
        </div>
        <div class="row m-2">
          <apx-chart [series]="chartOptionsCRUXLastMonthCompetitorLCP.series" [chart]="chartOptionsCRUXLastMonthCompetitorLCP.chart"
            [dataLabels]="chartOptionsCRUXLastMonthCompetitorLCP.dataLabels"
            [plotOptions]="chartOptionsCRUXLastMonthCompetitorLCP.plotOptions" [xaxis]="chartOptionsCRUXLastMonthCompetitorLCP.xaxis"
            [yaxis]="chartOptionsCRUXLastMonthCompetitorLCP.yaxis" [stroke]="chartOptionsCRUXLastMonthCompetitorLCP.stroke"
            [fill]="chartOptionsCRUXLastMonthCompetitorLCP.fill" [title]="chartOptionsCRUXLastMonthCompetitorLCP.title"
            [tooltip]="chartOptionsCRUXLastMonthCompetitorLCP.tooltip" [legend]="chartOptionsCRUXLastMonthCompetitorLCP.legend">
          </apx-chart>
        </div>
        <div class="row m-2">
          <apx-chart [series]="chartOptionsCRUXLastMonthCompetitorInp.series" [chart]="chartOptionsCRUXLastMonthCompetitorInp.chart"
            [dataLabels]="chartOptionsCRUXLastMonthCompetitorInp.dataLabels"
            [plotOptions]="chartOptionsCRUXLastMonthCompetitorInp.plotOptions" [xaxis]="chartOptionsCRUXLastMonthCompetitorInp.xaxis"
            [yaxis]="chartOptionsCRUXLastMonthCompetitorInp.yaxis" [stroke]="chartOptionsCRUXLastMonthCompetitorInp.stroke"
            [fill]="chartOptionsCRUXLastMonthCompetitorInp.fill" [title]="chartOptionsCRUXLastMonthCompetitorInp.title"
          [tooltip]="chartOptionsCRUXLastMonthCompetitorInp.tooltip" [legend]="chartOptionsCRUXLastMonthCompetitorInp.legend"></apx-chart>
        </div>

        <div class="row m-2">
          <apx-chart [series]="chartOptionsCRUXLastMonthCompetitorCLS.series" [chart]="chartOptionsCRUXLastMonthCompetitorCLS.chart"
            [dataLabels]="chartOptionsCRUXLastMonthCompetitorCLS.dataLabels"
            [plotOptions]="chartOptionsCRUXLastMonthCompetitorCLS.plotOptions" [xaxis]="chartOptionsCRUXLastMonthCompetitorCLS.xaxis"
            [yaxis]="chartOptionsCRUXLastMonthCompetitorCLS.yaxis" [stroke]="chartOptionsCRUXLastMonthCompetitorCLS.stroke"
            [fill]="chartOptionsCRUXLastMonthCompetitorCLS.fill" [title]="chartOptionsCRUXLastMonthCompetitorCLS.title"
          [tooltip]="chartOptionsCRUXLastMonthCompetitorCLS.tooltip" [legend]="chartOptionsCRUXLastMonthCompetitorCLS.legend"></apx-chart>
        </div>


      </div>
    </mat-tab>
    <mat-tab label="Over time">
      <ng-template matTabContent>
        <div class="m-3">
          <div class="row">
            <apx-chart  [colors]="colors" [series]="chartOptionsCRUXCompetitorTTFB.series" [chart]="chartOptionsCRUXCompetitorTTFB.chart"
              [xaxis]="chartOptionsCRUXCompetitorTTFB.xaxis" [stroke]="chartOptionsCRUXCompetitorTTFB.stroke"
              [tooltip]="chartOptionsCRUXCompetitorTTFB.tooltip" [dataLabels]="chartOptionsCRUXCompetitorTTFB.dataLabels"
              [legend]="chartOptionsCRUXCompetitorTTFB.legend" [markers]="chartOptionsCRUXCompetitorTTFB.markers"
              [grid]="chartOptionsCRUXCompetitorTTFB.grid" [yaxis]="chartOptionsCRUXCompetitorTTFB.yaxis"
            [title]="chartOptionsCRUXCompetitorTTFB.title"></apx-chart>
          </div>
          <div class="row">
            <apx-chart [colors]="colors" [series]="chartOptionsCRUXCompetitorFCP.series" [chart]="chartOptionsCRUXCompetitorFCP.chart"
              [xaxis]="chartOptionsCRUXCompetitorFCP.xaxis" [stroke]="chartOptionsCRUXCompetitorFCP.stroke"
              [tooltip]="chartOptionsCRUXCompetitorFCP.tooltip" [dataLabels]="chartOptionsCRUXCompetitorFCP.dataLabels"
              [legend]="chartOptionsCRUXCompetitorFCP.legend" [markers]="chartOptionsCRUXCompetitorFCP.markers"
              [grid]="chartOptionsCRUXCompetitorFCP.grid" [yaxis]="chartOptionsCRUXCompetitorFCP.yaxis"
            [title]="chartOptionsCRUXCompetitorFCP.title"></apx-chart>
          </div>
          <div class="row">
            <apx-chart  [colors]="colors" [series]="chartOptionsCRUXCompetitorLCP.series" [chart]="chartOptionsCRUXCompetitorLCP.chart"
              [xaxis]="chartOptionsCRUXCompetitorLCP.xaxis" [stroke]="chartOptionsCRUXCompetitorLCP.stroke"
              [tooltip]="chartOptionsCRUXCompetitorLCP.tooltip" [dataLabels]="chartOptionsCRUXCompetitorLCP.dataLabels"
              [legend]="chartOptionsCRUXCompetitorLCP.legend" [markers]="chartOptionsCRUXCompetitorLCP.markers"
              [grid]="chartOptionsCRUXCompetitorLCP.grid" [yaxis]="chartOptionsCRUXCompetitorLCP.yaxis"
            [title]="chartOptionsCRUXCompetitorLCP.title"></apx-chart>
          </div>
          <div class="row">
            <apx-chart  [colors]="colors" [series]="chartOptionsCRUXCompetitorInp.series" [chart]="chartOptionsCRUXCompetitorInp.chart"
              [xaxis]="chartOptionsCRUXCompetitorInp.xaxis" [stroke]="chartOptionsCRUXCompetitorInp.stroke"
              [tooltip]="chartOptionsCRUXCompetitorInp.tooltip" [dataLabels]="chartOptionsCRUXCompetitorInp.dataLabels"
              [legend]="chartOptionsCRUXCompetitorInp.legend" [markers]="chartOptionsCRUXCompetitorInp.markers"
              [grid]="chartOptionsCRUXCompetitorInp.grid" [yaxis]="chartOptionsCRUXCompetitorInp.yaxis"
            [title]="chartOptionsCRUXCompetitorInp.title"></apx-chart>
          </div>
          <div class="row">
            <apx-chart  [colors]="colors" [series]="chartOptionsCRUXCompetitorCLS.series" [chart]="chartOptionsCRUXCompetitorCLS.chart"
              [xaxis]="chartOptionsCRUXCompetitorCLS.xaxis" [stroke]="chartOptionsCRUXCompetitorCLS.stroke"
              [tooltip]="chartOptionsCRUXCompetitorCLS.tooltip" [dataLabels]="chartOptionsCRUXCompetitorCLS.dataLabels"
              [legend]="chartOptionsCRUXCompetitorCLS.legend" [markers]="chartOptionsCRUXCompetitorCLS.markers"
              [grid]="chartOptionsCRUXCompetitorCLS.grid" [yaxis]="chartOptionsCRUXCompetitorCLS.yaxis"
              [title]="chartOptionsCRUXCompetitorCLS.title"></apx-chart>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
}
</div>

</div>
