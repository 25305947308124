
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ttfp',
    templateUrl: './ttfp.component.html',
    styleUrls: ['./ttfp.component.scss'],
    standalone: false
})
export class TtfpComponent implements OnInit {

  metric : string = 'ttfp'
  constructor() {}
  ngOnInit(): void {}

}

