
      <div class="d-flex justify-content-center align-items-center">
    
    </div>


<mat-dialog-content>
  <div class="row" style="padding: 2%">
    <apx-chart
      [series]="chartOptions1.series"
      [chart]="chartOptions1.chart"
      [xaxis]="chartOptions1.xaxis"
      [stroke]="chartOptions1.stroke"
      [tooltip]="chartOptions1.tooltip"
      [dataLabels]="chartOptions1.dataLabels"
      [legend]="chartOptions1.legend"
      [markers]="chartOptions1.markers"
      [grid]="chartOptions1.grid"
      [yaxis]="chartOptions1.yaxis"
      [annotations]="chartOptions1.annotations"
      [title]="chartOptions1.title"
    ></apx-chart>
    <div *ngIf="isUXRovertime" style="text-align: center;">
      <b style="font-size: 6em; color: #15cd6a">-</b> Good
      <b style="font-size: 6em; color: #fba400">-</b> Needs Improvement
      <b style="font-size: 6em; color: #fa4f44;">-</b> Poor
    </div>
  </div>
  <div class="row" style="padding: 2%">
    <apx-chart
      [series]="chartOptions2.series"
      [chart]="chartOptions2.chart"
      [xaxis]="chartOptions2.xaxis"
      [stroke]="chartOptions2.stroke"
      [tooltip]="chartOptions2.tooltip"
      [dataLabels]="chartOptions2.dataLabels"
      [legend]="chartOptions2.legend"
      [markers]="chartOptions2.markers"
      [grid]="chartOptions2.grid"
      [yaxis]="chartOptions2.yaxis"
      [annotations]="chartOptions2.annotations"
      [title]="chartOptions2.title"
    ></apx-chart>
    <div *ngIf="isWVovertime" style="text-align: center;">
      <b style="font-size: 6em; color: #15cd6a">-</b> Good
      <b style="font-size: 6em; color: #fba400">-</b> Needs Improvement
      <b style="font-size: 6em; color: #fa4f44;">-</b> Poor
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button  type="button" class="btn btn-primary" mat-dialog-close tabindex="-1">
    {{ cancelButtonText }}
  </button>
</mat-dialog-actions>