import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InternalCompareService {
  private page1Data: object | null = null;
  private page2Data: object | null = null;

  constructor() { }

  setPagesData(page1: object, page2: object): void {
    this.page1Data = page1;
    this.page2Data = page2;
  }

  getPagesData(): { page1: object | null, page2: object | null } {
    return {
      page1: this.page1Data,
      page2: this.page2Data
    };
  }
}
