<app-help-widget
  [currentURL]="currentURL"
  [userId]="userId"
  [projectID]="projectID"
  [Url]="Url"
>
</app-help-widget>

<div class="insights-container">
  <ng-container *ngIf="insights$ | async as insights">
    <div class="page-header">
      
      <button mat-stroked-button class="export-button" (click)="printToPdf()">
        <mat-icon>picture_as_pdf</mat-icon>
        Export as PDF
      </button>
    </div>

    <!-- Period Context -->
    <div class="period-context">
      <mat-icon>calendar_today</mat-icon>
      <span>Data from {{ formatPeriod(insights.metadata?.yyyymm) }}</span>
    </div>

    <!-- UXR Scores Section -->
    <div class="scores-overview">
      <div class="score-card desktop">
        <h3>Desktop UXR Score</h3>
        <div class="score-container">
          <div class="score">{{ insights.initialObservation.desktop.uxrScore }}</div>
          <a [routerLink]="['../overview']" class="trend-link">
            <mat-icon>show_chart</mat-icon>
          </a>
        </div>
        <p>{{ insights.initialObservation.desktop.comment }}</p>
      </div>

      <div class="score-card mobile">
        <h3>Mobile UXR Score</h3>
        <div class="score-container">
          <div class="score">{{ insights.initialObservation.mobile.uxrScore }}</div>
          <a [routerLink]="['../overview']" class="trend-link">
            <mat-icon>show_chart</mat-icon>
          </a>
        </div>
        <p>{{ insights.initialObservation.mobile.comment }}</p>
      </div>
    </div>

    <!-- Metrics Needing Improvement Section -->
    <div class="metrics-overview">
      <h2>Metrics priorities</h2>
      <div class="metrics-grid">
        <div class="metrics-card desktop">
          <h3>Desktop Metrics</h3>
          <div class="metrics-status">
            <div *ngFor="let metric of getMetricsArray(insights.metricsNeedingImprovement.desktop)" 
                 class="metric-item"
                 [class.needs-improvement]="metric.value">
              <span class="metric-name">{{ metric.name }}</span>
              <div class="metric-actions">
                <a [routerLink]="['../', metric.name.toLowerCase()]" class="trend-link">
                  <mat-icon>show_chart</mat-icon>
                </a>
                <mat-icon>{{ metric.value ? 'warning' : 'check_circle' }}</mat-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="metrics-card mobile">
          <h3>Mobile Metrics</h3>
          <div class="metrics-status">
            <div *ngFor="let metric of getMetricsArray(insights.metricsNeedingImprovement.mobile)" 
                 class="metric-item"
                 [class.needs-improvement]="metric.value">
              <span class="metric-name">{{ metric.name }}</span>
              <div class="metric-actions">
                <a [routerLink]="['../', metric.name.toLowerCase()]" class="trend-link">
                  <mat-icon>show_chart</mat-icon>
                </a>
                <mat-icon>{{ metric.value ? 'warning' : 'check_circle' }}</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Trends Section -->
    <div class="trends-section">
      <div class="section-header">
        <h2>Trends</h2>
        <span class="period-badge">
          {{ getComparisonPeriod(insights) }}
        </span>
      </div>
      <div class="trends-container">
        <div class="trends-platform">
          <h3>Desktop Trends</h3>
          <div class="trends-grid">
            <div *ngFor="let metric of getMetricTrends(insights.trends) | slice:0:4" class="trend-card">
              <h4>{{ metric.name.split(' ')[0] }}</h4>
              <div class="trend-info" [class]="metric.direction">
                <mat-icon>
                  {{ 
                    metric.direction === 'improvement' ? 'trending_up' : 
                    metric.direction === 'degradation' ? 'trending_down' : 
                    'trending_flat'
                  }}
                </mat-icon>
                <span>{{ metric.changePercentage | number:'1.0-1' }}%</span>
              </div>
            </div>
          </div>
        </div>

        <div class="trends-platform">
          <h3>Mobile Trends</h3>
          <div class="trends-grid">
            <div *ngFor="let metric of getMetricTrends(insights.trends) | slice:4:8" class="trend-card">
              <h4>{{ metric.name.split(' ')[0] }}</h4>
              <div class="trend-info" [class]="metric.direction">
                <mat-icon>
                  {{ 
                    metric.direction === 'improvement' ? 'trending_up' : 
                    metric.direction === 'degradation' ? 'trending_down' : 
                    'trending_flat'
                  }}
                </mat-icon>
                <span>{{ metric.changePercentage | number:'1.0-1' }}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Device Performance Gap -->
    <div class="gaps-section">
      <h2>Device Performance Gap</h2>
      <div class="gaps-container">
        <div *ngFor="let gap of getGapMetrics(insights.gapsBetweenMobileAndDesktop)" class="gap-item">
          <div class="gap-header">
            <span class="metric-name">{{ gap.name }}</span>
            <span class="gap-value" [class.significant]="gap.gap > 0.1">
              {{ gap.gap | number:'1.0-3' }}{{ getMetricUnit(gap.name) }}
            </span>
          </div>
          <div class="gap-bar-container">
            <div class="gap-bar" [style.width.%]="getGapPercentage(gap)"></div>
          </div>
          <p class="gap-insight">{{ gap.insight }}</p>
        </div>
      </div>
    </div>

    <!-- Recommendations Section -->
    <div class="recommendations">
      <div class="section-header">
        <h2>
          <mat-icon>rocket_launch</mat-icon>
          Performance Optimization Plan
        </h2>
        <span class="subtitle">Actionable steps to boost your web performance</span>
      </div>

      <!-- Metrics Filter -->
      <div class="metrics-filter">
        <span class="filter-label">Show recommendations relevant to:</span>
        <div class="filter-buttons">
          <button mat-stroked-button
                  *ngFor="let metric of metricOrder"
                  [class.active]="selectedMetric === metric"
                  (click)="filterByMetric(metric)">
            {{ metric }}
            <span class="count" *ngIf="getRecommendationCount(insights.recommendations, metric)">
              ({{ getRecommendationCount(insights.recommendations, metric) }})
            </span>
          </button>
          <button mat-stroked-button
                  [class.active]="!selectedMetric"
                  (click)="filterByMetric(null)">
            All Metrics
          </button>
        </div>
      </div>
      
      <div class="recommendation-list">
        <div *ngFor="let rec of getFilteredRecommendations(insights.recommendations)" 
             class="recommendation-item" 
             [class.high-priority]="rec.priority === 3"
             [class.medium-priority]="rec.priority === 2"
             [class.low-priority]="rec.priority === 1">
          <div class="header">
            <span class="priority-badge">P{{ rec.priority }}</span>
            <h3>{{ rec.action }}</h3>
          </div>
          <div class="details">
            <div class="target-metric">
              <strong>Target metric:</strong>
              <span class="metric-badge">{{ rec.target }}</span>
            </div>
            <div class="impact">
              <strong>Expected gains:</strong> 
              <span>{{ rec.expectedImpact.description }}</span>
            </div>
            <div class="platforms">
              <strong>Affects:</strong>
              <span *ngFor="let platform of rec.platforms" class="platform-badge">
                {{ platform }}
              </span>
            </div>
            <div class="complexity">
              <strong>Effort level:</strong>
              <span class="complexity-badge" [class.low]="rec.implementationComplexity === 'easy'"
                                          [class.high]="rec.implementationComplexity === 'complex'">
                {{ rec.implementationComplexity }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="loading-state">
      <mat-spinner diameter="40"></mat-spinner>
      <p>Loading insights...</p>
    </div>
  </ng-template>
</div>