import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/services/common/user/user.service';
import { nanoid } from 'nanoid';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { Plan, WebsiteAnalyze } from 'src/app/shared/models/user.models';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from 'src/app/shared/helper/helper.service';
import { ValidationService, SiteData } from '../../../services/validation.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CreateWorkflowDialogComponent } from '../create-workflow-dialog/create-workflow-dialog.component';
import { NavigationWorkflowService } from 'src/app/services/navigation-workflow.service';
import { Router } from '@angular/router';
import { catchError, forkJoin, of } from 'rxjs';

@Component({
    selector: 'app-my-account-page',
    templateUrl: './my-account-page.component.html',
    styleUrls: ['./my-account-page.component.scss'],
    standalone: false
})
export class MyAccountPageComponent implements OnInit, OnDestroy {
  revealApiKey: boolean = false;
  userData: any;
  plan: Plan;
  editable: boolean;
  PlanTitle: string = '';
  website: WebsiteAnalyze[] = [];
  testView = false;
  resp: any;
  upgrade: boolean;
  websitesRestantes = 0;
  isfree: boolean;
  device: string;
  locationAllow: string = 'ok';
  free: boolean;
  form: UntypedFormGroup;
  domainthreshold = new UntypedFormControl();
  pagethreshold = new UntypedFormControl();
  private userId: string;
  Scaffolding: object;
  designationform: any;
  formBuilder: any;
  pageLevelThreshold: number;
  domainLevelThreshold: number;
  isConnected: boolean;
  teamName: string;
  slackIntegrationId: string;
  channelName: string;
  diffThreshold: number;
  slackAlertPeriod: string;
  alertId: string;
  randomState: string;
  remainingInternalPages = 0;
  rumSettings = { samplingRate: 100 };
  createPasswordForm: UntypedFormGroup = new UntypedFormGroup(
    {
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30),
      ]),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30),
      ]),
    },
    { validators: this.checkPasswords }
  );

  updatePasswordForm: UntypedFormGroup = new UntypedFormGroup({
    currentPassword: new UntypedFormControl('', [Validators.required]),
    newPassword: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(30),
    ]),
  });
  isRUM: boolean;
  messageFromParent: string = `To take advantage of the RUM, <a class="alert-prompt-link" href="/buyPlan">Upgrade now!</a>`;
  messageForNoData: string = `You do not have access to the Slack integration as no active subscription was found. <a class="alert-prompt-link" href="/buyPlan" >Upgrade now!</a>`;
  escapedSnippet: any;
  isAsync: boolean = false;
  isDefer: boolean = false;
  scriptSnippet: string = "";
  clientSideSamplingRate: number = 100;
  clientSideScriptSnippet: string = '';
  isClientAsync: boolean = false;
  isClientDefer: boolean = false;
  showCheckList1: boolean = false;
  showCheckList2: boolean = false;
  siteData1: SiteData;
  siteData2: SiteData;

  private validationSubscription1: Subscription;
  private validationSubscription2: Subscription;
  private siteDataSubscription1: Subscription;
  private siteDataSubscription2: Subscription;

  userJourneys: any[] = [];
  loadingWorkflows = false;
  selectedTabIndex = 0; // Default tab

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    private snackBar: MatSnackBar,
    private helperService: HelperService,
    private validationService: ValidationService,
    private workflowService: NavigationWorkflowService,
    private router: Router
  ) {
    this.updateScriptSnippet();
  }

  createForm() {
    this.form = new UntypedFormGroup({
      page: new UntypedFormControl(),
      domain: new UntypedFormControl(),
    });
  }

  async ngOnInit(): Promise<void> {
    // Écouter la validation de la première checklist
    this.validationSubscription1 = this.validationService.isValidated$('listwebsite').subscribe(
      (status) => {
        this.showCheckList1 = status;
      }
    );

    this.siteDataSubscription1 = this.validationService.siteData$('listwebsite').subscribe(
      (data) => {
        this.siteData1 = data;
      }
    );

    // Écouter la validation de la deuxième checklist
    this.validationSubscription2 = this.validationService.isValidated$('listwebsite-first-party-data').subscribe(
      (status) => {
        this.showCheckList2 = status;
      }
    );

    this.siteDataSubscription2 = this.validationService.siteData$('listwebsite-first-party-data').subscribe(
      (data) => {
        this.siteData2 = data;
      }
    );
    this.userId = localStorage.getItem('userId');
    const userId = localStorage.getItem('userId');
    this.userData = await this.userService.getUserDetail(userId).toPromise();
    this.scriptSnippet = "<script src=\"https://cdn.speetals.com/rumagent/sp-rum-v2.1.js?apiKey=" + this.userData?.apiKey + "\"></script>";
    this.rumSettings = this.userData.rumSettings || this.rumSettings;
    this.plan = await this.userService.getPlan(this.userId).toPromise();
    if (this.plan.name.toLowerCase() === 'free') {
      this.free = true;
    }
    if(this.plan.details.realUserMonitoring.value){
      this.isRUM = true
    }else{
      this.isRUM = false
    }
    const userScaffolding = await this.userService
      .getScaffolding(this.userId)
      .toPromise();
    const scaffolding = userScaffolding?.[0];

    if (scaffolding) {
      this.pageLevelThreshold = scaffolding?.pageLevelThreshold;
      this.domainLevelThreshold = scaffolding?.domainLevelThreshold;
      this.alertId = scaffolding.id;
    }

    this.activatedRoute.queryParams.subscribe(async (d) => {
      const slackCode = d.code;
      const stateCode = d.state;
      const randFromLocalstorage = localStorage.getItem(
        ConstantService.localStorageKeys.slackStateCode
      );
      if (!d.hasOwnProperty('code') && !d.hasOwnProperty('state')) {
        this.randomState = nanoid(5);
        localStorage.setItem(
          ConstantService.localStorageKeys.slackStateCode,
          this.randomState
        );
        try {
          const userIntegration = await this.userService
            .getSlackIntegration(userId)
            .toPromise();
          if (!userIntegration.team_name) throw '';
          this.teamName = userIntegration.team_name;
          this.slackIntegrationId = userIntegration.id;
          this.channelName = userIntegration.channel_name;
          this.diffThreshold = userIntegration.diffThreshold;
          this.slackAlertPeriod = userIntegration.slackAlertPeriod;
          this.isConnected = true;
        } catch (error) {
          this.isConnected = false;
        }
      } else if (
        !!slackCode &&
        !!stateCode &&
        stateCode === randFromLocalstorage
      ) {
        try {
          const integrateSlackResponse = await this.userService
            .integrateSlack({
              userId,
              slackCode,
            })
            .toPromise();
          this.teamName = integrateSlackResponse.team_name;
          this.slackIntegrationId = integrateSlackResponse.id;
          this.channelName = integrateSlackResponse.channel_name;
          this.isConnected = true;
          localStorage.removeItem(
            ConstantService.localStorageKeys.slackStateCode
          );
        } catch (error) {
          this.isConnected = false;
        }
      }
    });

    // Read the tab query parameter
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['tab'] !== undefined) {
        this.selectedTabIndex = +params['tab'];
      }
    });

    try {
      this.testView = true;
      this.PlanTitle = this.plan.name || 'FREE';
      this.isfree = this.PlanTitle.toLowerCase() === 'free' ? true : false;
      this.locationAllow = this.plan.details.locationSelectionAllowed.value
        ? 'location supported'
        : 'location not supported';
      if (this.plan.details.internalPagesOnly) {
        this.resp = await this.websiteAnalyzeService
          .getInternalPagesOnlyList(this.userId, this.device)
          .toPromise();
        let urlSpeedData = this.pageSpeedInsigthService.getUrlSpeedData1(
          this.resp,
          this.device
        );
        const datasL = [];
        urlSpeedData.map((ele: any) => {
          datasL.push({
            ...this.pageSpeedInsigthService.getSpeedData(ele),
            ttfbDiff: ele.ttfbDiff,
            lcpDiff: ele.lcpDiff,
            fidDiff: ele.fidDiff,
            clsDiff: ele.clsDiff,
            inpDiff: ele.inpDiff,
            projectId: ele.projectId,
          });
        });
        this.remainingInternalPages =
          Number(this.plan.details.nbInternalPage.value) - datasL.length;
      } else {
        this.website = await this.websiteAnalyzeService
          .getWebsiteAnalyze(this.userId)
          .toPromise();
        this.website.map((value) => {
          this.editable = value.editable;
        });
        if (this.website.length > Number(this.plan.details.nbWebsite.value)) {
          this.website.length = Number(this.plan.details.nbWebsite.value);
        }
        this.websitesRestantes =
          Number(this.plan.details.nbWebsite.value) -
          Number(this.website.length);

        this.upgrade =
          (this.isfree || this.websitesRestantes <= 0) &&
          this.plan.name.toLowerCase() != 'Agency';
      }
    } catch {}
    this.updateClientSideSnippet();
    this.loadUserJourneys();
  }

  async confirmDisconect() {
    const confirmAction = confirm(
      'Are you sure you want to disconnect your Slack workspace?'
    );
    if (confirmAction) {
      try {
        await this.userService
          .disconnectSlackIntegration(this.slackIntegrationId)
          .toPromise();
        this.isConnected = false;
      } catch (error) {}
    } else {
    }
  }
  toggleReveal(): void {
    this.revealApiKey = !this.revealApiKey;
  }

  async generateApiKey(): Promise<void> {
    this.userData = await this.userService
      .generateApiKey(localStorage.getItem('userId'))
      .toPromise();
  }

  onSubmit() {
    if (!this.alertId && this.pageLevelThreshold && this.domainLevelThreshold) {
      this.userService
        .postAlert({
          userId: this.userId,
          pageLevelThreshold: this.pageLevelThreshold,
          domainLevelThreshold: this.domainLevelThreshold,
        })
        .subscribe(
          (data) => {
            this.alertId = data.id;
          },
          (error) => {
            console.log(error);
          },
          () => {}
        );
    } else {
      this.userService
        .updateScaffolding(this.alertId, {
          pageLevelThreshold: this.pageLevelThreshold,
          domainLevelThreshold: this.domainLevelThreshold,
        })
        .subscribe(
          (data) => {
            console.log('data update', JSON.stringify(data));
          },
          (error) => {
            console.log(error);
          },
          () => {}
        );
    }
  }

  async updateRumSettings() {
    try {
      if (
        this.rumSettings.samplingRate < 1 ||
        this.rumSettings.samplingRate > 100
      ) {
        this.snackBar.open('RUM sampling rate not updated!', 'close', {
          duration: 1000,
        });
      } else {
        await this.userService
          .updateRumSettings(this.userId, this.rumSettings)
          .toPromise();
        this.snackBar.open('RUM sampling rate updated!', 'close', {
          duration: 1000,
        });
      }
    } catch (error) {}
  }

  async saveSlackDiffAndPeriod() {
    if (
      !this.slackAlertPeriod ||
      (!this.diffThreshold && this.diffThreshold !== 0)
    )
      return;
    await this.userService
      .updateSlackIntegration(this.slackIntegrationId, {
        diffThreshold: this.diffThreshold,
        slackAlertPeriod: this.slackAlertPeriod,
      })
      .toPromise();
    alert('Integration for Slack saved!');
  }
  getClientRandomId() {
    return `https://slack.com/oauth/v2/authorize?scope=incoming-webhook&user_scope=&client_id=2117902035025.3692296941319&state=${this.randomState}`;
  }
  copyCodeToClipboard() {
    const code = this.userData?.apiKey;
    const textarea = document.createElement('textarea');
    textarea.value = code;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.snackBar.open('API Key copied! : ' + code, 'close', {
      duration: 3000,
    });
  }
  updateScriptSnippet() {

    this.scriptSnippet = `<script src="https://cdn.speetals.com/rumagent/sp-rum-v2.1.js?apiKey=${this.userData?.apiKey}"`;


    if (this.isAsync) {
      this.scriptSnippet += ' async';
    }
    if (this.isDefer) {
      this.scriptSnippet += ' defer';
    }

    this.scriptSnippet += '></script>';
  }

  copyRumSnippet() {
    const code = this.scriptSnippet
    const textarea = document.createElement('textarea');
    textarea.value = code;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.snackBar.open('RUM snippet copied! : ' + code, 'close', {
      duration: 3000,
    });
}


  async createPassword() {
    try {
      if (this.createPasswordForm.invalid) return;
      await this.websiteAnalyzeService
        .passwordUpdate(this.userId, this.createPasswordForm.value.password)
        .toPromise();

      this.helperService.createSnackBar(
        'Password credential created successfully!'
      );
    } catch (error) {}
  }

  async updatePassword() {
    try {
      if (this.updatePasswordForm.invalid) return;
      await this.websiteAnalyzeService
        .passwordUpdate(
          this.userId,
          this.updatePasswordForm.value.newPassword,
          this.updatePasswordForm.value.currentPassword
        )
        .toPromise();

      this.helperService.createSnackBar('Password updated successfully');
    } catch (error) {
      this.helperService.createSnackBar(error.error.error.message);
    }
  }

  checkPasswords(group: UntypedFormGroup): any {
    const password = group.controls.password.value;
    const confirmpassword = group.controls.confirmPassword.value;
    return password == confirmpassword
      ? null
      : group.controls.confirmPassword.setErrors({ notSame: true });
  }
  getPageVisitPercentage() {
    return (
      (this.userData.userPageViews / this.plan.details?.rumMonthlyPV.value) *
      100
    ).toFixed(2);
  }

  updateClientSideSnippet() {
    const asyncAttr = this.isClientAsync ? ' async' : '';
    const deferAttr = this.isClientDefer ? ' defer' : '';
    const baseUrl = 'https://cdn.speetals.com/rumagent/sp-rum-v2.1.js'; // Replace with your actual script URL
    this.clientSideScriptSnippet =
      `<script src="${baseUrl}?apiKey=${this.userData?.apiKey}&sampling=${this.clientSideSamplingRate}"${asyncAttr}${deferAttr}></script>`;
  }

  copyClientSideSnippet() {
    navigator.clipboard.writeText(this.clientSideScriptSnippet);
    // Show success notification
    this.snackBar.open('Snippet copied to clipboard!', 'Close', {
      duration: 3000,
    });
  }

  getRenewalDate(user: any): string {
    const today = new Date();

    if (user.isTrialActive && user.trialEndDate) {
      const expireDate = new Date(user.trialEndDate);
      return expireDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    }
    if (user.lastPaymentDate) {
      const lastPaymentDate = new Date(user.lastPaymentDate);


      // Get next monthly anniversary date
      let nextRenewal = new Date(today.getFullYear(), today.getMonth(), lastPaymentDate.getDate());

      // If we've already passed this month's date, move to next month
      if (nextRenewal <= today) {
        nextRenewal.setMonth(nextRenewal.getMonth() + 1);
      }

      // Format date as DD/MM/YYYY
      return nextRenewal.toLocaleDateString('en-GB');
    } else {
      return 'No payment date recorded. Please make a payment.';
    }
  }

  openCreateWorkflowDialog(): void {
    const dialogRef = this.dialog.open(CreateWorkflowDialogComponent, {
      width: '900px',
      data: { userId: this.userId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.created) {
        console.log('New workflow created:', result.workflow);
        this.loadUserJourneys();
      }
    });
  }

  loadUserJourneys(): void {
    this.loadingWorkflows = true;
    
    // Get all projects for this user first
    this.websiteAnalyzeService.getWebsiteAnalyze(this.userId).subscribe({
      next: (websites) => {
        // Create an array to hold all workflow requests
        const workflowRequests = websites.map(website => 
          this.workflowService.getNavigationWorkflows(website.id).pipe(
            catchError(err => {
              console.error(`Error loading workflows for project ${website.id}:`, err);
              return of([]); // Return empty array on error
            })
          )
        );
        
        // Combine all workflow requests
        forkJoin(workflowRequests).subscribe({
          next: (results) => {
            // Flatten the array of arrays into a single array of workflows
            this.userJourneys = [].concat(...results);
            this.loadingWorkflows = false;
          },
          error: (err) => {
            console.error('Error loading user journeys:', err);
            this.loadingWorkflows = false;
          }
        });
      },
      error: (err) => {
        console.error('Error loading websites:', err);
        this.loadingWorkflows = false;
      }
    });
  }

  extractPath(url: string): string {
    try {
      const urlObj = new URL(url);
      return urlObj.pathname;
    } catch (e) {
      return url;
    }
  }

  viewJourney(journey: any): void {
    // Navigate to the appropriate workflow view based on type
    if (journey.type === 'CRUX') {
      this.router.navigate(['/home/page', journey.projectId, 'crux-workflow']);
    } else if (journey.type === 'RUM') {
      this.router.navigate(['/home/rum', journey.projectId, 'rum-workflow']);
    }
  }

  deleteJourney(journey: any): void {
    // Simple confirmation without a dialog component
    const confirmed = window.confirm(`Are you sure you want to delete "${journey.name}"?`);
    
    if (confirmed) {
      this.workflowService.deleteNavigationWorkflow(journey.id).subscribe({
        next: () => {
          this.loadUserJourneys();
        },
        error: (err) => {
          console.error('Error deleting journey:', err);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.validationSubscription1) {
      this.validationSubscription1.unsubscribe();
    }
    if (this.validationSubscription2) {
      this.validationSubscription2.unsubscribe();
    }
    if (this.siteDataSubscription1) {
      this.siteDataSubscription1.unsubscribe();
    }
    if (this.siteDataSubscription2) {
      this.siteDataSubscription2.unsubscribe();
    }
  }
}
