import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';


export function mailExistValidator(listMail): ValidatorFn {
  return(control:AbstractControl) : ValidationErrors | null => {
    const value = control.value

    if (!value)
      return null;
    const mailExist = !listMail.includes(value)
    return mailExist  ? null : { mailExistErrors : true }
    }
}
