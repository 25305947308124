import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InsightsService } from '../../../services/insights.service';
import { WebPerfInsights } from '../../../shared/models/webperf-insights.model';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { PlatformGap } from '../../../shared/models/webperf-insights.model';

@Component({
    selector: 'app-insights-viewer',
    templateUrl: './insights-viewer.component.html',
    styleUrls: ['./insights-viewer.component.scss'],
    standalone: false
})
export class InsightsViewerComponent implements OnInit {
  insights$!: Observable<WebPerfInsights>;
  readonly metricOrder = ['TTFB', 'LCP', 'CLS', 'INP'];
  selectedMetric: string | null = null;

  // Variables pour le help-widget
  currentURL: string = this.router.url;
  userId: string = localStorage.getItem(ConstantService.localStorageKeys.userId) || '';
  projectID: string = this.route.parent?.snapshot.paramMap.get('id') || '';
  Url: string = '';

  constructor(
    private route: ActivatedRoute,
    private insightsService: InsightsService,
    private router: Router,
  ) {
    // Récupérer le projectID depuis la route
    this.projectID = this.route.parent?.snapshot.paramMap.get('id') || '';
  
}
  

  printToPdf(): void {
    window.print();
  }

  ngOnInit() {
    const projectId = this.route.parent?.snapshot.paramMap.get('id');
    if (projectId) {
      this.insights$ = this.insightsService.getLatestInsights(projectId);
      this.insights$.subscribe(insights => {
        this.Url = insights.metadata.originDomain;
      });
    }
  }

  formatPeriod(yyyymm: string | undefined): string {
    if (!yyyymm) return 'N/A';
    try {
      // Le format est déjà "YYYY-MM"
      return formatDate(new Date(yyyymm), 'MMMM yyyy', 'en-US');
    } catch (error) {
      console.error('Error formatting date:', yyyymm, error);
      return 'Invalid date';
    }
  }

  getComparisonPeriod(insights: any): string {
    if (!insights?.metadata?.yyyymm) return 'No comparison data available';
    try {
      const currentDate = new Date(insights.metadata.yyyymm);
      const previousDate = new Date(insights.metadata.yyyymm);
      previousDate.setMonth(previousDate.getMonth() - 1);
      
      const current = formatDate(currentDate, 'MMMM yyyy', 'en-US');
      const previous = formatDate(previousDate, 'MMMM yyyy', 'en-US');
      return `${current} vs ${previous}`;
    } catch (error) {
      console.error('Error formatting comparison period:', error);
      return 'Invalid comparison period';
    }
  }

  getMetricsArray(metrics: { [key: string]: boolean }) {
    return Object.entries(metrics).map(([name, value]) => ({ name, value }));
  }

  getMetricTrends(trends: any) {
    const desktopMetrics = this.getOrderedMetrics(trends.desktop, 'Desktop');
    const mobileMetrics = this.getOrderedMetrics(trends.mobile, 'Mobile');
    return [...desktopMetrics, ...mobileMetrics];
  }

  private getOrderedMetrics(platformTrends: any, platform: string) {
    return this.metricOrder
      .filter(metric => platformTrends[metric])
      .map(metric => ({
        name: `${metric} (${platform})`,
        direction: this.getTrendDirection(platformTrends[metric].changePercentage),
        changePercentage: platformTrends[metric].changePercentage
      }));
  }

  private getTrendDirection(changePercentage: number): 'improvement' | 'degradation' | 'stable' {
    if (Math.abs(changePercentage) < 1) return 'stable';
    if (changePercentage < 0) return 'degradation';
    return 'improvement';
  }

  getGapMetrics(gaps: any) {
    return Object.entries(gaps).map(([name, data]: [string, any]) => ({
      name,
      gap: data.gap,
      desktopValue: data.desktopValue,
      mobileValue: data.mobileValue,
      insight: data.insight
    }));
  }

  filterByMetric(metric: string | null) {
    this.selectedMetric = metric;
  }

  getFilteredRecommendations(recommendations: any[]) {
    if (!this.selectedMetric) {
      return recommendations;
    }
    return recommendations.filter(rec => rec.target === this.selectedMetric);
  }

  getRecommendationCount(recommendations: any[], metric: string): number {
    return recommendations.filter(rec => rec.target === metric).length;
  }

  getMetricUnit(metric: string): string {
    return metric === 'CLS' ? '' : 'ms';
  }
  
  getGapPercentage(gap: any): number {
    console.log('Processing gap:', gap);
    
    const desktopValue = Number(gap.desktopValue);
    const mobileValue = Number(gap.mobileValue);
    
    if (isNaN(desktopValue) || desktopValue === 0) {
      console.log('Invalid desktop value:', desktopValue);
      return 0;
    }
    
    const relativeGap = (Math.abs(mobileValue - desktopValue) / desktopValue) * 100;
    console.log(`Calculated gap percentage: ${relativeGap}% (mobile: ${mobileValue}, desktop: ${desktopValue})`);
    
    return Math.min(relativeGap, 100);
  }
}