import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-lcp',
    templateUrl: './lcp.component.html',
    styleUrls: ['./lcp.component.scss'],
    standalone: false
})
export class LcpComponent implements OnInit {
  metric : string = 'lcp'

  constructor() {}
  ngOnInit(): void {}

}
