import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { InternalCompareService } from 'src/app/services/internal-compare.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { legend } from 'src/app/shared/models/legend';
import { CountryCodes } from '../../../consts/countryCode';
import { DataService } from 'src/app/services/common/sidabar/Data.service';

interface PageData {
  projectId1: string;
  projectId2: string;
  page1: {
    Url: string;
  };
  page2: {
    Url: string;
  };
}
enum matSelectedFields {
  _7days = '_7days',
  _30days = '_30days',
  _60days = '_60days',
  _90days = '_90days',
}
@Component({
    selector: 'app-compare-internal-pages',
    templateUrl: './compare-internal-pages.component.html',
    styleUrl: './compare-internal-pages.component.scss',
    standalone: false
})
export class CompareInternalPagesComponent {
  device: string;
  selectedDiffDate: any;
  data1: any;
  userId: string;
  metrics = ["ttfb", "fcp", "lcp", "cls" ,"inp"];
  data2: any;
  chartOptionsGPN = {};
  title: string;
  p75ChartOpts = {
    lcp: {},
    fid: {},
    fcp: {},
    cls: {},
    inp: {},
    ttfb: {},
  };
  AllConfig= {};
  Good7days= {};
  NeedsImprovement7days= {};
  Poor7days= {};
  _7data= {};
  dataname1: string;
  dataname2: string;
  projectId1: any;
  pagesData: PageData | null = null;
  siteId: string;
  detailUrl: string;
  detailUrlDecoded: string;
  SiteTitle: any;
  country: any;
  loading: boolean = true;


  constructor(
    private route: ActivatedRoute,
    private speetalsDatabaseService: SpeetalsDatabaseService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    private router: Router,
    private dataService: DataService,
  ) {}

  async ngOnInit(): Promise<void> {
    const urlD = this.router.url.split('/');
    this.siteId = urlD[3];
    this.detailUrl = urlD[urlD.length - 1].split('=').pop();
    this.detailUrlDecoded = decodeURIComponent(
      decodeURIComponent(this.detailUrl)
    );
    this.detailUrl = this.detailUrlDecoded;
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    this.pagesData = JSON.parse(localStorage.getItem(ConstantService.localStorageKeys.pagesData));
    this.SiteTitle = localStorage.getItem('sitename')
    this.dataname1 =this.pagesData.page1.Url;
    this.dataname2 = this.pagesData.page2.Url;
    this.device = this.device === "tablet" ? "mobile" : this.device
    this.selectedDiffDate = this.selectedDiffDate || 'most_recent';
    this.country = this.dataService.getSharedData()?.country; 
    this.data1 = await this.speetalsDatabaseService
      .getInternalPageDetail(
        this.userId,
        this.pagesData.projectId1,
        this.dataname1,
        this.device,
        this.route.snapshot.queryParamMap.get('type'),
        this.selectedDiffDate,
      )
      .toPromise();
    this.data2 = await this.speetalsDatabaseService
      .getInternalPageDetail(
        this.userId,
        this.pagesData.projectId2,
        this.dataname2,
        this.device,
        this.route.snapshot.queryParamMap.get('type'),
        this.selectedDiffDate,
      )
      .toPromise();

      const dates7 = this.splitTable(matSelectedFields._7days, this.data1.date);
    for (let metric of this.metrics) {
        this.Good7days[metric] = this.splitTable(
        matSelectedFields._7days,
        this.data1[metric].prop.good,
        this.data2[metric].prop.good,
      );
      this.NeedsImprovement7days[metric] = this.splitTable(
        matSelectedFields._7days,
        this.data1[metric].prop.needImprovement,
        this.data2[metric].prop.needImprovement,
      );
      this.Poor7days[metric] = this.splitTable(
        matSelectedFields._7days,
        this.data1[metric].prop.poor,
        this.data2[metric].prop.poor,
      );
      this._7data[metric] = this.splitTablePages(
        dates7,
        this.Good7days[metric][0],
        this.Good7days[metric][1],
        this.NeedsImprovement7days[metric][0],
        this.NeedsImprovement7days[metric][1],
        this.Poor7days[metric][0],
        this.Poor7days[metric][1],
        legend[metric],
      );
      
      const keys = Object.keys(this._7data[metric]);
      this.AllConfig[metric] = {
        selectedDays: keys[keys.length - 1],
        days: this._7data[metric],
        ...this._7data[metric],
      };
      this.chartOptionsGPN[metric] = this.pageSpeedInsigthService.chartOptionsGPNCompare(
        this.dataname1,
        this.dataname2,
        this.data1[metric].prop.good[this.data1[metric].prop.good.length - 1],
        this.data1[metric].prop.needImprovement[this.data1[metric].prop.needImprovement.length - 1],   
        this.data1[metric].prop.poor[this.data1[metric].prop.poor.length - 1],
        this.data2[metric].prop.good[this.data2[metric].prop.good.length - 1],
        this.data2[metric].prop.needImprovement[this.data2[metric].prop.needImprovement.length - 1],
        this.data2[metric].prop.poor[this.data2[metric].prop.poor.length - 1]
      );
      this.title = this.pageSpeedInsigthService.getIcon(
        this.data1[metric].prop.good[this.data1[metric].prop.good.length - 1],
      );
    }

    ['lcp', 'fid', 'fcp', 'inp', 'ttfb', 'cls'].forEach((metric) => {
      ['_7days', '_30days', '_60days', '_90days'].forEach((days) => {
        this.processP75ChartOption(metric, days);
      });
    });

    this.loading = false;
  }
  processP75ChartOption(metric, selected, type = 'p75') {
    let currentData1;
    let currentData2;
    if (type === 'p75') {
      currentData1 = this.data1?.[metric]?.p75 || [];
      currentData2 = this.data2?.[metric]?.p75 || [];
    }
    const dates = this.data1?.date || [];

    switch (selected) {
      case '_7days':
        const data1_7Days = this.splitTable('_7days', currentData1);
        const data2_7Days = this.splitTable('_7days', currentData2);
        const days7 = this.splitTable('_7days', dates);
        const partionedD7 = this.partitionData(data1_7Days,data2_7Days, days7, type);
        if (type === 'p75') {
          this.p75ChartOpts[metric]['_7days'] = partionedD7;
        }
        break;
      case '_30days':
        const data1_30Days = this.splitTable('_30days', currentData1);
        const data2_30Days = this.splitTable('_30days', currentData2);
        const days30 = this.splitTable('_30days', dates);
        const partitionedD30 = this.partitionData(data1_30Days,data2_30Days, days30, type);
        if (type === 'p75') {
          this.p75ChartOpts[metric]['_30days'] = partitionedD30;
        }
  
        break;
      case '_60days':
        const data1_60Days = this.splitTable('_60days', currentData1);
        const data2_60Days = this.splitTable('_60days', currentData2);
        const days60 = this.splitTable('_60days', dates);
        const partitionedD60 = this.partitionData(data1_60Days,data2_60Days, days60, type);
        if (type === 'p75') {
          this.p75ChartOpts[metric]['_60days'] = partitionedD60;
        }

        break;
      case '_90days':
        const data1_90Days = this.splitTable('_90days', currentData1);
        const data2_90Days = this.splitTable('_90days', currentData2);
        const days90 = this.splitTable('_90days', dates);
        const partitionedD90 = this.partitionData(data1_90Days,data2_90Days, days90, type);
        if (type === 'p75') {
          this.p75ChartOpts[metric]['_90days'] = partitionedD90;
        }

        break;
    }
  }
  splitTable(dateType, ...tabs) {
    const getSubTab = (tab, k) => {
      let lengthTab = tab.length;
      let newTab = [];
      for (let i = k; i < lengthTab; i++) {
        newTab.push(tab[i]);
      }
      return newTab;
    };
  
    let k;
    let lengthTab = tabs[0].length;
  
    switch (dateType) {
      case '_7days':
        if (lengthTab - 7 < 0) return tabs;
        k = lengthTab - 7;
        break;
      case '_30days':
        if (lengthTab - 30 < 0) return tabs;
        k = lengthTab - 30;
        break;
      case '_60days':
        if (lengthTab - 60 < 0) return tabs;
        k = lengthTab - 60;
        break;
      case '_90days':
        if (lengthTab - 90 < 0) return tabs;
        k = lengthTab - 90;
        break;
      default:
    }
  
    return tabs.map(tab => getSubTab(tab, k));
  }
  
  partitionData(data1, data2, dates, type) {
    if (!dates.length) return;
    const rounds = Math.floor(dates[0].length / 15);
    const reminder = dates[0].length % 15;
    const dateGroups = [];
    const dataByRange = {};
   
    if (rounds > 0) {
      for (let i = 0; i < rounds; i++) {
        const currentRange = [15 * i, 15 * (i + 1)];
        const dateGroup = `${dates[0][currentRange[0]]} to ${
          dates[0][currentRange[1] - 1]
        }`;
        dateGroups.push(dateGroup);
        if (type === 'p75') {
          
          dataByRange[dateGroup] =
            this.pageSpeedInsigthService.chartOptionsmetricOverTimeCompare(
              this.dataname1,
              this.dataname2,
              data1[0].slice(currentRange[0], currentRange[1]),
              data2[0].slice(currentRange[0], currentRange[1]),
              dates[0].slice(currentRange[0], currentRange[1]), 
            );
        }

        if (reminder > 0) {
          const startIndex = rounds * 15;
          const endIndex = dates[0].length;
          const dateGroup = `${dates[0][startIndex]} to ${dates[0][endIndex - 1]}`;
          dateGroups.push(dateGroup);
          if (type === 'p75') {
            dataByRange[dateGroup] =
              this.pageSpeedInsigthService.chartOptionsmetricOverTimeCompare(
                this.dataname1,
                this.dataname2,
                data1[0].slice(startIndex, endIndex),
                data2[0].slice(startIndex, endIndex),
                dates[0].slice(startIndex, endIndex),
                
              );
          }
        }
      }
    }
    if (!rounds && !!reminder) {

      const dateGroup = `${dates[0][0]} to ${dates[0][reminder - 1]}`;
      if (type === 'p75') {
        dataByRange[dateGroup] =
          this.pageSpeedInsigthService.chartOptionsmetricOverTimeCompare(
            this.dataname1,
            this.dataname2,
            data1[0].slice(0, reminder),
            data2[0].slice(0, reminder),
            dates[0].slice(0, reminder),
             
          );
      }
    }
    return {
      dateGroups,
      ...dataByRange,
    };
  }
  splitTablePages(
    dates,
    good1,
    good2,
    needsIprovement1,
    needsIprovement2,
    poor1,
    poor2,
    legendData?: { good: string; moderate: string; poor: string },
  ) {
    const configObj: any = {};
    const rounds = Math.floor(good1.length / 15);
    const reminder = good1.length % 15;
    if (rounds > 0) {
      for (let i = 0; i < rounds; i++) {
        const currentRange = [15 * i, 15 * (i + 1)];
        const key = `${dates[0][currentRange[0]]} to ${
          dates[0][currentRange[1] - 1]
        }`;

        configObj[key] = this.pageSpeedInsigthService.chartOptionMonthGPNCompare(
          this.dataname1,
          this.dataname2,
          good1.slice(currentRange[0], currentRange[1]),
          good2.slice(currentRange[0], currentRange[1]),
          poor1.slice(currentRange[0], currentRange[1]),
          poor2.slice(currentRange[0], currentRange[1]),
          needsIprovement1.slice(currentRange[0], currentRange[1]),
          needsIprovement2.slice(currentRange[0], currentRange[1]),
          dates[0].slice(currentRange[0], currentRange[1]),
        );
      }
      if (reminder > 0) {
        const startIndex = rounds * 15;
        const endIndex = good1.length;
        const key = `${dates[startIndex]} to ${dates[endIndex - 1]}`;
        configObj[key] = this.pageSpeedInsigthService.chartOptionMonthGPNCompare(
          this.dataname1,
          this.dataname2,
          good1.slice(startIndex, endIndex),
          good2.slice(startIndex, endIndex),
          poor1.slice(startIndex, endIndex),
          poor2.slice(startIndex, endIndex),
          needsIprovement1.slice(startIndex, endIndex),
          needsIprovement2.slice(startIndex, endIndex),
          dates.slice(startIndex, endIndex),
        );
      }
    }
    if (!rounds && !!reminder) {
      const key = `${dates[0][0]} to ${dates[0][reminder - 1]}`;
      configObj[key] = this.pageSpeedInsigthService.chartOptionMonthGPNCompare(
        this.dataname1,
        this.dataname2,
        good1.slice(0, reminder),
        good2.slice(0, reminder),
        poor1.slice(0, reminder),
        poor2.slice(0, reminder),
        needsIprovement1.slice(0, reminder),
        needsIprovement2.slice(0, reminder),
        dates[0].slice(0, reminder),
      );
    }
    return configObj;
  }
  setDevice($event: string) {
    this.loading = true;
    this.device = $event;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);
    this.ngOnInit();
  }
  getCountryCode(countryName: string): string | undefined {
    return CountryCodes[countryName];
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined || code == 'Global')
      return false;
    return true;
  }
  getPages() {
    return {
      overview: '/home/rum/' + this.siteId + '/overview',
      internal: '/home/rum/' + this.siteId + '/internalpages',
    };
  }
}
  
