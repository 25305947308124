import { Component, OnInit } from '@angular/core';
import { uniq } from 'lodash';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';

@Component({
    selector: 'app-page-view-over-time',
    templateUrl: './page-view-over-time.component.html',
    styleUrl: './page-view-over-time.component.scss',
    standalone: false
})
export class PageViewOverTimeComponent implements OnInit {
  monthlyPvSessionOverTime: any = {};
  dailyPvSessionOverTime: any = {};

  selectedInterval = 'daily';
  selectedPVdatesInterval = '';
  loading = false;
  NoDataMonthly : string = "No monthly data available for the selected period."
  NoDataDaily: string = "No daily data available for the selected period."

  constructor(
    private webAnalyzeService: WebsiteAnalyzeService,
    private pageInsightService: PageSpeedInsigthService
  ) {}

  ngOnInit(): void {
    this.fetchChartData();
  }

  async fetchChartData() {
    try {
      this.loading = true;
      const userId = localStorage.getItem(
        ConstantService.localStorageKeys.userId
      );

      const result: any = await this.webAnalyzeService
        .getPageVisitOvertime(userId, this.selectedInterval)
        .toPromise();

      const { pageVisitsOvertime, sessionsOvertime } = result;

      if (!Array.isArray(pageVisitsOvertime) || !Array.isArray(sessionsOvertime)) {
        throw new Error('Données invalides reçues depuis le serveur.');
      }

      const pagesData = pageVisitsOvertime.map((pv) => pv.pageVisits);
      const pagesDates = pageVisitsOvertime.map((pv) => pv._id);

      const sessionsData = sessionsOvertime.map((pv) => pv.count);
      const sessionsDates = sessionsOvertime.map((pv) => pv._id);

      // Correction du tri des dates
      const combinedDates = uniq([...pagesDates, ...sessionsDates]).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      );

      if (this.selectedInterval === 'daily') {
        try {
          this.dailyPvSessionOverTime = this.partitionData(combinedDates, {
            pagesData,
            pagesDates,
            sessionsData,
            sessionsDates,
          });

          if (this.dailyPvSessionOverTime && this.dailyPvSessionOverTime.dateGroups) {
            this.selectedPVdatesInterval = this.dailyPvSessionOverTime.dateGroups.pop() || '';
          } else {
            this.selectedPVdatesInterval = '';
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (this.selectedInterval === 'monthly') {
        this.monthlyPvSessionOverTime =
          this.pageInsightService.pageViewAndSessionOvertime(
            [
              {
                name: 'Sessions',
                type: 'column',
                data: combinedDates.map((d) => {
                  const indexOfD = sessionsDates.indexOf(d);
                  return indexOfD !== -1 ? sessionsData[indexOfD] : 0;
                }),
              },
              {
                name: 'Page views',
                type: 'line',
                data: combinedDates.map((d) => {
                  const indexOfD = pagesDates.indexOf(d);
                  return indexOfD !== -1 ? pagesData[indexOfD] : 0;
                }),
              },
            ],
            combinedDates
          );
      }
      this.loading = false;
    } catch (error) {
      console.error('Erreur lors de la récupération des données de graphique:', error);
      this.loading = false;
    }
  }

  onOptionChanged(event) {
    this.selectedInterval = event.target.value;
    this.fetchChartData();
  }

  onDateRangeSelected(dateRange, field) {
    this[field] = dateRange;
  }

  partitionData(dates, data) {
    if (!dates.length) {
      return { dateGroups: [], dataByRange: {} };
    }
    try {
      const { sessionsData, sessionsDates, pagesData, pagesDates } = data;
      const rounds = Math.floor(dates.length / 15);
      const reminder = dates.length % 15;
      const dateGroups = [];
      const dataByRange = {};
      let partitionedDates = [];

      if (rounds > 0) {
        for (let i = 0; i < rounds; i++) {
          const currentRange = [15 * i, 15 * (i + 1)];
          const dateGroup = `${dates[currentRange[0]]} to ${dates[currentRange[1] - 1]}`;
          dateGroups.push(dateGroup);

          partitionedDates = dates.slice(currentRange[0], currentRange[1]);

          dataByRange[dateGroup] = this.pageInsightService.pageViewAndSessionOvertime(
            [
              {
                name: 'Sessions',
                type: 'column',
                data: partitionedDates.map((d) => {
                  const indexOf = sessionsDates.indexOf(d);
                  return indexOf !== -1 ? sessionsData[indexOf] : 0;
                }),
              },
              {
                name: 'Page views',
                type: 'line',
                data: partitionedDates.map((d) => {
                  const indexOf = pagesDates.indexOf(d);
                  return indexOf !== -1 ? pagesData[indexOf] : 0;
                }),
              },
            ],
            partitionedDates
          );

          // Ajouter le groupe de rappel seulement une fois
          if (reminder > 0 && i === rounds - 1) {
            const startIndex = rounds * 15;
            const endIndex = dates.length;
            const dateGroup = `${dates[startIndex]} to ${dates[endIndex - 1]}`;
            dateGroups.push(dateGroup);
            partitionedDates = dates.slice(startIndex, endIndex);
            dataByRange[dateGroup] = this.pageInsightService.pageViewAndSessionOvertime(
              [
                {
                  name: 'Sessions',
                  type: 'column',
                  data: partitionedDates.map((d) => {
                    const indexOf = sessionsDates.indexOf(d);
                    return indexOf !== -1 ? sessionsData[indexOf] : 0;
                  }),
                },
                {
                  name: 'Page views',
                  type: 'line',
                  data: partitionedDates.map((d) => {
                    const indexOf = pagesDates.indexOf(d);
                    return indexOf !== -1 ? pagesData[indexOf] : 0;
                  }),
                },
              ],
              partitionedDates
            );
          }
        }
      }
      if (!rounds && !!reminder) {
        const dateGroup = `${dates[0]} to ${dates[reminder - 1]}`;
        dateGroups.push(dateGroup);
        partitionedDates = dates.slice(0, reminder);

        dataByRange[dateGroup] = this.pageInsightService.pageViewAndSessionOvertime(
          [
            {
              name: 'Sessions',
              type: 'column',
              data: partitionedDates.map((d) => {
                const indexOf = sessionsDates.indexOf(d);
                return indexOf !== -1 ? sessionsData[indexOf] : 0;
              }),
            },
            {
              name: 'Page views',
              type: 'line',
              data: partitionedDates.map((d) => {
                const indexOf = pagesDates.indexOf(d);
                return indexOf !== -1 ? pagesData[indexOf] : 0;
              }),
            },
          ],
          partitionedDates
        );
      }
      return {
        dateGroups,
        ...dataByRange,
      };
    } catch (error) {
      console.log(error);
      return { dateGroups: [], dataByRange: {} };
    }
  }

  get hasDailyData(): boolean {
    const data = this.dailyPvSessionOverTime?.[this.selectedPVdatesInterval]?.series;
    if (!data || data.length === 0) return false;

    return data.some(s => s.data && s.data.length > 0);
  }

  get hasMonthlyData(): boolean {
    const data = this.monthlyPvSessionOverTime?.series;
    if (!data || data.length === 0) return false;

    return data.some(s => s.data && s.data.length > 0);
  }
}
