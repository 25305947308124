import { Component, EventEmitter, Output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { RumService } from 'src/app/services/rum.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { CountryCodes } from '../../../../consts/countryCode';


@Component({
    selector: 'app-first-party-data-compare',
    templateUrl: './first-party-data-compare.component.html',
    styleUrl: './first-party-data-compare.component.scss',
    standalone: false
})
export class FirstPartyDataCompareComponent {
  @Output() public deviceEvent = new EventEmitter<string>();
  device: string;
  userId: string;
  pagesData: any;
  dataUrl1: any;
  dataUrl2: any;
  selectedDimension: string;
  selectedDimGroup: string;
  queryPeriod: string;
  sharedMode: boolean;
  selectedCountry: string;
  daysRangeSelected = '7';
  loading: boolean;
  monitoringLocations1: any;
  monitoringLocations2: any;
  apiResponseMessage: string;
  metricsData;
  siteTitle1: any;
  siteTitle2: any;
  dataList1: any;
  dataList2: any;
  attributionData1: any;
  attributionData2: any;
  metrics = ["ttfb", "fcp", "lcp", "cls" ,"inp"];
  overTimeP75Data: {};
  overTimeBarchartData: {};
  deviceTypes = ConstantService.defaultDevices;
  dimensionGroups = ConstantService.defaultDimensionGroup;
  monitoringLocations: any;
  siteId: string;
  detailUrl: string;
  detailUrlDecoded: string;
  SiteTitle: any;
  site1: any;
  site2: any;

  constructor(
    private route: ActivatedRoute,
    private rumService: RumService,
    public webAnalyzeService: WebsiteAnalyzeService,
    private router: Router,
  ) {}


  async ngOnInit() {
    this.pagesData = JSON.parse(localStorage.getItem(ConstantService.localStorageKeys.pagesData));
    this.dataUrl1 =this.pagesData.page1.Url;
    this.dataUrl2 = this.pagesData.page2.Url;
    this.SiteTitle = this.pagesData.siteTitle
    const urlD = this.router.url.split('/');
    this.siteId = urlD[3];
    this.detailUrl = urlD[urlD.length - 1].split('=').pop();
    this.detailUrlDecoded = decodeURIComponent(
      decodeURIComponent(this.detailUrl)
    );
    this.detailUrl = this.detailUrlDecoded;

    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    this.selectedDimension =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimension) || 'all';
    this.selectedDimGroup =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimGroup) || 'all';
    this.queryPeriod =
      localStorage.getItem(ConstantService.loadingUrl.fpdQueryPeriod) ||
      'daily';
    this.sharedMode =
      localStorage.getItem(ConstantService.localStorageKeys.sharedMode) ===
      'on';
    this.selectedCountry =
      localStorage.getItem(
        ConstantService.localStorageKeys.selectedRUMCountry
      ) || 'Global';
    this.fetchMetricsData();
  }
  onCountrySelect(country) {
    this.selectedCountry = country;
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      country
    );
    this.updateTableData();
  }

  setDevice(selectedDevice: string) { 
    this.device = selectedDevice;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);

    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';

    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimension,
      this.selectedDimension
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    this.updateTableData(false);
  }

  async fetchMetricsData() {
    this.loading = true;
    try {
      const { data: result1, locations1 } = await this.webAnalyzeService
        .getRumInternalPageDetail(
          this.pagesData.projectId1,
          this.queryPeriod,
          this.dataUrl1,
          this.daysRangeSelected
        )
        .toPromise();
        const { data: result2, locations2 } = await this.webAnalyzeService
        .getRumInternalPageDetail(
          this.pagesData.projectId2,
          this.queryPeriod,
          this.dataUrl2,
          this.daysRangeSelected
        )
        .toPromise();
        
      this.monitoringLocations1 = locations1;
      this.monitoringLocations2 = locations2;

      if (!result2.length && !result1.length ) {
        this.apiResponseMessage = 'No data for the selected options';
        this.loading = false;
        this.metricsData = null;
        return;
      }


      this.dataList1 = result1;
      this.dataList2 = result2;
      
      this.site1 = this.dataList1[this.dataList1.length - 1].title
      this.site2 = this.dataList2[this.dataList2.length - 1].title
      this.siteTitle1 =  this.site1.substring(0, 15);
      this.siteTitle2 =  this.site2.substring(0, 15);

      this.attributionData1 = result1?.[0]?.attributionData;
      this.attributionData2 = result2?.[0]?.attributionData;

      
      // this.lcpAttributionDatasource.data =
      //   this.attributionData?.[this.device]?.lcp;

      this.updateTableData();
      this.loading = false;
      return;

    } catch (err) {
      console.error(err);
      this.dataList1 = [];
      this.dataList2 = [];
      this.loading = false;
    }
  }
  getPages() {
    return {
      overview: '/home/rum/' + this.siteId + '/overview',
      internal: '/home/rum/' + this.siteId + '/internalpages',
    };
  }

  setQueryPeriod(event: Event) {
    const target = event.target as HTMLInputElement;
    const QueryPeriod = target.value;

    this.deviceEvent.emit(QueryPeriod);
    this.queryPeriod = QueryPeriod;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdQueryPeriod,
      this.queryPeriod
    );

    this.fetchMetricsData();
  }
  setDimension(dimension, dimensionGroup) {
    this.selectedDimension = dimension;
    this.selectedDimGroup = dimensionGroup;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      dimensionGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, dimension);
    this.updateTableData(false);
  }
  clearCurrentFilters() {
    this.selectedCountry = 'Global';
    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      this.selectedCountry
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, 'all');
    this.updateTableData();
  }
  

  updateTableData(isAfterLoading = true) {
    this.metricsData = this.rumService.getRUMInternalPageDetailAllForCompare(
      this.siteTitle1,
      this.siteTitle2,
      this.dataList1[this.dataList1.length - 1] || {},
      this.dataList2[this.dataList2.length - 1] || {},
      this.selectedDimGroup,
      this.selectedDimension,
      this.device,
      this.selectedCountry,
      this.metrics
    );


    // this.lcpAttributionDatasource.data =
    //   this.dataList?.[0]?.attributionData?.[this.device]?.lcp;
    const processedOverTime = this.rumService.getRUMInternalPageDetailOvertimeForCompare(
      this.siteTitle1,
      this.siteTitle2,
      this.dataList1,
      this.dataList2,
      this.selectedDimGroup,
      this.selectedDimension,
      this.device,
      this.selectedCountry,
      this.queryPeriod
    );

    this.overTimeP75Data = processedOverTime.p75OverTime;
    this.overTimeBarchartData = processedOverTime.barchartData;
  //   if (this.queryPeriod === 'daily') {
  //     const partitioned = this.partitionData(this.dataList);
  //     this.allDaysRange = partitioned?.dateGroups || [];
  //     for (const daysRange of this.allDaysRange) {
  //       const partiData = partitioned?.[daysRange] || {};
  //       this.partitionedDataOverTime[daysRange] =
  //         this.rumService.getRUMInternalPageDetailOvertime(
  //           partiData,
  //           this.selectedDimGroup,
  //           this.selectedDimension,
  //           this.device,
  //           this.selectedCountry,
  //           this.queryPeriod
  //         );
  //     }
  //     const daysRange = isAfterLoading
  //       ? this.allDaysRange[this.allDaysRange.length - 1]
  //       : this.selectedDatesRange;
  //     if (isAfterLoading) {
  //       this.selectedDatesRange = daysRange;
  //     }
  //     this.overTimeP75Data = {
  //       ...this.partitionedDataOverTime?.[daysRange]?.p75OverTime,
  //     };
  //     this.overTimeBarchartData = {
  //       ...this.partitionedDataOverTime?.[daysRange]?.barchartData,
  //     };
  //   } else {
  //     const processedOverTime =
  //       this.rumService.getRUMInternalPageDetailOvertime(
  //         this.dataList,
  //         this.selectedDimGroup,
  //         this.selectedDimension,
  //         this.device,
  //         this.selectedCountry,
  //         this.queryPeriod
  //       );

  //     this.overTimeP75Data = processedOverTime.p75OverTime;
  //     this.overTimeBarchartData = processedOverTime.barchartData;
  //   }
   }
   closeMe(menuTrigger: MatMenuTrigger) {
    menuTrigger.closeMenu();
  }
  getCountryCode(countryName: string): string | undefined {
    return CountryCodes[countryName];
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined || code == 'Global')
      return false;
    return true;
  }
}
