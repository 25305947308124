import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { HelperService } from 'src/app/shared/helper/helper.service';

@Component({
    selector: 'app-url-by-segment-settings',
    templateUrl: './url-by-segment-settings.component.html',
    styleUrl: './url-by-segment-settings.component.scss',
    standalone: false
})
export class UrlBySegmentSettingsComponent implements OnInit {
  rumSites: any = [];
  urlBySegmentDatasource: any = [];
  showAddSegmentForm = false;
  segmentFormData: UntypedFormGroup = new UntypedFormGroup({
    segmentName: new UntypedFormControl('', [Validators.required]),
    segmentPath: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(new RegExp('^/.+')),
    ]),
    segmentOwnerSite: new UntypedFormControl('', [Validators.required]),
    execludeRumData: new UntypedFormControl(false, [Validators.required]),
  });
  userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
  displayedColumns: string[] = [
    'segmentName',
    'path',
    'site',
    'rumDataExcluded',
    // 'action',
  ];

  constructor(
    private wsaServices: WebsiteAnalyzeService,
    private helperService: HelperService,
    private router: Router
  ) {}

  async ngOnInit() {
    try {
      this.fetchURLSegmentDataList();
      this.rumSites = await this.wsaServices
        .getRUMEnabledSitesForUser(this.userId)
        .toPromise();
    } catch (error) {}
  }

  async createURLSegment() {
    try {
      if (this.segmentFormData.invalid) return;
      const values = this.segmentFormData.value;
      const segmentOwnerSiteTitle = this?.rumSites?.find(
        (d) => d.id == values.segmentOwnerSite
      )?.title;

      await this.wsaServices
        .postURLSegmentData({
          ...values,
          segmentOwnerSiteTitle,
        })
        .toPromise();
      this.fetchURLSegmentDataList();
      this.helperService.createSnackBar('URL segment created successfully');
      this.showAddSegmentForm = false;
    } catch (error) {}
  }

  async updateSegmentData(event, data) {
    try {
      data.execludeRumData = event.checked;
      await this.wsaServices.postURLSegmentData(data).toPromise();
      this.helperService.createSnackBar('Updated url segment setting');
    } catch (error) {}
  }

  async fetchURLSegmentDataList() {
    try {
      const urlDataSourceResult: any = await this.wsaServices
        .getURLSegmentsList(this.userId)
        .toPromise();
      this.urlBySegmentDatasource = new MatTableDataSource(
        urlDataSourceResult || []
      );
    } catch (error) {}
  }

  goToInternalPagesDetail(element) {
    this.router.navigate(
      [`/home/rum/${element?.segmentOwnerSite}/internalpages/details`],
      {
        queryParams: {
          url: encodeURIComponent(element?.originDomain + element?.segmentPath),
        },
      }
    );
  }

  handleShowAddSegmentForm() {
    this.showAddSegmentForm = !this.showAddSegmentForm;
  }
}
