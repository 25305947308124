import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrl: './success.component.scss',
    standalone: false
})
export class SuccessComponent implements OnInit {
  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    if (document.location.hostname.search("app.speetals.com") !== -1) {
      // Get URL parameters
      const params = new URLSearchParams(window.location.search);
      const amount = parseFloat(params.get('amount') || '0');
      const currency = params.get('currency')?.toUpperCase() || 'USD';

      const script = this.renderer2.createElement('script');
      script.text = `
        gtag('event', 'conversion', {
          'send_to': 'AW-1008372131/_Lx_CL_owpMaEKOT6uAD',
          'value': ${amount},
          'currency': '${currency}',
          'transaction_id': ''
        });
      `;
      this.renderer2.appendChild(this.document.head, script);
    }
  }
}
