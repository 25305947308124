import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounce } from 'lodash';
import { UserService } from 'src/app/services/common/user/user.service';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { SharedWithDialogComponent } from '../shared-with-dialog/shared-with-dialog.component';
import { Subscription } from 'rxjs';

import { CountryCodes } from '../../../consts/countryCode';
import { MatMenuTrigger } from '@angular/material/menu';
import { RumService } from 'src/app/services/rum.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ValidationService, SiteData } from '../../../services/validation.service'

@Component({
    selector: 'app-list-websites-first-party-data',
    templateUrl: './list-websites-first-party-data.component.html',
    styleUrls: ['./list-websites-first-party-data.component.scss'],
    standalone: false
})
export class ListWebsitesFirstPartyDataComponent implements OnInit, OnDestroy {
  @ViewChild('menuContacts') menuContacts;
  @Output() public deviceEvent = new EventEmitter<string>();
  dataSource = new MatTableDataSource([]);
  metrics: string[] = ['lcp', 'cls'];
  displayedColumns: string[] = ['title', 'LCP', 'CLS'];

  device: string = 'desktop';
  queryLevel: string = 'domain';
  queryPeriod: string = 'daily';
  loading: boolean = true;
  userId = localStorage.getItem('userId');

  dataList = [];
  currentComputedData = [];
  apiResponseMessage: string = '';
  deviceTypes = ConstantService.defaultDevices;
  dimensionGroups = ConstantService.defaultDimensionGroup;
  selectedDimension: string = 'all';
  selectedDimGroup: string = 'all';
  modeShared = false;
  showShared = false;
  showAllMetrics = false;
  mailsExist: Array<string> = [];
  form: UntypedFormGroup;
  userHasRum;
  monitoringLocations = [];
  selectedCountry = 'All';
  searchKeyword = '';
  searchDebouncer = debounce(this.fetchMetricsData, 800);
  noSitesCreated = false;
  addedToday = false;

  private toggleSubscription: Subscription;
  isModalOpen: boolean;
  videoUrl: SafeResourceUrl;
  public isCRUX = false;
  hasSharedSites: any;
  SitesCreated: boolean;
  areWeReceivingData: any;
  sharedToThisUser: any;
  noAddedWebSite: String = "No website have been added"
  isValidated: boolean = false;
  private validationSubscription: Subscription;

  checklistId: string = 'listwebsite-first-party-data';



  constructor(
    private websiteAnalizeService: WebsiteAnalyzeService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private rumService: RumService,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private sanitizer: DomSanitizer,
    private validationService: ValidationService
  ) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/mvJePraln4k'
    );
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit() {
    this.validationSubscription = this.validationService.isValidated$(this.checklistId).subscribe(
      (status) => {
        this.isValidated = status;
      }
    );
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.selectedDimension =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimension) || 'all';
    this.selectedDimGroup =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimGroup) || 'all';
    this.queryPeriod =
      localStorage.getItem(ConstantService.loadingUrl.fpdQueryPeriod) ||
      'daily';
    const shMode =
      localStorage.getItem(ConstantService.localStorageKeys.sharedMode) == 'on';
    this.selectedCountry =
      localStorage.getItem(
        ConstantService.localStorageKeys.selectedRUMCountry
      ) || 'Global';
    this.modeShared = shMode;
    this.showShared = shMode;
    
    // Initialize showAllMetrics from localStorage
    const showAllMetricsMode = localStorage.getItem('showAllMetrics') === 'on';
    this.showAllMetrics = showAllMetricsMode;
    
    try {
      const userPlan = await this.userService.getPlan(this.userId).toPromise();
      this.userHasRum = !!userPlan.details?.realUserMonitoring?.value;
      if (!this.userHasRum) {
        this.modeShared = true;
        this.showShared = true;
        localStorage.setItem(ConstantService.localStorageKeys.sharedMode, 'on');
      }
    } catch (error) {}
    this.fetchMetricsData();
  }

  setDevice(selectedDevice: string) {
    this.device = selectedDevice;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);

    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';

    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimension,
      this.selectedDimension
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    this.updateTableData();
  }

  onCountrySelect(country) {
    this.selectedCountry = country;
    this.searchKeyword = '';
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      country
    );
    this.updateTableData();
  }

  onSearchInput(event) {
    this.searchKeyword = event.target.value;
    this.selectedCountry = 'All';
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      'Global'
    );
    this.searchDebouncer();
  }

  updateTableData() {
    this.currentComputedData = this.rumService.getComputedRumSitesList(
      this.dataList,
      this.selectedDimGroup,
      this.selectedDimension,
      this.device,
      this.selectedCountry
    );
  }

  setQueryPeriod(event: Event) {
    const target = event.target as HTMLInputElement;
    const QueryPeriod = target.value;

    this.deviceEvent.emit(QueryPeriod);
    this.queryPeriod = QueryPeriod;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdQueryPeriod,
      this.queryPeriod
    );
    this.fetchMetricsData();
  }

  setDimension(dimension, dimensionGroup) {
    this.selectedDimension = dimension;
    this.selectedDimGroup = dimensionGroup;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      dimensionGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, dimension);
    this.updateTableData();
  }

  async fetchMetricsData() {
    this.loading = true;
    this.dataList = [];

    try {
      const allSites = await this.websiteAnalyzeService
        .getWebsiteAnalyze(this.userId)
        .toPromise();
      this.noSitesCreated = !allSites.length;
      const {
        data:result,
        locations,
        SitesCreated,
        hasShared,
        areWeReceivingData,
        sharedToThisUser
      }: any = await this.websiteAnalizeService
        .getListofRumSites(this.userId, this.queryPeriod)
        .toPromise();

      this.dataList = result;
      this.monitoringLocations = locations;
      this.SitesCreated = SitesCreated;
      this.hasSharedSites = hasShared;
      this.monitoringLocations = locations;
      this.areWeReceivingData = areWeReceivingData
      this.sharedToThisUser = sharedToThisUser
      if (!result?.length && this.areWeReceivingData) {
        this.apiResponseMessage = 'we are receiving Data...  please wait !';
        this.loading = false;
        this.currentComputedData = [];
        return;
      }
      this.updateTableData();
      this.loading = false;
    } catch (err) {
      console.error(err);
      this.loading = false;
    }
  }
  onChangeSlideShared(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.showShared = checked;
    localStorage.setItem(
      ConstantService.localStorageKeys.sharedMode,
      checked ? 'on' : 'off'
    );
    this.fetchMetricsData();
  }

  onToggleAllMetrics(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.showAllMetrics = checked;
    localStorage.setItem(
      'showAllMetrics',
      checked ? 'on' : 'off'
    );
    // No need to fetch data again, just update the UI
  }

  clearCurrentFilters() {
    this.selectedCountry = 'All';
    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      this.selectedCountry
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, 'all');
    this.updateTableData();
  }

  async openDialog(element: any) {
    let websiteAnalyse = await this.websiteAnalizeService
      .getWebsiteAnalyzeById(this.userId, element.siteId)
      .toPromise();
    if (websiteAnalyse.sharedWith === undefined) {
      websiteAnalyse.sharedWith = [];
    }
    this.mailsExist = websiteAnalyse.sharedWith;

    const dialogRef = this.dialog.open(SharedWithDialogComponent, {
      data: {
        form: this.form,
        element: { projectId: element.siteId },
        websiteAnalyse: websiteAnalyse,
        buttonText: {
          cancel: 'Close',
        },
      },
      height: '70%',
      width: '50%',
      //panelClass: 'full-screen-modal'
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result !== undefined && result.mails !== undefined) {
        result.mails.map((m) => websiteAnalyse.sharedWith.push(m.mail));
        await this.websiteAnalizeService
          .putWebsiteAnalyze(result.projectId, websiteAnalyse)
          .toPromise();
      }
    });
  }

  goToOverview(metric) {
    if (['6hrs', '12hrs'].includes(this.queryPeriod)) {
      return this.router.navigate([`/home/rum/${metric?.siteId}/overview`], {
        queryParams: {
          queryPeriod: this.queryPeriod,
          queryLevel: this.queryLevel,
          siteId: metric?.siteId,
        },
      });
    }
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      metric.location
    );
    this.router.navigate([`/home/rum/${metric?.siteId}/overview`]);
  }
  navigateToMyAccount(){
    this.router.navigate([`/home/myAccount`]);
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined || code == 'Global')
      return false;
    return true;
  }
  getCountryCode(countryName: string): string | undefined {
    return CountryCodes[countryName];
  }
  closeMe(menuTrigger: MatMenuTrigger) {
    menuTrigger.closeMenu();
  }
  openVideoModal() {
    this.isModalOpen = true;
    document.body.classList.add('modal-open'); // s un flou
  }
  closeVideoModal() {
    this.isModalOpen = false;
    document.body.classList.remove('modal-open'); // Retirer le flou
    this.stopVideo();
  }


  private stopVideo() {
    this.videoUrl = '';
    setTimeout(() => {
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/watch?v=mvJePraln4k'
      );
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.validationSubscription) {
      this.validationSubscription.unsubscribe();
    }
  }
}

