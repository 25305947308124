import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ChartComponent } from 'ng-apexcharts';
import { AnnotationService } from 'src/app/services/common/annotation/annotation.service';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import {
  DataCrux,
  ListOverallFeatures,
  metricOverTime,
  PageSpeedData,
} from 'src/app/shared/models/pagespeed.model';
import { legend } from '../../../shared/models/legend';
import { MatDialog } from '@angular/material/dialog';
import { HistogramDialogComponent } from '../histogram-dialog/histogram-dialog.component';
import { ChartOptionsService } from 'src/app/services/common/chart/chart-options.service';
import { UserService } from 'src/app/services/common/user/user.service';
import { WebsiteAnalyze } from 'src/app/shared/models/user.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/common/sidabar/Data.service';

@Component({
    selector: 'app-metric-page',
    templateUrl: './metric-page.component.html',
    styleUrls: ['./metric-page.component.scss',
        '../what-is-uxr/what-is-uxr.component.scss'],
    standalone: false
})
export class MetricPageComponent implements OnInit {
  @Input() metric: string;

  @ViewChild('chart') chart: ChartComponent;
  @ViewChild(MatSort) sort: MatSort;


  public chartOptionsHistogram : any;
  public chartOptionsCrux: Partial<any>;
  public chartOptionsCruxP75: Partial<any> = [];
  public chartOptionsPSI: Partial<any> = [];
  public chartOptions: any = {};
  device: string;
  userId: string;
  loading = true;
  listInternalUrls: any;
  dataSource: any;
  metricOverTime: metricOverTime;
  titleURL: string;
  displayedColumns: string[];
  datasMetric: PageSpeedData[] = [];
  response: any;
  filterList = ['Passing Page', 'No-Passing Page'];
  ValidOrNot: boolean = true;
  overallList = ListOverallFeatures;
  currentURL: string;
  CruxData: DataCrux;
  p75: number;
  predefinedCountries: any;
  defaultValue: any;
  displaydata: boolean = false;
  showCountry: boolean;
  legend = legend;
  diffp75: number;
  displayName: string;
  result : any;
  uxrOvertimeCHartOptions = {}
  Alldevice = false;
  metrics = ["lcp", "cls", "fcp", "inp", "ttfb"];
  title: any = {};
  status: string;
  all: any;
  auth_token: any;
  passOrNot: boolean;
  improvement: string = "";
  color: string;
  messageFromParent: string = 'Upgrade to unlock more history and unlimited data retention';
  websites:WebsiteAnalyze[];
  WebSiteTitle: string;
  isPSI: boolean;
  showOvertimeBarChart = false
  isSub: boolean;
  userData: any;
  Url: string;
  currentUrl: any;
  projectID: any;

  constructor(
    private speetalsDatabase: SpeetalsDatabaseService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    public senderVarService: SenderVarService,
    private annotationService: AnnotationService,
    private dataService: DataService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private chartOptionsService : ChartOptionsService,
    private snackBar: MatSnackBar,
  ) {}

    async ngOnInit(): Promise<void> {
      const sharedData = this.dataService.getSharedData();
      this.Url = this.router.url;
      this.currentUrl = sharedData.url;
      this.projectID = sharedData.projectID;
      this.device = localStorage.getItem(ConstantService.loadingUrl.device);
      this.device = this.device === 'tablet' ? 'mobile': this.device
      this.displayName =
        this.metric.toLowerCase() === 'ttfp' ? 'ttfb' : this.metric;
      this.displayedColumns = ['title', this.metric, 'link'];

      let URL = localStorage.getItem(ConstantService.loadingUrl.Url);
      this.currentURL = URL;
      this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
      this.userData = await this.userService.getUserDetail(this.userId).toPromise();
      this.isSub = this.userData.subStatus == 'active' ? true : false
      this.speetalsDatabase
        .getDataByMetric(
          this.userId,
          this.senderVarService.id,
          this.device,
          this.metric,
          ).subscribe(
            async (resp) => {
          if (resp.length !== 0) {
            if (resp[resp.length - 1].country !== 'NotAllowed') {
              let country = this.pageSpeedInsigthService.getCountry(resp);
              this.predefinedCountries = country;
              this.defaultValue = country;
              this.showCountry = true;
            } else {
              this.showCountry = false;
            }
            let annotation = await this.annotationService
              .getAnnotation(
                this.senderVarService.id,
                'barChartCrux',
                undefined,
              )
              .toPromise();

            //let moderate = legend[this.metric.toLowerCase()].moderate;
            //moderate = 'moderate';
            this.CruxData = this.pageSpeedInsigthService.getCruxData(
              resp,
              this.metric,
              this.legend[this.displayName],
            );

            this.chartOptionsCrux = this.pageSpeedInsigthService.chartOptionCrux(
              this.CruxData,
              this.annotationService.constructAnnotation(annotation, 'barChart'),
            );

            //this.chartOptionsCruxP75=this.pageSpeedInsigthService.chartOptionsCompetitor(this.CruxData.p75, this.CruxData.date, this.metric);
            this.chartOptionsCruxP75 =
              this.pageSpeedInsigthService.chartOptionCruxP75(
                this.CruxData.p75,
                this.CruxData.date,
                this.metric,
              );
            this.p75 = this.CruxData.p75[this.CruxData.p75?.length - 1] || 0;
            this.diffp75 = this.CruxData.diffp75;

            this.showOvertimeBarChart = true;
          } else {
            this.displaydata = false;
          }
          this.loading = false;

        });
        this.response = await this.speetalsDatabase
          .getDatainternalUrl(
            this.userId,
            this.senderVarService.id,
            this.device.toLowerCase(),
            'most_recent',
          )
          .toPromise();
        let urlSpeedData = this.pageSpeedInsigthService.getUrlSpeedData1(
          this.response,
          this.device,
        );
        this.datasMetric =[]
        urlSpeedData.map((ele: any) => {
          this.datasMetric.push(
            this.pageSpeedInsigthService.getMetricSpeedData(ele, this.metric),
          );
        });

        this.titleURL = this.datasMetric[0]?.title || 'title';
        this.datasMetric.map((data) => {
          this.chartOptionsPSI = this.pageSpeedInsigthService.chartOptions(
            data[this.metric],
          );
          data.chartOptions = this.chartOptionsPSI;
        });
        this.dataSource = new MatTableDataSource(this.datasMetric);
        /* configure filter */
        this.dataSource.filterPredicate = (data, filter: string) => {
          if (filter === this.filterList[0].toLowerCase())
            return data.overall.includes('FAST');
          else
            return (
              data.overall.includes('SLOW') || data.overall.includes('AVERAGE')
            );
        };

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case this.metric:
              return item[this.metric].good;
            //case 'Overall' : return item.overall;
          }
        };
        this.dataSource.sort = this.sort;

      this.device = localStorage.getItem(ConstantService.loadingUrl.device);
      if (this.router.url.slice(0, 5) === '/demo') {
        localStorage.setItem(
          ConstantService.loadingUrl.Url,
          'https://www.amazon.com'
        );
        //localStorage.setItem(ConstantService.loadingUrl.device, 'mobile');
        this.auth_token = await this.userService.getDemoToken().toPromise();
        localStorage.setItem(
          ConstantService.localStorageKeys.access_Token,
          this.auth_token
        );
      }

      this.currentURL = URL;
      this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);

      this.all = await this.speetalsDatabase
        .getDataOverviewAll(
          this.senderVarService.id,
          this.userId
        )
        .toPromise();

    this.result = await this.speetalsDatabase
      .getDataOverview(
        this.senderVarService.id,
        this.userId,
        this.device
      )
      .toPromise();
      this.WebSiteTitle = this.result?.title;
    const uxrData = [
      { name: 'mobile', data: this.result?.uxrProgress?.mobile },
      { name: 'desktop', data: this.result?.uxrProgress?.desktop }
    ]
    this.uxrOvertimeCHartOptions = this.pageSpeedInsigthService.chartOptionsUXROverTime(uxrData, this.result?.uxrProgress?.yyyymm)
    if (this.Alldevice){
      for (let metric of this.metrics){
        this.chartOptions[metric] = this.chartOptionsService.chartOptionsOverviewAll(this.all[metric], this.all.device, this.legend[metric]);
      }
    }
    else {
      if (this.result === undefined || this.result === null) {
        this.displaydata = false;
      }
      else {

        this.chartOptionsHistogram = {}
        for (let property of this.metrics) {
          if (property === "ttfp") property = "ttfb"

          this.title[property] = this.pageSpeedInsigthService.getTitle2(this.result[property].title)
          this.chartOptions[property] = this.chartOptionsService.chartOptionsOverview(
          this.result[property]
          );

          this.chartOptionsHistogram[property] = this.chartOptionsService.chartOptionsCompare(
            this.result[property], property
          );
          this.chartOptionsHistogram[property]['chart']['events'] = {
            mounted: (chart) => {
              chart.windowResizeHandler();
            }
          };
        }

        this.status = this.result.status;
        this.passOrNot = (this.result.status === "passes") ? true : false;
        if( !this.passOrNot && this.result.arrayNeedImprovements !== undefined  && this.result.arrayNeedImprovements.length > 0){
          this.result.arrayNeedImprovements.map(elt => {
            let ptVirgule = (elt === this.result.arrayNeedImprovements[this.result.arrayNeedImprovements.length - 1]) ? ' ' : ',';
            this.improvement +=  elt.metric.toUpperCase() + " (" + elt.value + "%)" + ptVirgule + ' ' ;
          })
        }

        this.color = this.result.color;

        this.displaydata = true;
      }
    }
    Object.keys(this.chartOptions)
      .forEach(key => {
        const series = this.chartOptions[key].series
        let hasNoData = true
        series.forEach(i => {
          if (i?.data?.[0] !== 0){
            hasNoData = false
          }
        })
        this.chartOptions[key]['hasNoData'] = hasNoData
      })
    this.loading = false;
  }

  setDevice($event) {
    localStorage.setItem(ConstantService.loadingUrl.device, $event);
    this.ngOnInit();
  }

  applyFilter(ob) {
    const filterValue = ob.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  resetFilters() {
    this.dataSource.filter = '';
  }

  overallFeatures(overallValue) {
    this.overallList.forEach((element) => {
      if (element.value == overallValue) {
        if (element.value == 'N/A') {
          this.ValidOrNot = false;
        }
        return element;
      }
    });
  }

  urlName(name, homeUrl) {
    return this.response.find((r) => r.url === homeUrl)?.originDomain + '/' ===
      homeUrl
      ? 'home page '
      : name;
  }

  navigateTo(url, chemin) {
    let URL = encodeURIComponent(url);
    return this.router.url + '/' + chemin + '/' + URL;
  }
  urlDetails(url) {
    let URL = encodeURIComponent(url);
    let metricName = this.metric === 'ttfp' ? 'ttfb' : this.metric;
    return (
      this.router.url.replace(metricName, 'internalpages').replace(/%2B/g, '') +
      '/details/' +
      URL
    );
  }
  urlAudits(url) {
    this.speetalsDatabase.urlAudit = url;
    let URL = encodeURIComponent(url);
    let metricName = this.metric === 'ttfp' ? 'ttfb' : this.metric;
    return (
      this.router.url.replace(metricName, 'internalpages').replace(/%2B/g, '') +
      '/audits/' +
      URL
    );
  }

  getPassing(overall: string) {
    return this.speetalsDatabase.passOrNot(overall);
  }

  getBadgeColor(x: number): string {
    return x > 0 ? 'warn' : 'accent';
  }

  description() {
    return 'Day to day change of Good ' + this.metric.toUpperCase() + ' %';
  }

  openDialog() {
    const dialogRef = this.dialog.open(HistogramDialogComponent, {
      data: {
        chartOptions: this.chartOptionsCruxP75,
        buttonText: {
          cancel: 'Close',
        },
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '70%',
      width: '70%',
      //panelClass: 'full-screen-modal'
    });
  }
  openDialogHistogram(metric : any){
    const dialogRef = this.dialog.open(HistogramDialogComponent,{
      data:{
        chartOptions: this.chartOptionsHistogram[metric],
        free : this.result.isShared ? false: this.result.free ,
        buttonText: {
          cancel: 'Close',
          upgrade : 'Upgrade'
        }
      },
      maxWidth: '200vw',
      maxHeight: '200vh',
      height: '80%',
      width: '80%',
    });
  }

  seeDialog(): boolean {
    if (!this.CruxData || !this.CruxData.p75 || this.p75 == 0 || this.CruxData.p75.length < 2){
      return false;
    }
    return true;
 }
  openLink() {
    window.open(`https://web.dev/${this.displayName}/`, '_blank');
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined) return false;
    return true;
  }
  copierUrlDansPressePapier(url:any): void {
    navigator.clipboard.writeText(url).then(() => {
      this.snackBar.open('URL copied!' , 'Close');
    }).catch(err => {
      this.snackBar.open('Error copying URL!' , 'Close');
    });
  }
}
