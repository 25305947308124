export interface TypeData {
  good: any;
  needsImprovement: any;
  poor: any;
  p75? : any;
}

export interface metric {
  cls: any;
  lcp: any;
  fcp: any;
  fid: any;
  ttfp?: any;
  inp? : any;
}
interface typeMetricOver {
  name: string;
  series: any;
  date: any;
}
export interface metricOverTime {
  interactive: typeMetricOver;
  speedIndex: typeMetricOver;
  totalBlockingTime: typeMetricOver;
}
export interface PageSpeedData {
  projectId : string;
  image: string;
  homeUrl: string;
  title: string;
  cls?: TypeData;
  lcp?: TypeData;
  fid?: TypeData;
  fcp?: TypeData;
  ttfp ?: TypeData;
  inp? : TypeData;
  ttfb? : TypeData;
  overall?: any;
  chartOptions?: ChartOptionsInterface | Partial<any>;
  countInternalUrls?: any;
  country : string;
  uxr? : number;
  ttfbDiff?: number;
  lcpDiff?: number;
  fidDiff?: number;
  clsDiff?: number;
  inpDiff?: number;
  transferSize?: number;
  isShared?: boolean
}
/**
 * this for overview page
 */
 export interface OverviewApi {
    title: string,
    userId: string,
    url: string,
    overallCategory: string,
    device: string,
    psiScore: string,
    goodCls: string,
    needsImprovementCls: string,
    poorCls: string,
    goodFcp: string,
    needsImprovementFcp: string,
    poorFcp: string,
    goodFid: string,
    needsImprovementFid: string,
    poorFid: string,
    goodLcp: string,
    needsImprovementLcp: string,
    poorLcp: string,
    updateAt: string,
    yyyymm: string,
    country: string,
    pctGoodTTFP: string,
    pctAvgTTFP: string,
    pctSlowTTFP: string,
    p75TTFP: string,
    pctGoodInp: string,
    pctAvgInp: string,
    pctSlowInp: string,
    p75Inp: string,

    diff: any
}
export interface PageSpeedDataOverview {
  cls: TypeData;
  lcp: TypeData;
  fcp: TypeData;
  ttfp: TypeData;
  inp?: TypeData;
  status: string;
  color: string;
  title: metric;
  chartOptions?: ChartOptionsInterface ;
  diff? : Object
}
export interface Countries {
  code: string;
  code3: string;
  name: string;
  number: string;
}
export interface ChartOptionsInterface {
  cls?: Partial<any>;
  lcp?: Partial<any>;
  fid?: Partial<any>;
  fcp?: Partial<any>;
  ttfp? : Partial<any>;
  inp? : Partial<any>;
  ttfb?: Partial<any>;
}
export interface DataCrux {
  [x: string]: any;
  good: any;
  nameGood: string;
  needsImprovement: any;
  nameNeedsImprovement: string;
  poor: any;
  namePoor: string;
  month: any;
  p75 :any;
  diffp75 : any;
  date ? : any;
}



export interface OverallInterface {
  value: string;
  icon?: string;
  color?: string;
}

export interface coord {
  value : string ;
  date : string;
};

export const ListOverallFeatures: OverallInterface[] = [
  { value: 'FAST', icon: 'check_circle', color: 'green' },
  { value: 'AVERAGE', icon: 'block', color: 'orange' },
  { value: 'SLOW', icon: 'block', color: 'red' },
  { value: 'N/A', icon: 'block', color: 'black' },
];
