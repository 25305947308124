import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-first-party-data-fcp',
    templateUrl: './first-party-data-fcp.component.html',
    styleUrls: ['./first-party-data-fcp.component.scss'],
    standalone: false
})
export class FirstPartyDataFcpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
