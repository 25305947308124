<mat-dialog-content>
  <div class="row" style="padding: 2%">
    <apx-chart
      [series]="chartOptionsCruxP75.series"
      [chart]="chartOptionsCruxP75.chart"
      [xaxis]="chartOptionsCruxP75.xaxis"
      [stroke]="chartOptionsCruxP75.stroke"
      [tooltip]="chartOptionsCruxP75.tooltip"
      [dataLabels]="chartOptionsCruxP75.dataLabels"
      [legend]="chartOptionsCruxP75.legend"
      [markers]="chartOptionsCruxP75.markers"
      [grid]="chartOptionsCruxP75.grid"
      [yaxis]="chartOptionsCruxP75.yaxis"
      [title]="chartOptionsCruxP75.title"
    ></apx-chart>
    <div style="text-align: center">
      <b style="font-size: 6em; color: #15cd6a">-</b> Good
      <b style="font-size: 6em; color: #fba400">-</b> Needs Improvement
      <b style="font-size: 6em; color: #fa4f44">-</b> Poor
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" mat-dialog-close tabindex="-1">
    {{ cancelButtonText }}
  </button>
</mat-dialog-actions>
