<app-layout>
  <i class="flag flag-iran"></i>
  @if (isNew && !plan.details.internalPagesOnly) {
  <h2 id="text">Start with adding a website to monitor</h2>
  } @if (loading) {
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: -1000px"
  >
    <mat-spinner class="spinner" diameter="150"></mat-spinner>
  </div>
  } @if (!loading) {
  <div class="row d-flex justify-content-between align-items-center m-4">
    @if (!radiobutton) {
    <h2 class="mx-4 mt-2 mb-0 p-0">CRUX Data</h2>
    <div class="col-sm-6 col-md-3 col-12 d-flex justify-content-center m-2">
      <button
        mat-raised-button
        class="custom-mat-button"
        [routerLink]="['pageLoad/new']"
        routerLinkActive="router-link-active"
      >
        Add Website <mat-icon>add</mat-icon>
      </button>
    </div>
    }
    <!-- @if (plan?.details?.internalPagesOnly && remainingInternalPages > 0) {
      <button mat-raised-button class="custom-mat-button" [routerLink]="['new-internal-pages-po']" >
        Monitor internal pages<mat-icon>add</mat-icon>
      </button>

    } -->

    @if (plan?.details?.internalPagesOnly) {
      @if(plan?.details?.internalPagesOnly) {
    <div class="table card p-0">
      <mat-table
        [dataSource]="dataSource"
        matSort
        class="table-scroll mat-elevation-z14 border border-3 m-0"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="image">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field>
              <mat-select
                (selectionChange)="applyFilter($event)"
                placeholder="filter by"
              >
                @for (item of filterList; track item) {
                <mat-option [value]="item">
                  {{ item }}
                </mat-option>
                }
                <mat-option (click)="resetFilters()"> Reset </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="img">
            <img
              loading="lazy"
              decoding="async"
              alt="screenshot"
              src="{{ element.image }}"
              width="150"
              height="150"
            />
          </mat-cell>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <b> {{ titleURL | uppercase }} </b>
          </mat-header-cell>
          <mat-cell class="box" *matCellDef="let element">
            <mat-card appearance="outlined">
              <mat-card-title
                class="title"
                #tooltip="matTooltip"
                matTooltip="{{ element.homeUrl }}"
                matTooltipHideDelay="100"
                matTooltipPosition="above"
                aria-label="Button that displays a tooltip that hides when scrolled out of the container"
              >
                {{ urlName(element.title, element.homeUrl) }}
              </mat-card-title>
              @if (printUxr(element.uxr)) {
              <mat-card-subtitle class="uxr">
                <circle-progress
                  title="UXR"
                  outerStrokeColor="{{ speedColors[element.overall] }}"
                  outerStrokeGradientStopColor="{{
                    speedColors[element.overall]
                  }}"
                  subtitle="{{ element.uxr }}%"
                  percent="{{ element.uxr }}"
                ></circle-progress>
              </mat-card-subtitle>
              }
              <mat-card-content>
                <div class="overall" style="text-align: center">
                  {{ element.overall }}
                  @if (getPassing(element.overall)) {
                  <i class="elt">
                    <mat-icon [className]="element.overall"
                      >check_circle</mat-icon
                    >
                  </i>
                  } @if (!getPassing(element.overall)) {
                  <i class="elt">
                    @if (element.overall != 'N/A') {
                    <mat-icon [className]="element.overall">cancel</mat-icon>
                    }
                  </i>
                  }
                </div>
              </mat-card-content>
              <mat-card-actions class="buttons">
                <div class="button" class="example-container">
                  <a
                    mat-raised-button
                    routerLink="{{
                      urlDetails(element.homeUrl, element.projectId)
                    }}"
                    ><mat-icon>timeline</mat-icon>Track</a
                  >
                </div>
                <br />
                <div class="button" class="example-container">
                  <a
                    mat-raised-button
                    [routerLink]="urlAudits(element.homeUrl, element.projectId)"
                  >
                    <mat-icon>flash_on</mat-icon>
                    Optimize
                  </a>
                </div>
              </mat-card-actions>
            </mat-card>
          </mat-cell>
        </ng-container>
        <!-- TTFB -->
        <ng-container matColumnDef="TTFB" class="chart">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            TTFB</mat-header-cell
          >
          <mat-cell
            style="display: block; font-weight: normal; text-align: center"
            *matCellDef="let element; let i = index"
          >
            diff: @if (element.ttfbDiff > 0) {
            <span class="success-span">{{ formatDiff(element.ttfbDiff) }}</span>
            } @if (element.ttfbDiff < 0) {
            <span class="danger-span">{{ formatDiff(element.ttfbDiff) }}</span>
            } @if (!element.ttfbDiff) {
            <span>O</span>
            }
            <div class="chart-container">
              <apx-chart
                [series]="element.chartOptions.ttfb.series"
                [chart]="element.chartOptions.ttfb.chart"
                [dataLabels]="element.chartOptions.ttfb.dataLabels"
                [plotOptions]="element.chartOptions.ttfb.plotOptions"
                [xaxis]="element.chartOptions.ttfb.xaxis"
                [yaxis]="element.chartOptions.ttfb.yaxis"
                [stroke]="element.chartOptions.ttfb.stroke"
                [fill]="element.chartOptions.ttfb.fill"
                [tooltip]="element.chartOptions.ttfb.tooltip"
                [legend]="element.chartOptions.ttfb.legend"
              >
              </apx-chart>
            </div>
          </mat-cell>
        </ng-container>
        <!-- FCP (only shown when showAllMetrics is true) -->
        <ng-container matColumnDef="FCP" class="chart">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            FCP</mat-header-cell
          >
          <mat-cell
            style="display: block; font-weight: normal; text-align: center"
            *matCellDef="let element; let i = index"
          >
            diff: @if (element.fcpDiff > 0) {
            <span class="success-span">{{ formatDiff(element.fcpDiff) }}</span>
            } @if (element.fcpDiff < 0) {
            <span class="danger-span">{{ formatDiff(element.fcpDiff) }}</span>
            } @if (!element.fcpDiff) {
            <span>O</span>
            }
            <div class="chart-container">
              <apx-chart
                [series]="element.chartOptions.fcp.series"
                [chart]="element.chartOptions.fcp.chart"
                [dataLabels]="element.chartOptions.fcp.dataLabels"
                [plotOptions]="element.chartOptions.fcp.plotOptions"
                [xaxis]="element.chartOptions.fcp.xaxis"
                [yaxis]="element.chartOptions.fcp.yaxis"
                [stroke]="element.chartOptions.fcp.stroke"
                [fill]="element.chartOptions.fcp.fill"
                [tooltip]="element.chartOptions.fcp.tooltip"
                [legend]="element.chartOptions.fcp.legend"
              >
              </apx-chart>
            </div>
          </mat-cell>
        </ng-container>
        <!-- LCP -->
        <ng-container matColumnDef="LCP" class="chart">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            LCP
          </mat-header-cell>
          <mat-cell
            style="display: block; font-weight: normal; text-align: center"
            *matCellDef="let element; let i = index"
          >
            diff: @if (element.lcpDiff > 0) {
            <span class="success-span">{{ formatDiff(element.lcpDiff) }}</span>
            } @if (element.lcpDiff < 0) {
            <span class="danger-span">{{ formatDiff(element.lcpDiff) }}</span>
            } @if (!element.lcpDiff) {
            <span>O</span>
            }
            <div class="chart-container">
              <apx-chart
                [series]="element.chartOptions.lcp.series"
                [chart]="element.chartOptions.lcp.chart"
                [dataLabels]="element.chartOptions.lcp.dataLabels"
                [plotOptions]="element.chartOptions.lcp.plotOptions"
                [xaxis]="element.chartOptions.lcp.xaxis"
                [yaxis]="element.chartOptions.lcp.yaxis"
                [stroke]="element.chartOptions.lcp.stroke"
                [fill]="element.chartOptions.lcp.fill"
                [tooltip]="element.chartOptions.lcp.tooltip"
                [legend]="element.chartOptions.lcp.legend"
              >
              </apx-chart>
            </div>
          </mat-cell>
        </ng-container>
        <!-- Symbol CLS -->
        <ng-container matColumnDef="CLS">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            CLS
          </mat-header-cell>
          <mat-cell
            style="display: block; font-weight: normal; text-align: center"
            *matCellDef="let element; let i = index"
          >
            diff: @if (element.clsDiff > 0) {
            <span class="success-span">{{ formatDiff(element.clsDiff) }}</span>
            } @if (element.clsDiff < 0) {
            <span class="danger-span">{{ formatDiff(element.clsDiff) }}</span>
            } @if (!element.clsDiff) {
            <span>O</span>
            }
            <div class="chart-container">
              <apx-chart
                [series]="element.chartOptions.cls.series"
                [chart]="element.chartOptions.cls.chart"
                [dataLabels]="element.chartOptions.cls.dataLabels"
                [plotOptions]="element.chartOptions.cls.plotOptions"
                [xaxis]="element.chartOptions.cls.xaxis"
                [yaxis]="element.chartOptions.cls.yaxis"
                [stroke]="element.chartOptions.cls.stroke"
                [fill]="element.chartOptions.cls.fill"
                [tooltip]="element.chartOptions.cls.tooltip"
                [legend]="element.chartOptions.cls.legend"
              >
              </apx-chart>
            </div>
          </mat-cell>
        </ng-container>
        <!-- Symbol INP -->
        <ng-container matColumnDef="INP">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            INP
          </mat-header-cell>
          <mat-cell
            style="display: block; font-weight: normal; text-align: center"
            *matCellDef="let element; let i = index"
          >
            diff: @if (element.inpDiff > 0) {
            <span class="success-span">{{ formatDiff(element.inpDiff) }}</span>
            } @if (element.inpDiff < 0) {
            <span class="danger-span">{{ formatDiff(element.inpDiff) }}</span>
            } @if (!element.inpDiff) {
            <span>O</span>
            }
            <div class="chart-container">
              <apx-chart
                [series]="element.chartOptions.inp.series"
                [chart]="element.chartOptions.inp.chart"
                [dataLabels]="element.chartOptions.inp.dataLabels"
                [plotOptions]="element.chartOptions.inp.plotOptions"
                [xaxis]="element.chartOptions.inp.xaxis"
                [yaxis]="element.chartOptions.inp.yaxis"
                [stroke]="element.chartOptions.inp.stroke"
                [fill]="element.chartOptions.inp.fill"
                [tooltip]="element.chartOptions.inp.tooltip"
                [legend]="element.chartOptions.inp.legend"
              >
              </apx-chart>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
      </mat-table>
    </div>
      }
    } @if (!plan?.details?.internalPagesOnly) {
    <div class="d-flex flex-column justify-content-end align-items-center w-100" >
      <div class="d-flex flex-column flex-lg-row  flex-wrap w-100">
        <!-- Première section -->
        <div class="graph col-12 col-lg-6 d-flex flex-column justify-content-between align-items-start p-4">
          <div class="text-section w-100">
            <h3 class="fw-bold text-dark" style="font-size: 1.5rem;">
              CrUX Data
            </h3>
            <p class="text-muted" style="font-size: 1rem;">
              Analyze your website's performance with the CrUX data to better understand your users' experience.
            </p>
          </div>
        </div>

        <!-- Deuxième section -->
        <div class="graph header-banner col-12 col-lg-6 d-flex flex-column justify-content-between align-items-start p-4">
          <div class="text-section w-100 mb-3">
            <h3 class="fw-bold text-dark" style="font-size: 1.5rem;">
              How to use Speetals
            </h3>
            <p class="text-muted" style="font-size: 1rem;">
              Watch this short video to learn how to interpret the data and make the most of it.
            </p>
          </div>
          <div class="video-section d-flex justify-content-center">
            <button type="button" class="btn btn-primary d-flex align-items-center" (click)="openVideoModal()">
              <i class="material-icons me-2">play_circle</i>
              Watch Video
            </button>
          </div>
        </div>

        <!-- Modale -->
        <div class="modal-backdrop" *ngIf="isModalOpen" style="background-color: rgba(0, 0, 0, 0.295);">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <iframe
                  *ngIf="videoUrl"
                  id="videoIframe"
                  width="800"
                  height="400"
                  [src]="videoUrl"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  class="rounded-3"
                ></iframe>
              </div>
              <div class="modal-header d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-danger"
                  aria-label="Close"
                  (click)="closeVideoModal()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- If there are no sites created, show a simple message -->
        @if(!isValidated){
          <app-check-list
            class="w-md-50 mt-2"
            [noSitesCreated]="noSitesCreated"
            [hasCreatedWebsite]="hasCreatedWebsite"
            [hasSharedWebsite]="hasSharedWebsite"
            [isCRUX]="isCRUX"
            [checklistId]="checklistId"
          ></app-check-list>
        }

        <div class="w-100 mt-4 d-flex ">
          <div class="graph m-3 flex-shrink-1 d-none d-lg-block w-auto p-2" style="max-width: 220px;">

            <!--
              Only show these filters if 'radiobutton' is true.
              This might be a user preference or a toggle in your code.
            -->
            @if (radiobutton) {
              <div class="filters d-flex flex-column">
                <!-- Search field for large screens -->
                <div class="mb-3">
                  <h4 class="text-secondary mb-2">Search</h4>
                  <div
                    class="group d-flex justify-content-between align-items-center p-2 rounded"
                    style="max-width: 180px;"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      class="icon mx-1"
                      style="width: 18px; height: 18px;"
                    >
                      <g>
                        <path
                          d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                        ></path>
                      </g>
                    </svg>
                    <input
                      class="input"
                      type="search"
                      placeholder="Search"
                      (keyup)="search($event)"
                      style="border: none; outline: none; font-size: 0.9rem;"
                    />
                  </div>
                </div>
                <!-- Device Selection heading -->
                <div class="mb-3">
                  <h4 class="text-secondary mb-2">Device Selection</h4>
                  <app-device-button
                    [instanceId]="'instance1'"
                    (deviceEvent)="setDevice($event)">
                  </app-device-button>
                </div>

                <!-- Visibility (shared) heading -->
                <div class="mb-3">
                  <h4 class="text-secondary mb-2">Visibility</h4>
                  <div class="button2 d-flex justify-content-start align-items-center rounded-3 mb-2 p-2">
                    <input
                      checked="true"
                      type="checkbox"
                      class="switch mx-2"
                      (change)="onChangeSlideShared($event)"
                      [(ngModel)]="isShared"
                    />
                    <span class="text-dark shared-text">Shared with me</span>
                  </div>
                  
                  <!-- All Metrics Toggle -->
                  <div class="button2 d-flex justify-content-start align-items-center rounded-3 mb-2 p-2">
                    <input
                      type="checkbox"
                      class="switch mx-2"
                      (change)="onToggleAllMetrics($event)"
                      [(ngModel)]="showAllMetrics"
                    />
                    <span class="text-dark shared-text">Show all metrics</span>
                  </div>
                </div>
              </div>
            }
          </div>

          <!-- Main content area (table, alerts, etc.) -->
          <div class="p-0 w-75 flex-fill" style="min-width: 0;">

            <!-- We wait for 'loading' to be false to display content -->
            @if (!loading) {

              <!--
                For smaller devices (responsive),
                these controls might appear differently
                (still conditioned by radiobutton)
              -->
              @if (radiobutton) {
                <div class="d-flex justify-content-end align-items-center d-lg-none d-sm-block d-none mx-4">
                  <div class="d-flex justify-content-end align-items-center">
                    <div
                      class="button1 d-flex justify-content-center align-items-center rounded-3"
                      style="padding-right: 5px; width: 150px; height: 40px"
                    >
                      <input
                        checked="true"
                        type="checkbox"
                        class="switch mx-1"
                        (change)="onChangeSlideShared($event)"
                        [(ngModel)]="isShared"
                      />
                      <span class="text-dark shared-text text-center">
                        Shared with me
                      </span>
                    </div>
                    
                    <!-- All Metrics Toggle for smaller screens -->
                    <div
                      class="button1 d-flex justify-content-center align-items-center rounded-3 mx-2"
                      style="padding-right: 5px; width: 150px; height: 40px"
                    >
                      <input
                        type="checkbox"
                        class="switch mx-1"
                        (change)="onToggleAllMetrics($event)"
                        [(ngModel)]="showAllMetrics"
                      />
                      <span class="text-dark shared-text text-center">
                        All metrics
                      </span>
                    </div>
                    
                    <div class="group mx-2">
                      <svg viewBox="0 0 24 24" aria-hidden="true" class="icon">
                        <g>
                          <path
                            d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                          ></path>
                        </g>
                      </svg>
                      <input
                        class="input"
                        type="search"
                        placeholder="Search"
                        (keyup)="search($event)"
                      />
                    </div>
                    
                    <app-device-button
                      [instanceId]="'instance2'"
                      (deviceEvent)="setDevice($event)">
                    </app-device-button>
                  </div>
                </div>

                <!--
                  Alternative layout for mobile (with mat-menu)
                -->
                <div class="d-flex justify-content-end align-items-cente d-sm-none d-block">
                  <!-- Search Field -->
                  <div class="group mb-2 mx-2" style="max-width: 200px;">
                    <svg viewBox="0 0 24 24" aria-hidden="true" class="icon">
                      <g>
                        <path
                          d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                        ></path>
                      </g>
                    </svg>
                    <input
                      class="input"
                      type="search"
                      placeholder="Search"
                      (keyup)="search($event)"
                    />
                  </div>
                  <div>
                    <button class="btn btn-primary" [matMenuTriggerFor]="menu">
                      <div class="d-flex justify-content-center align-items-center w-100">
                        <mat-icon>menu</mat-icon>
                      </div>
                    </button>

                    <mat-menu #menu="matMenu" autoClose="false">
                      <div class="d-flex flex-column justify-content-center align-items-center p-3">
                        <!-- Device Button -->
                        <app-device-button
                          [instanceId]="'instance3'"
                          (deviceEvent)="setDevice($event)"
                          class="mb-2"
                        >
                        </app-device-button>
                        <!-- Switch -->
                        <div
                          class="button1 d-flex justify-content-center align-items-center rounded-3 mb-2"
                          style="padding-right: 5px; width: 200px; height: 40px"
                        >
                          <input
                            checked="true"
                            type="checkbox"
                            class="switch mx-1"
                            (change)="onChangeSlideShared($event)"
                            [(ngModel)]="isShared"
                          />
                          <span class="text-dark shared-text text-center">
                            Shared with me
                          </span>
                        </div>
                      </div>
                    </mat-menu>
                  </div>
                </div>
              }

              <!--
                If ALL sites in dataSource have no CrUX data,
                we show an information alert
              -->
              @if (hasNoCruxData) {
                <div class="alert alert-info m-3">
                  <i class="fas fa-info-circle me-2"></i>
                  One or more of your monitored websites don't have data in the Chrome User Experience Report database.
                  This typically means the sites don't have enough traffic to be included in the CrUX dataset. Use RUM to get data.
                </div>
              }

              <!-- If displayTable is true, let's show the mat-table -->
              @if (displayTable) {
                <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                  <!-- Name Column -->
                  <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef> Website </mat-header-cell>
                    <mat-cell class="p-2" *matCellDef="let element">
                      <div>
                        <div
                          class="title d-flex justify-content-center align-items-center"
                          #tooltip="matTooltip"
                          matTooltip="{{ element.homeUrl }}"
                          matTooltipHideDelay="100"
                          matTooltipPosition="above"
                        >
                          @if (!estAlloue(element.country)) {
                          <mat-icon class="mx-1">language</mat-icon>
                          } @if (estAlloue(element.country)) {
                          <span class="mx-1">
                            <mat-icon [svgIcon]="element.country"></mat-icon>
                          </span>
                          }
                          <p class="m-0 fs-5">{{ element.title }}</p>
                        </div>
                        <div
                          class="title d-flex justify-content-center align-items-center"
                        >
                          @if (element.isShared) {
                          <p style="color: brown">shared</p>
                          }
                        </div>
                        @if (printUxr(element.uxr)) {
                        <div class="uxr my-1">
                          <circle-progress
                            title="UXR"
                            outerStrokeColor="{{ speedColors[element.overall] }}"
                            outerStrokeGradientStopColor="{{ speedColors[element.overall] }}"
                            subtitle="{{ element.uxr }}%"
                            percent="{{ element.uxr }}"
                          ></circle-progress>
                        </div>
                        }

                        <!-- Overall Info -->
                        <div class="m-0">
                          <div
                            class="overall d-flex justify-content-center align-items-center"
                          >
                            <p class="m-0 mx-1" style="padding-bottom: 3px">
                              {{ element.overall }}
                            </p>
                            @if (getPassing(element.overall)) {
                            <i class="elt">
                              <mat-icon [className]="element.overall">
                                check_circle
                              </mat-icon>
                            </i>
                            } @if (!getPassing(element.overall)) {
                            <i class="elt">
                              @if (element.overall != 'N/A') {
                              <mat-icon [className]="element.overall">
                                cancel
                              </mat-icon>
                              }
                            </i>
                            }
                          </div>
                        </div>

                        <!-- Action buttons -->
                        <div
                          class="d-flex justify-content-center align-items-center p-0"
                        >
                          <button
                            class="custom-mat-button1 mx-1"
                            mat-button
                            #tooltip="matTooltip"
                            matTooltip="Access to Overview"
                            matTooltipHideDelay="100"
                            (click)="navigateTo(element)"
                          >
                            <mat-icon class="m-0">visibility</mat-icon>
                          </button>
                          <button
                            class="custom-mat-button1 mx-1"
                            [disabled]="isShared || isChecked"
                            mat-button
                            (click)="openDialog(element)"
                            #tooltip="matTooltip"
                            matTooltip="Share website with a team mate"
                            matTooltipHideDelay="100"
                          >
                            <mat-icon class="m-0">share</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <!-- Metrics Columns -->
                  @for (metric of metrics; track $index) {
                  <ng-container matColumnDef="{{ metric | uppercase }}" class="chart border-bottom">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ metric | uppercase }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      @if (element.chartOptions[metric].hasNoData) {
                      <div style="text-align: center">
                        No <strong>{{ metric }}</strong> data
                      </div>
                      } @if (!element.chartOptions[metric].hasNoData) {
                      <div
                        class="d-flex flex-column justify-content-center align-items-center"
                      >
                        <div
                          class="d-flex justify-content-center align-items-center mt-1 text-muted"
                        >
                          <span class="mx-1">
                            75th:
                            {{
                              metric === "ttfb"
                                ? element["ttfp"]?.p75
                                : element[metric]?.p75
                            }}
                            {{ metric === 'cls' ? '' : 'ms' }}
                          </span>
                          <mat-icon
                            style="font-size: 18px; opacity: 0.7;"
                            [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                            matTooltipPosition="left"
                          >
                            help
                          </mat-icon>
                        </div>
                        <div class="chart-container">
                          <apx-chart
                            [series]="element.chartOptions[metric].series"
                            [chart]="element.chartOptions[metric].chart"
                            [dataLabels]="element.chartOptions[metric].dataLabels"
                            [plotOptions]="element.chartOptions[metric].plotOptions"
                            [xaxis]="element.chartOptions[metric].xaxis"
                            [yaxis]="element.chartOptions[metric].yaxis"
                            [stroke]="element.chartOptions[metric].stroke"
                            [fill]="element.chartOptions[metric].fill"
                            [tooltip]="element.chartOptions[metric].tooltip"
                            [legend]="element.chartOptions[metric].legend"
                          >
                          </apx-chart>
                        </div>
                      </div>
                      }
                    </mat-cell>
                  </ng-container>
                  }

                  <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    class="m-3 d-flex flex-row justify-content-start"
                  >
                  </mat-row>
                </mat-table>
              }@else if (noDataWebAnalyze.length) {
                <!--
                If we have a subset of sites with no data => noDataWebAnalyze
              -->
                <div class="graph m-4 p-2">
                  @for (s of noDataWebAnalyze; track s) {
                  <div class="d-flex m-3">
                    <div>
                      <mat-card
                        appearance="outline"
                        class="card p-3 mx-3"
                        style="width: 177px; height: 201px;"
                      >
                        <mat-card-title
                          class="title d-flex justify-content-center align-items-center"
                          #tooltip="matTooltip"
                          matTooltip="{{ s.homeUrl }}"
                          matTooltipHideDelay="100"
                          matTooltipPosition="above"
                        >
                          @if (!estAlloue(s.country)) {
                          <span>
                            <mat-icon class="mx-1">language</mat-icon>
                          </span>
                          } @if (estAlloue(s.country)) {
                          <span class="mx-1">
                            <mat-icon [svgIcon]="s.country"></mat-icon>
                          </span>
                          }
                          <p class="m-0 fs-5">{{ s.title }}</p>
                        </mat-card-title>
                        <mat-card-subtitle class="uxr">
                          <circle-progress
                            title="UXR"
                            subtitle="0%"
                            percent="0"
                          ></circle-progress>
                        </mat-card-subtitle>
                        <mat-card-actions>
                          <button
                            class="custom-mat-button1 mx-1"
                            mat-button
                            color="primary"
                            #tooltip="matTooltip"
                            matTooltip="Access to Overview"
                            disabled
                          >
                            <mat-icon class="m-0">visibility</mat-icon>
                          </button>
                          <button
                            class="custom-mat-button1 mx-1"
                            mat-button
                            #tooltip="matTooltip"
                            matTooltip="Share website with a team mate"
                            disabled
                          >
                            <mat-icon class="m-0">share</mat-icon>
                          </button>
                        </mat-card-actions>
                      </mat-card>
                    </div>
                    <div
                      class="d-flex flex-column justify-content-center align-items-center flex-grow-1"
                    >
                      <h2>No CrUX data for {{ s.title }}</h2>
                      <h3>({{ s.homeUrl }})</h3>
                    </div>
                  </div>
                  }
                </div>
              }@else{
                <app-oops-no-data [message] = "noSiteCreated"></app-oops-no-data>
              }
            }
          </div>
        </div>

      </div>
    }
    </div>
    }
</app-layout>
