<div>
  <div class="d-flex justify-content-between align-items-center">
    <div></div>
    <div style="height: 100%; overflow-y: hidden">
      <div class="radio-inputs mb-1">
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="daily"
            [checked]="selectedInterval === 'daily'"
            (click)="onOptionChanged($event)"
          />
          <span class="name">Daily</span>
        </label>
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="monthly"
            [checked]="selectedInterval === 'monthly'"
            (click)="onOptionChanged($event)"
          />
          <span class="name">Monthly</span>
        </label>
      </div>
    </div>
  </div>

  @if (loading) {
    <div class="d-flex justify-content-center align-items-center" style="height: 200px;">
      <mat-spinner class="spinner" diameter="150"></mat-spinner>
    </div>
  }

  @if (!loading && selectedInterval === 'daily') {
    @if (hasDailyData) {
      <div style="display: flex; justify-content: center">
        <mat-chip-listbox aria-label="Days selection">
          <mat-chip-option
            style="cursor: pointer"
            *ngFor="let day of dailyPvSessionOverTime?.dateGroups"
            [selected]="selectedPVdatesInterval === day"
            (click)="onDateRangeSelected(day, 'selectedPVdatesInterval')"
          >
            {{ day }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div style="padding: 2%">
        <apx-chart
          [series]="dailyPvSessionOverTime?.[selectedPVdatesInterval]?.series"
          [chart]="dailyPvSessionOverTime?.[selectedPVdatesInterval]?.chart"
          [xaxis]="dailyPvSessionOverTime?.[selectedPVdatesInterval]?.xaxis"
          [dataLabels]="dailyPvSessionOverTime?.[selectedPVdatesInterval]?.dataLabels"
          [stroke]="dailyPvSessionOverTime?.[selectedPVdatesInterval]?.stroke"
        ></apx-chart>
      </div>
    } @else {
      <!-- No daily data available -->
      <app-oops-no-data [message] = "NoDataDaily"></app-oops-no-data>
    }
  }

  @if (!loading && selectedInterval === 'monthly') {
    @if (hasMonthlyData) {
      <div style="padding: 2%">
        <apx-chart
          [series]="monthlyPvSessionOverTime?.series"
          [chart]="monthlyPvSessionOverTime?.chart"
          [xaxis]="monthlyPvSessionOverTime?.xaxis"
          [dataLabels]="monthlyPvSessionOverTime?.dataLabels"
          [stroke]="monthlyPvSessionOverTime?.stroke"
        ></apx-chart>
      </div>
    } @else {
      <!-- No monthly data available -->
      <app-oops-no-data [message] = "NoDataMonthly"></app-oops-no-data>
    }
  }
</div>
