import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { FirstPartyDataServiceService } from 'src/app/services/first-party-data-service.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { CountryCodes } from '../../../../consts/countryCode';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { RumService } from 'src/app/services/rum.service';
import { UxrDialogComponent } from '../../uxr-dialog/uxr-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
@Component({
    selector: 'app-first-party-data-overview',
    templateUrl: './first-party-data-overview.component.html',
    styleUrls: ['./first-party-data-overview.component.scss'],
    standalone: false
})
export class FirstPartyDataOverviewComponent implements OnInit {
  @Output() public deviceEvent = new EventEmitter<string>();
  @ViewChild('menuContacts') menuContacts;
  projectTitle;
  projectUrl;
  loading: boolean;
  metricsData = {};
  userId = localStorage.getItem('userId');
  queryLevel: string = 'domain';
  queryPeriod: string = 'daily';
  selectedDimension: string = 'all';
  selectedDimGroup: string = 'all';
  metricsGrouped = {
    speed: ['ttfb', 'fcp', 'lcp'],
    layoutStability: ['cls'],
    interactivity: ['inp']
  };
  device: string = 'desktop';
  siteId;
  params;
  apiResponseMessage: string;
  currentComputedData = {};
  deviceTypes = ConstantService.defaultDevices;
  dimensionGroups = ConstantService.defaultDimensionGroup;
  dataList: any[];
  sharedMode = false;
  monitoringLocations = [];
  selectedCountry = 'Global';
  metrics = ['lcp', 'inp', 'cls', 'ttfb', 'fcp'];

  uxrOverTimeChartOptions: any = {};
  wvOverTimeChartOptions: any = {};
  Url: string;
  projectID: any;

  constructor(
    public webAnalyzeService: WebsiteAnalyzeService,
    private route: Router,
    private fpdService: FirstPartyDataServiceService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    private helpersService: HelpersService,
    private rumService: RumService,
    private dialog: MatDialog
  ) {
    this.siteId = this.route.url.split('/')[3];
  }

  async ngOnInit() {
    this.Url = this.route.url;
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.selectedDimension =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimension) || 'all';
    this.selectedDimGroup =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimGroup) || 'all';
    this.queryPeriod =
      localStorage.getItem(ConstantService.loadingUrl.fpdQueryPeriod) ||
      'daily';
    this.sharedMode =
      localStorage.getItem(ConstantService.localStorageKeys.sharedMode) ===
      'on';
    this.selectedCountry =
      localStorage.getItem(
        ConstantService.localStorageKeys.selectedRUMCountry
      ) || 'Global';
    this.fetchMetricsData();
  }

  onCountrySelect(country) {
    this.selectedCountry = country;
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      country
    );
    this.updateTableData();
  }

  setDevice(selectedDevice: string) {
    this.device = selectedDevice;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);

    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';

    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimension,
      this.selectedDimension
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    this.updateTableData();
  }

  updateTableData() {
    const computedMetrics = this.rumService.getComputedOverview(
      this.dataList || [],
      this.selectedDimGroup,
      this.selectedDimension,
      this.device || 'desktop',
      this.selectedCountry
    );
    this.metricsData = computedMetrics;
  }

  processDiff(computedData: any[]) {
    if (computedData.length < 2) return computedData[0];
    const latest = computedData[computedData.length - 1];
    const prev = computedData[computedData.length - 2];

    this.metrics.forEach((metric) => {
      if (!latest[metric] || !prev[metric]) return;
      latest[metric]['diff'] = (
        latest[metric]?.series?.[0]?.data?.[0] -
          prev[metric]?.series?.[0]?.data?.[0] || 0
      ).toFixed(2);
    });
    return latest;
  }

  setQueryPeriod(event: Event) {
    const target = event.target as HTMLInputElement;
    const QueryPeriod = target.value;

    this.deviceEvent.emit(QueryPeriod);
    this.queryPeriod = QueryPeriod;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdQueryPeriod,
      this.queryPeriod
    );
    this.fetchMetricsData();
  }

  clearCurrentFilters() {
    this.selectedCountry = 'Global';
    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      this.selectedCountry
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, 'all');
    this.updateTableData();
  }

  setDimension(dimension, dimensionGroup) {
    this.selectedDimension = dimension;
    this.selectedDimGroup = dimensionGroup;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      dimensionGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, dimension);
    this.updateTableData();
  }

  async fetchMetricsData() {
    this.loading = true;

    try {
      let {
        data: result,
        locations,
        uxrProgress,
        wvProgress,
      } = (await this.webAnalyzeService
        .getRumOverview(this.siteId, this.queryPeriod)
        .toPromise()) || {};
      this.monitoringLocations = locations;

      if (!result.length) {
        this.apiResponseMessage = 'No data for the selected options';
        this.loading = false;
        this.metricsData = null;
        return;
      }
      const { title, url, projectId } = result[0];
      this.projectTitle = title;
      this.projectUrl = url;
      this.projectID = projectId;
      this.apiResponseMessage = !!result.length
        ? null
        : `No ${this.queryPeriod} data`;

      this.dataList = result;
      this.uxrOverTimeChartOptions =
        this.pageSpeedInsigthService.chartOptionsUXROverTime(
          Object.keys(uxrProgress)
            .filter((field) => field !== 'yyyymm')
            .map((name) => ({
              name,
              data: uxrProgress[name],
            })),
          uxrProgress.yyyymm
        );

      this.wvOverTimeChartOptions =
        this.pageSpeedInsigthService.chartOptionsWVOverTime(
          Object.keys(wvProgress)
            .filter((field) => field !== 'yyyymm')
            .map((name) => ({
              name,
              data: wvProgress[name],
            })),
          wvProgress.yyyymm
        );

      this.updateTableData();
      this.loading = false;
    } catch (err) {
      console.error(err);
      this.metricsData = null;
      this.loading = false;
    }
  }

  showScoreProgressChart(isUXR?: boolean) {
    const dialogRef = this.dialog.open(UxrDialogComponent, {
      data: {
        chartOptions1: this.uxrOverTimeChartOptions,
        chartOptions2: this.wvOverTimeChartOptions,
        free:
          localStorage.getItem(ConstantService.localStorageKeys.plan_name) ===
          'Free',
        isUXR: isUXR,
        buttonText: {
          cancel: 'Close',
          upgrade: 'Upgrade',
        },
        isUXRovertime: true,
        isWVovertime: true,
      },
      maxWidth: '200vw',
      maxHeight: '100vh',
      height: '70%',
      width: '80%',
    });
  }

  getMetricName(metric: string) {
    return (
      this.helpersService.getMetricName(metric) +
      ' ( ' +
      metric.toUpperCase() +
      ' )'
    );
  }

  formatP75Display(value: number) {
    return value?.toFixed(2);
  }

  getBadgeColor(x: number): string {
    return x < 0 ? 'warn' : 'accent';
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined || code == 'Global')
      return false;
    return true;
  }
  getCountryCode(countryName: string): string | undefined {
    return CountryCodes[countryName];
  }
  closeMe(menuTrigger: MatMenuTrigger) {
    menuTrigger.closeMenu();
  }
  getTooltipText(group: string): string {
    switch (group) {
      case 'speed':
        return 'Metrics related to the loading speed performance of your website';
      case 'layoutStability':
        return 'Metrics related to how stable your website\'s layout is';
      case 'interactivity':
        return 'Metrics related to how quickly your website responds to user interactions';
      default:
        return 'Information related to this section.';
    }
  }
  getDiffStatus(diff: number | null): string {
    if (diff === null || diff === 0) return 'stabilizing';
    return diff > 0 ? 'improving' : 'regressing';
  }
}
