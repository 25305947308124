import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-cls',
    templateUrl: './cls.component.html',
    styleUrls: ['./cls.component.scss'],
    standalone: false
})
export class  ClsComponent implements OnInit {
  metric : string = 'cls'
  constructor() {}
  ngOnInit(): void {}
}

