<app-help-widget
  [currentURL]="currentUrl"
  [userId]="userId"
  [projectID]="projectId"
  [Url] = "Url"
  >
</app-help-widget>

<nav class="mx-4 mt-3" aria-label="breadcrumb">
  <ol style="height: 50px; width: 52px;" class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center">
    @defer{
      @if (estAlloue(country)) {
        <span class="mx-1">
            <mat-icon [svgIcon]="country"></mat-icon>
        </span>
       }@else{
        <mat-icon  class="mx-1">language</mat-icon>
       }
    }
    <li>
      <span class="fw-bold">CRUX > </span>
    </li>
    <li class="breadcrumb-item">
      <a class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center " [href]="'/home/page/' + this.senderVarService.id + '/overview'">
      <mat-icon class="mx-2 ">desktop_windows</mat-icon>
       <span>{{WebSiteTitle}} Overview</span>
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      Internal Pages
    </li>

  </ol>
</nav>
@if (loading) {
  <div class="d-flex justify-content-center align-items-center" style="height: -1000px">
    <mat-spinner class="spinner" diameter="150"></mat-spinner>
  </div>
}
<div class="d-flex justify-content-center justify-content-center w-100">
    <div class=" m-4 w-100">
        @if (!loading) {
          <div class="text-center my-3">
            <h2 class="m-0">Page-level data for the previous 28-day period</h2>
            <h4>
              You can sort, filter, click on track button to see the progress, or clickon optimize
            </h4>
          </div>
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between align-items-center m-3">
                <app-device-button (deviceEvent)="setDevice($event)"></app-device-button>
                <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" (click)="refreshbutton()">
                  Compare Two URLs
                </button>
                @if (!isFreeAccount) {
                  <div style="margin-left: 1%">
                    <div class="input-group">
                      <button class="btn btn-primary" type="button">Compare to</button>
                      <select class="form-select" id="diffDateSelect" [(ngModel)]="selectedDiffDate" (change)="onDiffDateChanged($event)">
                        @for (i of diffDates; track i) {
                          <option [value]="i.id">{{ i.name }}</option>
                        }
                      </select>
                    </div>
                  </div>
                }
              </div>
              <div class="collapse" id="collapseExample">
                <div class="card card-body mx-5">
                  <span class="fs-5 text-center mb-3 me-3 fw-semibold">Select two URLs to compare</span>
                  <div class="d-flex justify-content-center align-content-center">
                    <div class="input-group w-25">
                      <select class="form-select" id="page1Select" [(ngModel)]="selectedPage1" (change)="onFirstSelectChange()">
                        <option value="" disabled selected>Select the first page</option>
                        <ng-container *ngFor="let group of transformedPages">
                          <optgroup [label]="group.title">
                            <option *ngFor="let page of group.urls" [value]="page.title">{{ page.title }}</option>
                          </optgroup>
                        </ng-container>
                      </select>
                    </div>
                    <div class="input-group mx-4 w-25">
                      <select class="form-select" id="page2Select" [(ngModel)]="selectedPage2">
                        <option value="" disabled selected>Select the second page</option>
                        <ng-container *ngFor="let group of filteredPages">
                          <optgroup [label]="group.title">
                            <option *ngFor="let page of group.urls" [value]="page.title">{{ page.title }}</option>
                          </optgroup>
                        </ng-container>
                      </select>
                    </div>
                    <button class="btn btn-primary" (click)="comparePages()">Compare</button>
                  </div>
                </div>
              </div>
            </div>
          <div class="table">
            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z14 m-3">
              <!-- Position Column -->
              <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef>
                  <mat-form-field>
                    <mat-select
                      (selectionChange)="applyFilter($event)"
                      placeholder="filter by"
                      >
                      @for (item of filterList; track item) {
                        <mat-option
                          [value]="item"
                          >
                          {{ item }}
                        </mat-option>
                      }
                      <mat-option (click)="resetFilters()"> Reset </mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="img">
                  <img
                    loading="lazy"
                    decoding="async"
                    alt="screenshot"
                    src="{{ element.image }}"
                    width="150"
                    height="150"
                    />
                  </mat-cell>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="title"  >
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <b> {{ titleURL | uppercase }}</b>
                  </mat-header-cell>
                  <mat-cell class="box" *matCellDef="let element">
                    <div appearance="outlined" class="d-flex flex-column justify-content-center align-items-center">
                      <div class="m-2">
                        <div class="d-flex justify-content-center align-items-center">
                            <span
                            class="title text-truncate"
                            #tooltip="matTooltip"
                            matTooltip="{{ element.title }}"
                            matTooltipHideDelay="100"
                            matTooltipPosition="above"
                            aria-label="Button that displays a tooltip that hides when scrolled out of the container"
                            style="white-space: nowrap; max-width: 100px;">
                            {{ urlName(element.title, element.homeUrl) }}
                        </span>
                          <mat-icon matTooltip="Copy Url:  {{element.homeUrl}} "(click) = "copyUrlToClipboard(element.homeUrl)"class="mx-1" style="cursor: pointer;">content_copy</mat-icon>
                        </div>

                        @if (printUxr(element.uxr)) {
                          <mat-card-subtitle class="uxr">
                            <circle-progress
                              title="UXR"
                              outerStrokeColor="{{ speedColors[element.overall] }}"
                              outerStrokeGradientStopColor="{{ speedColors[element.overall] }}"
                              subtitle="{{ element.uxr }}%"
                              percent="{{ element.uxr|number }}"
                            ></circle-progress>
                          </mat-card-subtitle>
                        }
                        <mat-card-content>
                          <div class="overall d-flex justify-content-center align-items-center my-2">
                            {{ element.overall }}
                            @if (getPassing(element.overall)) {
                              <i class="elt">
                                <mat-icon [className]="element.overall">check_circle</mat-icon>
                              </i>
                            }
                            @if (!getPassing(element.overall)) {
                              <i class="elt">
                                @if (element.overall != 'N/A') {
                                  <mat-icon
                                    [className]="element.overall"
                                    >cancel</mat-icon
                                    >
                                }
                              </i>
                            }
                          </div>
                        </mat-card-content>
                        <mat-card-actions class="buttons d-flex justify-content-center align-items-center ">
                          <div class="example-container ">
                            <a type="button" class="btn btn-primary d-flex justify-content-center align-content" routerLink="{{ urlDetails(element.title) }}" >
                              <mat-icon>timeline</mat-icon>
                              Track
                            </a>
                          </div>
                          </mat-card-actions>
                        </div>
                    </div>
                  </mat-cell>
                  </ng-container>
                  <ng-container *ngFor="let metric of metrics" [matColumnDef]="metric.toUpperCase()" class="chart">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ metric.toUpperCase() }}</mat-header-cell>
                    <mat-cell
                      style="display: block; font-weight: normal; text-align: center"
                      *matCellDef="let element; let i = index"
                      >
                      <div class="d-flex flex-column justify-content-center align-items-center">
                          <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                              <span class="mx-1">
                                75th: {{ metric === 'cls' ? (element[metric].p75 / 100) : element[metric].p75 }}{{ metric === 'cls' ? '' : 'ms' }}
                              </span>
                            <mat-icon
                              style="font-size: 18px; opacity: 0.7;"
                              [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                              matTooltipPosition="left">
                              help
                            </mat-icon>
                          </div>
                        <div class="diff-indicator d-flex justify-content-center align-items-center mt-2">
                          <ng-container [ngSwitch]="getDiffStatus(formatDiff(element[metric + 'Diff']))">

                            <!-- "improving" -->
                            <span *ngSwitchCase="'improving'" class="text-success small">
                              Improving (+{{ formatDiff(element[metric + 'Diff']) }}%)
                              <mat-icon
                              class="interactive-icon"
                                matTooltip="The metric has improved compared to the previous measurement."
                                matTooltipPosition="above"
                                matTooltipTouchGestures="on"
                              >
                                arrow_upward
                              </mat-icon>
                            </span>

                            <!-- "regressing" -->
                            <span *ngSwitchCase="'regressing'" class="text-danger small">
                              Regressing ({{ formatDiff(element[metric + 'Diff'])}}%)
                              <mat-icon
                              class="interactive-icon"
                                matTooltip="The metric has worsened compared to the previous measurement."
                                matTooltipPosition="above"
                                matTooltipTouchGestures="on"
                              >
                                arrow_downward
                              </mat-icon>
                            </span>

                            <!-- "stabilizing" -->
                            <span *ngSwitchCase="'stabilizing'" class="text-muted small">
                              Stabilizing ({{ formatDiff(element[metric + 'Diff'])}}%)
                              <mat-icon
                              class="interactive-icon"
                                matTooltip="The metric is stable with no significant change."
                                matTooltipPosition="above"
                                matTooltipTouchGestures="on"
                              >
                                arrow_forward
                              </mat-icon>
                            </span>

                          </ng-container>
                        </div>

                        <div class="chart-container">
                          <apx-chart
                            [series]="element.chartOptions[metric].series"
                            [chart]="element.chartOptions[metric].chart"
                            [dataLabels]="element.chartOptions[metric].dataLabels"
                            [plotOptions]="element.chartOptions[metric].plotOptions"
                            [xaxis]="element.chartOptions[metric].xaxis"
                            [yaxis]="element.chartOptions[metric].yaxis"
                            [stroke]="element.chartOptions[metric].stroke"
                            [fill]="element.chartOptions[metric].fill"
                            [tooltip]="element.chartOptions[metric].tooltip"
                            [legend]="element.chartOptions[metric].legend"
                            >
                          </apx-chart>
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
                </mat-table>
          </div>
        }

    </div>
</div>

