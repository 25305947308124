import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartComponent } from 'ng-apexcharts';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    standalone: false
})
export class DialogComponent implements OnInit {

  @ViewChild('chart') chart: ChartComponent;
  public chartOptionsPSI: Partial<any> = [];
  chartOptionsCruxP75: Partial<any>;
  cancelButtonText = "Cancel"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogComponent>) {
    if (data) {
      this.chartOptionsCruxP75 = data.chartOptionsCruxP75;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
    //this.dialogRef.updateSize('300vw','300vw')
  }

  ngOnInit(){

  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
