<app-layout>
  <div class="title d-flex flex-column justify-content-center align-items-center">
    <div class="fs-3 m-4">
      {{ alertPeriod | titlecase }} web performance drop alert
    </div>
    <div class="fs-5">
      Diff alert threshold is set to
      <b><span style="color: red">{{ alertThreshold }}</span></b>
    </div>
  </div>

      @if (!loading) {
          @for (d of dataSources; track $index) {
            <div class="m-4">
              <div class="fs-5 mb-4">
                <span class=" rounded-pill bg-white py-2 px-3 my-2 ">{{ d.siteTitle }} ({{ d.siteUrl }}) pages</span>
              </div>
              <mat-table [dataSource]="d.dataSource" class="card">
                <ng-container matColumnDef="PageTitle">
                  <mat-header-cell *matHeaderCellDef>Page Title</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="d-flex justify-content-center align-items-center"> 
                    <span class="fs-4">
                      {{ element.title }} 
                    </span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="URL">
                  <mat-header-cell *matHeaderCellDef>URL</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="m-3">
                    <div class="cards d-flex flex-column justify-content-center align-items-center p-3 h-100">
                      <span class="fs-6">
                        {{ element.url }}
                      </span>
                      <button
                        class="btn btn-primary mt-3"
                        (click)="navigateToPageDetails(d.projectId, element.title)"
                        >
                        Details
                      </button>
                    </div>
                  </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="OnMobile">
                    <mat-header-cell *matHeaderCellDef style="display: flex; justify-content: center">
                      On Mobile
                    </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="cards p-3 m-3">
                        <div style="width: 100%">
                          <div style="display: flex; border-bottom: 0.8px solid">
                            <div class="table-cell"><p>metric</p></div>
                            <div class="table-cell">prev</div>
                            <div class="table-cell">current</div>
                            <div class="table-cell">diff</div>
                          </div>
                          <div
                            style="display: flex"
                            [class]="
                            element.mobileDiffs.diff.fcpDiff <= alertThreshold
                            ? 'highlight'
                            : ''
                            "
                            >
                            <div class="table-cell">FCP</div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.previousData?.goodFcp || '0.00' }}
                            </div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.previousData?.goodFcp || '0.00' }}
                            </div>
                            <div class="table-cell">
                              @if (element.mobileDiffs.diff.fcpDiff < 0.0) {
                                <p
                                  style="color: red"
                                  >
                                  {{ element.mobileDiffs.diff.fcpDiff }}
                                </p>
                              }
                              @if (element.mobileDiffs.diff.fcpDiff >= 0.0) {
                                <p>
                                  {{ element.mobileDiffs.diff.fcpDiff }}
                                </p>
                              }
                            </div>
                          </div>
                          <div
                            style="display: flex"
                            [class]="
                            element.mobileDiffs.diff.clsDiff <= alertThreshold
                            ? 'highlight'
                            : ''
                            "
                            >
                            <div class="table-cell">CLS</div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.previousData?.goodCls || '0.00' }}
                            </div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.currentData?.goodCls || '0.00' }}
                            </div>
                            <div class="table-cell">
                              @if (element.mobileDiffs.diff.clsDiff < 0.0) {
                                <p
                                  style="color: red"
                                  >
                                  {{ element.mobileDiffs.diff.clsDiff }}
                                </p>
                              }
                              @if (element.mobileDiffs.diff.clsDiff >= 0.0) {
                                <p>
                                  {{ element.mobileDiffs.diff.clsDiff }}
                                </p>
                              }
                            </div>
                          </div>
                          <div
                            style="display: flex"
                            [class]="
                            element.mobileDiffs.diff.lcpDiff <= alertThreshold
                            ? 'highlight'
                            : ''
                            "
                            >
                            <div class="table-cell">LCP</div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.previousData?.goodLcp || '0.00' }}
                            </div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.currentData?.goodLcp || '0.00' }}
                            </div>
                            <div class="table-cell">
                              @if (element.mobileDiffs.diff.lcpDiff < 0.0) {
                                <p
                                  style="color: red"
                                  >
                                  {{ element.mobileDiffs.diff.lcpDiff }}
                                </p>
                              }
                              @if (element.mobileDiffs.diff.lcpDiff >= 0.0) {
                                <p>
                                  {{ element.mobileDiffs.diff.lcpDiff }}
                                </p>
                              }
                            </div>
                          </div>
                          <div
                            style="display: flex"
                            [class]="
                            element.mobileDiffs.diff.ttfbDiff <= alertThreshold
                            ? 'highlight'
                            : ''
                            "
                            >
                            <div class="table-cell">TTFB</div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.previousData?.goodTtfb || '0.00' }}
                            </div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.currentData?.goodTtfb || '0.00' }}
                            </div>
                            <div class="table-cell">
                              @if (element.mobileDiffs.diff.ttfbDiff < 0.0) {
                                <p
                                  style="color: red"
                                  >
                                  {{ element.mobileDiffs.diff.ttfbDiff }}
                                </p>
                              }
                              @if (element.mobileDiffs.diff.ttfbDiff >= 0.0) {
                                <p>
                                  {{ element.mobileDiffs.diff.ttfbDiff }}
                                </p>
                              }
                            </div>
                          </div>
                          <div
                            style="display: flex"
                            [class]="
                            element.mobileDiffs.diff.inpDiff <= alertThreshold
                            ? 'highlight'
                            : ''
                            "
                            >
                            <div class="table-cell">INP</div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.previousData?.goodInp }}
                            </div>
                            <div class="table-cell">
                              {{ element.mobileDiffs?.currentData?.goodInp }}
                            </div>
                            <div class="table-cell">
                              @if (element.mobileDiffs.diff.inpDiff < 0.0) {
                                <p
                                  style="color: red"
                                  >
                                  {{ element.mobileDiffs.diff.inpDiff }}
                                </p>
                              }
                              @if (element.mobileDiffs.diff.inpDiff >= 0.0) {
                                <p>
                                  {{ element.mobileDiffs.diff.inpDiff }}
                                </p>
                              }
                            </div>
                          </div>
                        </div>
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="OnDesktop">
                      <mat-header-cell *matHeaderCellDef style="display: flex; justify-content: center">
                        On Desktop
                      </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="cards p-3 m-3">
                          <div style="width: 100%">
                            <div style="display: flex; border-bottom: 0.8px solid">
                              <div class="table-cell"><p>metric</p></div>
                              <div class="table-cell">prev</div>
                              <div class="table-cell">current</div>
                              <div class="table-cell">diff</div>
                            </div>
                            <div
                              style="display: flex"
                              [class]="
                              element.desktopDiffs.diff.fcpDiff <= alertThreshold
                              ? 'highlight'
                              : ''
                              "
                              >
                              <div class="table-cell">FCP</div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.previousData?.goodFcp || '0.00' }}
                              </div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.previousData?.goodFcp || '0.00' }}
                              </div>
                              <div class="table-cell">
                                @if (element.desktopDiffs.diff.fcpDiff < 0.0) {
                                  <p
                                    style="color: red"
                                    >
                                    {{ element.desktopDiffs.diff.fcpDiff }}
                                  </p>
                                }
                                @if (element.desktopDiffs.diff.fcpDiff >= 0.0) {
                                  <p>
                                    {{ element.desktopDiffs.diff.fcpDiff }}
                                  </p>
                                }
                              </div>
                            </div>
                            <div
                              style="display: flex"
                              [class]="
                              element.desktopDiffs.diff.clsDiff <= alertThreshold
                              ? 'highlight'
                              : ''
                              "
                              >
                              <div class="table-cell">CLS</div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.previousData?.goodCls || '0.00' }}
                              </div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.currentData?.goodCls || '0.00' }}
                              </div>
                              <div class="table-cell">
                                @if (element.desktopDiffs.diff.clsDiff < 0.0) {
                                  <p
                                    style="color: red"
                                    >
                                    {{ element.desktopDiffs.diff.clsDiff }}
                                  </p>
                                }
                                @if (element.desktopDiffs.diff.clsDiff >= 0.0) {
                                  <p>
                                    {{ element.desktopDiffs.diff.clsDiff }}
                                  </p>
                                }
                              </div>
                            </div>
                            <div
                              style="display: flex"
                              [class]="
                              element.desktopDiffs.diff.lcpDiff <= alertThreshold
                              ? 'highlight'
                              : ''
                              "
                              >
                              <div class="table-cell">LCP</div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.previousData?.goodLcp || '0.00' }}
                              </div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.currentData?.goodLcp || '0.00' }}
                              </div>
                              <div class="table-cell">
                                @if (element.desktopDiffs.diff.lcpDiff < 0.0) {
                                  <p
                                    style="color: red"
                                    >
                                    {{ element.desktopDiffs.diff.lcpDiff }}
                                  </p>
                                }
                                @if (element.desktopDiffs.diff.lcpDiff >= 0.0) {
                                  <p>
                                    {{ element.desktopDiffs.diff.lcpDiff }}
                                  </p>
                                }
                              </div>
                            </div>
                            <div
                              style="display: flex"
                              [class]="
                              element.desktopDiffs.diff.ttfbDiff <= alertThreshold
                              ? 'highlight'
                              : ''
                              "
                              >
                              <div class="table-cell">TTFB</div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.previousData?.goodTtfb || '0.00' }}
                              </div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.currentData?.goodTtfb || '0.00' }}
                              </div>
                              <div class="table-cell">
                                @if (element.desktopDiffs.diff.ttfbDiff < 0.0) {
                                  <p
                                    style="color: red"
                                    >
                                    {{ element.desktopDiffs.diff.ttfbDiff }}
                                  </p>
                                }
                                @if (element.desktopDiffs.diff.ttfbDiff >= 0.0) {
                                  <p>
                                    {{ element.desktopDiffs.diff.ttfbDiff }}
                                  </p>
                                }
                              </div>
                            </div>
                            <div
                              style="display: flex"
                              [class]="
                              element.desktopDiffs.diff.inpDiff <= alertThreshold
                              ? 'highlight'
                              : ''
                              "
                              >
                              <div class="table-cell">INP</div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.previousData?.goodInp || '0.00' }}
                              </div>
                              <div class="table-cell">
                                {{ element.desktopDiffs?.currentData?.goodInp || '0.00' }}
                              </div>
                              <div class="table-cell">
                                @if (element.desktopDiffs.diff.inpDiff < 0.0) {
                                  <p
                                    style="color: red"
                                    >
                                    {{ element.desktopDiffs.diff.inpDiff }}
                                  </p>
                                }
                                @if (element.desktopDiffs.diff.inpDiff >= 0.0) {
                                  <p>
                                    {{ element.desktopDiffs.diff.inpDiff }}
                                  </p>
                                }
                              </div>
                            </div>
                          </div>
                        </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                  </mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
              </mat-table>
            </div>
                }
            }
        </app-layout>
        