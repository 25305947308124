import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.scss'],
    standalone: false
})
export class DeleteDialogComponent {
  message: string = "Are you sure?";
  confirmButtonText = "Yes";
  cancelButtonText = "No";
  showCancellationReason: boolean = false;
  cancellationReason: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DeleteDialogComponent>,
    private snackBar: MatSnackBar
  ) {
    if (data) {
      this.message = data.message || this.message;
      this.showCancellationReason = data.showCancellationReason || false;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(): void {
    if (this.showCancellationReason && !this.cancellationReason.trim()) {
      this.snackBar.open("Please provide a cancellation reason.", "Close", {
        duration: 4000,
      });
      return;
    }
    if (this.showCancellationReason) {
      this.dialogRef.close({ confirmed: true, cancellationReason: this.cancellationReason });
    } else {
      this.dialogRef.close(true);
    }
  }
}
