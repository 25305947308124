<app-help-widget
  [currentURL]="currentUrl"
  [userId]="userId"
  [projectID]="projectID"
  [Url] = "Url"
  >
</app-help-widget>
<nav class="mx-4 mt-3" aria-label="breadcrumb">
  <ol style="height: 50px; width: 52px;" class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center">
    @defer{
      @if (estAlloue(result?.country)) {
        <span class="mx-1">
            <mat-icon [svgIcon]="result?.country"></mat-icon>
        </span>
       }@else{
        <mat-icon  class="mx-1">language</mat-icon>
       }
    }
    <li>
      <span class="fw-bold">CRUX > </span>
    </li>
    <li class="breadcrumb-item">
      <a class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center " [href]="'/home/page/' + this.senderVarService.id + '/overview'">
        <mat-icon class="mx-2 ">desktop_windows</mat-icon>
       <span>{{WebSiteTitle}} Overview</span>
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      {{ displayName | uppercase }}
    </li>
  </ol>
</nav>
<div class="w-100 d-flex justify-content-center align-items-center">
  <div class="m-2 w-75">

    @if (loading) {
      <div class="d-flex justify-content-center align-items-center" style="height: -1000px">
        <mat-spinner class="spinner" diameter="150"></mat-spinner>
      </div>
    }


    @if (!loading && displaydata) {
      <div class="text-center">
        <h1 class="m-1">Domain-level {{ displayName | uppercase }} evolution</h1>
        <div>
          See how your domain-aggregated {{ displayName | uppercase }} is doing
          over time
          <button class="cta flex-grow-1" (click)="openLink()">
            <span class="hover-underline-animation">What's {{ displayName | uppercase }}?</span>
            <svg viewBox="0 0 46 16" height="10" width="30" xmlns="http://www.w3.org/2000/svg" id="arrow-horizontal">
              <path transform="translate(30)" d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z" data-name="Path 10" id="Path_10"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="m-3 d-flex justify-content-between align-items-center">
        <app-device-button (deviceEvent)="setDevice($event)"></app-device-button>
        <div class="">
          <button
            type="button"
            class="btn btn-primary btn-sm d-flex justify-content-center align-items-center"
            (click)="openDialogHistogram(displayName)"
            #tooltip="matTooltip"
            matTooltip="{{ metric | uppercase }} distribution histogram"
            matTooltipHideDelay="100">
            <p class="m-0 mx-1">Histogram</p>
            <mat-icon>history</mat-icon>
          </button>
        </div>
      </div>
      <div class="domain-level m-3 p-2">
        <div id="lcpOverTime">
          <div class="container">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div id="p75">
                <div class="chart d-flex justify-content-center align-items-center my-2">
                    <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                      <span class="mx-1">
                        {{ displayName | uppercase }} 75th percentile : {{p75}}{{ metric === 'cls' ? '' : 'ms' }}
                      </span>
                    <mat-icon
                      style="font-size: 18px; opacity: 0.7;"
                      [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                      matTooltipPosition="left">
                      help
                    </mat-icon>
                  </div>
                  @if (seeDialog()) {
                    <div>
                      <button type="button" class="btn btn-sm btn-primary d-flex justify-content-center align-items-center mx-2" (click)="openDialog()">
                        <p class="m-0">75th percentile progress</p>
                        <mat-icon>timeline</mat-icon>
                      </button>
                    </div>
                  }
                </div>
              </div>
              @if(!isSub){
                  <app-Free-msg [Message]="messageFromParent"></app-Free-msg>
              }
              <app-annotations></app-annotations>
            </div>
          </div>
          <apx-chart
            *ngIf="showOvertimeBarChart"
            [series]="chartOptionsCrux?.series"
            [chart]="chartOptionsCrux?.chart"
            [dataLabels]="chartOptionsCrux?.dataLabels"
            [plotOptions]="chartOptionsCrux?.plotOptions"
            [responsive]="chartOptionsCrux?.responsive"
            [xaxis]="chartOptionsCrux?.xaxis"
            [yaxis]="chartOptionsCrux?.yaxis"
            [legend]="chartOptionsCrux?.legend"
            [fill]="chartOptionsCrux?.fill"
            [annotations]="chartOptionsCrux?.annotations"
            [grid]="chartOptionsCrux?.grid">>
          </apx-chart >
        </div>
      </div>
    }
  @if (!loading && displaydata) {
    <div class="table">
      <h3>
        Check how your internal pages are performing on
        {{ displayName | uppercase }}
      </h3>
      <mat-table [dataSource]="dataSource" matSort class=" mat-elevation-z14 m-3">
        <!-- Screenshoot -->
        <ng-container matColumnDef="image">
          <mat-header-cell *matHeaderCellDef>
            <mat-form-field>
              <mat-select
                (selectionChange)="applyFilter($event)"
                placeholder="filter by"
                >
                @for (item of filterList; track $index) {
                  <mat-option [value]="item">
                    {{ item }}
                  </mat-option>
                }
                <mat-option (click)="resetFilters()"> Reset </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-header-cell>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef>
            <b> {{ titleURL | uppercase }} </b>
          </mat-header-cell>
          <mat-cell class="box" *matCellDef="let element">
            <div class="d-flex justify-content-center align-items-center">
              <div>
                {{ urlName(element.title, element.homeUrl) }}
              </div>
              <a matTooltip="Copy Url" class="mx-1 mt-2">
                <mat-icon (click) = "copierUrlDansPressePapier(element.homeUrl)" style="cursor: pointer;">content_copy</mat-icon>
              </a>
              <a class="mt-2" matTooltip="{{ element.homeUrl }}"  aria-label="Button that displays a tooltip that hides when scrolled out of the container" href="{{ element.homeUrl }}" target="_blank">
                <mat-icon >open_in_new</mat-icon>
              </a>
            </div>
          </mat-cell>
        </ng-container>
        <!-- CLS -->
          <ng-container matColumnDef="{{ metric }}" >
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ displayName | uppercase }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
               <apx-chart
              *ngIf="!!dataSource?.data?.length"
              [series]="element.chartOptions?.series"
              [chart]="element.chartOptions?.chart"
              [dataLabels]="element.chartOptions?.dataLabels"
              [plotOptions]="element.chartOptions?.plotOptions"
              [xaxis]="element.chartOptions?.xaxis"
              [yaxis]="element.chartOptions?.yaxis"
              [stroke]="element.chartOptions?.stroke"
              [fill]="element.chartOptions?.fill"
              [tooltip]="element.chartOptions?.tooltip"
              [legend]="element.chartOptions?.legend"
              >
            </apx-chart>
          </mat-cell>
        </ng-container>
        <!-- link -->
        <ng-container matColumnDef="link">
          <mat-header-cell *matHeaderCellDef>
            <b> Details </b>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <br />
            <div class="example-container">
              <a type="button" class="btn btn-sm btn-primary d-flex justify-content-center align-items-center " routerLink="{{ urlDetails(element.title) }}">
                <mat-icon>timeline</mat-icon>
                Track
              </a>
            </div>
            <br />
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
      </mat-table>
    </div>
  }
  @if (!displaydata && !loading) {
    <div class=" container d-flex justify-content-center align-items-center m-4">
      <div class="row">
        <div class="domain-level col-md-12 p-3">
          <div class="error-template">
            <h1>Oops!</h1>
            <h2>You chose to not monitor this website on {{ device }}</h2>
            <div class="error-actions">
              <a href="#/home" class="btn btn-primary btn-lg"
                ><span class="glyphicon glyphicon-home"></span> Take Me Home </a
                ><a href="" class="btn btn-default btn-lg"
                ><span class="glyphicon glyphicon-envelope"></span> Contact Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  </div>


</div>

