import { Component } from '@angular/core';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { firstValueFrom } from 'rxjs';
import { DataService } from 'src/app/services/common/sidabar/Data.service';
import { Router } from '@angular/router';


@Component({
    selector: 'app-nav-type',
    templateUrl: './nav-type.component.html',
    styleUrl: './nav-type.component.scss',
    standalone: false
})
export class NavTypeComponent {

  device: string;
  userId: string;
  loading = true;
  response: any;
  Country: string;
  result : any;
  title: string;
  ChartOptionsNavType:Partial<any>;
    ChartData = {
      nav_back_forward: [],
      nav_back_forward_cache: [],
      nav_navigate: [],
      nav_navigate_cache: [],
      nav_reload: [],
      nav_restore: [],
      nav_prerender: [],
      dates:[],
  };
  projectID: any;
  currentUrl: any;
  Url: any;

  constructor(
    private speetalsDatabase: SpeetalsDatabaseService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    public senderVarService: SenderVarService,
    private dataService: DataService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
      const sharedData = this.dataService.getSharedData();
      this.Url = this.router.url;
      this.currentUrl = sharedData.url;
      this.projectID = sharedData.projectID;
      this.device = localStorage.getItem(ConstantService.loadingUrl.device);
      this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
          try {
            const resp = await firstValueFrom(
              this.speetalsDatabase.getNavTypeData(
                this.userId,
                this.senderVarService.id,
                this.device,
              )
            );
            this.result = resp;
            this.Country = this.result[0].country
            this.title = this.result[0].title
            resp.forEach(item => {
              const navBackForward = item.nav_back_forward != null ? Math.round(item.nav_back_forward * 100) : 0;
              const navBackForwardCache = item.nav_back_forward_cache != null ? Math.round(item.nav_back_forward_cache * 100) : 0;
              const navNavigate = item.nav_navigate != null ? Math.round(item.nav_navigate * 100) : 0;
              const navNavigateCache = item.nav_navigate_cache != null ? Math.round(item.nav_navigate_cache * 100) : 0;
              const navReload = item.nav_reload != null ? Math.round(item.nav_reload * 100) : 0;
              const navRestore = item.nav_restore != null ? Math.round(item.nav_restore * 100) : 0;
              const navPrerender = item.nav_prerender != null ? Math.round(item.nav_prerender * 100) : 0;

              // Vérifier si toutes les valeurs calculées sont zéro
              if (navBackForward === 0 && navBackForwardCache === 0 && navNavigate === 0 &&
                  navNavigateCache === 0 && navReload === 0 && navRestore === 0 && navPrerender === 0) {
              } else {
                this.ChartData.nav_back_forward.push(navBackForward);
                this.ChartData.nav_back_forward_cache.push(navBackForwardCache);
                this.ChartData.nav_navigate.push(navNavigate);
                this.ChartData.nav_navigate_cache.push(navNavigateCache);
                this.ChartData.nav_reload.push(navReload);
                this.ChartData.nav_restore.push(navRestore);
                this.ChartData.nav_prerender.push(navPrerender);
                this.ChartData.dates.push(item.yyyymm != null ? item.yyyymm : "Unknown Date");
              }
            });
            this.ChartOptionsNavType = this.pageSpeedInsigthService.chartOptionNavType(this.ChartData);
            this.loading = false;
          } catch (error) {
            console.error('Une erreur est survenue:', error);
          }
    }
  setDevice($event) {
    localStorage.setItem(ConstantService.loadingUrl.device, $event);
    this.resetChartData()
    this.ngOnInit();
  }
  urlName(name, homeUrl) {
    return this.response.find((r) => r.url === homeUrl)?.originDomain + '/' ===
      homeUrl
      ? 'home page '
      : name;
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined) return false;
    return true;
  }
  resetChartData() {
    this.ChartData.nav_back_forward = [];
    this.ChartData.nav_back_forward_cache = [];
    this.ChartData.nav_navigate = [];
    this.ChartData.nav_navigate_cache = [];
    this.ChartData.nav_reload = [];
    this.ChartData.nav_restore = [];
    this.ChartData.nav_prerender = [];
    this.ChartData.dates = [];
  }
  openLink() {
    window.open(`https://developer.chrome.com/blog/crux-navigation-types`, '_blank');
  }
}
