<!-- <mat-form-field appearance="fill">
<mat-label>Date</mat-label>
<input matInput [matDatepicker]="picker"
  (dateInput)="addEvent('input', $event)">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<mat-form-field appearance="fill">
  <p><input type="text" id="username" name="username" placeholder="text" [(ngModel)]="text" matInput autofocus></p>
</mat-form-field>
<button mat-raised-button color="primary"  type="button" (click)="addAnnotation()">Add annotation</button> -->
<div class="d-flex flex-column justify-content-center align-items-center my-2">
  <button type="button" class="btn btn-outline-primary d-flex justify-content-center align-items-center" (click)="checked=!checked">
    <mat-icon>flag</mat-icon>
    <p class="m-0">Add annotation</p>
  </button>
  @if ( checked) {
    <div class="add-annotation my-3 p-2">
      <div class="container d-flex justify-content-center align-items-center">
        <mat-form-field class="rounded p-0 mt-3" appearance = "outline">
          <mat-label>Date</mat-label>
          <input matInput [matDatepicker]="picker" (click)="picker.open()" (dateInput)="addEvent('input', $event)" autocomplete="off" placeholder="Select date" (keydown)="false">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="rounded p-0 mx-3 mt-3" appearance = "outline">
          <mat-label>Annotation</mat-label>
          <input type="text" id="text" name="text" placeholder="Annotation" [(ngModel)]="text" autocomplete="off" matInput autofocus>
        </mat-form-field>
        <button type="button" class="btn btn-success btn-sm"  (click)="addAnnotation()">
          Add annotation
        </button>
      </div>
    </div>
  }
</div>
