import {  Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartComponent } from 'ng-apexcharts';

@Component({
    selector: 'app-uxr-dialog',
    templateUrl: './uxr-dialog.component.html',
    styleUrls: ['./uxr-dialog.component.scss'],
    standalone: false
})
export class UxrDialogComponent implements OnInit {
  @ViewChild('chart1') chart1: ChartComponent;
  @ViewChild('chart2') chart2: ChartComponent;
  public chartOptions1: Partial<any>;
  public chartOptions2: Partial<any>;
  element: any;
  free : boolean;
  isUXRovertime: boolean = false;
  isWVovertime: boolean = false;
  cancelButtonText = "Cancel"
  upgradeButtonText = "Upgrade"
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<UxrDialogComponent>,
  ) {
    if (data){
      this.element = data.element
      this.chartOptions1 = data.chartOptions1;
      this.chartOptions2 = data.chartOptions2;
      this.free = data.free
      this.isUXRovertime = data.isUXRovertime
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText || this.upgradeButtonText ;
      }
    }

   
  }

  ngOnInit(): void {
    this.dialogRef.afterOpened().subscribe(() => {
      this.simulateResize();
    });

  }


  simulateResize() {
    window.dispatchEvent(new Event('resize'));
  }
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
