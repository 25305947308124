import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/common/user/user.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { StripeService } from 'src/app/services/stripe.service';

export interface ReqOffer {
  id?: string;
  IDoffer: string;
  price: number;
  Qte: number;
  description: string;
  active?: boolean;
  status?: string;
  // Vous pouvez ajouter d'autres champs, par exemple, cancellationReason, si nécessaire côté modèle
}

@Component({
    selector: 'app-request-offer',
    templateUrl: './request-offer.component.html',
    styleUrls: ['./request-offer.component.scss'],
    standalone: false
})
export class RequestOfferComponent implements OnInit {
  displayedColumns: string[] = ['IDoffer', 'price', 'description', 'Website', 'status', 'action'];
  dataSource: MatTableDataSource<ReqOffer> = new MatTableDataSource();
  userId: string;
  message: string = "No offers available";
  hasOffer: boolean;
  plan: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private stripeService: StripeService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userId = localStorage.getItem('userId');
    this.plan = await firstValueFrom(this.userService.getPlanWithName("credit"));
    this.loadOffers();
  }

  loadOffers() {
    this.userService.getOffersByUserId(this.userId).subscribe({
      next: (data: ReqOffer[]) => {
        this.dataSource.data = data.map(offer => {
          if (!offer.status) {
            offer.status = offer.active === false ? 'rejected' : 'pending';
          }
          return offer;
        });
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.hasOffer = this.dataSource.data.length > 0;
      },
      error: (err) => {
        this.snackBar.open('Error loading offers.', 'Close', { duration: 3000 });
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async redirectToCheckout(priceID: string, quantity: number, mode?: string) {
    const model = {
      priceId: priceID,
      userID: localStorage.getItem('userId'),
      mode: mode,
      planType: 'credit',
      quantity: quantity,
    };
    try {
      await this.stripeService.redirectToCheckout(model);
    } catch (error) {
      this.snackBar.open('Error during checkout redirection.', 'Close', { duration: 3000 });
      console.error('Redirect to checkout error:', error);
    }
  }

  handleAction(offer: ReqOffer, action: string) {
    if (action === 'reject') {
      // Ouvrir le dialogue en passant showCancellationReason: true
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: {
          title: 'Confirm Rejection',
          message: `Are you sure you want to reject offer ${offer.IDoffer}? Please provide a cancellation reason.`,
          showCancellationReason: true
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.confirmed) {
          const cancellationReason = result.cancellationReason;
          this.userService.rejectOffer(offer.id, cancellationReason).subscribe({
            next: () => {
              this.snackBar.open(`Offer ${offer.IDoffer} rejected.`, 'Close', { duration: 3000 });
              this.loadOffers();
            },
            error: (err) => {
              this.snackBar.open('Error rejecting offer.', 'Close', { duration: 3000 });
            }
          });
        }
      });
    } else if (action === 'checkout') {
      const priceID = this.plan.priceID.monthly;
      this.snackBar.open(`Redirecting to checkout for offer ${offer.IDoffer}.`, 'Close', { duration: 3000 });
      this.redirectToCheckout(priceID, offer.Qte, 'payment').then(() => {
        this.userService.updateOfferStatus(offer.id, 'paid').subscribe({
          next: () => {
            this.snackBar.open(`Offer ${offer.IDoffer} marked as paid.`, 'Close', { duration: 3000 });
            this.loadOffers();
          },
          error: (err) => {
            this.snackBar.open('Error updating offer status.', 'Close', { duration: 3000 });
          }
        });
      });
    }
  }
}
