<app-layout>
  <div class="d-flex justify-content-center align-items-center">
    <h1 style="margin-top: 20px; margin-bottom: 0">My Account</h1>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <div class="cards m-4 w-75">
      <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="Usage and limits">
          <ng-template matTabContent>
            @if (testView) {
            <div class="section conteneur-flex">
              <div class="cards p-3 m-3 plan-container text-center d-flex justify-content-center align-items-center"style="flex: 1">
                <div>
                  <h2 class="m-0">Plan limits and usage:</h2>
                  <div>
                    <h4>{{ plan.name | uppercase }}</h4>
                    @if (!plan.details.internalPagesOnly) {
                    <p>
                      {{ websitesRestantes }} remaining websites out of
                      {{ plan.details.nbWebsite.value }}
                      <br />
                      {{ plan.details.nbInternalPage.value }} Internal pages per
                      website<br />
                      {{ plan.details.nbCompetitor.value }} competitors per
                      website <br />
                      {{ locationAllow }} for domain-level track
                    </p>
                    } @if (!!plan && plan.details.internalPagesOnly) {
                    <p>
                      {{ plan.details.nbInternalPage.value }} Internal pages /
                      {{ remainingInternalPages }} remaining<br />
                    </p>
                    } @if (plan?.details?.realUserMonitoring?.value) {
                      <p>
                        RUM: {{ userData.userPageViews }} page views /
                        {{ plan?.details?.rumMonthlyPV?.value }} this month
                        ({{ getPageVisitPercentage() }}% usage).<br />
                        @if(userData.isTrialActive){
                          Your quota will expire on: <strong>{{ getRenewalDate(userData) }}</strong>.
                        }@else{
                          Your quota will renew on: <strong>{{ getRenewalDate(userData) }}</strong>.
                        }

                      </p>
                    }
                  </div>
                  @if (!free) {
                  <div class="section">
                    <a
                      class="btn btn-primary mb-2"
                      href="https://billing.stripe.com/p/login/bIY00D8ZCh2H7kYaEE"
                      target="_blank"
                      >Manage your subscription</a
                    >
                  </div>
                  } @if (upgrade) {
                  <div class="upgrade-button">

                   <a href="/buyPlan"> <button
                      class="btn btn-primary m-2"

                    >
                      Upgrade
                    </button></a>
                  </div>
                  }
                </div>
              </div>
              @if(plan?.details?.realUserMonitoring?.value) {
              <div class="cards p-3 m-3" style="flex: 2">
                <app-page-view-over-time></app-page-view-over-time>
              </div>
              }
            </div>
            }
          </ng-template>
        </mat-tab>
        @if(showCheckList1 || showCheckList2){
          <mat-tab label="Guided tour">
            <ng-template matTabContent>
             <div class="d-flex flex-column flex-md-row justify-content-around align-items-center">
               @if(showCheckList1){
                 <app-check-list
                 class="w-100 w-md-50 align-self-md-start"
                 style="max-width: 400px;"
                 [noSitesCreated]="siteData1.noSitesCreated"
                 [hasCreatedWebsite]="siteData1.hasCreatedWebsite"
                 [hasSharedWebsite]="siteData1.hasSharedWebsite"
                 [isCRUX]="siteData1.isCRUX"
                 [checklistId]="'listwebsite'"
               ></app-check-list>
                }
                @if(showCheckList2){
                 <app-check-list
                 class="w-100 w-md-50"
                  style="max-width: 400px;"
                 [noSitesCreated]="siteData2.noSitesCreated"
                 [hasCreatedWebsite]="siteData2.hasCreatedWebsite"
                 [hasSharedWebsite]="siteData2.hasSharedWebsite"
                 [isCRUX]="siteData2.isCRUX"
                 [areWeReceivingData]="siteData2.areWeReceivingData"
                 [hasRUM]="siteData2.hasRUM"
                 [checklistId]="'listwebsite-first-party-data'"
               ></app-check-list>
                }
             </div>
            </ng-template>
           </mat-tab>
        }
        <mat-tab label="RUM Settings">
          <ng-template matTabContent>
            @if(isRUM) {
              <div class="cards p-3 m-5">
                @if (userData?.apiKey) {
                  <div class="section d-flex flex-column justify-content-center align-items-center">
                    <!-- API Key section stays the same -->
                  </div>

                  <!-- Sampling Settings Section -->
                  <div class="section mt-4">
                    <h2 class="fw-medium">Sampling Configuration</h2>

                    <!-- Client-side Sampling (Default) -->
                    <div class="mb-4">
                      <h3>1. Client-Side Sampling (Recommended)</h3>
                      <p class="text-muted mb-2">
                        💡 Client-side sampling reduces network traffic and provides more predictable costs.
                        Events are filtered before being sent to our servers.
                      </p>

                      <mat-form-field appearance="outline" class="my-2">
                        <mat-label>Client-Side Sampling Rate (%):</mat-label>
                        <input
                          type="number"
                          [(ngModel)]="clientSideSamplingRate"
                          matInput
                          placeholder="Ex: 60%"
                          step="0.1"
                          min="1"
                          max="100"
                          (ngModelChange)="updateClientSideSnippet()"
                        />
                        <mat-error>Please enter a number between 1 and 100.</mat-error>
                      </mat-form-field>

                      <div class="input-group input-group-sm mb-2">
                        <input
                          type="text"
                          class="form-control input-non-desactive"
                          [placeholder]="clientSideScriptSnippet"
                          disabled
                        />
                        <button
                          (click)="copyClientSideSnippet()"
                          class="btn btn-primary d-flex justify-content-center align-items-center"
                          type="button"
                        >
                          <mat-icon class="icon-display">content_copy</mat-icon>
                        </button>
                      </div>

                      <p class="text-muted small">
                        ℹ️ Note: To adjust the sampling rate later, modify the <code>sampling</code> parameter in the script URL.
                        For example: <code>...js?apiKey=xxx&sampling=75</code>
                      </p>

                      <!-- Async/Defer checkboxes for client-side -->
                      <div class="input-group input-group-sm mb-3">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="csAsyncCheckbox"
                            [(ngModel)]="isClientAsync"
                            (change)="updateClientSideSnippet()"
                          />
                          <label class="form-check-label" for="csAsyncCheckbox">Async</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="csDeferCheckbox"
                            [(ngModel)]="isClientDefer"
                            (change)="updateClientSideSnippet()"
                          />
                          <label class="form-check-label" for="csDeferCheckbox">Defer</label>
                        </div>
                      </div>
                    </div>

                    <!-- Server-side Sampling (Additional Option) -->
                    <mat-expansion-panel class="mt-3">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          2. Server-Side Sampling (Additional Option)
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="px-5 py-2">
                        <div class="text-muted">
                          <p>💡 Server-side sampling can be used:</p>
                          <ul>
                            <li>As an alternative to client-side sampling</li>
                            <li>In addition to client-side sampling for more granular control</li>
                          </ul>
                          <p>
                            ⚠️ Note: With server-side sampling, all events are sent to our servers before being filtered.
                            Consider using client-side sampling first for better performance especially for high traffic websites.
                          </p>
                        </div>

                        <form>
                          <div class="d-flex flex-column">
                            <mat-form-field appearance="outline" class="mb-2">
                              <mat-label>Server-Side Sampling Rate (%):</mat-label>
                              <input
                                type="number"
                                [(ngModel)]="rumSettings.samplingRate"
                                matInput
                                placeholder="Ex: 60%"
                                step="0.1"
                                [ngModelOptions]="{ standalone: true }"
                                min="1"
                                max="100"
                              />
                              <mat-error>Please enter a number between 1 and 100.</mat-error>
                            </mat-form-field>

                            <div class="d-flex justify-content-end">
                              <button
                                (click)="updateRumSettings()"
                                class="btn btn-primary"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>

                        <!-- Server-side script snippet with async/defer options -->
                        <div class="input-group input-group-sm mt-3">
                          <input
                            type="text"
                            class="form-control input-non-desactive"
                            [placeholder]="scriptSnippet"
                            disabled
                          />
                          <button
                            (click)="copyRumSnippet()"
                            class="btn btn-primary d-flex justify-content-center align-items-center"
                            type="button"
                          >
                            <mat-icon class="icon-display">content_copy</mat-icon>
                          </button>
                        </div>

                        <!-- Added back the async/defer checkboxes for server-side -->
                        <div class="input-group input-group-sm mt-2">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="ssAsyncCheckbox"
                              [(ngModel)]="isAsync"
                              (change)="updateScriptSnippet()"
                            />
                            <label class="form-check-label" for="ssAsyncCheckbox">Async</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="ssDeferCheckbox"
                              [(ngModel)]="isDefer"
                              (change)="updateScriptSnippet()"
                            />
                            <label class="form-check-label" for="ssDeferCheckbox">Defer</label>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </div>
                } @else {
                  <div>
                    <button (click)="generateApiKey()" mat-raised-button>
                      <mat-icon>cached</mat-icon> Generate Your api key
                    </button>
                  </div>
                }
              </div>

              <!-- URL Segments Card - Now full width -->
              <div class="cards p-3 m-5 mt-0">
                <app-url-by-segment-settings></app-url-by-segment-settings>
              </div>
            } @else {
              <div class="cards p-3 m-5">
                <app-oops-no-data [message] = "messageFromParent" ></app-oops-no-data>
              </div>
            }
          </ng-template>
        </mat-tab>
        <mat-tab label="User Journeys">
          <ng-template matTabContent>
            <!-- En-tête -->
            <div *ngIf="userJourneys && userJourneys.length > 0" class="d-flex justify-content-between align-items-center mt-3 px-4">
              <h3 class="fw-bold">Your User Journeys</h3>
              <button
                class="btn btn-primary d-flex justify-content-center align-items-center"
                (click)="openCreateWorkflowDialog()"
              >
                <mat-icon class="me-2">add</mat-icon>
                <span>Create a new User Journey</span>
              </button>
            </div>

            <!-- État de chargement -->
            <div *ngIf="loadingWorkflows" class="d-flex justify-content-center align-items-center my-5">
              <mat-spinner diameter="40"></mat-spinner>
            </div>

            <!-- État vide -->
            <div
              *ngIf="!loadingWorkflows && (!userJourneys || userJourneys.length === 0)"
              class="text-center my-5 py-5"
            >
              <mat-icon class="text-muted" style="font-size: 48px; height: 48px; width: 48px;">
                route
              </mat-icon>
              <p class="mt-3 text-muted">You haven't created any User Journeys yet.</p>
              <button class="btn btn-outline-primary mt-2" (click)="openCreateWorkflowDialog()">
                Create your first journey
              </button>
            </div>

            <!-- Liste des journeys -->
            <div *ngIf="!loadingWorkflows && userJourneys && userJourneys.length > 0" class="m-4 px-4">
              <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                <div *ngFor="let journey of userJourneys" class="col">
                  <div class="card h-100 shadow-sm">
                    <!-- En-tête de la carte -->
                    <div class="card-header d-flex justify-content-between align-items-center bg-light">
                      <span
                        class="badge"
                        [ngClass]="journey.type === 'CRUX' ? 'bg-primary text-white' : 'bg-success text-white'"
                      >
                        {{ journey.type }}
                      </span>
                      <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="viewJourney(journey)">
                          <span>View</span>
                        </button>
                        <button mat-menu-item class="text-danger" (click)="deleteJourney(journey)">
                          <span>Delete</span>
                        </button>
                      </mat-menu>
                    </div>

                    <!-- Contenu de la carte -->
                    <div class="card-body">
                      <h5 class="card-title fw-bold">{{ journey.name }}</h5>
                      <p class="card-text text-muted">
                        {{ journey.pages?.length || 0 }} pages
                      </p>
                      <div class="journey-pages mt-3">
                        <div
                          *ngFor="let page of journey.pages; let i = index"
                          class="d-flex align-items-center mb-2"
                        >
                          <span class="journey-step me-2">{{ i + 1 }}</span>
                          <span class="journey-url text-truncate" [title]="page.url">
                            {{ extractPath(page.url) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- Pied de la carte -->
                    <div class="card-footer bg-white border-0">
                      <button class="btn btn-sm btn-outline-primary w-100" (click)="viewJourney(journey)">
                        View Journey
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Alert Settings">
          <ng-template matTabContent>
            @if (!free) {
            <div
              class="section cards p-3 m-5 d-flex flex-column justify-content-center align-items-center"
            >
              <div>
                <h2 class="m-0">Integration for Slack</h2>
              </div>
              @if (isConnected) {
              <h3 class="m-0 text-center">
                Connected To: <b>{{ channelName }}</b> channel in
                <b>{{ teamName }}</b> workspace
              </h3>
              <div
                class="col-10 mt-3 conteneur-flex d-flex justify-content-center align-items-center"
              >
                <mat-form-field appearance="outline" class="mx-2">
                  <mat-label> Diff threshold for alert:</mat-label>
                  <input
                    [(ngModel)]="diffThreshold"
                    type="number"
                    matInput
                    min="0"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline" class="mx-2">
                  <mat-label>Slack alert period:</mat-label>
                  <mat-select [(ngModel)]="slackAlertPeriod">
                    <mat-option value="daily">Daily</mat-option>
                    <mat-option value="weekly">Weekly</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 d-flex justify-content-end align-items-center">
                <button
                  (click)="confirmDisconect()"
                  type="button"
                  class="btn btn-primary col-2 mx-4 d-flex justify-content-center align-items-center"
                  style="width: max-content"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="height: 16px; width: 16px; margin-right: 12px"
                    viewBox="0 0 122.8 122.8"
                  >
                    <path
                      d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                      fill="#e01e5a"
                    ></path>
                    <path
                      d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                      fill="#36c5f0"
                    ></path>
                    <path
                      d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                      fill="#2eb67d"
                    ></path>
                    <path
                      d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                      fill="#ecb22e"
                    ></path>
                  </svg>
                  Disconnect Slack
                </button>
                <button
                  (click)="saveSlackDiffAndPeriod()"
                  type="button"
                  class="col-2 btn btn-primary"
                  style="width: max-content"
                >
                  Save
                </button>
              </div>
              }
              <div
                class="col-12 d-flex justify-content-center align-items-center"
              >
                @if (!isConnected && !free) {
                <a
                  [href]="getClientRandomId()"
                  type="button"
                  class="btn btn-primary m-2 d-flex justify-content-center align-items-center"
                  style="width: max-content"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="height: 20px; width: 20px; margin-right: 12px"
                    viewBox="0 0 122.8 122.8"
                  >
                    <path
                      d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                      fill="#e01e5a"
                    ></path>
                    <path
                      d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                      fill="#36c5f0"
                    ></path>
                    <path
                      d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                      fill="#2eb67d"
                    ></path>
                    <path
                      d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                      fill="#ecb22e"
                    ></path>
                  </svg>
                  Add to Slack
                </a>
                }
              </div>
            </div>
            }@else{
              <div class="cards p-3 m-5">
              <app-oops-no-data [message] = "messageForNoData" ></app-oops-no-data>
              </div>
            }
          </ng-template>
        </mat-tab>
        <mat-tab label="Account Settings">
          <ng-template matTabContent>
            <div class="conteneur-flex">
              <div class="cards p-3 m-5 flex-fill">
                <div
                  class="section d-flex flex-column justify-content-center align-items-center"
                >
                  @if (userData?.hasCredentialLogin) {
                  <h2 class="fw-medium">Update Password</h2>
                  <form
                    [formGroup]="updatePasswordForm"
                    (ngSubmit)="updatePasswordForm.valid && updatePassword()"
                    novalidate
                  >
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Current Password</mat-label>
                          <input
                            type="password"
                            matInput
                            placeholder="Current Password"
                            formControlName="currentPassword"
                          />
                          @if
                          (updatePasswordForm.controls['currentPassword'].hasError('required'))
                          {
                          <mat-error>
                            Current password is <strong>required</strong>
                          </mat-error>
                          } @if
                          (updatePasswordForm.controls.currentPassword.errors?.minlength)
                          {
                          <mat-error> Minimum length should be 8 </mat-error>
                          }
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>New Password</mat-label>
                          <input
                            type="password"
                            matInput
                            placeholder="New password"
                            formControlName="newPassword"
                          />
                          @if
                          (updatePasswordForm.controls['newPassword'].hasError('required'))
                          {
                          <mat-error>
                            New Password is <strong>required</strong>
                          </mat-error>
                          } @if
                          (updatePasswordForm.controls.newPassword.errors?.minlength)
                          {
                          <mat-error> Minimum length should be 8 </mat-error>
                          }
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <button
                          mat-raised-button
                          color="primary"
                          type="submit"
                          class="full-width"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                  } @if (!userData?.hasCredentialLogin) {
                  <h2 class="fw-medium">Create Password</h2>
                  <form
                    [formGroup]="createPasswordForm"
                    (ngSubmit)="createPasswordForm.valid && createPassword()"
                    novalidate
                  >
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Password</mat-label>
                          <input
                            type="password"
                            matInput
                            placeholder="Password"
                            formControlName="password"
                          />
                          @if
                          (createPasswordForm.controls['password'].hasError('required'))
                          {
                          <mat-error>
                            Password is <strong>required</strong>
                          </mat-error>
                          } @if
                          (createPasswordForm.controls.password.errors?.minlength)
                          {
                          <mat-error> Minimum length should be 8 </mat-error>
                          }
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Confirm Password</mat-label>
                          <input
                            type="password"
                            matInput
                            placeholder="Confirm password"
                            formControlName="confirmPassword"
                          />
                          @if
                          (createPasswordForm.controls['confirmPassword'].hasError('required'))
                          {
                          <mat-error>
                            Confrim Password is <strong>required</strong>
                          </mat-error>
                          } @if
                          (createPasswordForm.controls.confirmPassword.errors?.minlength)
                          {
                          <mat-error> Minimum length should be 8 </mat-error>
                          } @if
                          (createPasswordForm.controls.confirmPassword.errors?.notSame)
                          {
                          <mat-error> Password dosen't match </mat-error>
                          }
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <button
                          mat-raised-button
                          color="primary"
                          type="submit"
                          class="full-width"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                  }
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</app-layout>
