import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebPerfInsights } from '../shared/models/webperf-insights.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  private apiUrl = `${environment.apiUrl}/insights`;

  constructor(private http: HttpClient) {}

  getLatestInsights(projectId: string): Observable<WebPerfInsights> {
    return this.http.get<WebPerfInsights>(`${this.apiUrl}/${projectId}/latest`);
  }
}