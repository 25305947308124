import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/common/user/user.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';

@Component({
    selector: 'app-loading-page',
    templateUrl: './loading-page.component.html',
    styleUrls: ['./loading-page.component.scss'],
    standalone: false
})
export class LoadingPageComponent implements OnInit {
  showAddAnotherPageButton = false;
  message = 'website, your pages, and your competitors';
  isPageOnly = false
  constructor(
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.delay(20000).then((resp) => {
      this.router.navigate(['/home']);
    });
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    this.userService.getPlan(userId).subscribe((resp) => {
      this.showAddAnotherPageButton = resp.name != 'Free';
    });

    this.activatedRoute.queryParams.subscribe((res) => {
      this.message = res?.t ? 'pages' : this.message;
      this.isPageOnly = !!res?.t
    });
  }
  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
