<!-- firstFormGroup : Domain && name && device && country -->
<app-layout>
 <div class="d-flex justify-content-center align-items-center">
  <div class="cards m-4 mb-5 w-75">
    <mat-horizontal-stepper [linear]="!isLinear" #stepper class="cards p-0" >

       <mat-step [stepControl]="firstFormGroup" *ngIf="edit">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Domain and monzitoring</ng-template>
          <mat-card-header>
            <mat-card-title class="m-2 pl-3"
              >Website and monitoring information:</mat-card-title
            >
          </mat-card-header>
          <div class="row">
            <div class="col">
              <mat-form-field *ngIf="!editDataLoading" class="full-width">
                <mat-label>Domain name</mat-label>
                <input
                  [(ngModel)]="editWebsite.title"
                  matInput
                  placeholder="Website name"
                  formControlName="title"
                  readonly
                />
              </mat-form-field>
            </div>
          </div>
          <div class="col">
            <mat-form-field *ngIf="!editDataLoading" class="full-width">
              <mat-label>Domain name</mat-label>
              <input
                [(ngModel)]="editWebsite.originDomain"
                matInput
                placeholder="Website domain"
                formControlName="domain"
                type="url"
                readonly
              />
            </mat-form-field>
          </div>
          <div class="from">

            <div class="d-flex justify-content-center">
              Check at least one checkbox <span class="highlight">*</span>
            </div>

            <div class="row justify-content-around">
              <div class="col-4" formGroupName="device">
                <div class="row justify-content-between">
                  <div *ngIf="!editDataLoading" class="col-4">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="myCheckbox1"
                        [(ngModel)]="deviceMobile"
                        name="deviceMobile"
                      />
                      <label class="form-check-label" for="myCheckbox1">
                        <b>Mobile</b>
                        <mat-icon>smartphone</mat-icon>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="!editDataLoading" class="col-4">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="myCheckbox2"
                        [(ngModel)]="deviceDesktop"
                        name="deviceDesktop"
                      />
                      <label class="form-check-label" for="myCheckbox2">
                        <b>Desktop</b>
                        <mat-icon>computer</mat-icon>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center" style="margin-top: 2%">
                <h2>Monitoring location</h2>
                <mat-card

                  *ngIf="!locationButton"
                  style="margin-top: -1%; margin-left: 1%; margin-right: 1%"
                >
                  <div
                    class="row d-flex justify-content-start align-items-center"
                  >
                    <mat-icon>language</mat-icon> Worldwide
                    <p *ngIf="!locationButton">
                      You should upgrade to enable domain-level location-based
                      monitoring.
                      <a mat-stroked-button [routerLink]="'/buyPlan'"
                        >Upgrade now!</a
                      >
                    </p>
                  </div>
                </mat-card>
                <app-mat-select-country 
                  *ngIf="locationButton"
                  [disabled]="!locationButton"
                  appearance="outline"
                  label="Country"
                  [value]="countrydefaultValue"
                  (onCountrySelected)="onCountrySelected($event)"
                  style="margin-top: -1%; margin-left: 1%; margin-right: 1%"
                >
                  <mat-select-trigger>
                    {{ selected?.flag }}
                  </mat-select-trigger>
                </app-mat-select-country>
              </div>

              <div></div>
            </div>
            <button mat-button class="bou" [routerLink]="['/home']">
              Cancel
            </button>

            <button mat-raised-button color="primary" matStepperNext>
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="firstFormGroup" *ngIf="!edit" class="card gray">
        <form [formGroup]="firstFormGroup" class="graph mt-3">
          <div class="p-3">
            <ng-template matStepLabel>Domain and monitoring</ng-template>
            <div class="card-body p-0">
              <div class="d-flex flex-column flex-md-row justify-content-center align-items-center">
                <div class="p-0 w-100 d-flex justify-content-center align-items-center">
                  <mat-form-field class="full-width w-50" appearance="outline">
                    <mat-label>Website name here </mat-label>
                    <input
                      *ngIf="!edit"
                      matInput
                      placeholder="My website"
                      formControlName="title"
                      autofocus
                    />
                    <input
                      *ngIf="edit && i === 0"
                      [(ngModel)]="editWebsite.title"
                      matInput
                      placeholder="example"
                      formControlName="title"
                      readonly
                    />
                    <mat-error
                      *ngIf="
                        firstFormGroup.controls['title'].hasError('required')
                      "
                    >
                      Website name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="p-0 w-100 d-flex justify-content-center align-items-center">
                    <mat-form-field
                      class="full-width w-75"
                      appearance="outline"
                    >
                      <mat-label>Domain name</mat-label>
                      <input
                        *ngIf="!edit"
                        matInput
                        placeholder="https://www.example.com"
                        formControlName="domain"
                        type="url"
                      />
                      <input
                        *ngIf="edit && i === 0"
                        [(ngModel)]="editWebsite.originDomain"
                        matInput
                        placeholder="Website domain"
                        formControlName="domain"
                        type="url"
                        readonly
                      />
                      <mat-error
                        *ngIf="
                          firstFormGroup.controls['domain'].hasError(
                            'required'
                          )
                        "
                      >
                        Domain is <strong>required</strong>
                      </mat-error>
                      <mat-error
                        *ngIf="
                          firstFormGroup.controls['domain'].hasError(
                            'pattern'
                          )
                        "
                      >
                        Please provide a valid domain name
                        <strong
                          >(absolute URL, without a trailing slash)</strong
                        >
                      </mat-error>
                    </mat-form-field>
                </div>
              </div>
              <div class="d-flex justify-content-center d-flex align-items-stretch flex-column flex-lg-row">
                <div class="cards container mx-4 mb-4 mt-5 mt-lg-4 p-0 w-100 w-lg-50">
                  <div class="m-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="col-sm-6 col-12 m-3 d-flex flex-column justify-content-center align-items-center" formGroupName="device">
                      <span class="fs-5">CrUX Settings</span>
                      <div class="my-3 d-flex justify-content-center align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                          <label *ngIf="!edit" class="container d-flex p-1">
                            <input formControlName="myCheckbox1" type="checkbox" id="cbx-mobile" class="hidden-xs-up">
                            <label for="cbx-mobile" class="cbx"></label>
                            <span class="mx-2 mt-1 fs-6">Mobile</span>
                          </label>
                          <label *ngIf="edit && i === 0" class="container d-flex p-1">
                            <input formControlName="myCheckbox1" [(ngModel)]="editWebsite.deviceMobile" disabled="true" type="checkbox" id="cbx-mobile-edit" class="hidden-xs-up">
                            <label for="cbx-mobile-edit" class="cbx"></label>
                            <span class="mx-2 mt-1 fs-6">Mobile</span>
                          </label>
                        </div>

                        <div class="d-flex justify-content-start align-items-center">
                          <label *ngIf="!edit" class="container d-flex p-1">
                            <input formControlName="myCheckbox2" type="checkbox" id="cbx-desktop" class="hidden-xs-up">
                            <label for="cbx-desktop" class="cbx"></label>
                            <span class="mx-2 mt-1 fs-6">Desktop</span>
                          </label>
                          <label *ngIf="edit && i === 0" class="container d-flex p-1">
                            <input formControlName="myCheckbox2" [(ngModel)]="editWebsite.deviceDesktop" disabled="true" type="checkbox" id="cbx-desktop-edit" class="hidden-xs-up">
                            <label for="cbx-desktop-edit" class="cbx"></label>
                            <span class="mx-2 mt-1 fs-6">Desktop</span>
                          </label>
                        </div>

                      </div>
                      <mat-error class="invalid-feedback" *ngIf="firstFormGroup.controls['device'].errors && firstFormGroup.controls['device'].errors.requireOneCheckboxToBeChecked">
                        At least one checkbox is required to check
                      </mat-error>
                    </div>
                    <div class="d-flex flex-column justify-content-center align-items-center w-75 ">
                      @if(!locationButton){
                        <div class="w-100">
                          <div class="d-flex justify-content-center align-items-center" >
                            <mat-icon>language</mat-icon> Worldwide
                          </div>
                          <div
                            class="row d-flex justify-content-center align-items-center"
                          >
                            <p class="my-3 col-12">
                              You should upgrade to enable domain-level
                              location-based monitoring
                            </p>
                            <div class="col-12 d-flex justify-content-center">
                              <a
                                class="btn btn-primary"
                                [routerLink]="'/buyPlan'"
                                >Upgrade now!</a
                              >
                            </div>
                          </div>
                        </div>
                      }@else{
                        <span class="fs-6 mb-2">CrUX monitoring location</span>
                        <app-mat-select-country
                          class = "w-100"
                          class="p-0 w-100"
                          [disabled]="!locationButton"
                          appearance="outline"
                          label="Monitoring Location"
                          [value]="countrydefaultValue"
                          (onCountrySelected)="onCountrySelected($event)"
                        >
                          <mat-select-trigger>
                            {{ selected?.flag }}
                          </mat-select-trigger>
                        </app-mat-select-country>
                      }
                    </div>
                  </div>
                </div>
                <div class="cards container m-4 p-0 w-100 w-lg-50 d-flex flex-column justify-content-center align-items-center">

                  @if(planHasRum) {
                  <div class="my-3">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                      <span class="fs-5">RUM Settings</span>
                      <div>
                        <label *ngIf="!edit" class="container d-flex py-3">
                          <input [checked]="enableRum" (change)="onCheckRumEnabled($event)" type="checkbox" id="cbx-RUM" class="hidden-xs-up">
                          <label for="cbx-RUM" class="cbx"></label>
                          <span class="mx-2 mt-1">Enable RUM</span>
                        </label>
                      </div>

                        <div class="p-0 d-flex flex-column justify-content-center align-items-center w-100  ">
                          @if(enableRum) {
                          <h4>RUM monitoring locations (Maximum 5)</h4>
                          <app-multi-country-selector
                            class="w-100"
                            appearance="outline"
                            label="Select Countries"
                            [initialSelections]="rumSelectedCountries"
                            [maxSelections]="5"
                            (selectionChange)="onRumCountriesChange($event)"
                          >
                          </app-multi-country-selector>
                          }
                        </div>
                    </div>
                  </div>

                  }@else{
                    <div class="d-flex flex-column justify-content-start align-items-center w-100 h-100">
                      <span class="fs-5 mt-3">RUM Settings</span>
                      <div class="mt-5 mx-2">
                        <app-Free-msg class="d-flex justify-content-center align-items-center" [Message]="messageFromParent"></app-Free-msg>
                      </div>
                    </div>

                  }
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-secondary m-1"
                  [routerLink]="['/home']"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn m-1"
                  [disabled]="!domainButtom"
                  matStepperNext
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-step>



      <mat-step [stepControl]="secondFormGroup" label="Internal pages">

        <form [formGroup]="secondFormGroup">
          <div id="internalUrls">
            <div class="mb-5">
              <h2>Internal pages information:</h2>
              <h3>
                From your top internal pages,enter one from each page type (product page, category, search page,cart..etc)
                <b> Don't add your homepage, it will per default analyzed.</b>
              </h3>

            </div>
            <section
              class="container cards mt-2 mb-4 p-3"
              formArrayName="internalUrls"
              *ngFor="let item of internalUrlControls; let i = index"
            >
              <div [formGroupName]="i" class="mt-2">

                <div class="row">
                  <div class="col-6">
                    <h4>Page {{ i + 1 }} name</h4>
                  </div>
                  <div class="col-md-6 col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-danger m-1 rounded-circle py-0"
                      (click)="removeInternalUrl(i)"
                      *ngIf="i > 0"
                    >
                      <strong>-</strong>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12 mb-3">
                    <mat-form-field class="full-width" appearance="outline">
                      <input
                        *ngIf="!addCount(i)"
                        matInput
                        placeholder="Page name"
                        formControlName="title"
                        autofocus
                      />
                      <input
                        *ngIf="addCount(i) && !editDataLoading"
                        [(ngModel)]="intUrl.title"
                        matInput
                        placeholder="page name"
                        formControlName="title"
                        readonly
                      />
                    </mat-form-field>
                    <mat-error
                      *ngIf="
                        item.controls.title.hasError('required') && validIn
                      "
                    >
                      Page name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="item.controls.title.hasError('unique')">
                      Please don't provide <strong>duplicate </strong> URL
                    </mat-error>
                  </div>
                  <div class="col-md-6 col-12 mb-3">
                    <mat-form-field class="full-width w-75" appearance="outline">
                      <input
                        *ngIf="!addCount(i)"
                        placeholder="https://www.example.com/page"
                        matInput
                        placeholder="Page URL"
                        formControlName="url"
                        autofocus
                      />
                      <input
                        *ngIf="addCount(i) && !editDataLoading"
                        placeholder="https://www.example.com/page"
                        [(ngModel)]="intUrl.url"
                        matInput
                        placeholder="page url"
                        formControlName="url"
                        readonly
                      />
                    </mat-form-field>
                    <mat-error *ngIf="item.controls.url.hasError('pattern')">
                      Please provide <strong>valid</strong> URL
                    </mat-error>
                    <mat-error *ngIf="item.controls.url.hasError('unique')">
                      Please don't provide <strong>duplicate </strong> URL
                    </mat-error>
                    <mat-error
                      *ngIf="item.controls.url.hasError('required') && validIn"
                    >
                      Internal page URL is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="item.controls.url.errors?.belongErrors">
                      Internal page url must be belong in Origin Domain
                    </mat-error>
                    <mat-error
                      class="invalid-feedback"
                      *ngIf="item.controls.url.errors?.diffErrors"
                    >
                      Internal page url must be different of origin Domain
                    </mat-error>
                  </div>
                </div>
              </div>
            </section>
            <div class="card-container">
              <button
                type="button"
                class="btn btn btn-success m-1 rounded-circle border"
                [disabled]="!internalButtom"
                (click)="addInternalUrl()"
              >
                <strong>+</strong>
              </button>
              <div *ngIf="!internalButtom">
                <mat-error>
                  Sorry! You have reached your plan internal pages limit.<br />
                </mat-error>
                <button
                  mat-raised-button
                  color="primary"
                  [routerLink]="'/home/buyPlan'"
                >
                  Upgrade your plan now!
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn btn-secondary m-1"
              matStepperPrevious
            >
              Back
            </button>
            <button
              type="button"
              class="btn btn-primary btn m-1"
              matStepperNext
              (click)="validInternal()"
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>



      <mat-step [stepControl]="thirdFormGroup" label="Competition">
        <form [formGroup]="thirdFormGroup">
          <div id="competitions">
            <div class="mb-5">
              <h2>Who is faster, you or your competitors?</h2>
              <h3>Add your main competitors websites</h3>
            </div>

            <section
              class="container cards mt-2 mb-4 p-3"
              formArrayName="competitors"
              *ngFor="let item of competitorControls; let i = index"
            >
              <div [formGroupName]="i">
                <div class="row">
                  <div class="col-6">
                    <h4>Competitor {{ i + 1 }}</h4>
                  </div>

                  <div class="col-md-6 col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-danger m-1 rounded-circle py-0"
                      (click)="removeCompetitor(i)"
                      *ngIf="i > 0"
                    >
                      <strong>-</strong>
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-12 mb-3">
                    <mat-form-field class="full-width" appearance="outline">
                      <input
                        *ngIf="!addCount(i)"
                        matInput
                        placeholder="Competitor Name"
                        formControlName="title"
                        autofocus
                      />
                      <input
                        *ngIf="addCount(i) && !editDataLoading"
                        [(ngModel)]="compet.title"
                        matInput
                        placeholder="page name"
                        formControlName="title"
                        readonly
                      />
                    </mat-form-field>
                    <mat-error
                      *ngIf="
                        item.controls.title.hasError('required') && validComp
                      "
                    >
                      Name is <strong>required</strong>
                    </mat-error>
                  </div>
                  <div class="col-md-6 col-12 mb-3">
                    <mat-form-field class="full-width w-75" appearance="outline">
                      <input
                        *ngIf="!addCount(i)"
                        placeholder="https://www.example.com/page"
                        matInput
                        placeholder="Competitor domain"
                        formControlName="url"
                        autofocus
                      />
                      <input
                        *ngIf="addCount(i) && !editDataLoading"
                        placeholder="https://www.example.com/page"
                        [(ngModel)]="compet.url"
                        matInput
                        placeholder="page url"
                        formControlName="url"
                        readonly
                      />
                    </mat-form-field>
                    <mat-error *ngIf="item.controls.url.hasError('pattern')">
                      Please provide a valid domain name
                      <strong>(absolute URL, without a trailing slash)</strong>
                    </mat-error>
                    <mat-error *ngIf="item.controls.url.hasError('unique')">
                      Please don't provide
                      <strong>duplicate </strong> competitor
                    </mat-error>
                    <mat-error
                      *ngIf="
                        item.controls.url.hasError('required') && validComp
                      "
                    >
                      Competitor domain is <strong>required</strong>
                    </mat-error>
                    <mat-error
                      class="invalid-feedback"
                      *ngIf="item.controls.url.errors?.diffErrors"
                    >
                      Competition Url must be different of origin Domain
                    </mat-error>
                  </div>
                </div>
              </div>
            </section>

              <div class="card-container">
                <button
                  type="button"
                  class="btn btn btn-success m-1 rounded-circle"
                  [disabled]="!competitionBouton"
                  (click)="addCompetitor()"
                >
                  <strong>+</strong>
                </button>
                <div *ngIf="!competitionBouton">
                  <mat-error>
                    Sorry! You have reached the competitors limit <br />
                  </mat-error>
                  <button
                    mat-raised-button
                    color="primary"
                    [routerLink]="'/home/buyPlan'"
                  >
                    Upgrade your plan now!
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary m-1"
                matStepperPrevious
              >
                Back
              </button>
              <button
                type="button"
                class="btn btn-primary btn m-1"
                matStepperNext
                (click)="validCompetitor()"
              >
                Next
              </button>
            </div>
          </form>
      </mat-step>

      <mat-step *ngIf="enableRum" label="RUM snippet">
        <div class="graph m-4">
          <h2 class="fw-medium">Sampling Configuration</h2>

          <!-- Client-side Sampling (Default) -->
          <div class="mb-4">
            <h3>1. Client-Side Sampling (Recommended)</h3>
            <p class="text-muted mb-2">
              💡 Client-side sampling reduces network traffic and provides more predictable costs.
              Events are filtered before being sent to our servers.
            </p>

            <mat-form-field appearance="outline" class="my-2">
              <mat-label>Client-Side Sampling Rate (%):</mat-label>
              <input
                type="number"
                [(ngModel)]="clientSideSamplingRate"
                matInput
                placeholder="Ex: 60%"
                step="0.1"
                min="1"
                max="100"
                (ngModelChange)="updateClientSideSnippet()"
              />
              <mat-error>Please enter a number between 1 and 100.</mat-error>
            </mat-form-field>

            <div class="input-group input-group-sm mb-2">
              <input
                type="text"
                class="form-control input-non-desactive"
                [placeholder]="clientSideScriptSnippet"
                disabled
              />
              <button
                (click)="copyClientSideSnippet()"
                class="btn btn-primary d-flex justify-content-center align-items-center"
                type="button"
              >
                <mat-icon class="icon-display">content_copy</mat-icon>
              </button>
            </div>

            <p class="text-muted small">
              ℹ️ Note: To adjust the sampling rate later, modify the <code>sampling</code> parameter in the script URL.
              For example: <code>...js?apiKey=xxx&sampling=75</code>
            </p>

            <!-- Async/Defer checkboxes for client-side -->
            <div class="input-group input-group-sm mb-3">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="csAsyncCheckbox"
                  [(ngModel)]="isClientAsync"
                  (change)="updateClientSideSnippet()"
                />
                <label class="form-check-label" for="csAsyncCheckbox">Async</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="csDeferCheckbox"
                  [(ngModel)]="isClientDefer"
                  (change)="updateClientSideSnippet()"
                />
                <label class="form-check-label" for="csDeferCheckbox">Defer</label>
              </div>
            </div>
          </div>

          <!-- Server-side Sampling (Additional Option) -->
          <mat-expansion-panel class="mt-3">
            <mat-expansion-panel-header>
              <mat-panel-title>
                2. Server-Side Sampling (Additional Option)
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="px-5 py-2">
              <div class="text-muted">
                <p>💡 Server-side sampling can be used:</p>
                <ul>
                  <li>As an alternative to client-side sampling</li>
                  <li>In addition to client-side sampling for more granular control</li>
                </ul>
                <p>
                  ⚠️ Note: With server-side sampling, all events are sent to our servers before being filtered.
                  Consider using client-side sampling first for better performance especially for high traffic websites.
                </p>
              </div>


              <form>
                <div class="d-flex flex-column">
                  <mat-form-field appearance="outline" class="mb-2">
                    <mat-label>Server-Side Sampling Rate (%):</mat-label>
                    <input
                      type="number"
                      [(ngModel)]="rumSettings.samplingRate"
                      matInput
                      placeholder="Ex: 60%"
                      step="0.1"
                      [ngModelOptions]="{ standalone: true }"
                      min="1"
                      max="100"
                    />
                    <mat-error>Please enter a number between 1 and 100.</mat-error>
                  </mat-form-field>

                  <div class="d-flex justify-content-end">
                    <button
                      (click)="updateRumSettings()"
                      class="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>

              <!-- Server-side script snippet with async/defer options -->
              <div class="input-group input-group-sm mt-3">
                <input
                  type="text"
                  class="form-control input-non-desactive"
                  [placeholder]="scriptSnippet"
                  disabled
                />
                <button
                  (click)="copyRumSnippet()"
                  class="btn btn-primary d-flex justify-content-center align-items-center"
                  type="button"
                >
                  <mat-icon class="icon-display">content_copy</mat-icon>
                </button>
              </div>

              <!-- Added back the async/defer checkboxes for server-side -->
              <div class="input-group input-group-sm mt-2">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="ssAsyncCheckbox"
                    [(ngModel)]="isAsync"
                    (change)="updateScriptSnippet()"
                  />
                  <label class="form-check-label" for="ssAsyncCheckbox">Async</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="ssDeferCheckbox"
                    [(ngModel)]="isDefer"
                    (change)="updateScriptSnippet()"
                  />
                  <label class="form-check-label" for="ssDeferCheckbox">Defer</label>
                </div>
              </div>
              <p class="mt-4 text-secondary d-flex justify-content-start align-items-center">
                <mat-icon class="me-1 text-primary" aria-hidden="true">info</mat-icon>
                <span>To modify the server-side sampling rate, please go to the RUM settings in your "My Account" section after adding your site.</span>

              </p>

            </div>
          </mat-expansion-panel>

        </div>
        <div class="d-flex justify-content-end mt-2">
          <button type="button" class="btn btn-secondary m-1" matStepperPrevious>
            Back
          </button>
          <button
            type="button"
            class="btn btn-primary m-1"
            matStepperNext
          >
            Next
          </button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Summary</ng-template>
        <div class="graph p-3 mt-3">
          <div>
            <h2 class="summary-text" style="margin-bottom:20px;">
              Almost finished. Please check what you entered. If all looks good to you, press the <strong>"let's go"</strong> button.
            </h2>
            <div class="d-flex flex-column flex-md-row justify-content-center d-flex align-items-stretch">
              <div class="cards container m-4 p-0 w-100 w-md-50">
                <div class="m-4">
                  <div class="my-2">
                    <div class="fs-5 my-2 section-title">Website:</div>
                    <p>Name: {{ websiteAnalyze.title }} | URL: {{ websiteAnalyze.originDomain }}</p>
                  </div>

                  <div class="my-2">
                    <div class="fs-5 my-2 section-title">Monitoring location:</div>
                    <p *ngIf="country1 != undefined">{{ country1.name }}</p>
                    <p *ngIf="country1 == undefined">
                      Not allowed in your plan. You'll get worldwide aggregated data.
                    </p>
                  </div>
                </div>
              </div>
              <div class="cards container m-4 p-0 w-100 w-md-50">
                <div class="m-4">
                  <div class="my-2">
                    <div class="fs-5 my-2 section-title">Monitoring device(s):</div>
                    <ul class="device-list">
                      <li>
                        <span>{{ selectedValue[0] }} : {{ websiteAnalyze.deviceMobile }}</span>
                      </li>
                      <li>
                        <span>{{ selectedValue[1] }} : {{ websiteAnalyze.deviceDesktop }}</span>
                      </li>
                    </ul>
                  </div>

                  <div class="my-2">
                    <div class="fs-5 my-2 section-title">Internal pages:</div>
                    <span *ngFor="let int of websiteAnalyze.internalURLs">
                      <span>Name: {{ int.title }} || URL: {{ int.url }}</span><br />
                    </span>
                  </div>

                  <div class="my-2">
                    <div class="fs-5 my-2 section-title">Competitors:</div>
                    <span *ngFor="let comp of websiteAnalyze.competitors">
                      <span>Name: {{ comp.title }} || URL: {{ comp.url }}</span>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div class="cards container m-4 p-1 w-100 w-md-50">
              <div class="m-4">
                <div class="my-2">
                  <div class="fs-5 my-2   section-title">RUM:</div>
                    <span>Enabled: {{enableRum }}</span>
                </div>

                <div class="my-2">
                  <div class="fs-5 my-2 section-title">RUM locations:</div>
                  @for(country of rumSelectedCountries; track country) {
                    <span> {{country}}</span><br/>
                  }
                </div>
                 <!-- Conditionally show a reminder if user has RUM enabled -->
                <div *ngIf="enableRum"  class="alert alert-warning mt-3" role="alert" style="max-width: 500px;">
                  <strong>Reminder:</strong> Since you enabled RUM, make sure you've
                  placed the RUM snippet inside your website's <code>&lt;head&gt;</code>.
                </div>
              </div>
            </div>

          </div>

          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary m-1" matStepperPrevious>
              Back
            </button>
            <button type="button" class="btn btn-primary m-1" matStepperNext [disabled]="!subBut" (click)="newPage()">
              Next
            </button>
          </div>
        </div>

      </mat-step>
    </mat-horizontal-stepper>
  </div>
 </div>
</app-layout>
