import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import {
  ChartOptionsInterface,
  ListOverallFeatures,
  PageSpeedData,
} from 'src/app/shared/models/pagespeed.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { co2 } from '@tgwf/co2';
import { WebsiteAnalyze } from 'src/app/shared/models/user.models';
import { DataService } from 'src/app/services/common/sidabar/Data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InternalCompareService } from 'src/app/services/internal-compare.service';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/common/user/user.service';

const oneByte = new co2({ model: '1byte' });
@Component({
    selector: 'app-page-interne',
    templateUrl: './page-interne.component.html',
    styleUrls: ['./page-interne.component.scss'],
    standalone: false
})
export class PageInterneComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptionsCLS: Partial<any>;
  public chartOptionsLCP: Partial<any>;
  public chartOptionsFCP: Partial<any>;
  public chartOptionsINP: Partial<any>;
  public chartOptionsTTFB: Partial<any>;
  deviceOptions: Array<String> = ['Mobile', 'Desktop'];
  speedColors = ConstantService.speedColors;
  device: string;
  datas: PageSpeedData[] = [];
  sortedData: PageSpeedData[];
  chartOptions: ChartOptionsInterface[] = [];
  internalUrls = [];
  userId: any;
  test: any;
  menu: string;
  loading = true;
  listInternalUrls: any;
  dataSource: any;
  metrics = ['ttfb', 'lcp', 'inp', 'cls'];
  displayedColumns: string[] = ['title', 'TTFB', 'LCP', 'INP', 'CLS'];
  diffDates = [
    { id: 'most_recent', name: 'Most recent' },
    { id: 'last_week', name: 'Previous week' },
    { id: 'last_month', name: 'Previous month' },
  ];
  selectedDiffDate = '';
  isFreeAccount = true;
  titleURL: string;
  i = 0;
  filterList = ['Passing Pages', 'No-Passing Pages'];
  ValidOrNot: boolean = true;
  overallList = ListOverallFeatures;
  event: MatRadioChange;
  currentURL: string;
  projectId: string;
  diff_date: string;
  @ViewChild(MatSort) sort: MatSort;
  resp: any;
  WebSiteTitle: any;
  country: any;
  selectedPage1: any;
  selectedPage2: any;
  filteredPages: any[];
  transformedPages: any[] = [];
  AllUrl: any;
  email: string;
  groupedPages: any;
  Url: string;
  currentUrl: any;
  constructor(
    private speetalsDatabase: SpeetalsDatabaseService,
    private websiteAnalyseService: WebsiteAnalyzeService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    private router: Router,
    public senderVarService: SenderVarService,
    private dataService: DataService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private internalCompareService: InternalCompareService
  ) {}
  async ngOnInit(): Promise<void> {
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.device = this.device === "tablet" ? 'mobile': this.device
    let URL = localStorage.getItem(ConstantService.loadingUrl.Url);
    this.currentURL = URL;
    this.projectId = this.router.url.split('/')?.[3];
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    this.email = localStorage.getItem(ConstantService.localStorageKeys.email);
    this.diff_date = localStorage.getItem(
      ConstantService.localStorageKeys.diff_date,
    );
    const planName = localStorage.getItem(
      ConstantService.localStorageKeys.plan_name,
    );
    if (planName) {
      this.isFreeAccount = planName === 'Free';
    }
    this.selectedDiffDate = this.selectedDiffDate || 'most_recent';
    const user = await firstValueFrom(this.userService.getUserDetail(this.userId));
    this.AllUrl = await firstValueFrom(this.websiteAnalyseService.getAllWebsiteAnalyze(this.userId,user.email))


    this.resp = await this.speetalsDatabase
      .getDatainternalUrl(
        this.userId,
        this.senderVarService.id,
        this.device.toLowerCase(),
        this.selectedDiffDate,
      ).toPromise();

      console.log(this.resp)
      this.WebSiteTitle = this.dataService.getSharedData()?.WebSiteTitle;
      this.country = this.dataService.getSharedData()?.country;
      this.Url = this.router.url;
      this.currentUrl = this.dataService.getSharedData()?.url;

    let urlSpeedData = this.pageSpeedInsigthService.getUrlSpeedData1(
      this.resp,
      this.device,
    );
    urlSpeedData.map((ele: any) => {
      this.datas.push({
        ...this.pageSpeedInsigthService.getSpeedData(ele),
        ttfbDiff: ele.ttfbDiff,
        lcpDiff: ele.lcpDiff,
        clsDiff: ele.clsDiff,
        inpDiff: ele.inpDiff,
        transferSize: ele.transferSize,
      });
    });
    this.titleURL = this.datas[0]?.title || 'title';

    this.datas.forEach((data) => {
      this.chartOptionsTTFB = this.pageSpeedInsigthService.chartOptions(
        data.ttfb,
      );
      this.chartOptionsCLS = this.pageSpeedInsigthService.chartOptions(
        data.cls,
      );
      this.chartOptionsLCP = this.pageSpeedInsigthService.chartOptions(
        data.lcp,
      );
      this.chartOptionsFCP = this.pageSpeedInsigthService.chartOptions(
        data.fcp,
      );
      this.chartOptionsINP = this.pageSpeedInsigthService.chartOptions(
        data.inp,
      );
      data.chartOptions = {
        cls: this.chartOptionsCLS,
        lcp: this.chartOptionsLCP,
        fcp: this.chartOptionsFCP,
        inp: this.chartOptionsINP,
        ttfb: this.chartOptionsTTFB,
      };
    });
    this.filteredPages = this.datas;
    //this.datas.length = 0;
    this.dataSource = new MatTableDataSource(this.datas);
    /* configure filter */
    this.dataSource.filterPredicate = (data, filter: string) => {
      if (filter === this.filterList[0].toLowerCase()) {
        return data.overall.includes('FAST');
      } else {
        return (
          data.overall.includes('SLOW') || data.overall.includes('AVERAGE')
        );
      }
    };
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'LCP':
          return item.lcp.good;
        case 'CLS':
          return item.cls.good;
        case 'TTFB':
          return item.ttfb.good;
        case 'INP':
          return item.inp.good;
        case 'Overall':
          return item.overall;
      }
    };
    this.dataSource.sort = this.sort;
    this.loading = false;
    this.transformPages()
  }
  showSpeedData(url: string) {
    let data = this.pageSpeedInsigthService.getSpeedData(url);
    this.datas.push(data);
  }
  showChartOptions(data: any) {
    data.chartOptions.ttfb = this.pageSpeedInsigthService.chartOptions(
      data.ttfb,
    );
    data.chartOptions.cls = this.pageSpeedInsigthService.chartOptions(data.cls);
    data.chartOptions.lcp = this.pageSpeedInsigthService.chartOptions(data.lcp);
    data.chartOptions.inp = this.pageSpeedInsigthService.chartOptions(data.inp);
    return {
      cls: this.chartOptionsCLS,
      lcp: this.chartOptionsLCP,
      ttfb: this.chartOptionsTTFB,
      inp: this.chartOptionsINP,
    };
  }

  applyFilter(ob) {
    const filterValue = ob.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  resetFilters() {
    this.dataSource.filter = '';
  }

  setDevice($event) {
    this.device = $event;
    this.datas = [];
    localStorage.setItem(ConstantService.loadingUrl.device, $event);
    this.ngOnInit();
  }

  // onDiffDateChanged(e) {
  //   const value = e.value;
  //   this.selectedDiffDate = value;
  //   this.loading = true;
  //   this.datas = [];
  //   this.ngOnInit();
  // }
  onDiffDateChanged(event: Event) {

    const selectElement = event.target as HTMLSelectElement;
    const newValue = selectElement.value;

    this.selectedDiffDate = newValue;
    this.loading = true;
    this.datas = [];
    this.ngOnInit();
  }
  overallFeatures(overallValue) {
    this.overallList.forEach((element) => {
      if (element.value == overallValue) {
        if (element.value == 'N/A') {
          this.ValidOrNot = false;
        }
        return element;
      }
    });
  }

  urlName(name, homeUrl) {
    return this.resp.find((r) => r.url === homeUrl)?.originDomain + '/' ===
      homeUrl
      ? 'home page '
      : name;
  }

  navigateTo(url, chemin) {
    let URL = encodeURIComponent(url);
    return this.router.url + '/' + chemin + '/' + URL;
  }
  urlDetails(url) {
    let URL = encodeURIComponent(url);
    return this.router.url.replace(/%2B/g, '') + '/details/' + URL;
  }
  urlAudits(url) {
    this.speetalsDatabase.urlAudit = url;
    let URL = encodeURIComponent(url);
    return (
      this.router.url.replace(/%2B/g, '') +
      '/audits/' +
      this.projectId +
      '/' +
      URL
    );
  }

  getPassing(overall: string) {
    return this.speetalsDatabase.passOrNot(overall);
  }
  printUxr(uxr: number) {
    return uxr !== null && uxr !== undefined;
  }
  getColorUxr(uxr: number) {
    if (uxr >= 90) return 'green';
    if (uxr > 50 && uxr < 90) return 'orange';
    return 'red';
  }
  getBadgeColor(x: number): string {
    return x < 0 ? 'warn' : 'green';
  }
  description(name: string) {
    return 'Day to day change of Good ' + name + ' %';
  }
  formatDiff(diff: number) {
    return Math.round(diff * 10) / 10 || 0;
  }
  copyUrlToClipboard(url:any): void {
    navigator.clipboard.writeText(url).then(() => {
      this.snackBar.open('URL copied!', 'Close', {
        duration: 2000
      });
    }).catch(err => {
      this.snackBar.open('Error copying URL!' , 'Close', {
        duration: 2000
      });
    });
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined) return false;
    return true;
  }
  transformPages(): void {
    this.transformedPages = this.AllUrl.map(data => {
      return {
        title: data.title,
        urls: [
          { id: data.id,title: data.title, url: data.originDomain, type: 'originDomain' },
          ...data.internalURLs.map(internal => ({
            id: data.id,
            title: internal.title,
            url: internal.url,
            type: 'internalURL'
          }))
        ]
      };
    });
    this.transformedPages.sort(item => item.urls.some(url => url.id === this.projectId) ? -1 : 1);
  }
  onFirstSelectChange(): void {
    // Filtrer les URLs pour exclure la première URL sélectionnée
    this.filteredPages = this.transformedPages.map(group => {
      return {
        title: group.title,
        urls: group.urls.filter(page => page.title !== this.selectedPage1)
      };
    }).filter(group => group.urls.length > 0); // Exclure les groupes sans URLs
    this.selectedPage2 = '';
  }
  refreshbutton(): void {
    this.selectedPage1 = '';
    this.selectedPage2 = '';
  }
  comparePages() {

    if (this.selectedPage1 && this.selectedPage2) {
      let page1 = null;
      let page2 = null;
      let projectId1 = null;
      let projectId2 = null;

      for (const group of this.transformedPages) {
        for (const page of group.urls) {
          if (page.title === this.selectedPage1) {
            page1 = page;
            projectId1 = page.id;
          }
          if (page.title === this.selectedPage2) {
            page2 = page;
            projectId2 = page.id;
          }
        }
      }

      if (page1 && page2) {
        const dataToStore = {
          projectId1: projectId1,
          projectId2: projectId2,
          page1: {
            Url: page1.title,
          },
          page2: {
            Url: page2.title
          },
        };

        localStorage.setItem('pagesData', JSON.stringify(dataToStore));
        localStorage.setItem('sitename',this.WebSiteTitle)

        const compareUrl = this.comparePagesUrl(this.selectedPage1, this.selectedPage2);
        this.router.navigateByUrl(compareUrl);
      } else {
        this.snackBar.open('Please select two pages to compare!', 'Close', {
          duration: 2000
        });
      }
    } else {
      this.snackBar.open('Please select two pages to compare!', 'Close', {
        duration: 2000
      });
    }
  }
  comparePagesUrl(page1: string, page2: string): string {
    let page1Encoded = encodeURIComponent(page1);
    let page2Encoded = encodeURIComponent(page2);
    return this.router.url.replace(/%2B/g, '') + '/compare/' + page1Encoded + '/' + page2Encoded;
  }
  getDiffStatus(diff: number | null): string {
    if (diff === null || diff === 0) return 'stabilizing';
    return diff > 0 ? 'improving' : 'regressing';
  }
}
