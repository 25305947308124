import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { NavigationWorkflowService } from 'src/app/services/navigation-workflow.service';
import { firstValueFrom } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface PageData {
  id: string;
  domain: string;
  url: string;
  isFPDEnabled?: boolean;
}

@Component({
    selector: 'app-create-workflow-dialog',
    templateUrl: './create-workflow-dialog.component.html',
    styleUrls: ['./create-workflow-dialog.component.scss'],
    standalone: false
})
export class CreateWorkflowDialogComponent implements OnInit {

  mode: 'CRUX' | 'RUM' | '' = 'CRUX';
  workflowName = '';
  searchTerm = '';

  allDomains: any[] = [];
  selectedDomain: any = null;

  allCruxPages: PageData[] = [];
  allRumPages: PageData[] = [];

  displayedPages: PageData[] = [];
  selectedPages: PageData[] = [];

  userId: string;
  allDocs: any;

  instanceId: string = 'workflowSwitch';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: string },
    @Inject(MatDialogRef) private dialogRef: MatDialogRef<CreateWorkflowDialogComponent>,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private navigationWorkflowService: NavigationWorkflowService,
    @Inject(MatSnackBar) private _snackBar: MatSnackBar
  ) {
    this.userId = data.userId;
  }

  async ngOnInit(): Promise<void> {
    try {
      this.allDocs = await firstValueFrom(
        this.websiteAnalyzeService.getAllWebsiteAnalyze(this.userId)
      );

      this.allDomains = this.allDocs.reduce((acc: any[], doc: any) => {
        if (!acc.find(d => d.id === doc.id)) {
          acc.push(doc);
        }
        return acc;
      }, []);

      const cruxPagesFlatten: PageData[] = this.allDocs.flatMap((doc: any) => {
        const internal = (doc.internalURLs || []).map((iUrl: any) => ({
          id: doc.id,
          domain: doc.originDomain,
          url: iUrl.url,
          isFPDEnabled: doc.isFPDEnabled
        }));
        return internal;
      });
      this.allCruxPages = cruxPagesFlatten;
      const rumDocs = this.allDocs.filter((doc: any) => doc.isFPDEnabled === true);
      if (rumDocs && rumDocs.length > 0) {
        const rumPagesArrays = await Promise.all(
          rumDocs.map(async (doc: any) => {
            const pages = await firstValueFrom(
              this.websiteAnalyzeService.getRUMPages(doc.id)
            );
            return pages.map((page: any) => ({
              id: doc.id,
              domain: doc.originDomain,
              url: page.url,
              isFPDEnabled: doc.isFPDEnabled,
            }));
          })
        );
        this.allRumPages = rumPagesArrays.reduce((acc, curr) => acc.concat(curr), []);
      } else {
        this.allRumPages = [];
      }

    } catch (error) {
      console.error('Error loading pages:', error);
      this._snackBar.open('Failed to load internal pages.', 'Close', {
        duration: 3000,
        panelClass: ['snackbar-error']
      });
    }
  }

  onDomainChange(): void {

    this.mode = 'CRUX';
    this.onModeChange();
  }
  setMode(event: any): void {
    this.onModeChange();
  }

  onModeChange(): void {
    if (!this.selectedDomain) {
      this._snackBar.open('Please select a site.', 'Close', {
        duration: 3000,
        panelClass: ['snackbar-warning']
      });
      this.mode = '';
      return;
    }
    if (this.mode === 'CRUX') {
      this.displayedPages = this.allCruxPages
        .filter(p => p.id === this.selectedDomain.id)
        .slice(0, 10);
    } else if (this.mode === 'RUM') {
      this.displayedPages = this.allRumPages
        .filter(p => p.id === this.selectedDomain.id)
        .slice(0, 10);
    } else {
      this.displayedPages = [];
    }
    this.selectedPages = [];
    this.searchTerm = '';
  }

  search(event: any): void {
    this.searchTerm = event.target.value;
    this.onSearchChange();
  }

  onSearchChange(): void {
    if (!this.selectedDomain) {
      return;
    }
    const source = (this.mode === 'CRUX')
      ? this.allCruxPages.filter(p => p.id === this.selectedDomain.id)
      : this.allRumPages.filter(p => p.id === this.selectedDomain.id);
    const filtered = source.filter(p =>
      p.url.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    this.displayedPages = filtered.slice(0, 10);
  }

  isSelected(page: PageData): boolean {
    return this.selectedPages.some(sp => sp.url === page.url && sp.id === page.id);
  }

  toggleSelection(page: PageData): void {
    const index = this.selectedPages.findIndex(sp => sp.url === page.url && sp.id === page.id);
    if (index === -1) {
      if (this.selectedPages.length < 5) {
        this.selectedPages.push(page);
      } else {
        this._snackBar.open('Maximum 5 pages!', 'Close', {
          duration: 3000,
          panelClass: ['snackbar-warning']
        });
      }
    } else {
      this.selectedPages.splice(index, 1);
    }
  }

  drop(event: CdkDragDrop<PageData[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.selectedPages, event.previousIndex, event.currentIndex);
    }
  }

  async createWorkflow(): Promise<void> {
    if (!this.selectedDomain || !this.mode || !this.workflowName || this.selectedPages.length === 0) {
      this._snackBar.open('Veuillez sélectionner un site, un mode, un nom et au moins 1 URL.', 'Close', {
        duration: 3000
      });
      return;
    }
    const finalPages = this.selectedPages.map(sp => ({
      url: sp.url
    }));

    const payload = {
      userId: this.userId,
      projectId: this.selectedDomain.id,
      name: this.workflowName,
      type: this.mode,
      pages: finalPages,
      createdAt: new Date()
    };

    try {
      const result = await firstValueFrom(
        this.navigationWorkflowService.createNavigationWorkflow(payload)
      );
      this._snackBar.open('Workflow created successfully!', 'Close', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      this.dialogRef.close({ created: true, workflow: result });
    } catch (err) {
      console.error('Error creating workflow:', err);
      this._snackBar.open('Error while creating the workflow!', 'Close', {
        duration: 3000,
        panelClass: ['snackbar-error']
      });
    }
  }

  // Extrait la partie path d'une URL
  extractPath(url: string): string {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.pathname;
    } catch (error) {
      // En cas d'erreur, découper manuellement
      const parts = url.split('/');
      return parts.length > 3 ? '/' + parts.slice(3).join('/') : url;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
