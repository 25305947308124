import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ChartComponent } from 'ng-apexcharts';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { Plan } from 'src/app/shared/models/user.models';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { mailExistValidator } from './check-mails-validators';
import { UserService } from 'src/app/services/common/user/user.service';

@Component({
    selector: 'app-shared-with-dialog',
    templateUrl: './shared-with-dialog.component.html',
    styleUrls: ['./shared-with-dialog.component.scss'],
    standalone: false
})
export class SharedWithDialogComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptionsPSI: Partial<any> = [];
  public mailForm: UntypedFormGroup;
  element: any;
  plan: Plan;
  mailsExist: Array<string> = [];
  cancelButtonText = 'Close';
  websiteAnalyse: any;
  title: any;
  cptMails = 0;
  submitButton: boolean;
  userData: any;
  userPlan: Plan;
  userSeats: number;
  remainingsite: number;
  maxshare: number;
  messageFromParent: string = 'to share with your colleagues';
  firstFormGroup: UntypedFormGroup;
  regex: string;
  userId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private userService: UserService,
    private dialogRef: MatDialogRef<SharedWithDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private websiteAnalyzeService: WebsiteAnalyzeService,
  ) {
    if (data) {
      this.mailForm = data.form;
      this.element = data.element;
      this.websiteAnalyse = data.websiteAnalyse;
      this.title = data.websiteAnalyse.title;

      this.mailForm = this.formBuilder.group({
        mails: this.formBuilder.array([this.newMail()]),
      });
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
    //this.dialogRef.updateSize('300vw','300vw')
  }

  async ngOnInit() {
    this.mailForm.setErrors(null);
    this.userId = localStorage.getItem('userId');
    this.userData = await this.userService
      .getUserIdentity(this.userId)
      .toPromise();
    this.plan = await this.userService.getPlan(this.userId).toPromise();
    this.userSeats = +this.plan.details.userSeats.value;
    this.remainingsite =
      this.userSeats - this.websiteAnalyse.sharedWith.length - 1;
    this.maxshare = this.remainingsite - 1;
    this.regex =
      '^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@' +
      '[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$';
    this.formBuilder.group({
      mail: [
        '',
        [
          Validators.required,
          Validators.pattern(this.regex),
          RxwebValidators.unique(),
          mailExistValidator(this.websiteAnalyse.sharedWith),
        ],
      ],
    });
  }
  get CanAddMail(): boolean {
    return this.userSeats <= 1 ? false : true;
  }

  get mailsControls() {
    return this.mailForm.get('mails')['controls'];
  }
  get mails(): UntypedFormArray {
    return this.mailForm.get('mails') as UntypedFormArray;
  }

  //, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
  //mailExistValidator(this.websiteAnalyse.sharedWith)

  newMail(): UntypedFormGroup {
    let regex = "^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@"
    + "[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$"
    return this.formBuilder.group({
      mail: [
        '',
        [
          Validators.required,
          Validators.pattern(regex),
          RxwebValidators.unique(),
          mailExistValidator(this.websiteAnalyse.sharedWith),
        ],
      ],
    });
  }

  addMails() {
    if (
      this.mailsControls[this.cptMails] !== undefined &&
      this.mailsControls[this.cptMails].invalid
    ) {
      return;
    }
    if (this.remainingsite > 0) {
      this.mails.push(this.newMail());
      this.cptMails++;
      this.maxshare--;
    }
  }

  maxSharing(): boolean {
    return this.remainingsite === 0 || this.maxshare === 0 ? true : false;
  }
  SharingIsEmpty(): boolean {
    return this.websiteAnalyse.sharedWith.length === 0 ? true : false;
  }

  removeMail(i: number) {
    this.mails.removeAt(i);
    this.cptMails--;
    this.maxshare++;
  }

  validSubmit(i) {
    let submitButton =
      this.mailsControls[i] !== undefined &&
      this.mailsControls[i].status === 'VALID'
        ? true
        : false;
    return submitButton;
  }

  async onSubmit() {
    const Mails = this.mailForm.value.mails;
    const length = this.mailForm.value.mails.length;
    const ProjectId = this.element.projectId;
    const Title = this.title;
    const LASTNAME = this.userData.profileGoogle.lastName;
    const FIRSTNAME = this.userData.profileGoogle.firstName;

    this.dialogRef.close({
      mails: Mails,
      projectId: ProjectId,
      title: Title,
      lastname: LASTNAME,
      firstname: FIRSTNAME,
    });
    try {
      await this.websiteAnalyzeService
        .inviteSharedWithUser(ProjectId, Mails, Title, FIRSTNAME, LASTNAME)
        .toPromise();
      this.remainingsite -= length;

      this.snackBar.open('Successfully sharing access ', 'close', {
        duration: 4000,
      });
    } catch (error) {
      console.log(error);
    }
  }

  deleteMail(mail) {
    alert('delete Mail Work in progress');
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  openDialogToRemove(mail: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        mail: mail,
        message: 'Are you sure to revoke access',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    const snack = this.snackBar.open('Revoking access in progress');

    dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
      if (confirmed) {
        snack.dismiss();
        const a = document.createElement('a');
        a.click();
        a.remove();
        snack.dismiss();
        let myIndex = this.websiteAnalyse.sharedWith.indexOf(mail);
        if (myIndex !== -1) {
          this.websiteAnalyse.sharedWith.splice(myIndex, 1);
        }
        this.remainingsite++;
        await this.websiteAnalyzeService
          .putWebsiteAnalyze(this.websiteAnalyse.id, this.websiteAnalyse)
          .toPromise();

        this.snackBar.open('Revoking access from this email', 'Dismiss', {
          duration: 2000,
        });
      }
    });
  }
}
