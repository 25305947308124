import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-fcp',
    templateUrl: './fcp.component.html',
    styleUrls: ['./fcp.component.scss'],
    standalone: false
})
export class FcpComponent implements OnInit {
  metric : string = 'fcp'
  constructor() {}
  ngOnInit(): void {}
}
