import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartComponent } from 'ng-apexcharts';

@Component({
    selector: 'app-histogram-dialog',
    templateUrl: './histogram-dialog.component.html',
    styleUrls: ['./histogram-dialog.component.scss'],
    standalone: false
})
export class HistogramDialogComponent implements OnInit {
  @ViewChild('chart1') chart1: ChartComponent;
  public chartOptions: Partial<any>;
  element: any;
  free : boolean;
  isUXRovertime: boolean = false;
  isWVovertime: boolean = false;
  cancelButtonText = "Cancel"
  upgradeButtonText = "Upgrade"

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<HistogramDialogComponent>,
  ) {
    if (data){
      this.element = data.element
      this.chartOptions = data.chartOptions;
      this.free = data.free
      this.isUXRovertime = data.isUXRovertime ? data.isUXRovertime : false;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText || this.upgradeButtonText ;
      }
    }
  
  }

  ngOnInit(): void {
    this.dialogRef.afterOpened().subscribe(() => {
      this.simulateResize();
    });
  }

  simulateResize() {
    window.dispatchEvent(new Event('resize'));
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
