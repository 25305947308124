import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { isInteger, keys, uniq } from 'lodash';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { FirstPartyDataServiceService } from 'src/app/services/first-party-data-service.service';
import { RumService } from 'src/app/services/rum.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';

@Component({
    selector: 'app-pages-by-performance',
    templateUrl: './pages-by-performance.component.html',
    styleUrl: './pages-by-performance.component.scss',
    standalone: false
})
export class PagesByPerformanceComponent implements OnInit, OnChanges {
  @Input() metric: string;
  @Input() userId: string;
  @Input() queryPeriod: string;
  @Input() sharedMode: boolean;
  @Input() projectId: string;
  @Input() selectedCountry: string;
  @Input() device: string;
  @Input() attributionData: any;
  // @Input() set device(value: string) {
  //   if (value !== this._device) {
  //     this._device = value;
  //     this.setDevice(this._device );
  //   }
  // }
  @Input() selectedDimGroup: string;
  @Input() selectedDimension: string;

  displayedColumns: string[] = ['title', 'pageViews', 'metric', 'link'];

  displayedColumnsWorst: string[];

  mostVisitedPagesDataList;
  worestPerformingPagesDataList;

  mostVisitedPagesDatasource = new MatTableDataSource();
  worestPerformingPagesDatasource = new MatTableDataSource();
  internalElementMetricDataSource = new MatTableDataSource();
  mostVisitedLoading = false;
  worstPagesLoading = false;

  pageVisitTrack = [];
  // private _device: string;

  constructor(
    private webAnalizeService: WebsiteAnalyzeService,
    private fpdService: FirstPartyDataServiceService,
    private snackBar: MatSnackBar,
    private rumService: RumService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    keys(changes).forEach((key) => {
      this[key] = changes[key].currentValue;
    });
    this.internalElementMetricDataSource.data = this.processAttributionData(
      this.attributionData?.[this.device]?.[this.metric],
      this.metric
    );

    this.updateWorstPages();
    this.updateTableData();
  }

  ngOnInit(): void {
    this.internalElementMetricDataSource.data = this.processAttributionData(
      this.attributionData?.[this.device]?.[this.metric],
      this.metric
    );
    this.displayedColumnsWorst = this.columnMatTable();
    this.fetchData();
    // throw new Error('Method not implemented.');
  }

  processAttributionData(attributionData: any[], metric: string) {
    if (metric !== 'lcp') return attributionData;
    return attributionData?.map((attr) => {
      return {
        ...attr,
        URLs: uniq(attr?.URLs?.filter((url) => !!url)),
      };
    });
  }

  columnMatTable() {
    switch (this.metric) {
      case 'lcp':
        return ['NbEvent', 'element', 'value', 'URL'];
      case 'inp':
        return ['NbEvent', 'element', 'value', 'inpEventType', 'URL'];
      case 'cls':
        return ['NbEvent', 'element', 'value', 'shiftTimeCls', 'URL'];
    }
  }

  setQueryPeriod(event: Event) {
    const target = event.target as HTMLInputElement;
    const QueryPeriod = target.value;
    this.queryPeriod = QueryPeriod;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdQueryPeriod,
      this.queryPeriod
    );

    this.fetchData();
  }
  setDevice(selectedDevice: string) {
    this.device = selectedDevice;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);
    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';

    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimension,
      this.selectedDimension
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    this.updateTableData();
  }

  updateTableData() {
    if (!this.mostVisitedPagesDataList) return;
    const mostVisitedPagesCalculatedMetricData =
      this.rumService.getComputedRUMInternalPagesList(
        this.mostVisitedPagesDataList || [],
        this.selectedDimGroup,
        this.selectedDimension,
        this.device,
        this.selectedCountry
      );

    this.mostVisitedPagesDatasource.data = mostVisitedPagesCalculatedMetricData;
    this.worestPerformingPagesDatasource.data =
      this.rumService.getRUMWorstPerfomingPages(
        this.worestPerformingPagesDataList,
        this.selectedDimGroup,
        this.selectedDimension,
        this.device,
        this.selectedCountry,
        [this.metric]
      );
  }

  async fetchData() {
    try {
      this.mostVisitedLoading = true;
      this.worestPerformingPagesDataList = true;

      const mostVisitedPagesResult = await this.webAnalizeService
        .getRumMostVisitedPages(this.projectId, this.queryPeriod)
        .toPromise();
      this.mostVisitedPagesDataList = mostVisitedPagesResult.data;
      console.log(this.mostVisitedPagesDataList)

      this.worestPerformingPagesDataList = await this.webAnalizeService
        .getRumWorstPerformingPages(
          this.projectId,
          this.metric,
          this.queryPeriod,
          this.selectedCountry,
          this.device,
          this.selectedDimension
        )
        .toPromise();

      this.mostVisitedLoading = false;
      this.worstPagesLoading = false;

      this.updateTableData();
    } catch (error) {
      console.error('Error fetching data', error);
    }
  }

  prepareInternals(data) {
    const allInternals = [];
    keys(data).forEach((k) => {
      const item = data[k][0];
      if (item?.[this.metric]?.length) {
        allInternals.push({
          url: item.url,
          metricData: item[this.metric],
          projectId: item.projectId,
          location: item.location,
        });
      }
    });
    this.worestPerformingPagesDataList = allInternals;
  }

  async updateWorstPages() {
    this.worstPagesLoading = true;
    const data = await this.webAnalizeService
      .getRumWorstPerformingPages(
        this.projectId,
        this.metric,
        this.queryPeriod,
        this.selectedCountry,
        this.device,
        this.selectedDimension
      )
      .toPromise();
    this.worstPagesLoading = false;
    this.worestPerformingPagesDatasource.data =
      this.rumService.getRUMWorstPerfomingPages(
        data,
        this.selectedDimGroup,
        this.selectedDimension,
        this.device,
        this.selectedCountry,
        [this.metric]
      );
  }

  getMetric(): string {
    return this.metric;
  }
  formatUrl(url: string) {
    const urlData = url.split('/');
    return urlData.slice(3).join('/');
  }

  trimURL(url) {
    if (!url) return '-';
    const joint = url.split('/');
    return [joint.pop(), joint.pop()].reverse().join('/');
  }

  getPercentileValue(array: any[], percentile: number) {
    array.sort();
    let k = percentile * (array.length - 1);
    if (isInteger(k)) return array[k];

    const kfloor = Math.floor(k);
    const kceil = Math.ceil(k);
    const lLow = array[kfloor];
    const lHigh = array[kceil];

    return lLow + (k - kfloor) * (lHigh - lLow);
  }
  copyUrlToClipboard(url: any): void {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        this.snackBar.open('URL copied!', 'Close', {
          duration: 2000,
        });
      })
      .catch((err) => {
        this.snackBar.open('Error copying URL!', 'Close', {
          duration: 2000,
        });
      });
  }

  goToDetails(metric) {
    this.router.navigate(
      [`/home/rum/${metric?.siteId}/internalpages/details`],
      { queryParams: { url: encodeURIComponent(metric?.website) } }
    );
  }
}
