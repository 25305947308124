<h3 style="text-align: center">
  <b>
    Check how your internal pages are performing on
    {{ metric }}</b
  >
</h3>
<div id="tab-metric">
  <div class="graph m-4 p-2">
    <div>
      <mat-tab-group mat-align-tabs="center" class="m-3">
        <mat-tab label="Most visited pages">
          <ng-template matTabContent>
            <div
              *ngIf="mostVisitedLoading"
              class="d-flex justify-content-center align-items-center m-4"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            <mat-table
              *ngIf="!mostVisitedLoading"
              [dataSource]="mostVisitedPagesDatasource"
              class="m-4"
              matSort
            >
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                  <b> Page </b>
                </mat-header-cell>
                <mat-cell class="box" *matCellDef="let element">
                  <div class="d-flex justify-content-center align-items-center">
                    {{ trimURL(element.website) }}
                    <a
                      #tooltip="matTooltip"
                      matTooltip="{{ element.website }}"
                      matTooltipHideDelay="1000"
                      aria-label="Button that displays a tooltip that hides when scrolled out of the container"
                      href="{{ element.website }}"
                      target="_blank"
                    >
                      <mat-icon>open_in_new</mat-icon>
                    </a>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="pageViews">
                <mat-header-cell *matHeaderCellDef style="text-align: center">
                  <b>Page Views</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  {{ element.total }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="metric">
                <mat-header-cell *matHeaderCellDef style="text-align: center">
                  <b>{{ metric.toUpperCase() }}</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  @if(!!!element?.[getMetric()]) {
                  <div>
                    <p style="text-align: center">
                      No <b>{{ metric }} data</b>
                    </p>
                  </div>
                  } @if(!!element?.[getMetric()]) {
                  <apx-chart
                    [series]="element[getMetric()].series"
                    [chart]="element[getMetric()].chart"
                    [dataLabels]="element[getMetric()].dataLabels"
                    [plotOptions]="element[getMetric()].plotOptions"
                    [xaxis]="element[getMetric()].xaxis"
                    [yaxis]="element[getMetric()].yaxis"
                    [stroke]="element[getMetric()].stroke"
                    [fill]="element[getMetric()].fill"
                    [tooltip]="element[getMetric()].tooltip"
                    [legend]="element[getMetric()].legend"
                  >
                  </apx-chart>
                  }
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="link">
                <mat-header-cell *matHeaderCellDef>
                  <b> Details </b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <br />
                  <div class="example-container">
                    <a
                      type="button"
                      class="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                      (click)="goToDetails(element)"
                    >
                      <mat-icon>timeline</mat-icon>
                      Track
                    </a>
                  </div>
                  <br />
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns">
              </mat-row> </mat-table
          ></ng-template>
        </mat-tab>
        <mat-tab label="Worst performing pages">
          <ng-template matTabContent>
            <div
              *ngIf="worstPagesLoading"
              class="d-flex justify-content-center align-items-center m-4"
              style="height: -1000px"
            >
              <mat-spinner class="spinner" diameter="150"></mat-spinner>
            </div>
            <mat-table
              [dataSource]="worestPerformingPagesDatasource"
              class="m-4"
              matSort
              *ngIf="!worstPagesLoading"
            >
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                  <b> Page </b>
                </mat-header-cell>
                <mat-cell class="box" *matCellDef="let element">
                  <div class="d-flex justify-content-center align-items-center">
                    {{ trimURL(element.website) }}
                    <a
                      #tooltip="matTooltip"
                      matTooltip="{{ element.website }}"
                      matTooltipHideDelay="1000"
                      aria-label="Button that displays a tooltip that hides when scrolled out of the container"
                      href="{{ element.website }}"
                      target="_blank"
                    >
                      <mat-icon>open_in_new</mat-icon>
                    </a>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="pageViews">
                <mat-header-cell *matHeaderCellDef style="text-align: center">
                  <b>Page Views</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  {{ element.pageVisits }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="metric">
                <mat-header-cell *matHeaderCellDef style="text-align: center">
                  <b>{{ metric.toUpperCase() }}</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  @if(!element[getMetric()]) {
                  <p style="text-align: center">
                    No <b>{{ metric }} data</b>
                  </p>
                  } @if(!!element[getMetric()]){

                  <apx-chart
                    [series]="element[getMetric()].series"
                    [chart]="element[getMetric()].chart"
                    [dataLabels]="element[getMetric()].dataLabels"
                    [plotOptions]="element[getMetric()].plotOptions"
                    [xaxis]="element[getMetric()].xaxis"
                    [yaxis]="element[getMetric()].yaxis"
                    [stroke]="element[getMetric()].stroke"
                    [fill]="element[getMetric()].fill"
                    [tooltip]="element[getMetric()].tooltip"
                    [legend]="element[getMetric()].legend"
                  >
                  </apx-chart>
                  }
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="link">
                <mat-header-cell *matHeaderCellDef>
                  <b> Details </b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <br />
                  <div class="example-container">
                    <a
                      type="button"
                      class="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                      (click)="goToDetails(element)"
                    >
                      <mat-icon>timeline</mat-icon>
                      Track
                    </a>
                  </div>
                  <br />
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns">
              </mat-row>
            </mat-table>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ metric.toUpperCase() }} Debug Data">
          <ng-template matTabContent>
            <mat-table
              [dataSource]="internalElementMetricDataSource"
              class="m-4"
              matSort
              *ngIf="metric === 'lcp'"
            >
              <ng-container matColumnDef="NbEvent">
                <mat-header-cell *matHeaderCellDef style="text-align: center">
                  <b>Nb event</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  {{ element.count }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="element">
                <mat-header-cell *matHeaderCellDef>
                  <b> {{ metric.toUpperCase() }} element </b>
                </mat-header-cell>
                <mat-cell class="box" *matCellDef="let element">
                  <div class="d-flex justify-content-center align-items-center">
                    <span
                      matTooltip="{{ element.targetElement }}"
                      matTooltipPosition="above"
                      class="d-inline-block text-truncate"
                      style="max-width: 180px"
                    >
                      <b>{{ element.targetElement }}</b>
                    </span>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="value">
                <mat-header-cell *matHeaderCellDef>
                  Value (75p)
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <br />
                  <div class="example-container">
                    {{ element.p75?.[0].toFixed(2)}}
                  </div>
                  <br />
                </mat-cell>
              </ng-container>
              @if(metric === 'cls') {
              <ng-container matColumnDef="shiftTimeCls">
                <mat-header-cell *matHeaderCellDef>
                  CLS shift time (75p)
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <br />
                  <div class="example-container">
                    {{ element.percentile75CLS.toFixed(2) }}
                  </div>
                  <br />
                </mat-cell>
              </ng-container>
              } @if(metric === 'inp') {
              <ng-container matColumnDef="inpEventType">
                <mat-header-cell *matHeaderCellDef>
                  <b> INP Event Type </b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-accordion class="m-1">
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title> Events </mat-panel-title>
                        <mat-panel-description> </mat-panel-description>
                      </mat-expansion-panel-header>
                      @for ( event of element.inpEventType; track event) {
                      <div
                        class="d-flex justify-content-between align-items-center p-2"
                      >
                        <span>
                          <b>{{ event }}</b>
                        </span>
                        <br />
                      </div>
                      }
                    </mat-expansion-panel>
                  </mat-accordion>
                </mat-cell>
              </ng-container>
              }
              <ng-container matColumnDef="URL" class="flex-grow">
                <mat-header-cell *matHeaderCellDef>
                  <b> URL </b>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-accordion class="m-1">
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title> URLs </mat-panel-title>
                        <mat-panel-description> event </mat-panel-description>
                      </mat-expansion-panel-header>
                      @for ( url of element.URLs; track url) {
                      <div
                        class="d-flex justify-content-between align-items-center p-2"
                      >
                        <div
                          class="d-flex justify-content-center align-items-center mx-1"
                        >
                          <span
                            matTooltip="{{ url }}"
                            matTooltipPosition="above"
                            class="d-inline-block text-truncate"
                            style="max-width: 150px"
                          >
                            <b>{{ formatUrl(url) }}:</b>
                          </span>
                        </div>
                        <div
                          class="d-flex justify-content-center align-items-center mx-1"
                        >
                          <a #tooltip="matTooltip" matTooltip="{{ Track }}">
                            <mat-icon
                              matTooltip="Copy Url"
                              (click)="copyUrlToClipboard(url)"
                              class="mx-1"
                              style="cursor: pointer"
                            >
                              content_copy
                            </mat-icon>
                          </a>

                          <a
                            #tooltip="matTooltip"
                            matTooltip="{{ url }}"
                            matTooltipHideDelay="1000"
                            aria-label="Button that displays a tooltip that hides when scrolled out of the container"
                            href="{{ url }}"
                            target="_blank"
                          >
                            <mat-icon>open_in_new</mat-icon>
                          </a>
                        </div>
                        <br />
                      </div>
                      }
                    </mat-expansion-panel>
                  </mat-accordion>
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumnsWorst; sticky: true"
              ></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsWorst">
              </mat-row>
            </mat-table>
            <div *ngIf="metric === 'inp'">
              <mat-grid-list cols="3" rowHeight="9:1">
                <mat-grid-tile> Event target element </mat-grid-tile>
                <mat-grid-tile>Event type</mat-grid-tile>
                <mat-grid-tile>Largest event time</mat-grid-tile>
                <mat-grid-tile>
                  {{attributionData?.[device]?.['inp']?.[0]?.targetElement || "-"}}
                </mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData?.[device]?.['inp']?.[0]?.inpEventType ||"-"}}
                </mat-grid-tile>
                <mat-grid-tile>
                  {{ attributionData?.[device]?.['inp']?.[0]?.value || 0}}
                </mat-grid-tile>
              </mat-grid-list>
            </div>
            <div *ngIf="metric === 'cls'">
              <mat-grid-list cols="3" rowHeight="9:1">
                <mat-grid-tile> Largest shift target Element </mat-grid-tile>
                <mat-grid-tile>Largest shift value</mat-grid-tile>
                <mat-grid-tile>Largest shift time</mat-grid-tile>
                <mat-grid-tile>
                  <p style="color: dodgerblue">
                    {{ attributionData?.[device]?.['cls']?.[0]?.targetElement || "-" }}
                  </p>
                </mat-grid-tile>
                <mat-grid-tile>
                  <p>
                    {{  attributionData?.[device]?.['cls']?.[0]?.largestShift || "-" }}
                  </p>
                </mat-grid-tile>
                <mat-grid-tile>
                  <p>
                    {{ attributionData?.[device]?.['cls']?.[0]?.largestShiftTime || 0












                    }}ms
                  </p>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
