import { Component, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexMarkers, ApexStroke, ApexTitleSubtitle, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { CompilerService } from 'src/app/shared/compiler/compiler.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import {
  ChartOptionsInterface,
  DataCrux,
  ListOverallFeatures,
  metricOverTime,
  PageSpeedDataOverview,
} from 'src/app/shared/models/pagespeed.model';
import { MatSort } from '@angular/material/sort';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { Router } from '@angular/router';
import { legend } from '../../../shared/models/legend';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/services/common/sidabar/Data.service';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};
@Component({
    selector: 'app-page-compare',
    templateUrl: './page-compare.component.html',
    styleUrls: ['./page-compare.component.scss'],
    standalone: false
})

export class PageCompareComponent  implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  public chartOptionsCrux: Partial<any>;
  public competitor:Partial<any>;
  public chartOptionsCRUXCompetitorLCP: Partial<any>;
  public chartOptionsCRUXCompetitorCLS: Partial<any>;
  public chartOptionsCRUXCompetitorFCP: Partial<any>;
  public chartOptionsCRUXCompetitorTTFB: Partial<any>;
  public chartOptionsCRUXCompetitorInp: Partial<any>;
  public chartOptionsUXRRank : Partial<any>;

  public chartOptionsCRUXLastMonthCompetitorFCP: Partial<any>;
  public chartOptionsCRUXLastMonthCompetitorCLS: Partial<any>;
  public chartOptionsCRUXLastMonthCompetitorLCP: Partial<any>;
  public chartOptionsCRUXLastMonthCompetitorTTFB: Partial<any>;
  public chartOptionsCRUXLastMonthCompetitorInp: Partial<any>;

  device : string;
  datas: any [];
  sortedData: PageSpeedDataOverview[];
  chartOptions: ChartOptionsInterface[] = [];
  internalUrls = [];
  userId: any;
  test: any;
  menu: string;
  loading = true;
  listInternalUrls: any;
  dataSource: any;
  metricOverTime: metricOverTime;
  displayedColumns: string[] = [
    'image',
    'title',
    'LCP',
    'CLS',
    'Overall',
  ];
  titleURL: string;
  filterList = ['Passing Page', 'No-Passing Page'];
  ValidOrNot: boolean = true;
  overallList = ListOverallFeatures;
  @ViewChild(MatSort) sort: MatSort;
  CruxData: DataCrux;
  predefinedCountries: any;
  defaultValue: any;
  resp: any;
  i=0;
  colors = []
  colorsLog = {}
  legend = legend;
  tableDataSource
  sortedTd
  tableDisplayedColumns: string[] = ['rank', 'url', 'TTFB', 'LCP', 'INP', 'CLS', 'uxrScore',];
  psiData
  WebSiteTitle: any;
  country: any;
  currentUrl: any;
  Url: string;
  projectID: any;
  constructor(
    private speetalsDatabase: SpeetalsDatabaseService,
    private compilerService: CompilerService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    public senderVarService: SenderVarService,
    private dataService: DataService,
    private router: Router
  ) {}



  async ngOnInit(): Promise<void> {
    const sharedData = this.dataService.getSharedData();
    this.WebSiteTitle = sharedData.WebSiteTitle;
    this.Url = this.router.url;
    this.currentUrl = sharedData.url;
    this.projectID = sharedData.projectID;

    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.device = this.device === "tablet" ? "mobile": this.device
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);

    let crux: any = await this.speetalsDatabase.getCompetitors(
      'crux',
      this.userId,
      this.senderVarService.id,
      this.device.toLowerCase()
    ).toPromise();
    this.country = sharedData.country;
    this.CruxData = crux;
    const cruxPdata: any = this.processCruxData(crux)
    this.tableDataSource = this.pageSpeedInsigthService.getUXRRanking(crux)
    this.sortedTd = this.tableDataSource
    this.tableDataSource = new MatTableDataSource(this.tableDataSource)

    let urls =this.compilerService.constructUrlCompetitors(crux); //crux and psi
    let date = this.pageSpeedInsigthService.getdateCompeitor(crux,urls[0]); //crux

    let dataCruxCls=[],
        dataCruxLcp=[],
        dataCruxFcp=[],
        dataCruxTtfb=[],
        dataCruxInp=[],
        dataUXRRank = []

    urls.map(url=>{
      dataUXRRank.push(this.pageSpeedInsigthService.getUXRCompetitors(crux,url,"uxr", date)) ;
      dataCruxCls.push(this.pageSpeedInsigthService.getCompetitors(crux,url,"CLS", date)) ;
      dataCruxLcp.push(this.pageSpeedInsigthService.getCompetitors(crux,url,"LCP", date)) ;
      dataCruxFcp.push(this.pageSpeedInsigthService.getCompetitors(crux,url,"FCP", date)) ;
      dataCruxTtfb.push(this.pageSpeedInsigthService.getCompetitors(crux,url,"TTFP", date)) ;
      dataCruxInp.push(this.pageSpeedInsigthService.getCompetitors(crux,url,"Inp", date)) ;

    })

    this.chartOptionsUXRRank = this.pageSpeedInsigthService.chartOptionsUXRCompetitor(dataUXRRank,date)
    this.chartOptionsCRUXCompetitorCLS=this.pageSpeedInsigthService.chartOptionsCompetitor(dataCruxCls,date,"CLS");
    this.chartOptionsCRUXCompetitorLCP=this.pageSpeedInsigthService.chartOptionsCompetitor(dataCruxLcp,date,"LCP");
    this.chartOptionsCRUXCompetitorFCP=this.pageSpeedInsigthService.chartOptionsCompetitor(dataCruxFcp,date,"FCP");
    this.chartOptionsCRUXCompetitorTTFB=this.pageSpeedInsigthService.chartOptionsCompetitor(dataCruxTtfb,date,"TTFB");
    this.chartOptionsCRUXCompetitorInp=this.pageSpeedInsigthService.chartOptionsCompetitor(dataCruxInp,date,"INP");
    this.fixLineCharColors(this.chartOptionsCRUXCompetitorFCP.series)

    const urlNames = cruxPdata.urlTitles

    this.chartOptionsCRUXLastMonthCompetitorCLS =
    this.pageSpeedInsigthService.chartOptionsCompareCrux(
      cruxPdata.pData.Cls,
      this.legend.cls,
      urlNames,
      'CLS'
    );

    this.chartOptionsCRUXLastMonthCompetitorLCP =
    this.pageSpeedInsigthService.chartOptionsCompareCrux(
      cruxPdata.pData.Lcp,
      this.legend.lcp,
      urlNames,
      'LCP'
    );

    this.chartOptionsCRUXLastMonthCompetitorFCP =
    this.pageSpeedInsigthService.chartOptionsCompareCrux(
      cruxPdata.pData.Fcp,
      this.legend.fcp,
      urlNames,
      'FCP'
    );
    this.chartOptionsCRUXLastMonthCompetitorTTFB =
    this.pageSpeedInsigthService.chartOptionsCompareCrux(
      cruxPdata.pData.TTFP,
      this.legend.ttfb,
      urlNames,
      'TTFB'
    );

    this.chartOptionsCRUXLastMonthCompetitorInp =
    this.pageSpeedInsigthService.chartOptionsCompareCrux(
      cruxPdata.pData.Inp,
      this.legend.inp,
      urlNames,
      'INP'
    );
    this.loading = false;
  }


  fixLineCharColors(chartData){
    const colorsPallete = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']
    const availaleColors = colorsPallete
      .filter(c => !Object.values(this.colorsLog)
      .includes(c))
    let colorIndex = 0
    const colorsBuilt = []

    chartData.forEach((item) => {
      if (!this.colorsLog[item.name]){
        this.colorsLog[item.name] = availaleColors[colorIndex]
        colorsBuilt.push(availaleColors[colorIndex])
        colorIndex += 1
      } else {
        colorsBuilt.push(this.colorsLog[item.name])
      }
    })
    this.colors = colorsBuilt
  }

  setDevice($event) {
    this.device= $event;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);
    this.CruxData.length = 0;
    this.ngOnInit();
  }
  sortByDate(arr) {
    arr.sort(function (a, b) {
      return Number(new Date(a.yyyymm)) - Number(new Date(b.yyyymm));
    });
}
isGold(uxr) {
  if (this.sortedTd.length >= 1) {
    const indexOfI = this.sortedTd.findIndex((d) => d?.uxr === uxr);
    return indexOfI === 0;
  }
  return false;
}

isSilver(uxr) {
  if (this.sortedTd.length >= 1) {
    const indexOfI = this.sortedTd.findIndex((d) => d?.uxr === uxr);
    return indexOfI === 1;
  }
  return false;
}

isBronze(uxr) {
  if (this.sortedTd.length >= 1) {
    const indexOfI = this.sortedTd.findIndex((d) => d?.uxr === uxr);
    return indexOfI === 2;
  }
  return false;
}

isFourth(uxr) {
  if (this.sortedTd.length >= 1) {
    const indexOfI = this.sortedTd.findIndex((d) => d?.uxr === uxr);
    return indexOfI === 3;
  }
  return false;
}
isFifth(uxr) {
  if (this.sortedTd.length >= 1) {
    const indexOfI = this.sortedTd.findIndex((d) => d?.uxr === uxr);
    return indexOfI === 4;
  }
  return false;
}
isUserDomain(url) {
  const data = this.CruxData.find((d) => d.url === url);
  return data.originDomain === url
}
processCruxData(arr) {
  const data: any = {};
  const lastMonthData = [];
  const urlTitles = []
  arr.forEach((d) => {
    if (data[d.url]) {
      data[d.url] = [...data[d.url], d];
    } else {
      data[d.url] = [d];
    }
  });

  Object.keys(data).forEach((k) => {
    const kA = data[k];
    lastMonthData.push(kA?.[kA?.length - 1]);
    urlTitles.push(kA?.[kA?.length - 1].title || "")
  });
  let pData = {};
  const metrics = ['Cls', 'Fcp', 'Inp', 'Lcp', 'TTFP'];
  metrics.forEach((m) => {
    const dataGood = [],
      dataNeed = [],
      dataPoor = [];
    for (let lastMonD of lastMonthData) {
      const valGood = Math.round(lastMonD[`pctGood${m}`] * 1000) / 10;
      const valNeed = Math.round(lastMonD[`pctAvg${m}`] * 1000) / 10;
      const valPoor = Math.round(lastMonD[`pctSlow${m}`] * 1000) / 10;

      dataGood.push(valGood);
      dataNeed.push(valNeed);
      dataPoor.push(valPoor);
    }
    pData[m] = {
      dataGood,
      dataNeed,
      dataPoor,
    };
  });

  return {pData, urlTitles}
}
estAlloue(code): boolean {
  if (code == 'NotAllowed' || code == undefined) return false;
  return true;
}
}
