<div class="confirm-dialog-container rounded-5">
  <div class="confirm-dialog-header">
    <mat-icon class="confirm-dialog-icon" aria-hidden="true">help_outline</mat-icon>
    <h2 class="confirm-dialog-title">Confirmation</h2>
  </div>
  <mat-dialog-content>
    <p class="confirm-dialog-message py-0">{{ message }}</p>
    <!-- Affichage conditionnel du champ pour le motif d'annulation -->
    <div *ngIf="showCancellationReason" class="mt-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Cancellation Reason</mat-label>
        <input matInput [(ngModel)]="cancellationReason" placeholder="Enter reason for cancellation" required>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="confirm-dialog-actions">
    <button class="btn btn-outline-danger me-2" mat-dialog-close tabindex="-1">
      {{ cancelButtonText || 'Cancel' }}
    </button>
    <button class="btn btn-outline-primary" (click)="onConfirmClick()" tabindex="1">
      {{ confirmButtonText || 'Confirm' }}
    </button>
  </mat-dialog-actions>
</div>
