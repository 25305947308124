<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>
                    Thank you for subscribing to Speetals</h1>
                <h2>
                    payment has been made successfully</h2>
                <div class="error-details">
                
                </div>
                <div class="error-actions">
                    <a href="/home/pageLoad/new" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
                        Monitor your website </a>
                </div>
            </div>
        </div>
    </div>
</div>