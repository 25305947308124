import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { UserService } from 'src/app/services/common/user/user.service';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { PageSpeedData } from 'src/app/shared/models/pagespeed.model';
import {
  BarUrl,
  Plan,
  WebsiteAnalyze,
} from 'src/app/shared/models/user.models';
import { AuthService } from '../../../services/core/auth/auth.service';
import { countries } from 'src/app/shared/models/country.model';
import { MatSelect } from '@angular/material/select';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { UntypedFormGroup } from '@angular/forms';
import { SharedWithDialogComponent } from '../shared-with-dialog/shared-with-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { flatten } from 'lodash';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ValidationService } from '../../../services/validation.service'

@Component({
    selector: 'app-list-websites',
    templateUrl: './list-websites.component.html',
    styleUrls: ['./list-websites.component.scss'],
    standalone: false
})
export class ListWebsitesComponent implements OnInit, OnDestroy {
  @ViewChild('select') select: MatSelect;

  public chartOptionsTTFP: Partial<any>;
  public chartOptionsCLS: Partial<any>;
  public chartOptionsLCP: Partial<any>;
  public chartOptionsINP: Partial<any>;

  url: string;
  userId: any;
  BarURL: any;
  datas: PageSpeedData[] = [];
  dataSource: MatTableDataSource<any>;
  metrics: string[] = ['ttfb', 'fcp', 'lcp', 'inp', 'cls'];
  displayedColumns: string[] = ['title', 'LCP', 'CLS', 'INP'];
  displayTable = false;
  device: string;
  isDisabled: boolean = false;
  loading = true;
  dataResult: any;
  demoResult: any;
  sharedWithResult: any;
  modeShared: boolean = false;
  isChecked = false;
  isDemo: boolean;
  isNew = false;
  i = 0;
  titleURL: any[];
  urlNav: any[];
  plan: Plan;
  testView = false;
  websitesRestantes = 0;
  locationAllow: string = 'ok';
  website: WebsiteAnalyze[] = [];
  radiobutton: boolean = true;
  editable: boolean;
  dataBar: BarUrl[];
  countries = countries;
  speedColors = ConstantService.speedColors;
  country1: any;
  isAllowed: boolean;
  isfree: boolean;
  passPage: boolean;
  upgrade: boolean;
  fb: UntypedFormGroup[] = [];
  form: UntypedFormGroup;
  projectId: any;
  isShared: boolean;
  mailsExist: Array<string> = [];
  displayNameMetrics: string[] = [];
  demoProjectId: string;
  resp: any;
  filterList = ['Passing Pages', 'No-Passing Pages'];
  remainingInternalPages = 0;
  noDataWebAnalyze = [];
  isRUM: boolean;
  noSitesCreated = false;
  isCRUX: boolean = true;
  isValidated: boolean = false;
  showAllMetrics: boolean = false;
  private validationSubscription: Subscription;
  checklistId: string = 'listwebsite';

  @ViewChild(MatSort) sort: MatSort;
  chartOptionsTTFB: {
    dataLabels: { enabled: boolean };
    labels: { colors: any; useSeriesColors: boolean };
    legend: {
      position: string;
      offsetY: number;
      useSeriesColors: boolean;
      labels: { opacity: number; colors: string[] };
      markers: {
        width: number;
        height: number;
        strokeWidth: number;
        opacity: number;
        fillColors: string[];
      };
    };
    series: { name: string; data: any[] }[];
    chart: {
      toolbar: { show: boolean; tools: { download: boolean } };

      height: number;
      stacked: boolean;
    };
    plotOptions: { bar: { columnWidth: string; horizontal: boolean } };
    stroke: { width: number; colors: string[] };
    title: { text: string };
    xaxis: { categories: string[] };
    yaxis: { max: number };
    tooltip: { y: { formatter: (val: any) => number } };
    fill: { colors: string[] };
  };
  chartOptionsFCP: {
    dataLabels: { enabled: boolean };
    labels: { colors: any; useSeriesColors: boolean };
    legend: {
      position: string;
      offsetY: number;
      useSeriesColors: boolean;
      labels: { opacity: number; colors: string[] };
      markers: {
        width: number;
        height: number;
        strokeWidth: number;
        opacity: number;
        fillColors: string[];
      };
    };
    series: { name: string; data: any[] }[];
    chart: {
      toolbar: { show: boolean; tools: { download: boolean } };
      type: string;
      height: number;
      stacked: boolean;
    };
    plotOptions: { bar: { columnWidth: string; horizontal: boolean } };
    stroke: { width: number; colors: string[] };
    title: { text: string };
    xaxis: { categories: string[] };
    yaxis: { max: number };
    tooltip: { y: { formatter: (val: any) => number } };
    fill: { colors: string[] };
  };
  videoUrl: SafeResourceUrl;
  isModalOpen: boolean;
  public hasCreatedWebsite: boolean = false;
  public hasSharedWebsite: boolean = false;
  hasReceivedSharedWebsite: boolean;
  userEmail: string;
  noSiteCreated: string =  "No website have been added"

  constructor(
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    private speetalsDatabaseService: SpeetalsDatabaseService,
    public authService: AuthService,
    private router: Router,
    private title: Title,
    private userService: UserService,
    private senderVarService: SenderVarService,
    private dialog: MatDialog,
    private ccService: NgcCookieConsentService,
    private sanitizer: DomSanitizer,
    private validationService: ValidationService
  ) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/mvJePraln4k'
    );
    this.dataSource = new MatTableDataSource();

    this.isShared = true;
    //this.isChecked = !this.isShared
    this.datas.length = 0;
    this.noDataWebAnalyze = [];
    this.fetchdata();

  }

  async ngOnInit(): Promise<void> {
      this.validationSubscription = this.validationService.isValidated$(this.checklistId).subscribe(
      (status) => {
        this.isValidated = status;
      }
    );

    const videoModal = document.getElementById('videoModal');
    if (videoModal) {
      videoModal.addEventListener('hidden.bs.modal', () => {
        this.stopVideo();
      });
    }
    this.title.setTitle('Speetals Web Performance Vitals');

    let device = localStorage.getItem(ConstantService.loadingUrl.device);
    device = device === 'tablet' ? 'mobile' : device;
    this.device = device || 'desktop';

    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    // this.userEmail = localStorage.getItem(ConstantService.localStorageKeys.email);
    this.plan = await this.userService.getPlan(this.userId).toPromise();
    this.isRUM = this.plan.details?.realUserMonitoring?.value;
    
    // Initialize showAllMetrics from localStorage
    const showAllMetricsMode = localStorage.getItem('showAllMetrics') === 'on';
    this.showAllMetrics = showAllMetricsMode;
    // Update displayed columns based on the toggle state
    this.displayedColumns = showAllMetricsMode 
      ? ['title', 'TTFB', 'FCP', 'LCP', 'CLS', 'INP'] 
      : ['title', 'LCP', 'CLS', 'INP'];
    
    // Update metrics array to match displayed columns
    this.metrics = showAllMetricsMode
      ? ['ttfb', 'fcp', 'lcp', 'cls', 'inp']
      : ['lcp', 'cls', 'inp'];
    
    if (this.userId) {
      this.ccService.popupOpen$.subscribe((e) => {});
    }
    this.fetchdata();
  }

  async fetchdata() {
    try {
      this.testView = true;
      this.locationAllow = this.plan.details.locationSelectionAllowed.value
        ? 'location supported'
        : 'location not supported';
      if (this.plan.details.internalPagesOnly) {
        this.resp = await this.websiteAnalyzeService
          .getInternalPagesOnlyList(this.userId, this.device)
          .toPromise();
        let urlSpeedData = this.pageSpeedInsigthService.getUrlSpeedData1(
          this.resp,
          this.device
        );

        const datasL = [];
        urlSpeedData.map((ele: any) => {
          datasL.push({
            ...this.pageSpeedInsigthService.getSpeedData(ele),
            ttfbDiff: ele.ttfbDiff,
            lcpDiff: ele.lcpDiff,
            clsDiff: ele.clsDiff,
            inpDiff: ele.inpDiff,
            projectId: ele.projectId,
          });
        });
        // this.titleURL = this.datas[0]?.title || 'title';
        datasL.forEach((data) => {
          this.chartOptionsTTFB = this.pageSpeedInsigthService.chartOptions(
            data.ttfb
          );
          this.chartOptionsCLS = this.pageSpeedInsigthService.chartOptions(
            data.cls
          );
          this.chartOptionsLCP = this.pageSpeedInsigthService.chartOptions(
            data.lcp
          );
          this.chartOptionsFCP = this.pageSpeedInsigthService.chartOptions(
            data.fcp
          );
          this.chartOptionsINP = this.pageSpeedInsigthService.chartOptions(
            data.inp
          );
          data.chartOptions = {
            cls: this.chartOptionsCLS,
            lcp: this.chartOptionsLCP,
            fcp: this.chartOptionsFCP,
            inp: this.chartOptionsINP,
            ttfb: this.chartOptionsTTFB,
          };
        });

        //this.datas.length = 0;
        this.remainingInternalPages =
          Number(this.plan.details.nbInternalPage.value) - datasL.length;
        this.dataSource = new MatTableDataSource(datasL);
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'LCP':
              return item.lcp.good;
            case 'CLS':
              return item.cls.good;
            case 'TTFB':
              return item.ttfb.good;
            case 'FCP':
              return item.fcp.good;
            case 'INP':
              return item.inp.good;
            case 'Overall':
              return item.overall;
          }
        };
        this.dataSource.sort = this.sort;
        this.loading = false;
        this.radiobutton = true;

        if (datasL.length < this.website.length) {
          const sites = this.website.filter(
            (w) => !!this.datas.find((d) => d.homeUrl != w.originDomain)
          );
          sites.forEach((site) => {
            this.noDataWebAnalyze.push({
              homeUrl: site.originDomain,
              title: site.title,
              country: site.country,
            });
          });
        }
      } else {
        this.website = await this.websiteAnalyzeService
          .getWebsiteAnalyze(this.userId)
          .toPromise();
        this.website.map((value) => {
          this.editable = value.editable;
        });

        this.noSitesCreated = !this.website.length;
        this.hasCreatedWebsite = this.website.length > 0;
        this.hasSharedWebsite = this.website.some(
          (site) => site.sharedWith?.length > 0
        );

        // this.hasReceivedSharedWebsite = this.website.some(
        //   (site) => site.sharedWith?.includes(this.userEmail)
        // );


        if (this.website.length > Number(this.plan.details.nbWebsite.value)) {
          this.website.length = Number(this.plan.details.nbWebsite.value);
        }

        this.websitesRestantes =
          Number(this.plan.details.nbWebsite.value) -
          Number(this.website.length);

        this.upgrade =
          (this.isfree || this.websitesRestantes <= 0) &&
          this.plan.name.toLowerCase() != 'Agency';

        const sitesResult = await Promise.all([
          this.speetalsDatabaseService
            .getDataListpage(this.userId, this.device.toLowerCase())
            .toPromise(),
          this.speetalsDatabaseService
            .getDataListpageShared(
              this.userId,
              this?.device?.toLowerCase() || 'desktop'
            )
            .toPromise()
            .then((res: any) => res.map((r) => ({ ...r, isShared: true }))),
        ]);
        this.dataResult = flatten(sitesResult);
        let urlSpeedData: any = this.pageSpeedInsigthService.getUrlSpeedData1(
          this.dataResult,
          this.device
        );

        if (urlSpeedData.length === 0) {
          this.noDataWebAnalyze = this.website.map(w => ({
            homeUrl: w.originDomain,
            title: w.title,
            country: w.country,
            noCruxData: true
          }));
          this.dataSource = new MatTableDataSource(this.noDataWebAnalyze);
        } else {
          urlSpeedData.map((ele) => {
            this.senderVarService.editId = ele.projectId;
            this.datas.push({
              ...this.pageSpeedInsigthService.getCruxSpeedData(ele),
              isShared: ele.isShared,
            });
          });
        }

        this.loading = false;

        this.datas.forEach((data) => {
          data.chartOptions = {};

          for (let metric of this.metrics) {
            let mtr: string;
            mtr = metric === 'ttfb' ? 'ttfp' : metric;

            data.chartOptions[metric] =
              this.pageSpeedInsigthService.chartOptions(data[mtr]);
          }
        });
        if (this.datas.length > 0) {
          this.displayTable = true;
        }

        this.datas.forEach((d, i) => {
          Object.keys(d.chartOptions).forEach((k) => {
            const series = d.chartOptions[k].series;
            let hasNoData = true;
            series.forEach((i) => {
              if (i?.data?.[0] != 0.0) {
                hasNoData = false;
              }
            });
            this.datas[i].chartOptions[k]['hasNoData'] = hasNoData;
          });
        });
        if (this.datas.length < this.website.length) {
          this.website.forEach((w) => {
            const exists = this.datas.find((d) => d.projectId === w.id);
            if (!exists)
              this.noDataWebAnalyze.push({
                homeUrl: w.originDomain,
                title: w.title,
                country: w.country,
              });
          });
        }
        this.dataSource = new MatTableDataSource(this.datas);
        this.dataSource.sort = this.sort;
      }
    } catch (error) {
      if (error?.status === 401) {
        localStorage.clear();
        this.router.navigate(['/auth/login']);
      }
    }
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'LCP':
          return item.lcp.good;
        case 'CLS':
          return item.cls.good;
        case 'TTFB':
          return item.ttfb.good;
        case 'FCP':
          return item.fcp.good;
        case 'INP':
          return item.inp.good;
      }
    };
    this.dataSource.sort = this.sort;
  }
  urlName(url) {
    let pageUrl = url.length <= 15 ? url : url.slice(0, 15) + '...';
    return pageUrl;
  }
  clearData() {
    this.authService.logoutUser();
  }

  navigateTo(row) {
    this.router.navigate(['/home/page/' + row.projectId + '/overview']);
  }

  getProjectId(row: any) {
    this.projectId = row.projectId;
  }

  setDevice($event) {
    this.device = $event;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);
    this.datas.length = 0;
    this.i++;
    this.noDataWebAnalyze = [];
    this.ngOnInit();
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onChangeSlideDemo(event: MatSlideToggleChange) {
    const { checked } = event;
    this.isChecked = checked;
    //this.isShared = !this.isChecked
    this.datas.length = 0;
    this.ngOnInit();
  }
  onChangeSlideShared(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.isShared = checked;
    this.noDataWebAnalyze = [];
    this.dataSource.data = checked
      ? this.datas
      : this.datas.filter((d) => !d.isShared);
  }

  onToggleAllMetrics(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.showAllMetrics = checked;
    localStorage.setItem(
      'showAllMetrics',
      checked ? 'on' : 'off'
    );
    // Update displayed columns based on the toggle state
    this.displayedColumns = checked 
      ? ['title', 'TTFB', 'FCP', 'LCP', 'CLS', 'INP'] 
      : ['title', 'LCP', 'CLS', 'INP'];
    
    // Update metrics array to match displayed columns
    this.metrics = checked
      ? ['ttfb', 'fcp', 'lcp', 'cls', 'inp']
      : ['lcp', 'cls', 'inp'];
  }

  printUxr(uxr: number) {
    return uxr !== null && uxr !== undefined;
  }
  getColorUxr(uxr: number) {
    if (uxr >= 90) return 'green';
    if (uxr > 50 && uxr < 90) return 'orange';
    return 'red';
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined) return false;
    return true;
  }

  getEdit() {
    this.senderVarService.isEdit = this.editable;
  }

  ViewEditButton(): boolean {
    return this.editable && !this.isfree && !this.isDemo;
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  getPassing(overall: string) {
    return this.speetalsDatabaseService.passOrNot(overall);
  }

  async openDialog(element: any) {
    let websiteAnalyse = await this.websiteAnalyzeService
      .getWebsiteAnalyzeById(this.userId, element.projectId)
      .toPromise();
    if (websiteAnalyse.sharedWith === undefined) {
      websiteAnalyse.sharedWith = [];
    }
    this.mailsExist = websiteAnalyse.sharedWith;

    const dialogRef = this.dialog.open(SharedWithDialogComponent, {
      data: {
        form: this.form,
        element: element,
        websiteAnalyse: websiteAnalyse,
        buttonText: {
          cancel: 'Close',
        },
      },
      height: '70%',
      width: '50%',
      //panelClass: 'full-screen-modal'
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result !== undefined && result.mails !== undefined) {
        result.mails.map((m) => websiteAnalyse.sharedWith.push(m.mail));
        await this.websiteAnalyzeService
          .putWebsiteAnalyze(result.projectId, websiteAnalyse)
          .toPromise();
      }
    });
  }
  urlDetails(url, projectId) {
    let URL = encodeURIComponent(url);
    return (
      this.router.url.replace(/%2B/g, '') +
      '/internal-detail-po/' +
      projectId +
      '/' +
      URL +
      '?type=internalPageOnly'
    );
  }
  urlAudits(url, projectId) {
    let URL = encodeURIComponent(url);
    return (
      this.router.url.replace(/%2B/g, '') +
      '/audit-internal-po/' +
      this.userId +
      '/' +
      projectId +
      '/' +
      URL
    );
  }
  formatDiff(diff: number) {
    return Math.round(diff * 10) / 10 || 0;
  }

  openVideoModal() {
    this.isModalOpen = true;
    document.body.classList.add('modal-open'); // s un flou
  }
  closeVideoModal() {
    this.isModalOpen = false;
    document.body.classList.remove('modal-open'); // Retirer le flou
    this.stopVideo();
  }


  private stopVideo() {
    this.videoUrl = '';
    setTimeout(() => {
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/watch?v=mvJePraln4k'
      );
    }, 0);
  }

  get hasNoCruxData(): boolean {
    if (this.noSitesCreated) {
      return false;
    }
    return this.dataSource?.data?.every(item => item.noCruxData) || false;
  }

  ngOnDestroy(): void {
    if (this.validationSubscription) {
      this.validationSubscription.unsubscribe();
    }
    this.ccService.close(true);
  }
}
