import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { FirstPartyDataServiceService } from 'src/app/services/first-party-data-service.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { WebsiteAnalyze } from 'src/app/shared/models/user.models';
import { debounce, orderBy } from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CountryCodes } from '../../../consts/countryCode';
import { MatMenuTrigger } from '@angular/material/menu';
import { RumService } from 'src/app/services/rum.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-first-party-internal-pages',
    templateUrl: './first-party-internal-pages.component.html',
    styleUrls: ['./first-party-internal-pages.component.scss'],
    standalone: false
})
export class FirstPartyInternalPagesComponent implements OnInit, AfterViewInit {
  @ViewChild('menuContacts') menuContacts;
  @Output() public deviceEvent = new EventEmitter<string>();
  sites: Array<WebsiteAnalyze>;
  selected: WebsiteAnalyze = null;
  displayedColumns: string[] = ['title', 'pageVisits', 'LCP', 'CLS', 'INP'];

  device: string = 'desktop';
  queryLevel: string = 'page';
  queryPeriod: string = 'daily';
  loading: boolean = true;
  userId = localStorage.getItem('userId');

  dataList = [];
  apiResponseMessage: string = '';

  pageSize = 10;
  pageSizeOptions = [5, 10, 15];
  pageIndex = 0;
  totalDataSize = 0;
  showFirstLastButtons = true;
  paginationInstance;
  projectId;

  allData = [];
  dataSource = new MatTableDataSource([]);
  rawDataBeforeFilter = [];
  deviceTypes = ConstantService.defaultDevices;
  dimensionGroups = ConstantService.defaultDimensionGroup;
  selectedDimension: string = 'all';
  selectedDimGroup: string = 'all';
  siteTitle = '';
  siteId = '';
  site: any;
  searchKeyword = '';
  sharedMode = false;
  monitoringLocations = [];
  selectedCountry = 'Global';
  showURLSbySegment = false;
  searchDebouncer = debounce(this.queryMetricData, 800);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedPage1: any;
  selectedPage2: any;
  filteredPages: any[];
  transformedPages: any[] = [];
  AllUrl: any;
  groupedPages: any;
  sitetitle: any;
  Url: string;

  constructor(
    private webAnalizeService: WebsiteAnalyzeService,
    private router: Router,
    private websiteAnalyseService: WebsiteAnalyzeService,
    private rumService: RumService,
    private snackBar: MatSnackBar
  ) {
    this.projectId = this.router.url.split('/')[3];
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    this.Url = this.router.url;
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.selectedDimension =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimension) || 'all';
    this.selectedDimGroup =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimGroup) || 'all';
    this.queryPeriod =
      localStorage.getItem(ConstantService.loadingUrl.fpdQueryPeriod) ||
      'daily';
    this.sharedMode =
      localStorage.getItem(ConstantService.localStorageKeys.sharedMode) ===
      'on';
    this.selectedCountry =
      localStorage.getItem(
        ConstantService.localStorageKeys.selectedRUMCountry
      ) || 'Global';
      this.AllUrl = await firstValueFrom(this.websiteAnalyseService.getAllWebsiteAnalyze(this.userId))
      this.AllUrl = this.AllUrl.filter( page => page.isFPDEnabled === true)
      this.transformPages()
    this.queryMetricData();
  }

  onCountrySelect(country) {
    this.selectedCountry = country;
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      country
    );
    this.updateTableData();
  }

  setDevice(selectedDevice: string) {
    this.device = selectedDevice;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);

    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';

    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimension,
      this.selectedDimension
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    this.updateTableData();
  }

  updateTableData() {
    this.dataSource.data = this.rumService.getComputedRUMInternalPagesList(
      this.allData.filter((e) =>
        this.showURLSbySegment ? e?.type == 'URLSegment' : !e?.type
      ),
      this.selectedDimGroup,
      this.selectedDimension,
      this.device,
      this.selectedCountry
    );

    this.dataSource.sortingDataAccessor = (item, headerId) => {
      switch (headerId) {
        case 'title':
          return item.website;
      }
      return 0;
    };
    this.dataSource.sort = this.sort;
  }

  setQueryPeriod(event: Event) {
    const target = event.target as HTMLInputElement;
    const selectPeriod = target.value;

    this.deviceEvent.emit(selectPeriod);
    this.queryPeriod = selectPeriod;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdQueryPeriod,
      this.queryPeriod
    );
    this.queryMetricData();
  }

  setDimension(dimension, dimensionGroup) {
    this.selectedDimension = dimension;
    this.selectedDimGroup = dimensionGroup;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      dimensionGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, dimension);

    this.updateTableData();
  }

  async selectSite(site: WebsiteAnalyze) {
    this.selected = site;
    this.queryMetricData();
  }

  search(event) {
    const keyword = event.target.value;
    this.searchKeyword = keyword;
    this.searchDebouncer();
  }

  goToOverview(metric) {
    if (['6hrs', '12hrs'].includes(this.queryPeriod)) {
      return this.router.navigate([`/home/rum/${metric?.siteId}/overview`], {
        queryParams: {
          queryPeriod: this.queryPeriod,
          queryLevel: this.queryLevel,
          siteId: metric?.siteId,
        },
      });
    }
    this.router.navigate(
      [`/home/rum/${metric?.siteId}/internalpages/details`],
      { queryParams: { url: encodeURIComponent(metric?.website) } }
    );
  }

  clearCurrentFilters() {
    this.selectedCountry = 'Global';
    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      this.selectedCountry
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, 'all');
    this.updateTableData();
  }

  async queryMetricData() {
    this.loading = true;

    try {
      let {
        data: result,
        totalPagesCount,
        locations,
      } = await this.webAnalizeService
        .getRumInternalPages(
          this.projectId,
          this.queryPeriod,
          this.pageIndex,
          this.pageSize,
          this.searchKeyword
        )
        .toPromise();
      this.monitoringLocations = locations;
      if (!result.length) {
        this.apiResponseMessage = 'No data for the selected options';
        this.loading = false;
        this.totalDataSize = 0;
        this.dataSource.data = [];
        return;
      }
      const { title } = result[0];
      this.siteTitle = title;
      this.apiResponseMessage = result?.length
        ? null
        : `No ${this.queryPeriod} data`;

      this.allData = result;
      this.totalDataSize = totalPagesCount;
      this.updateTableData();
      this.loading = false;
      return;
    } catch (err) {
      this.loading = false;
    }
  }

  paginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.queryMetricData();
  }

  onUrlsBySegmentChange(e) {
    this.showURLSbySegment = e.checked;
    this.updateTableData();
  }

  formatUrl(url: string) {
    const urlData = url.split('/');
    return urlData.slice(3).join('/');
  }

  onMatSortChange(event) {
    const { active, direction } = event;
    let data = this.dataSource.data;
    const isAsc = direction === 'asc';
    switch (active) {
      case 'title':
        data.sort((a, b) => compare(a.title, b.title, isAsc));
        break;
      case 'pageVisits':
        data.sort((a, b) => compare(a.total, b.total, isAsc));
        break;
      case 'LCP':
        data.sort((a, b) =>
          compare(
            Number(a?.lcps?.series?.[0]?.data?.[0]),
            Number(b?.lcps?.series?.[0]?.data?.[0]),
            isAsc
          )
        );
        break;
      case 'CLS':
        data.sort((a, b) =>
          compare(
            Number(a?.cls?.series?.[0]?.data?.[0]),
            Number(b?.cls?.series?.[0]?.data?.[0]),
            isAsc
          )
        );
        break;
      case 'INP':
        data.sort((a, b) =>
          compare(
            Number(a?.inps?.series?.[0]?.data?.[0]),
            Number(b?.inps?.series?.[0]?.data?.[0]),
            isAsc
          )
        );
        break;
    }
    this.dataSource.data = data;
  }

  overViewPage() {
    return '/home/rum/' + this.projectId + '/overview';
  }
  copyUrlToClipboard(url: any): void {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        this.snackBar.open('URL copied!', 'Close', {
          duration: 2000,
        });
      })
      .catch((err) => {
        this.snackBar.open('Error copying URL!', 'Close', {
          duration: 2000,
        });
      });
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined || code == 'Global')
      return false;
    return true;
  }
  getCountryCode(countryName: string): string | undefined {
    return CountryCodes[countryName];
  }
  closeMe(menuTrigger: MatMenuTrigger) {
    menuTrigger.closeMenu();
  }
  transformPages(): void {

    this.transformedPages = this.AllUrl.map(data => {
      return {
        title: data.title,
        urls: [
          { id: data.id, title: data.title, url: data.originDomain, type: 'originDomain' },
          ...(Array.isArray(data.internalUrlsFPD) ?
              data.internalUrlsFPD?.slice(0,10).map(internal => ({
                id: data.id,
                title: internal.url,
                url: internal.url,
                type: 'internalUrlFDP'
              })) : [])
        ]
      };
    });

    this.sitetitle = this.transformedPages.find(item => item.urls.some(url => url.id === this.projectId))?.title;
    this.transformedPages.sort(item => item.urls.some(url => url.id === this.projectId) ? -1 : 1);


  }
  onFirstSelectChange(): void {
    // Filtrer les URLs pour exclure la première URL sélectionnée
    this.filteredPages = this.transformedPages.map(group => {
      return {
        title: group.title,
        urls: group.urls.filter(page => page.title !== this.selectedPage1)
      };
    }).filter(group => group.urls.length > 0); // Exclure les groupes sans URLs
    this.selectedPage2 = '';
  }
  refreshbutton(): void {
    this.selectedPage1 = '';
    this.selectedPage2 = '';
  }
  comparePages() {

    if (this.selectedPage1 && this.selectedPage2) {
      let page1 = null;
      let page2 = null;
      let projectId1 = null;
      let projectId2 = null;

      for (const group of this.transformedPages) {
        for (const page of group.urls) {
          if (page.title === this.selectedPage1) {
            page1 = page;
            projectId1 = page.id;
          }
          if (page.title === this.selectedPage2) {
            page2 = page;
            projectId2 = page.id;
          }
        }
      }

      if (page1 && page2) {
        const dataToStore = {
          projectId1: projectId1,
          projectId2: projectId2,
          page1: {
            title: page1.title,
            Url: page1.url,
          },
          page2: {
            title: page2.title,
            Url: page2.url,
          },
          siteTitle: this.sitetitle,
        };

        localStorage.setItem('pagesData', JSON.stringify(dataToStore));

        const compareUrl = this.comparePagesUrl(this.selectedPage1, this.selectedPage2);
        this.router.navigateByUrl(compareUrl);
      } else {
        this.snackBar.open('Please select two pages to compare!', 'Close', {
          duration: 2000
        });
      }
    } else {
      this.snackBar.open('Please select two pages to compare!', 'Close', {
        duration: 2000
      });
    }
  }
  comparePagesUrl(page1: string, page2: string): string {
    let page1Encoded = encodeURIComponent(page1);
    let page2Encoded = encodeURIComponent(page2);
    const newUrl = this.router.url.replace("/internalpages", '');
    return newUrl.replace(/%2B/g, '') + '/compare/' + page1Encoded + '/' + page2Encoded;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
