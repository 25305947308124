import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuyPlanComponent } from '../buy-plan/buy-plan.component';
import { ClsComponent } from './cls/cls.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FcpComponent } from './fcp/fcp.component';
import { InternalPageDetailComponent } from './internal-page-detail/internal-page-detail.component';
import { LcpComponent } from './lcp/lcp.component';
import { ListWebsitesComponent } from './list-websites/list-websites.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { NewPageComponent } from './new-page/new-page.component';
import { OverviewComponent } from './overview/overview.component';
import { PageCompareComponent } from './page-compare/page-compare.component';
import { PageInterneComponent } from './page-interne/page-interne.component';
import { InpComponent } from './inp/inp.component';
import { TtfpComponent } from './ttfp/ttfp.component';
import { MyAccountPageComponent } from './my-account-page/my-account-page.component';
import { ListWebsitesFirstPartyDataComponent } from './list-websites-first-party-data/list-websites-first-party-data.component';
import { FirstPartyDataHomeComponent } from './first-party-data/first-party-data-home/first-party-data-home.component';
import { FirstPartyDataOverviewComponent } from './first-party-data/first-party-data-overview/first-party-data-overview.component';
import { FirstPartyDataLcpComponent } from './first-party-data/first-party-data-lcp/first-party-data-lcp.component';
import { FirstPartyDataClsComponent } from './first-party-data/first-party-data-cls/first-party-data-cls.component';
import { FirstPartyInternalPagesComponent } from './first-party-internal-pages/first-party-internal-pages.component';
import { AddInternalPagesOnlyComponent } from 'src/app/add-internal-pages-only/add-internal-pages-only.component';
import { AlertDiffComponent } from 'src/app/alert-diff/alert-diff.component';
import { FirstPartyDataFcpComponent } from './first-party-data/first-party-data-fcp/first-party-data-fcp.component';
import { FirstPartyDataInpComponent } from './first-party-data/first-party-data-inp/first-party-data-inp.component';
import { FirstPartyDataTtfbComponent } from './first-party-data/first-party-data-ttfb/first-party-data-ttfb.component';
import { FirstPartyInternalDetailComponent } from './first-party-internal-detail/first-party-internal-detail.component';
import { NavTypeComponent } from './nav-type/nav-type.component';
import { SuccessComponent } from './success/success.component';
import { CompareInternalPagesComponent } from './compare-internal-pages/compare-internal-pages.component';
import { FirstPartyDataCompareComponent } from './first-party-data/first-party-data-compare/first-party-data-compare.component';
import { RequestOfferComponent } from './request-offer/request-offer.component';
import { CruxWorkflowComponent } from './crux-workflow/crux-workflow.component';
import { RumWorkflowComponent } from './rum-workflow/rum-workflow.component';

import { InsightsViewerComponent } from './insights-viewer/insights-viewer.component';

const routes: Routes = [
  {
    path: 'page/:id',
    component: DashboardComponent,
    children: [
      {
        path: 'internalpages',
        component: PageInterneComponent,
      },
      {
        path: 'overview',
        component: OverviewComponent,
      },
      {
        path: 'overview+',
        component: OverviewComponent,
      },
      {
        path: 'lcp',
        component: LcpComponent,
      },
      {
        path: 'lcp+',
        component: LcpComponent,
      },
      {
        path: 'cls',
        component: ClsComponent,
      },
      {
        path: 'cls+',
        component: ClsComponent,
      },
      {
        path: 'internalpages+',
        component: PageInterneComponent,
      },
      {
        path: 'competitor',
        component: PageCompareComponent,
      },
      {
        path: 'fcp',
        component: FcpComponent,
      },
      {
        path: 'ttfb',
        component: TtfpComponent,
      },
      {
        path: 'competitor+',
        component: PageCompareComponent,
      },
      {
        path: 'fcp+',
        component: FcpComponent,
      },
      {
        path: 'ttfb+',
        component: TtfpComponent,
      },
      {
        path: 'inp',
        component: InpComponent,
      },
      {
        path: 'inp+',
        component: InpComponent,
      },
      {
        path: 'navtype',
        component: NavTypeComponent,
      },
      {
        path: 'offer',
        component: RequestOfferComponent,
      },
      {
        path: 'offer+',
        component: RequestOfferComponent,
      },
      {
        path: 'crux-workflow+',
        component: CruxWorkflowComponent,
      },
      {
        path: 'crux-workflow',
        component: CruxWorkflowComponent,
      },
      {
        path: 'insights',
        component: InsightsViewerComponent,
      },
    ],
  },
  { path: 'success', component: SuccessComponent },
  { path: 'myAccount', component: MyAccountPageComponent },
  { path: 'pageLoad/new', component: NewPageComponent },
  {
    path: 'rum',
    component: ListWebsitesFirstPartyDataComponent,
  },
  {
    path: 'rum/:id',
    component: FirstPartyDataHomeComponent,
    children: [
      {
        path: 'lcp',
        component: FirstPartyDataLcpComponent,
      },
      {
        path: 'cls',
        component: FirstPartyDataClsComponent,
      },
      { path: 'fcp', component: FirstPartyDataFcpComponent },
      { path: 'inp', component: FirstPartyDataInpComponent },
      { path: 'ttfb', component: FirstPartyDataTtfbComponent },
      { path: 'internalpages', component: FirstPartyInternalPagesComponent },
      { path: 'compare/:page1/:page2', component: FirstPartyDataCompareComponent },
      {
        path: 'internalpages/details',
        component: FirstPartyInternalDetailComponent,
      },
      {
        path: 'overview',
        component: FirstPartyDataOverviewComponent,
      },
      {
        path: 'rum-workflow',
        component: RumWorkflowComponent,
      },
    ],
  },
  { path: 'page/:id/pageLoad/new', component: NewPageComponent },
  { path: 'pageLoad/new/compare', component: PageCompareComponent },
  { path: 'pageLoad/loading', component: LoadingPageComponent },
  { path: 'pageLoad/loading-po', component: LoadingPageComponent },
  {
    path: 'page/:id/internalpages/details/:url',
    component: InternalPageDetailComponent,
  },
  { path: 'page/:id/internalpages/compare/:page1/:page2',
    component: CompareInternalPagesComponent
  },
  { path: 'new-internal-pages-po', component: AddInternalPagesOnlyComponent },
  {
    path: 'internal-detail-po/:id/:url',
    component: InternalPageDetailComponent,
  },
  { path: 'diff-alert/:id', component: AlertDiffComponent },
  {
    path: '',
    component: ListWebsitesComponent,
  },
  { path: 'pagecompare', component: PageCompareComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
