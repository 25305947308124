import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-first-party-data-home',
    templateUrl: './first-party-data-home.component.html',
    styleUrls: ['./first-party-data-home.component.scss'],
    standalone: false
})
export class FirstPartyDataHomeComponent implements OnInit {
  constructor(private route: ActivatedRoute,) {}

  ngOnInit(): void {
  }
}
