<h1 mat-dialog-title>Create a new User Journey</h1>

<div mat-dialog-content class="my-3">
  <div class="my-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
    <mat-form-field appearance="outline" class="w-50">
      <mat-label>Select Domain</mat-label>
      <mat-select [(ngModel)]="selectedDomain" (selectionChange)="onDomainChange()">
        <mat-option *ngFor="let domain of allDomains" [value]="domain">
          {{ domain.originDomain }} 
          {{ domain.country ? 
              (domain.country === 'NotAllowed' ? '(Global)' : '(' + domain.country + ')') 
              : '' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="me-2">
      <mat-label>Workflow name</mat-label>
      <input matInput [(ngModel)]="workflowName" placeholder="e.g. My funnel" />
    </mat-form-field>
  </div>
  <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
    <div class="radio-inputs mb-3">
      <label class="radio">
        <input
          type="radio"
          [name]="'radio-' + instanceId"
          value="CRUX"
          [(ngModel)]="mode"
          (change)="setMode($event)">
        <span class="name">CRUX</span>
      </label>
      <label class="radio">
        <input
          type="radio"
          [name]="'radio-' + instanceId"
          value="RUM"
          [(ngModel)]="mode"
          (change)="setMode($event)">
        <span class="name">RUM</span>
      </label>
    </div>
    <div class="group mx-2 mb-3">
      <input
        class="input"
        type="search"
        placeholder="Search"
        (keyup)="search($event)" />
    </div>
  </div>

  <div class="text-muted mt-2">
    Selected pages: {{ selectedPages.length }} / 5
  </div>

  <div *ngIf="selectedPages.length > 0" class="mt-3">
    <h5>Order your selected URLs:</h5>
    <div
      cdkDropList
      [cdkDropListLockAxis]="'x'"
      (cdkDropListDropped)="drop($event)"
      class="chip-container d-flex justify-content-center align-items-center">
      <mat-chip-list class="w-100">
        <mat-chip *ngFor="let page of selectedPages; let i = index"
                  cdkDrag
                  (cdkDragEntered)="onDragEntered(i)"
                  (cdkDragExited)="onDragExited(i)"
                  [ngClass]="{'drag-over': dragOverIndex === i}"
                  matTooltip="{{ page.url }}"
                  matTooltipPosition="above">
          {{ i + 1 }}. {{ extractPath(page.url) | slice:0:20 }}{{ extractPath(page.url).length > 20 ? '...' : '' }}
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>

  <div *ngIf="displayedPages.length > 0" class="page-list-container mt-3">
    <h5>Available internal URLs (up to 10 displayed):</h5>
    <div *ngFor="let page of displayedPages" class="page-item">
      <div class="text-muted">Domain: {{ page.domain }}</div>
      <mat-checkbox [checked]="isSelected(page)" (change)="toggleSelection(page)"
                    [disabled]="selectedPages.length >= 5 && !isSelected(page)">
        {{ extractPath(page.url) }}
      </mat-checkbox>
    </div>
  </div>

  <div *ngIf="mode && displayedPages.length === 0" class="text-muted mt-3">
    No pages found for your search...
  </div>

</div>

<div mat-dialog-actions class="justify-content-end">
  <button class="btn btn-outline-danger me-3" (click)="cancel()">Cancel</button>
  <button
    class="btn btn-primary"
    [disabled]="!selectedDomain || !mode || !workflowName || (selectedPages.length === 0)"
    (click)="createWorkflow()">
    Create
  </button>
</div>
