import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { WebsiteAnalyzeService } from '../services/common/website-analyze/website-analyze.service';


@Component({
    selector: 'app-alert-diff',
    templateUrl: './alert-diff.component.html',
    styleUrls: ['./alert-diff.component.scss'],
    standalone: false
})
export class AlertDiffComponent implements OnInit {
  dataSources: any[];
  displayedColumns: string[] = ['PageTitle', 'URL', 'OnMobile', 'OnDesktop'];
  loading = false;
  alertPeriod;
  alertThreshold;

  constructor(
    private activatedRoute: ActivatedRoute,
    private webAnalyzeService: WebsiteAnalyzeService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async (params) => {
      const alertId = params.get('id');
      if (alertId) {
        await this.loadAlertDetails(alertId);
      }
    });
  }

  async loadAlertDetails(alertId: string) {
    try {
      this.loading = true;
      const alertDetail: any = await this.webAnalyzeService
        .getAlertDetail(alertId)
        .toPromise();
      this.alertPeriod = alertDetail.alertPeriod;
      this.alertThreshold = alertDetail.diffThreshold;
      const processedAlert = this.processAlertDetail(alertDetail.data);
      console.log(processedAlert);
      const dataSources = Object.keys(processedAlert).map((u) => {
        const { siteTitle, siteUrl, projectId } = processedAlert[u][0];
        return {
          siteTitle,
          siteUrl,
          projectId,
          dataSource: new MatTableDataSource(processedAlert[u]),
        };
      });
      this.dataSources = dataSources;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.error('Failed to load alert details', error);
    }
  }
  processAlertDetail(detailsData) {
    const domains: any = {};
    for (const d of detailsData) {
      const key = `${d.siteTitle}-${d.siteUrl}`;
      if (domains[key]) {
        domains[key] = [...domains[key], d];
      } else {
        domains[key] = [d];
      }
    }
    return domains;
  }

  navigateToPageDetails(projectId, title) {
    this.router.navigate([
      `/home/page/${projectId}/internalpages/details/${title}`,
    ]);
  }
}
