<div *ngIf="hasOffer; else noData" class="table-container p-5">
  <!-- Filtre global -->
  <mat-form-field appearance="outline">
    <mat-label>Filter offers</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex: premium">
  </mat-form-field>

  <!-- Tableau Angular Material -->
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <!-- IDoffer Column -->
    <ng-container matColumnDef="IDoffer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Offer ID</th>
      <td mat-cell *matCellDef="let element">{{ element.IDoffer }}</td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
      <td mat-cell *matCellDef="let element">{{ element.price }}</td>
    </ng-container>


    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="Website">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Website</th>
          <td mat-cell *matCellDef="let element">{{ element.OriginDomain }}</td>
        </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        <span
          [ngClass]="{
            'badge-rejected': element.status === 'rejected',
            'badge-paid': element.status === 'paid',
            'badge-pending': element.status === 'pending'
          }">
          {{ element.status }}
        </span>
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
      <td mat-cell *matCellDef="let element">
        <span matTooltip="Action not available if offer is not pending"
              [matTooltipDisabled]="element.status === 'pending'">
          <button class="btn btn-sm btn-outline-danger me-2"
                  (click)="handleAction(element, 'reject')"
                  [disabled]="element.status !== 'pending'">
            Reject
          </button>
        </span>
        <span matTooltip="Action not available if offer is not pending"
              [matTooltipDisabled]="element.status === 'pending'">
          <button class="btn btn-sm btn-outline-primary"
                  (click)="handleAction(element, 'checkout')"
                  [disabled]="element.status !== 'pending'">
            Accept offer
          </button>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<ng-template #noData>
  <div class="d-flex justify-content-center align-items-center mt-5">
    <app-oops-no-data [message]="message"></app-oops-no-data>
  </div>
</ng-template>
