import {Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { legend } from '../../../shared/models/legend';
import {
  ChartOptionsInterface,
  metric,
  PageSpeedDataOverview,
} from 'src/app/shared/models/pagespeed.model';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/common/user/user.service';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { MatDialog } from '@angular/material/dialog';
import { ChartOptionsService } from 'src/app/services/common/chart/chart-options.service';
import { UxrDialogComponent } from '../uxr-dialog/uxr-dialog.component';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
    standalone: false
})
export class OverviewComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  titleCls: string;


  public chartOptionsHistogram : any;
  public chartOptions: any = {};
  public chartOptionsInteractive: Partial<any>;
  public chartOptionsTotalBlockingTime: Partial<any>;
  public chartOptionsSpeedIndex: Partial<any>;
  status: string;
  color: string;
  public chartOptions2: Partial<any>;
  public isMobileLayout = false;
  deviceOptions: Array<String> = ['Mobile', 'Desktop'];
  device: string;
  datas: PageSpeedDataOverview[] = [];
  sortedData: PageSpeedDataOverview[];

  internalUrls = [];
  userId: any;
  test: any;
  menu: string;
  loading = true;
  titleURL: string;
  displaydata: boolean;
  titleFcp: string;
  titleLcp: string;
  titleTtfp: string;
  titleInp: string;
  resp: any;
  i = 0;
  j: number;
  datas1: any = [];
  datattfp: any;
  data: PageSpeedDataOverview;
  currentURL: string;
  defaultValue: any;
  predefinedCountries: any;
  showCountry: boolean;
  auth_token: any;
  diff: metric;
  compareTab : metric;

  id1: string;
  all: any;
  Alldevice = false;
  dataAll: any;
  dataMobile: any;
  dataDesktop: any;
  legend = legend;
  chartOptionsMobile: ChartOptionsInterface;
  chartOptionsDesktop: ChartOptionsInterface;
  metrics = ["lcp", "inp", "cls", "ttfb" ,"fcp"];
  metricsGrouped = {
    speed: ['ttfb', 'fcp', 'lcp'],
    layoutStability: ['cls'],
    interactivity: ['inp']
  };
  result : any;
  nameComplet: {};
  passOrNot: boolean;
  improvement: string = "";
  title: any = {};
  uxrOvertimeCHartOptions = {}
  wvOvertimeCHartOptions = {}
  WebSiteTitle: string;
  projectID: any;
  Url: string;



  constructor(
    private speetalsDatabase: SpeetalsDatabaseService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    private router: Router,
    private userService: UserService,
    public senderVarService :SenderVarService,
    private helpersService : HelpersService,
    private dialog: MatDialog,
    private chartOptionsService : ChartOptionsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);

    if (!this.device) {
      this.device = 'mobile';
      localStorage.setItem(ConstantService.loadingUrl.device, this.device);
    }

    this.device = this.device === "tablet" ? 'mobile' : this.device;
    if (this.router.url.slice(0, 5) === '/demo') {
      localStorage.setItem(
        ConstantService.loadingUrl.Url,
        'https://www.amazon.com'
      );
      //localStorage.setItem(ConstantService.loadingUrl.device, 'mobile');
      this.auth_token = await this.userService.getDemoToken().toPromise();
      localStorage.setItem(
        ConstantService.localStorageKeys.access_Token,
        this.auth_token
      );
    }
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);


    this.all = await this.speetalsDatabase
      .getDataOverviewAll(
        this.senderVarService.id,
        this.userId
      )
      .toPromise();

      this.result = await this.speetalsDatabase
      .getDataOverview(
        this.senderVarService.id,
        this.userId,
        this.device
      )
      .toPromise();
      this.Url = this.router.url;
      this.WebSiteTitle = this.result?.title;
      this.projectID = this.result?.projectId;
      this.currentURL = this.result?.originDomain

    const uxrData = [
      { name: 'mobile', data: this.result?.uxrProgress?.mobile },
      { name: 'desktop', data: this.result?.uxrProgress?.desktop }
    ]
    const wvData = [
      { name: 'mobile', data: this.result?.wvProgress?.mobile },
      { name: 'desktop', data: this.result?.wvProgress?.desktop }
    ]
    this.uxrOvertimeCHartOptions = this.pageSpeedInsigthService.chartOptionsUXROverTime(uxrData, this.result?.uxrProgress?.yyyymm)
    this.wvOvertimeCHartOptions = this.pageSpeedInsigthService.chartOptionsWVOverTime(wvData, this.result?.wvProgress?.yyyymm)
    if (this.Alldevice){
      for (let metric of this.metrics){
        this.chartOptions[metric] = this.chartOptionsService.chartOptionsOverviewAll(this.all[metric], this.all.device, this.legend[metric]);
      }
    }
    else {
      if (this.result === undefined || this.result === null) {
        this.displaydata = false;
      }
      else {

        this.chartOptionsHistogram = {}
        for (let property of this.metrics) {
          if (property === "ttfp") property = "ttfb"

          this.title[property] = this.pageSpeedInsigthService.getTitle2(this.result[property].title)
          this.chartOptions[property] = this.chartOptionsService.chartOptionsOverview(
           this.result[property]
          );


          this.chartOptionsHistogram[property] = this.chartOptionsService.chartOptionsCompare(
            this.result[property], property
          );
        }

        this.status = this.result.status;
        this.passOrNot = (this.result.status === "passes") ? true : false;
        if( !this.passOrNot && this.result.arrayNeedImprovements !== undefined  && this.result.arrayNeedImprovements.length > 0){
          this.result.arrayNeedImprovements.map(elt => {
            let ptVirgule = (elt === this.result.arrayNeedImprovements[this.result.arrayNeedImprovements.length - 1]) ? ' ' : ',';
            this.improvement +=  elt.metric.toUpperCase() + " (" + elt.value + "%)" + ptVirgule + ' ' ;
          })
        }

        this.color = this.result.color;

        this.displaydata = true;
      }
    }
    Object.keys(this.chartOptions)
      .forEach(key => {
        const series = this.chartOptions[key].series
        let hasNoData = true

        series.forEach(i => {
          if (i?.data?.[0] !== 0){
            hasNoData = false
          }
        })
        this.chartOptions[key]['hasNoData'] = hasNoData
        this.chartOptions[key]['chart']['events'] = {
          mounted: (chart) => {
            chart.windowResizeHandler();
          }
        };
      })
    this.loading = false;

  }
  setDeviceUrl(event: Event) {
    const target = event.target as HTMLInputElement;
    const deviceValue = target.value;

    if (deviceValue === "all") {
      this.Alldevice = true;
      this.device = null;
    } else {
      this.device = deviceValue;
      this.Alldevice = false;
      localStorage.setItem(ConstantService.loadingUrl.device, deviceValue);
      this.datas.length = 0;
      this.improvement = " ";
    }
    this.ngOnInit();
  }

  getBadgeColor(x: number): string {
    return x < 0 ? 'warn' : 'accent';
  }



  navigateTo(typeMetric : string) {
    this.router.navigate(['/home/page/' + this.senderVarService.id + '/' + typeMetric.toLowerCase()]);
  }

  descriptionDiff(name: string): string {
    return 'Monthly change of Good ' + name.toUpperCase() + ' %';
  }

  printUxr(uxr : number){
    return (uxr !== null)  && (uxr !== undefined);
  }
  getColorUxr(uxr : number){
    if (uxr >= 90) return 'green';
    if (uxr > 50 && uxr < 90) return 'orange';
    return 'red';
  }

  getMetricName(metric: string){
    return this.helpersService.getMetricName(metric) + ' ( ' + (metric.toUpperCase()) + ' )';
  }

  openDialog(metric : any){

    const dialogRef = this.dialog.open(UxrDialogComponent,{
      data:{
        chartOptions: this.chartOptionsHistogram[metric],
        free : this.result.isShared ? false: this.result.free ,

        buttonText: {
          cancel: 'Close',
          upgrade : 'Upgrade'
        }
      },
      maxWidth: '200vw',
      maxHeight: '200vh',
      height: '40%',
      width: '80%',
    });

  }

  showScoreProgressChart(isUXR?: boolean) {
    const dialogRef = this.dialog.open(UxrDialogComponent, {
      data: {
        chartOptions1: this.uxrOvertimeCHartOptions,
        chartOptions2: this.wvOvertimeCHartOptions,
        free: this.result.isShared ? false : this.result.free,
        isUXR: isUXR,
        buttonText: {
          cancel: 'Close',
          upgrade: 'Upgrade'
        },
        isUXRovertime: true,
        isWVovertime: true
      },
      maxWidth: '200vw',
      maxHeight: '100vh',
      height: '70%',
      width: '80%',
    });
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined) return false;
    return true;
  }
  getTooltipText(group: string): string {
    switch (group) {
      case 'speed':
        return 'Metrics related to the speed loading of your website';
      case 'layoutStability':
        return 'Metrics related to how stable your website\'s layout is during loading';
      case 'interactivity':
        return 'Metrics related to how quickly your website responds to user interactions';
      default:
        return 'Information related to this section.';
    }
  }
  getDiffStatus(diff: number | null): string {
    if (diff === null || diff === 0) return 'stabilizing';
    return diff > 0 ? 'improving' : 'regressing';
  }


}

