<app-layout>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="loader1">
          <svg viewBox="0 0 80 80">
            <circle id="test" cx="40" cy="40" r="32"></circle>
          </svg>
        </div>
      </div>
      <div class="col">
        <div class="loader2 triangle">
          <svg viewBox="0 0 86 80">
            <polygon points="43 8 79 72 7 72"></polygon>
          </svg>
        </div>
      </div>
      <div class="col">
        <div class="loader3">
          <svg viewBox="0 0 80 80">
            <rect x="8" y="8" width="64" height="64"></rect>
          </svg>
        </div>
      </div>
      <!-- dribbble -->
    </div>
  </div>
  <!-- <div class="text"> -->
  <div class="container">
    <div style="margin-bottom: 5%">
      <div style="text-align: center;">
        <h2>
          We are analyzing your {{message}}...It
          could take up to 2 minutes<mat-icon>hourglass_full</mat-icon>
        </h2>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-5">
        <button
          mat-raised-button
          color="primary"
          [routerLink]="['/home']"
          routerLinkActive="router-link-active"
          >
          Go back to Dashboard
          <mat-icon>home</mat-icon>
        </button>
      </div>
      <div class="col-5">
        @if (showAddAnotherPageButton && !isPageOnly) {
          <button
            mat-raised-button
            color="primary"
            [routerLink]="['/home/pageLoad/new']"
            routerLinkActive="router-link-active"
            >
            Add another website<mat-icon>add</mat-icon>
          </button>
        }
        @if (showAddAnotherPageButton && isPageOnly) {
          <button
            mat-raised-button
            color="primary"
            [routerLink]="['/home/new-internal-pages-po']"
            routerLinkActive="router-link-active"
            >
            Addinternal another pages <mat-icon>add</mat-icon>
          </button>
        }
      </div>
    </div>
  </div>
  <!-- <div class="row container"> -->
<!-- </div> -->
</app-layout>
