<!-- <p>{{element.title}} - {{element.projectId}}</p>
<p>{{websiteAnalyse.title}} - {{websiteAnalyse.id}}</p> -->
<div class="w-100 h-100 d-flex flex-column justify-content-between ">
  @if (!CanAddMail) {
    <div class="row flex-grow-1">
      <div class="graph">
        <div class="m-2 d-flex flex-column justify-content-start align-items-between h-100">
          <div class="col-12 d-flex justify-content-between align-items-start">
            <h2>Share your website data with your colleagues</h2>
          </div>
          <app-Free-msg class="d-flex justify-content-center" [Message]="messageFromParent"></app-Free-msg>
        </div>
      </div>
    </div>
  }
  @if (CanAddMail) {
    <div class="row flex-grow-1 m-3">
      <div class="">
        <div class="m-2 d-flex flex-column justify-content-between align-items-between h-100">
          <div class="col-12 d-flex justify-content-between align-items-start">
            <div>
              <h2 class="m-0">Share your website data with your colleagues</h2>
            </div>
            @if (!maxSharing()) {
              <button class="Btn" (click)="addMails()">
                <div class="sign">
                  <mat-icon class="white-icon ">add</mat-icon>
                </div>
                <div class="text">add email</div>
              </button>
            }
            @if (maxSharing()) {
              <div>
                <h2>
                  max sharing reached
                </h2>
              </div>
            }
          </div>
          <div class="col-12  flex-grow-1">
            <form class="h-100 d-flex flex-column justify-content-between" [formGroup]="mailForm" (ngSubmit)="onSubmit()">
              <div class="flex-grow-1" formArrayName="mails">
                @for (mail of mailsControls; track mail; let i = $index) {
                  <div class="d-flex flex-column justify-content-start col-10" [formGroupName]="i">
                    <div class="input-group my-2 " >
                      <input  type="email" formControlName="mail" placeholder="example@example.com" class="form-control">
                      <button type="button" (click)="removeMail(i)" class="btn btn-danger">remove</button>
                    </div>
                    <div >
                      @if (mail.controls.mail.hasError('pattern') ) {
                        <mat-error>
                          Please enter a valid mail
                        </mat-error>
                      }
                      @if (mail.controls.mail.hasError('required') ) {
                        <mat-error>
                          Email is <strong>required</strong>
                        </mat-error>
                      }
                      @if (mail.controls.mail.hasError('unique')) {
                        <mat-error>
                          Please don't provide <strong>duplicate </strong> mail
                        </mat-error>
                      }
                      @if (mail.controls.mail.errors?.mailExistErrors) {
                        <mat-error>
                          Mail already <strong>exists</strong> in BDD
                        </mat-error>
                      }
                    </div>
                  </div>
                }
              </div>
              @if (!maxSharing()) {
                <h2 class="mx-4" style="margin-top: 15px;">
                  {{maxshare}} out of {{userSeats-1}} places left
                </h2>
              }
              <div class=" my-3 col-12 d-flex justify-content-between align-items-center">
                <div class="notifications-container">
                  <div class="info">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg class="info-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                        </svg>
                      </div>
                      <div class="info-prompt-wrap">
                        <p class="m-0">
                          Added persons will be notified by email
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-content-center align-content-center">
                  <button mat-dialog-close class="buttn p-2 " style="margin:10px;">
                    <div class="svg-wrapper-1">
                      <div class="svg-wrapper">
                        <mat-icon>cancel</mat-icon>
                      </div>
                    </div>
                    <span class="m-0">{{cancelButtonText}}</span>
                  </button>
                <button  [disabled]="!validSubmit(cptMails)" type="submit" class="buttn p-2" style="margin:10px;" >
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span class="m-0">Share</span>
                </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      @if (!SharingIsEmpty()) {
        <div class="graph my-2 col-12">
          <h2 class="m-2">Shared With</h2>
          <div class=" d-flex flex-column justify-content-start m-2 col-10">
            <!-- <div *ngFor="let mail of websiteAnalyse.sharedWith">
            <span> {{ mail }}</span>
            <mat-icon color="warn" (click)="openDialog(mail)">delete</mat-icon>
          </div> -->
          @for (mail of websiteAnalyse.sharedWith; track mail) {
            <div class="input-group input-group my-2">
              <input type="text" class="form-control" placeholder="{{ mail }}" aria-label="Shared with" disabled>
              <button (click)="openDialogToRemove(mail)" class="btn btn-danger" type="button">remove</button>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
</div>
