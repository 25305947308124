import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-first-party-data-cls',
    templateUrl: './first-party-data-cls.component.html',
    styleUrls: ['./first-party-data-cls.component.scss'],
    standalone: false
})
export class FirstPartyDataClsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
