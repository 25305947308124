import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-what-is-uxr',
    templateUrl: './what-is-uxr.component.html',
    styleUrls: ['./what-is-uxr.component.scss'],
    standalone: false
})
export class WhatIsUxrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
