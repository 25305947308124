
<app-help-widget
  [currentURL]="currentURL"
  [userId]="userId"
  [projectID]="projectID"
  [Url] = "Url"
  >
</app-help-widget>
<nav class="mx-4 mt-3" aria-label="breadcrumb">
  <ol style="height: 50px; width: 52px;" class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center">

    @defer{
      @if (estAlloue(result?.country)) {
        <span class="mx-1">
          <mat-icon [svgIcon]="(result?.country | lowercase)"></mat-icon>
        </span>
       }@else{
        <mat-icon  class="mx-1">language</mat-icon>
       }
    }
    <li>
      <span class="fw-bold">CRUX > </span>
    </li>
    <li class="breadcrumb-item">
      <a class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center " [href]="'/home/page/' + this.senderVarService.id + '/overview'">
        <mat-icon class="mx-2 ">desktop_windows</mat-icon>
       <span>{{WebSiteTitle}} Overview</span>
      </a>
    </li>
  </ol>
</nav>
<div class="m-4">
  @if (loading) {
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: -1000px">
      <mat-spinner class="spinner" diameter="150"></mat-spinner>
    </div>
  }
  @if (!loading && displaydata) {
        <div class="graph text-center p-3 d-flex flex-column justify-content-center align-items-center">
          <h1>Domain-level data for the previous month period</h1>
          <p>
            Over the previous month, field data shows that this
            domain <FONT [color]="color"> {{ status }} @if (passOrNot) {🟩} @else {🟥} </FONT> the Core Web Vitals
            assessment.
          </p>
          <div *ngIf="!passOrNot && !Alldevice" class="col-10 m-1">
            <div class="notifications-container">
              <div class="info">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="info-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true">
                      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div class="info-prompt-wrap">
                    <p>
                      Each Web Vitals metric should at least reach 75% of Good.
                      For detailed analysis and personalized recommendations, check out our 
                      <a [routerLink]="['/home/page/' + this.senderVarService.id + '/insights']" class="text-primary">
                        Performance Analysis <mat-icon class="align-middle">psychology</mat-icon>
                      </a>
                      section.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center align-items-center">
            <div class="col-12 d-flex justify-content-center align-items-center">
              <button type="button" class="btn btn-sm btn-outline-primary mx-3" style="margin-left: 30px;" (click)="showScoreProgressChart(true)">Score and status progress</button>
              @if (printUxr(result?.uxr) && !Alldevice) {
              <div class="d-flex flex-column justify-content-center align-items-center mx-3">
                  <circle-progress title="UXR" subtitle="{{ result?.uxr }}%" percent="{{ result?.uxr }}">
                  </circle-progress>
                  <div class="diff-indicator d-flex justify-content-center align-items-center mt-2">
                    <ng-container [ngSwitch]="getDiffStatus(result?.uxrDiff)">
                      <!-- "improving" -->
                      <span
                       *ngSwitchCase="'improving'" class="text-success">
                        Improving (+{{ result?.uxrDiff }}%)
                        <mat-icon
                        class="interactive-icon"
                          matTooltip="The User Experience Rating (UXR) is improving compared to the previous evaluation."
                          matTooltipPosition="above"
                           matTooltipTouchGestures="on"
                        >
                          arrow_upward
                        </mat-icon>
                      </span>

                      <!-- "regressing" -->
                      <span *ngSwitchCase="'regressing'" class="text-danger">
                        Regressing ({{ result?.uxrDiff }}%)
                        <mat-icon
                        class="interactive-icon"
                          matTooltip="The User Experience Rating (UXR) is worsening compared to the previous evaluation."
                          matTooltipPosition="above"
                          matTooltipTouchGestures="on"
                        >
                          arrow_downward
                        </mat-icon>
                      </span>

                      <!-- "stabilizing" -->
                      <span *ngSwitchCase="'stabilizing'" class="text-muted">
                        Stabilizing ({{ result?.uxrDiff }}%)
                        <mat-icon
                        class="interactive-icon"
                          matTooltip="The User Experience Rating (UXR) is stable with no significant change."
                          matTooltipPosition="above"
                          matTooltipTouchGestures="on"
                        >
                          arrow_forward
                        </mat-icon>
                      </span>
                    </ng-container>
                  </div>

                </div>
              }
              <app-what-is-uxr class="m-0"></app-what-is-uxr>
            </div>
          </div>
      </div>

      <div class=" d-flex justify-content-start col-12 align-items-center">
        <!-- <app-device-button (deviceEvent)='setDevice($event)'></app-device-button> -->
        <div class="mx-2 my-3 radio-inputs">
          <label class="radio">
            <input type="radio" name="radio" value="mobile"  [(ngModel)]="device" (change)="setDeviceUrl($event)">
            <span class="name">Mobile</span>
          </label>
          <label class="radio">
            <input type="radio" name="radio" value="desktop" [(ngModel)]="device" (change)="setDeviceUrl($event)">
            <span class="name">Desktop</span>
          </label>
          <label class="radio">
            <input type="radio" name="radio" value="all" [(ngModel)]="device"  (change)="setDeviceUrl($event)">
            <span class="name">mobile VS Desktop</span>
          </label>
        </div>
      </div>
      <div class="d-flex justify-content-center  align-items-center w-100 ">
        <div class="w-75">
            <!-- Section for Alldevice === false -->
            @if (!Alldevice) {
              <div class="m-2">
                <div class="row">
                  <!-- Loop through each group of metrics -->
                  @for (group of ['speed', 'layoutStability', 'interactivity']; track $index) {
                    <!-- Display the group title -->
                    <div class="d-flex align-items-center mt-4 py-1 px-4 border-bottom mb-3">
                      <h1 class="mb-0  text-dark">
                        {{ $index +1 }}.{{ (group === 'layoutStability' ? 'Layout Stability' : group) | titlecase }}
                      </h1>
                      <span class="text-muted small ms-3 ps-3 border-start">
                        ({{ getTooltipText(group) }})
                      </span>
                    </div>

                    <!-- Loop through each metric within the group -->
                    @for (metric of metricsGrouped[group]; track $index) {
                      <div class="col-sm-12 col-md-6 col-lg-4 ">
                        <div class="graph my-2 p-2">
                          <div class="title">
                            <strong [matTooltip]="result[metric]?.description">
                              {{ result[metric]?.name }}
                              <a *ngIf="result[metric]?.resourceURL" href="{{ result[metric]?.resourceURL }}" target="_blank">
                                <mat-icon>open_in_new</mat-icon>
                              </a>
                            </strong>


                            <div class="diff-indicator d-flex justify-content-center align-items-center mt-2">
                              <ng-container [ngSwitch]="getDiffStatus(result[metric]?.diff)">

                                <!-- "improving" -->
                                <span *ngSwitchCase="'improving'" class="text-success">
                                  Improving (+{{ result[metric]?.diff }}%)
                                  <mat-icon
                                  class="interactive-icon"
                                    matTooltip="The metric has improved compared to the previous measurement."
                                    matTooltipPosition="above"
                                    matTooltipTouchGestures="on"
                                  >
                                    arrow_upward
                                  </mat-icon>
                                </span>

                                <!-- "regressing" -->
                                <span *ngSwitchCase="'regressing'" class="text-danger">
                                  Regressing ({{ result[metric]?.diff }}%)
                                  <mat-icon
                                  class="interactive-icon"
                                    matTooltip="The metric has worsened compared to the previous measurement."
                                    matTooltipPosition="above"
                                    matTooltipTouchGestures="on"
                                  >
                                    arrow_downward
                                  </mat-icon>
                                </span>

                                <!-- "stabilizing" -->
                                <span *ngSwitchCase="'stabilizing'" class="text-muted">
                                  Stabilizing ({{ result[metric]?.diff }}%)
                                  <mat-icon
                                  class="interactive-icon"
                                    matTooltip="The metric is stable with no significant change."
                                    matTooltipPosition="above"
                                    matTooltipTouchGestures="on"
                                  >
                                    arrow_forward
                                  </mat-icon>
                                </span>

                              </ng-container>
                            </div>


                            <!-- Afficher la valeur du 75ème percentile -->
                            <div class="d-flex justify-content-center align-items-center mt-3 text-muted small">
                              <span>75th percentile: {{ result[metric]?.p75 }}{{ metric === 'cls' ? '' : 'ms' }}</span>
                              <mat-icon
                                style="font-size: 18px; opacity: 0.7;"
                                [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                                matTooltipPosition="left">
                                help
                              </mat-icon>
                            </div>
                          </div>

                          <!-- Show the chart if data is available -->
                          @if (!chartOptions[metric]?.hasNoData) {
                            <apx-chart
                              [series]="chartOptions[metric]?.series"
                              [chart]="chartOptions[metric]?.chart"
                              [dataLabels]="chartOptions[metric].dataLabels"
                              [plotOptions]="chartOptions[metric].plotOptions"
                              [xaxis]="chartOptions[metric]?.xaxis"
                              [yaxis]="chartOptions[metric]?.yaxis"
                              [stroke]="chartOptions[metric]?.stroke"
                              [fill]="chartOptions[metric]?.fill"
                              [tooltip]="chartOptions[metric]?.tooltip"
                              [legend]="chartOptions[metric].legend">
                            </apx-chart>
                          }
                        </div>
                      </div>
                    }
                  }
                </div>
              </div>
            }

            <!-- Section for Alldevice === true -->
            @if (Alldevice) {
              <div class="container">
                <div class="row">
                  <!-- Loop through each group of metrics -->
                  @for (group of ['speed', 'layoutStability', 'interactivity']; track $index) {
                    <!-- Display the group title -->
                    <div class="d-flex align-items-center mt-4 py-1 px-4 border-bottom mb-3">
                      <h1 class="mb-0  text-dark">
                        {{ $index +1 }}.{{ (group === 'layoutStability' ? 'Layout Stability' : group) | titlecase }}
                      </h1>
                      <span class="text-muted small ms-3 ps-3 border-start">
                        ({{ getTooltipText(group) }})
                      </span>
                    </div>

                    <!-- Loop through each metric within the group -->
                    @for (metric of metricsGrouped[group]; track $index) {
                      <div class="col-sm-12 col-md-6 col-lg-4 ">
                        <div class="graph my-2 p-2">
                          <!-- Display metric name and tooltip -->
                          <strong [matTooltip]="result[metric]?.description">
                            {{ result[metric]?.name }}
                          </strong>

                          <!-- Show the chart if data is available -->
                          @if (!chartOptions[metric]?.hasNoData) {
                            <apx-chart
                              [series]="chartOptions[metric]?.series"
                              [chart]="chartOptions[metric]?.chart"
                              [legend]="chartOptions[metric].legend"
                              [plotOptions]="chartOptions[metric].plotOptions"
                              [xaxis]="chartOptions[metric]?.xaxis"
                              [yaxis]="chartOptions[metric]?.yaxis"
                              [stroke]="chartOptions[metric]?.stroke"
                              [fill]="chartOptions[metric]?.fill"
                              [tooltip]="chartOptions[metric]?.tooltip">
                            </apx-chart>
                          }
                        </div>
                      </div>
                    }
                  }
                </div>
              </div>
            }
          </div>
      </div>



      @if (!displaydata && !loading) {
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="error-template">
                <h1>Oops!</h1>
                <h2>You chose to not monitor this website on {{ device }}</h2>
                <div class="error-actions">
                  <a href="" class="btn btn-primary btn-lg"
                    ><span class="glyphicon glyphicon-home"></span> Take Me Home </a
                    ><a href="" class="btn btn-default btn-lg"
                    ><span class="glyphicon glyphicon-envelope"></span> Contact Support
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
  }
