import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { AnnotationService } from 'src/app/services/common/annotation/annotation.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { Annotation } from 'src/app/shared/models/annotation.model';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-annotations',
    templateUrl: './annotations.component.html',
    styleUrls: ['./annotations.component.scss'],
    standalone: false
})
export class AnnotationsComponent implements OnInit {
  text: string;
  userId: string;
  annotationData = new Annotation();
  checked:boolean =false;


  ngOnInit() {}

  constructor(
    private annotationService: AnnotationService,
    private senderVarService: SenderVarService,
    private router: Router
  ) {

  }
  events: string;

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events = `${type}: ${event.value}`;
  }

  addAnnotation() {
    const datepipe: DatePipe = new DatePipe('en-US');
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);

    this.annotationData.date = datepipe.transform(this.events, 'yyyy-MM', 'en-US');

    this.annotationData.nameOfGarph = 'barChartCrux';

    this.annotationData.text = this.text;
    this.annotationData.userId = this.userId;
    this.annotationData.projectId = this.senderVarService.id;
    this.annotationService
      .postAnnotation(this.annotationData)
      .subscribe((resp) => {
        console.log(resp);


        let route = this.router.url.substring(
          this.router.url.lastIndexOf('/'),
          this.router.url.length
        );

        console.log('route', route);

        if (route === '/lcp%2B') {
          this.router.navigate([
            '/home/page/' + this.senderVarService.id + '/lcp',
          ]);
        } else if (route === '/lcp') {
          this.router.navigate([
            '/home/page/' + this.senderVarService.id + '/lcp+',
          ]);
        } else if (route === '/fid%2B') {
          this.router.navigate([
            '/home/page/' + this.senderVarService.id + '/fid',
          ]);
        } else if (route === '/fid') {
          this.router.navigate([
            '/home/page/' + this.senderVarService.id + '/fid+',
          ]);
        } else if (route === '/fcp') {
          this.router.navigate([
            '/home/page/' + this.senderVarService.id + '/fcp+',
          ]);
        } else if (route === '/cls') {
          this.router.navigate([
            '/home/page/' + this.senderVarService.id + '/cls+',
          ]);
        } else if (route === '/cls%2B') {
          this.router.navigate([
            '/home/page/' + this.senderVarService.id + '/cls',
          ]);
        } else if (route === '/ttfb') {
          this.router.navigate([
            '/home/page/' + this.senderVarService.id + '/ttfb+',
          ]);
        } else if (route === '/ttfb%2B') {
          this.router.navigate([
            '/home/page/' + this.senderVarService.id + '/ttfb',
          ]);
        }
      });
  }
}
