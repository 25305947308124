<app-layout>
  <nav class="mx-4 mt-3" aria-label="breadcrumb">
    <ol
      style="height: 50px; width: 52px"
      class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center"
    >
      @defer{  
        @if (estAlloue(country)) { 
        <span class="mx-1">
            <mat-icon [svgIcon]="country"></mat-icon>  
        </span>
       }@else{
        <mat-icon  class="mx-1">language</mat-icon>
       }
      }
      <li>
        <span class="fw-bold">RUM > </span>
      </li>
      <li class="breadcrumb-item">
        <a
          class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center"
          [routerLink]="getPages().overview"
        >
          <mat-icon class="mx-2">desktop_windows</mat-icon>
          <span>{{ SiteTitle }} Overview</span>
        </a>
      </li>
      <li class="breadcrumb-item">
        <a
          class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center"
          [routerLink]="getPages().internal"
        >
          <span>Internal Pages</span>
        </a>
      </li>
      <li class="breadcrumb-item active"></li>
      <span class="mx-1">  Compare  </span>
      <span matTooltip="{{ dataname1 }}" class="d-inline-block text-truncate"style="max-width: 150px" >
      {{ dataname1 }}
      </span>

      <span class="mx-1">  vs  </span>

      <span matTooltip="{{ dataname2 }}"class="d-inline-block text-truncate" style="max-width: 150px" >
        {{ dataname2 }}
      </span>
      <!-- <mat-icon
        matTooltip="Copy Url"
        (click)="copyUrlToClipboard(detailUrlDecoded)"
        class="mx-1"
        style="cursor: pointer"
        >content_copy</mat-icon
      > -->
    </ol>
  </nav>
    <div class="m-3 d-flex justify-content-between align-content-center">
        <app-device-button  [device]="device" (deviceEvent)="setDevice($event)"></app-device-button>
    </div>
    <div id="tab-metric">
          <mat-tab-group mat-align-tabs="center" class="m-3">
          <mat-tab label="The last measurement">
            <ng-template matTabContent>
                <div>
                  @if (loading) {
                    <div class="d-flex justify-content-center align-items-center my-5" style="height:-1000px;">
                      <mat-spinner class="spinner" diameter="150"></mat-spinner>
                    </div>
                  }@else{
                    <div class="container">
                      <div class="row">
                        @for (metric of metrics; track $index) {
                          <div class="col-sm-12 col-md-6 col-4">
                            <div class=" graph m-2 p-4">
                              <strong class="font-met">{{metric | uppercase }} </strong>
                              <apx-chart style="width: 100%;"
                                [series]="chartOptionsGPN[metric]?.series"
                                [chart]="chartOptionsGPN[metric]?.chart"
                                [legend]="chartOptionsGPN[metric]?.legend"
                                [plotOptions]="chartOptionsGPN[metric]?.plotOptions"
                                [xaxis]="chartOptionsGPN[metric]?.xaxis"
                                [yaxis]="chartOptionsGPN[metric]?.yaxis"
                                [stroke]="chartOptionsGPN[metric]?.stroke"
                                [fill]="chartOptionsGPN[metric]?.fill"
                                [tooltip]="chartOptionsGPN[metric]?.tooltip"
                                >
                              </apx-chart>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
            </ng-template>
          </mat-tab> 
          @for (metric of metrics; track $index) {
          <mat-tab label="{{metric | uppercase}}">
            <ng-template matTabContent>
                <div class="row align-items-start">
                  <div class="col-12 d-flex flex-column justify-content-center align-content-center">
                    <span class="fs-4 text-center mt-5"> {{AllConfig[metric].selectedDays}}</span>
                    <mat-card  class="graph m-5 p-3">
                      <h2 class="m-0 mx-3">
                        P75
                        @if (metric !== 'cls' )
                        {
                          <span>(ms)</span>
                        }
                      </h2>
    
                      <mat-card-content>
                        <div
                          *ngIf="
                            !!p75ChartOpts[metric]['_7days'][
                            AllConfig[metric]?.selectedDays
                          ]
                          "
                          class="col-sm"
                        >
                          <apx-chart
                            [series]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].series
                            "
                            [chart]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].chart
                            "
                            [xaxis]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].xaxis
                            "
                            [yaxis]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].yaxis
                            "
                            [dataLabels]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].dataLabels
                            "
                            [grid]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].grid
                            "
                            [stroke]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].stroke
                            "
                            [title]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].title
                            "
                            [annotations]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].annotations
                            "
                            [grid]="
                              p75ChartOpts[metric]['_7days'][
                                AllConfig[metric]?.selectedDays
                              ].grid
                            "
                            class="col-sm"
                          >
                          </apx-chart>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="col-12 ">
                    <mat-card  class="graph m-5 p-3">
                      <h2 class="m-0 mx-3">{{metric | uppercase}} over time
                      </h2>
                      <apx-chart
                        *ngIf="!!AllConfig[metric]"
                        [series]="
                          AllConfig[metric][AllConfig[metric].selectedDays]?.series
                        "
                        [chart]="AllConfig[metric][AllConfig[metric].selectedDays]?.chart"
                        [dataLabels]="
                          AllConfig[metric][AllConfig[metric].selectedDays]?.dataLabels
                        "
                        [plotOptions]="
                          AllConfig[metric][AllConfig[metric].selectedDays]?.plotOptions
                        "
                        [responsive]="
                          AllConfig[metric][AllConfig[metric].selectedDays]?.responsive
                        "
                        [xaxis]="AllConfig[metric][AllConfig[metric].selectedDays]?.xaxis"
                        [yaxis]="AllConfig[metric][AllConfig[metric].selectedDays]?.yaxis"
                        [legend]="
                          AllConfig[metric][AllConfig[metric].selectedDays]?.legend
                        "
                        [fill]="AllConfig[metric][AllConfig[metric].selectedDays]?.fill"
                        [annotations]="
                          AllConfig[metric][AllConfig[metric].selectedDays]?.annotations
                        "
                        [grid]="AllConfig[metric][AllConfig[metric].selectedDays]?.grid"
                      >
                      </apx-chart>
                    </mat-card>
                  </div>
                </div>
              </ng-template>
          </mat-tab>
          }
          </mat-tab-group>
    </div>


</app-layout>
