<body>
  <app-layout>
    <app-help-widget
      [currentURL]="currentUrl"
      [userId]="userId"
      [projectID]="projectID"
      [Url] = "Url"
      >
    </app-help-widget>
    <nav class="mx-4 mt-3" aria-label="breadcrumb">
      <ol style="height: 50px; width: 52px;" class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center">
        @defer{
          @if (estAlloue(country)) {
            <span class="mx-1">
                <mat-icon [svgIcon]="country"></mat-icon>
            </span>
           }@else{
            <mat-icon  class="mx-1">language</mat-icon>
           }
        }
        <li>
          <span class="fw-bold">CRUX > </span>
        </li>
        <li class="breadcrumb-item">
          <a class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center " [href]="'/home/page/' + this.senderVarService.id + '/overview'">
          <mat-icon class="mx-2 ">desktop_windows</mat-icon>
          <span>{{WebSiteTitle}} Overview</span>
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
        <a class="fw-semibold text-decoration-none" [href]="'/home/page/' + this.senderVarService.id + '/internalpages'">
          <span>Internal Pages</span>
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ title }}
        </li>
        <li>
          <div matTooltip="Copy Url" class="mx-1 mt-2">
            <mat-icon (click) = "copyUrlToClipboard()" style="cursor: pointer;">content_copy</mat-icon>
          </div>
        </li>
      </ol>
    </nav>
    <div class=" d-flex justify-content-center align-content-center w-100">
      <div class="m-4 w-75">
        <div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: -1000px">
          <mat-spinner class="spinner" diameter="150"></mat-spinner>
        </div>
        <div *ngIf="!loading">
          <div>
            <div class="graph m-2">
                <div class="text-center p-3 d-flex flex-column justify-content-center align-items-center">
                  <h1 class="m-1">Internal page Web Vitals details and Over-time progress</h1>
                  <div *ngIf="!passOrNot">
                    <div class="notifications-container my-2">
                      <div class="info">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <svg class="info-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true">
                              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                          </svg>
                        </div>
                        <div class="info-prompt-wrap">
                          <p class="m-0">
                            On this page, you should prioritize the optimizations
                            on these metrics: {{ needImprovement }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <div matTooltip="UXR: User eXperience Rating assess real users' experiences.">
                    <button type="button" class="btn btn-sm btn-outline-primary d-flex justify-content-center align-items-center mx-2" (click)="openDialog(true)">
                      UXR progress<mat-icon>timeline</mat-icon>
                    </button>
                  </div>
                  <div class="mx-3">
                    UXR:
                    <i
                      style="font-size: large"
                      [className]="getColorUxr(data.uxr)"
                      >{{ data.uxr }}</i
                    >
                    <!-- <span class="my-custom-badge-accent"
                          matBadge={{data.uxrDiff}}
                          [matBadgeHidden] ="(data.uxrDiff === 0)"
                          [matBadgeColor]="getBadgeColor(data.uxrDiff)"
                         matBadgePosition="after"
                          matBadgeOverlap="false">

                    </span> -->
                  </div>
                  <app-what-is-uxr></app-what-is-uxr>
                </div>
                <div *ngIf="data.deviceShare !== undefined && data.deviceShare !== null" class="device-share-container">
                  <h3>Data based on device distribution:</h3>
                  <div class="device-share">
                    <div class="device-item">
                      <mat-icon>phone_android</mat-icon>
                      Mobile: <span>{{ data.deviceShare?.phone | percent }}</span>
                    </div>
                    <div class="device-item">
                      <mat-icon>desktop_windows</mat-icon>
                      Desktop: <span>{{ data.deviceShare?.desktop | percent }}</span>
                    </div>
                    <div class="device-item">
                      <mat-icon>tablet</mat-icon>
                      Tablet: <span>{{ data.deviceShare?.tablet | percent }}</span>
                    </div>
                  </div>
                </div>

                <div class="my-2">
                  <div *ngIf="upgrade">
                    <div class="notifications-container">
                      <div class="alert">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <svg
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5 alert-svg"
                            >
                              <path
                                clip-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                fill-rule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <div class="alert-prompt-wrap">
                            <p class="text-sm text-yellow-700">
                              Want a daily monitoring for internal pages?
                              <a
                                class="alert-prompt-link"
                                [routerLink]="'/buyPlan'"
                                >Upgrade now!</a
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-3 d-flex justify-content-between align-content-center">
              <app-device-button  [device]="device" (deviceEvent)="setDevice($event)"></app-device-button>
              <div *ngIf="!isFreeAccount">
                <div class="input-group">
                  <button class="btn btn-primary" type="button">
                    Compare to
                  </button>
                  <select
                    class="form-select"
                    id="diffDateSelect"
                    [(ngModel)]="selectedDiffDate"
                    (change)="onDiffDateChanged($event)"
                  >
                    <option *ngFor="let i of diffDates" [value]="i.id">
                      {{ i.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-metric">
            <mat-tab-group mat-align-tabs="center" class="m-3">
              <mat-tab label="The last measurement">
                <ng-template matTabContent>
                  <div>
                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-4 ">
                        <mat-card class="graph m-4 p-2">
                          <mat-card-title class="visits-chart__header">
                          </mat-card-title>
                          <mat-card-content>
                            <div
                              class="chart d-flex justify-content-start align-items-center h-100"
                            >
                              <img
                                loading="lazy"
                                decoding="async"
                                [src]="titleLcp"
                                alt="titleLcp"
                                class="rounded"
                              />
                              <strong class="font-met">LCP </strong>

                            </div>
                            <div class="diff-indicator d-flex justify-content-center align-items-center mb-2">
                              <ng-container [ngSwitch]="getDiffStatus(diffLcp )">
                                <span *ngSwitchCase="'improving'" class="text-success">
                                   Improving (+{{ diffLcp  }}%)<mat-icon>arrow_upward</mat-icon>
                                </span>
                                <span *ngSwitchCase="'regressing'" class="text-danger">
                                  Regressing ({{ diffLcp  }}%)<mat-icon>arrow_downward</mat-icon>
                                </span>
                                <span *ngSwitchCase="'stabilizing'" class="text-muted">
                                  Stabilizing ({{ diffLcp  }}%)<mat-icon>arrow_forward</mat-icon>
                                </span>
                              </ng-container>
                            </div>
                            <div class="col-sm d-flex flex-column justify-content-center align-items-center">
                              <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                                  <span class="mx-1">
                                    75th: {{lastValues.lcp| number:'1.2-2' }}
                                  </span>
                                <mat-icon
                                  style="font-size: 18px; opacity: 0.7;"
                                  [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                                  matTooltipPosition="left">
                                  help
                                </mat-icon>
                              </div>
                              <apx-chart
                                [series]="chartOptionsLcpGPN.series"
                                [chart]="chartOptionsLcpGPN.chart"
                                [dataLabels]="chartOptionsLcpGPN.dataLabels"
                                [plotOptions]="chartOptionsLcpGPN.plotOptions"
                                [xaxis]="chartOptionsLcpGPN.xaxis"
                                [yaxis]="chartOptionsLcpGPN.yaxis"
                                [stroke]="chartOptionsLcpGPN.stroke"
                                [fill]="chartOptionsLcpGPN.fill"
                                [title]="chartOptionsLcpGPN.title"
                                [tooltip]="chartOptionsLcpGPN.tooltip"
                                [legend]="chartOptionsLcpGPN.legend"
                              >
                              </apx-chart>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4 ">
                        <mat-card class="graph m-4 p-2">
                          <mat-card-title class="visits-chart__header">
                            <!-- <h5 class="chart">Cumulative Layout Shift (CLS)</h5>
                          <app-settings-menu></app-settings-menu> -->
                          </mat-card-title>
                          <mat-card-content>
                            <div
                              class="chart d-flex justify-content-start align-items-center"
                            >
                              <img
                                loading="lazy"
                                decoding="async"
                                [src]="titleInp"
                                alt="titleInp"
                                class="rounded"
                              />
                              <strong class="font-met">INP</strong>
                            </div>
                            <div class="col-sm d-flex flex-column justify-content-center align-items-center">
                              <div class="diff-indicator d-flex justify-content-center align-items-center mb-2">
                                <ng-container [ngSwitch]="getDiffStatus(diffInp )">
                                  <span *ngSwitchCase="'improving'" class="text-success">
                                     Improving (+{{ diffInp  }}%)<mat-icon>arrow_upward</mat-icon>
                                  </span>
                                  <span *ngSwitchCase="'regressing'" class="text-danger">
                                    Regressing ({{ diffInp  }}%)<mat-icon>arrow_downward</mat-icon>
                                  </span>
                                  <span *ngSwitchCase="'stabilizing'" class="text-muted">
                                    Stabilizing ({{ diffInp  }}%)<mat-icon>arrow_forward</mat-icon>
                                  </span>
                                </ng-container>
                              </div>
                              <apx-chart
                                [series]="chartOptionsInpGPN.series"
                                [chart]="chartOptionsInpGPN.chart"
                                [dataLabels]="chartOptionsInpGPN.dataLabels"
                                [plotOptions]="chartOptionsInpGPN.plotOptions"
                                [xaxis]="chartOptionsInpGPN.xaxis"
                                [yaxis]="chartOptionsInpGPN.yaxis"
                                [stroke]="chartOptionsInpGPN.stroke"
                                [fill]="chartOptionsInpGPN.fill"
                                [title]="chartOptionsInpGPN.title"
                                [tooltip]="chartOptionsInpGPN.tooltip"
                                [legend]="chartOptionsInpGPN.legend"
                              >
                              </apx-chart>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4 ">
                        <mat-card class="graph m-4 p-2 " >
                          <mat-card-title class="visits-chart__header">
                            <!-- <h5 class="chart">Cumulative Layout Shift (CLS)</h5>
                          <app-settings-menu></app-settings-menu> -->
                          </mat-card-title>
                          <mat-card-content>
                            <div
                              class="chart d-flex justify-content-start align-items-center"
                            >
                              <img
                                loading="lazy"
                                decoding="async"
                                [src]="titleCls"
                                alt="titleCls"
                                class="rounded"
                              />
                              <strong class="font-met">CLS</strong>
                            </div>
                            <div class="col-sm d-flex flex-column justify-content-center align-items-center">
                              <div class="diff-indicator d-flex justify-content-center align-items-center mb-2">
                                <ng-container [ngSwitch]="getDiffStatus(diffCls )">
                                  <span *ngSwitchCase="'improving'" class="text-success">
                                     Improving (+{{ diffCls  }}%)<mat-icon>arrow_upward</mat-icon>
                                  </span>
                                  <span *ngSwitchCase="'regressing'" class="text-danger">
                                    Regressing ({{ diffCls  }}%)<mat-icon>arrow_downward</mat-icon>
                                  </span>
                                  <span *ngSwitchCase="'stabilizing'" class="text-muted">
                                    Stabilizing ({{ diffCls  }}%)<mat-icon>arrow_forward</mat-icon>
                                  </span>
                                </ng-container>
                              </div>
                              <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                                  <span class="mx-1">
                                    75th: {{lastValues.cls| number:'1.2-2' }}
                                  </span>
                                <mat-icon
                                  style="font-size: 18px; opacity: 0.7;"
                                  [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                                  matTooltipPosition="left">
                                  help
                                </mat-icon>
                              </div>
                              <apx-chart
                                [series]="chartOptionsClsGPN.series"
                                [chart]="chartOptionsClsGPN.chart"
                                [dataLabels]="chartOptionsClsGPN.dataLabels"
                                [plotOptions]="chartOptionsClsGPN.plotOptions"
                                [xaxis]="chartOptionsClsGPN.xaxis"
                                [yaxis]="chartOptionsClsGPN.yaxis"
                                [stroke]="chartOptionsClsGPN.stroke"
                                [fill]="chartOptionsClsGPN.fill"
                                [title]="chartOptionsClsGPN.title"
                                [tooltip]="chartOptionsClsGPN.tooltip"
                                [legend]="chartOptionsClsGPN.legend"
                              >
                              </apx-chart>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-4 ">
                        <mat-card class="graph m-4 p-2 ">
                          <mat-card-title class="visits-chart__header">
                            <!-- <h5 class="chart">Cumulative Layout Shift (CLS)</h5>
                          <app-settings-menu></app-settings-menu> -->
                          </mat-card-title>
                          <mat-card-content>
                            <div
                              class="chart d-flex justify-content-start align-items-center"
                            >
                              <img
                                loading="lazy"
                                decoding="async"
                                [src]="titleTtfb"
                                alt="titleTtfb"
                                class="rounded"
                              />
                              <strong class="font-met">TTFB</strong>
                            </div>
                            <div class="col-sm d-flex flex-column justify-content-center align-items-center">
                              <div class="diff-indicator d-flex justify-content-center align-items-center mb-2">
                                <ng-container [ngSwitch]="getDiffStatus(diffTtfb )">
                                  <span *ngSwitchCase="'improving'" class="text-success">
                                     Improving (+{{ diffTtfb  }}%)<mat-icon>arrow_upward</mat-icon>
                                  </span>
                                  <span *ngSwitchCase="'regressing'" class="text-danger">
                                    Regressing ({{ diffTtfb  }}%)<mat-icon>arrow_downward</mat-icon>
                                  </span>
                                  <span *ngSwitchCase="'stabilizing'" class="text-muted">
                                    Stabilizing ({{ diffTtfb  }}%)<mat-icon>arrow_forward</mat-icon>
                                  </span>
                                </ng-container>
                              </div>
                              <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                                  <span class="mx-1">
                                    75th: {{lastValues.ttfb| number:'1.2-2' }}
                                  </span>
                                <mat-icon
                                  style="font-size: 18px; opacity: 0.7;"
                                  [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                                  matTooltipPosition="left">
                                  help
                                </mat-icon>
                              </div>
                              <apx-chart
                                [series]="chartOptionsTtfbGPN.series"
                                [chart]="chartOptionsTtfbGPN.chart"
                                [dataLabels]="chartOptionsTtfbGPN.dataLabels"
                                [plotOptions]="chartOptionsTtfbGPN.plotOptions"
                                [xaxis]="chartOptionsTtfbGPN.xaxis"
                                [yaxis]="chartOptionsTtfbGPN.yaxis"
                                [stroke]="chartOptionsTtfbGPN.stroke"
                                [fill]="chartOptionsTtfbGPN.fill"
                                [title]="chartOptionsTtfbGPN.title"
                                [tooltip]="chartOptionsTtfbGPN.tooltip"
                                [legend]="chartOptionsTtfbGPN.legend"
                              >
                              </apx-chart>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4 ">
                        <mat-card class="graph m-4 p-2">
                          <mat-card-title class="visits-chart__header">
                          </mat-card-title>
                          <mat-card-content>
                            <div
                              class="chart d-flex justify-content-start align-items-center"
                            >
                              <img
                                loading="lazy"
                                decoding="async"
                                [src]="titleFcp"
                                alt="titleFcp"
                                class="rounded"
                              />
                              <strong class="font-met">FCP </strong>
                            </div>
                            <div class="col-sm d-flex flex-column justify-content-center align-items-center ">
                              <div class="diff-indicator d-flex justify-content-center align-items-center mb-2">
                                <ng-container [ngSwitch]="getDiffStatus(diffFcp )">
                                  <span *ngSwitchCase="'improving'" class="text-success">
                                     Improving (+{{ diffFcp }}%)<mat-icon>arrow_upward</mat-icon>
                                  </span>
                                  <span *ngSwitchCase="'regressing'" class="text-danger">
                                    Regressing ({{ diffFcp }}%)<mat-icon>arrow_downward</mat-icon>
                                  </span>
                                  <span *ngSwitchCase="'stabilizing'" class="text-muted">
                                    Stabilizing ({{ diffFcp }}%)<mat-icon>arrow_forward</mat-icon>
                                  </span>
                                </ng-container>
                              </div>
                              <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                                  <span class="mx-1">
                                    75th: {{lastValues.fcp| number:'1.2-2' }}
                                  </span>
                                <mat-icon
                                  style="font-size: 18px; opacity: 0.7;"
                                  [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                                  matTooltipPosition="left">
                                  help
                                </mat-icon>
                              </div>
                              <apx-chart
                                [series]="chartOptionsFcpGPN.series"
                                [chart]="chartOptionsFcpGPN.chart"
                                [dataLabels]="chartOptionsFcpGPN.dataLabels"
                                [plotOptions]="chartOptionsFcpGPN.plotOptions"
                                [xaxis]="chartOptionsFcpGPN.xaxis"
                                [yaxis]="chartOptionsFcpGPN.yaxis"
                                [stroke]="chartOptionsFcpGPN.stroke"
                                [fill]="chartOptionsFcpGPN.fill"
                                [title]="chartOptionsFcpGPN.title"
                                [tooltip]="chartOptionsFcpGPN.tooltip"
                                [legend]="chartOptionsFcpGPN.legend"
                              >
                              </apx-chart>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </div>

                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="TTFB">
                <ng-template matTabContent>
                  <div>
                    <div class="row align-items-start">
                      <div class="col ">
                        <mat-card  class="graph m-5">
                          <mat-card-content>
                            <div class="row align-items-end">
                                  <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                                    <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                                    <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                                    <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                                    <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                                  </mat-select>
                            </div>
                            <div style="display: flex; justify-content: center">
                              <h3><b>Days range:</b></h3>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                padding: 1%;
                              "
                            >
                              <mat-chip-listbox aria-label="Days selection">
                                <mat-chip-option
                                  (click)="selectDateRange(day, 'ttfb')"
                                  style="cursor: pointer"
                                  *ngFor="let day of ttfbAllConfig.days"
                                  [selected]="day === ttfbAllConfig.selectedDays"
                                >
                                  {{ day }}
                                </mat-chip-option>
                              </mat-chip-listbox>
                            </div>
                            <div
                              *ngIf="
                                !!p75ChartOpts?.['ttfb']?.[selectedMatSelectValue]?.[
                                  ttfbAllConfig.selectedDays
                                ]
                              "
                              class="col-sm"
                            >
                              <apx-chart
                                [series]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].series
                                "
                                [chart]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].chart
                                "
                                [xaxis]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].xaxis
                                "
                                [yaxis]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].yaxis
                                "
                                [dataLabels]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].dataLabels
                                "
                                [grid]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].grid
                                "
                                [stroke]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].stroke
                                "
                                [title]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].title
                                "
                                [annotations]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].annotations
                                "
                                [grid]="
                                  p75ChartOpts['ttfb'][selectedMatSelectValue][
                                    ttfbAllConfig.selectedDays
                                  ].grid
                                "
                              >
                              </apx-chart>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div
                        class="d-flex flex-column justify-content-center align-items-center my-2"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-primary d-flex justify-content-center align-items-center"
                          (click)="checked = !checked"
                        >
                          <mat-icon>flag</mat-icon>
                          <p class="m-0">Add annotation</p>
                        </button>
                        <div *ngIf="checked" class="add-annotation my-3 p-2">
                          <div class="graph d-flex flex-column justify-content-center align-items-center p-5">
                            <div class="d-flex justify-content-center align-content-center">
                              <mat-form-field  appearance = "outline" class="rounded p-0">
                                <mat-label>Date</mat-label>
                                <input
                                  matInput
                                  [matDatepicker]="picker"
                                  (click)="picker.open()"
                                  (dateInput)="addEvent('input', $event)"
                                  autocomplete="off"
                                  placeholder="Select date"
                                  (keydown)="(false)"
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field appearance = "outline" class="rounded p-0 mx-3">
                                <mat-label>Annotation</mat-label>
                                <input
                                  type="text"
                                  id="text"
                                  name="text"
                                  placeholder="Annotation"
                                  [(ngModel)]="text"
                                  autocomplete="off"
                                  matInput
                                  autofocus
                                />
                              </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-center align-content-center">
                              <button type="button" class="btn btn-success btn-sm" (click)="addAnnotation()">
                                Add annotation
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col ">
                        <mat-card  class="graph m-5 p-2">
                          <h2 class="m-0 mx-3">TTFB over time</h2>
                          <div class="row align-items-end m-1">
                            <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                              <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                              <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                              <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                              <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                            </mat-select>
                         </div>
                          <div style="display: flex; justify-content: center">
                            <h3><b>Days range:</b></h3>
                          </div>
                          <div
                            style="
                              display: flex;
                              justify-content: center;
                              padding: 1%;
                            "
                          >
                            <mat-chip-listbox aria-label="Days selection">
                              <mat-chip-option
                                (click)="selectDateRange(day, 'ttfb')"
                                style="cursor: pointer"
                                *ngFor="let day of ttfbAllConfig.days"
                                [selected]="day === ttfbAllConfig.selectedDays"
                              >
                                {{ day }}
                              </mat-chip-option>
                            </mat-chip-listbox>
                          </div>
                          <!-- <div style="display: flex; justify-content: center; padding: 1%;">
                            <mat-chip-list aria-label="Fish selection">
                              <mat-chip>2022/11/08 to 2022/11/08</mat-chip>
                              <mat-chip>2022/11/08 to 2022/11/08</mat-chip>
                              <mat-chip>2022/11/08 to 2022/11/08</mat-chip>
                            </mat-chip-list>
                          </div> -->
                          <apx-chart
                            *ngIf="!!ttfbAllConfig"
                            [series]="
                              ttfbAllConfig[ttfbAllConfig.selectedDays].series
                            "
                            [chart]="ttfbAllConfig[ttfbAllConfig.selectedDays].chart"
                            [dataLabels]="
                              ttfbAllConfig[ttfbAllConfig.selectedDays].dataLabels
                            "
                            [plotOptions]="
                              ttfbAllConfig[ttfbAllConfig.selectedDays].plotOptions
                            "
                            [responsive]="
                              ttfbAllConfig[ttfbAllConfig.selectedDays].responsive
                            "
                            [xaxis]="ttfbAllConfig[ttfbAllConfig.selectedDays].xaxis"
                            [yaxis]="ttfbAllConfig[ttfbAllConfig.selectedDays].yaxis"
                            [legend]="
                              ttfbAllConfig[ttfbAllConfig.selectedDays].legend
                            "
                            [fill]="ttfbAllConfig[ttfbAllConfig.selectedDays].fill"
                            [annotations]="
                              ttfbAllConfig[ttfbAllConfig.selectedDays].annotations
                            "
                            [grid]="ttfbAllConfig[ttfbAllConfig.selectedDays].grid"
                          >
                          </apx-chart>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>

              <mat-tab label="FCP">
                <ng-template matTabContent>
                  <div>
                    <div>
                      <div class="col ">
                        <mat-card  class="graph m-5">
                          <mat-card-content>
                            <div class="row align-items-end">
                              <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                                <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                                <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                                <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                                <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                              </mat-select>
                            </div>
                            <div style="display: flex; justify-content: center">
                              <h3><b>Days range:</b></h3>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                padding: 1%;
                              "
                            >
                              <mat-chip-listbox aria-label="Days selection">
                                <mat-chip-option
                                  (click)="selectDateRange(day, 'fcp')"
                                  style="cursor: pointer"
                                  *ngFor="let day of fcpAllConfig.days"
                                  [selected]="day === fcpAllConfig.selectedDays"
                                >
                                  {{ day }}
                                </mat-chip-option>
                              </mat-chip-listbox>
                            </div>
                            <div
                              *ngIf="
                                !!p75ChartOpts?.['fcp']?.[selectedMatSelectValue]?.[
                                  fcpAllConfig.selectedDays
                                ]
                              "
                              class="col-sm"
                            >
                              <apx-chart
                                [series]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].series
                                "
                                [chart]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].chart
                                "
                                [xaxis]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].xaxis
                                "
                                [yaxis]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].yaxis
                                "
                                [dataLabels]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].dataLabels
                                "
                                [grid]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].grid
                                "
                                [stroke]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].stroke
                                "
                                [title]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].title
                                "
                                [annotations]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].annotations
                                "
                                [grid]="
                                  p75ChartOpts['fcp'][selectedMatSelectValue][
                                    fcpAllConfig.selectedDays
                                  ].grid
                                "
                              >
                              </apx-chart>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>

                      <div
                        class="d-flex flex-column justify-content-center align-items-center my-2"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-primary d-flex justify-content-center align-items-center"
                          (click)="checked = !checked"
                        >
                          <mat-icon>flag</mat-icon>
                          <p class="m-0">Add annotation</p>
                        </button>
                        <div *ngIf="checked" class="add-annotation my-3 p-2">
                          <div class="graph d-flex flex-column justify-content-center align-items-center p-5">
                            <div class="d-flex justify-content-center align-content-center">
                              <mat-form-field  appearance = "outline" class="rounded p-0">
                                <mat-label>Date</mat-label>
                                <input
                                  matInput
                                  [matDatepicker]="picker"
                                  (click)="picker.open()"
                                  (dateInput)="addEvent('input', $event)"
                                  autocomplete="off"
                                  placeholder="Select date"
                                  (keydown)="(false)"
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field appearance = "outline" class="rounded p-0 mx-3">
                                <mat-label>Annotation</mat-label>
                                <input
                                  type="text"
                                  id="text"
                                  name="text"
                                  placeholder="Annotation"
                                  [(ngModel)]="text"
                                  autocomplete="off"
                                  matInput
                                  autofocus
                                />
                              </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-center align-content-center">
                              <button type="button" class="btn btn-success btn-sm" (click)="addAnnotation()">
                                Add annotation
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col ">
                        <mat-card  class="graph m-5 p-2 ">
                        <h2 class="m-0 mx-3">FCP over time</h2>
                        <div class="row align-items-end m-1">
                          <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                            <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                            <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                            <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                            <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                          </mat-select>
                       </div>

                        <div style="display: flex; justify-content: center">
                          <h3><b>Days range:</b></h3>
                        </div>
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            padding: 1%;
                          "
                        >
                          <mat-chip-list aria-label="Days selection">
                            <mat-chip
                              (click)="selectDateRange(day, 'fcp')"
                              style="cursor: pointer"
                              *ngFor="let day of fcpAllConfig.days"
                              [selected]="day === fcpAllConfig.selectedDays"
                            >
                              {{ day }}
                            </mat-chip>
                          </mat-chip-list>
                        </div>

                          <apx-chart
                            *ngIf="!!fcpAllConfig"
                            [series]="
                              fcpAllConfig[fcpAllConfig.selectedDays].series
                            "
                            [chart]="
                              fcpAllConfig[fcpAllConfig.selectedDays].chart
                            "
                            [dataLabels]="
                              fcpAllConfig[fcpAllConfig.selectedDays].dataLabels
                            "
                            [plotOptions]="
                              fcpAllConfig[fcpAllConfig.selectedDays].plotOptions
                            "
                            [responsive]="
                              fcpAllConfig[fcpAllConfig.selectedDays].responsive
                            "
                            [xaxis]="
                              fcpAllConfig[fcpAllConfig.selectedDays].xaxis
                            "
                            [yaxis]="
                              fcpAllConfig[fcpAllConfig.selectedDays].yaxis
                            "
                            [legend]="
                              fcpAllConfig[fcpAllConfig.selectedDays].legend
                            "
                            [fill]="fcpAllConfig[fcpAllConfig.selectedDays].fill"
                            [annotations]="
                              fcpAllConfig[fcpAllConfig.selectedDays].annotations
                            "
                            [grid]="fcpAllConfig[fcpAllConfig.selectedDays].grid"
                          >
                          </apx-chart>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>

              <mat-tab label="LCP">
                <ng-template matTabContent>
                  <div>
                    <div class="row">
                      <div class="col ">
                        <mat-card  class="graph m-5">
                          <mat-card-content>
                            <div class="row align-items-end">
                              <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                                <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                                <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                                <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                                <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                              </mat-select>
                            </div>
                            <div style="display: flex; justify-content: center">
                              <h3><b>Days range:</b></h3>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                padding: 1%;
                              "
                            >
                              <mat-chip-listbox aria-label="Days selection">
                                <mat-chip-option
                                  (click)="selectDateRange(day, 'lcp')"
                                  style="cursor: pointer"
                                  *ngFor="let day of lcpAllConfig.days"
                                  [selected]="day === lcpAllConfig.selectedDays"
                                >
                                  {{ day }}
                                </mat-chip-option>
                              </mat-chip-listbox>
                            </div>
                            <div
                              *ngIf="
                                !!p75ChartOpts?.['lcp']?.[selectedMatSelectValue]?.[
                                  lcpAllConfig.selectedDays
                                ]
                              "
                              class="col-sm"
                            >
                              <apx-chart
                                [series]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].series
                                "
                                [chart]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].chart
                                "
                                [xaxis]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].xaxis
                                "
                                [yaxis]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].yaxis
                                "
                                [dataLabels]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].dataLabels
                                "
                                [grid]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].grid
                                "
                                [stroke]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].stroke
                                "
                                [title]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].title
                                "
                                [annotations]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].annotations
                                "
                                [grid]="
                                  p75ChartOpts['lcp'][selectedMatSelectValue][
                                    lcpAllConfig.selectedDays
                                  ].grid
                                "
                              >
                              </apx-chart>
                            </div>
                              <div class="d-flex justify-content-center align-items-center">
                                <div class="mb-4 w-100">

                                    <apx-chart
                                      [series]="chartOptionsLcpBreakdown.series"
                                      [chart]="chartOptionsLcpBreakdown.chart"
                                      [xaxis]="chartOptionsLcpBreakdown.xaxis"
                                      [title]="chartOptionsLcpBreakdown.title"
                                      [plotOptions]="chartOptionsLcpBreakdown.plotOptions"
                                      [dataLabels]="chartOptionsLcpBreakdown.dataLabels"
                                      [colors]="chartOptionsLcpBreakdown.colors"
                                      [tooltip]="chartOptionsLcpBreakdown.tooltip"
                                      [legend]="chartOptionsLcpBreakdown.legend"
                                    ></apx-chart>

                                </div>
                              </div>
                          </mat-card-content>
                        </mat-card>
                      </div>

                      <div
                        class="d-flex flex-column justify-content-center align-items-center my-2"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-primary d-flex justify-content-center align-items-center"
                          (click)="checked = !checked"
                        >
                          <mat-icon>flag</mat-icon>
                          <p class="m-0">Add annotation</p>
                        </button>
                        <div *ngIf="checked" class="add-annotation my-3 p-2">
                          <div class="graph d-flex flex-column justify-content-center align-items-center p-5">
                            <div class="d-flex justify-content-center align-content-center">
                              <mat-form-field  appearance = "outline" class="rounded p-0">
                                <mat-label>Date</mat-label>
                                <input
                                  matInput
                                  [matDatepicker]="picker"
                                  (click)="picker.open()"
                                  (dateInput)="addEvent('input', $event)"
                                  autocomplete="off"
                                  placeholder="Select date"
                                  (keydown)="(false)"
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field appearance = "outline" class="rounded p-0 mx-3">
                                <mat-label>Annotation</mat-label>
                                <input
                                  type="text"
                                  id="text"
                                  name="text"
                                  placeholder="Annotation"
                                  [(ngModel)]="text"
                                  autocomplete="off"
                                  matInput
                                  autofocus
                                />
                              </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-center align-content-center">
                              <button type="button" class="btn btn-success btn-sm" (click)="addAnnotation()">
                                Add annotation
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col ">
                        <mat-card  class="graph m-5 p-2">
                        <h2 class="m-0 mx-3" >LCP over time</h2>
                        <div class="row align-items-end m-1">
                          <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                            <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                            <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                            <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                            <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                          </mat-select>
                       </div>


                        <div style="display: flex; justify-content: center">
                          <h3><b>Days range:</b></h3>
                        </div>
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            padding: 1%;
                          "
                        >
                          <mat-chip-list aria-label="Days selection">
                            <mat-chip
                              (click)="selectDateRange(day, 'lcp')"
                              style="cursor: pointer"
                              *ngFor="let day of lcpAllConfig.days"
                              [selected]="day === lcpAllConfig.selectedDays"
                            >
                              {{ day }}
                            </mat-chip>
                          </mat-chip-list>
                        </div>

                          <apx-chart
                            [series]="
                              lcpAllConfig[lcpAllConfig.selectedDays].series
                            "
                            [chart]="
                              lcpAllConfig[lcpAllConfig.selectedDays].chart
                            "
                            [dataLabels]="
                              lcpAllConfig[lcpAllConfig.selectedDays].dataLabels
                            "
                            [plotOptions]="
                              lcpAllConfig[lcpAllConfig.selectedDays].plotOptions
                            "
                            [responsive]="
                              lcpAllConfig[lcpAllConfig.selectedDays].responsive
                            "
                            [xaxis]="
                              lcpAllConfig[lcpAllConfig.selectedDays].xaxis
                            "
                            [yaxis]="
                              lcpAllConfig[lcpAllConfig.selectedDays].yaxis
                            "
                            [legend]="
                              lcpAllConfig[lcpAllConfig.selectedDays].legend
                            "
                            [fill]="lcpAllConfig[lcpAllConfig.selectedDays].fill"
                            [annotations]="
                              lcpAllConfig[lcpAllConfig.selectedDays].annotations
                            "
                            [grid]="lcpAllConfig[lcpAllConfig.selectedDays].grid"
                          ></apx-chart>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>

              <mat-tab label="CLS">
                <ng-template matTabContent>
                  <div>
                    <div class="row">
                      <div class="col ">
                        <mat-card  class="graph m-5">
                          <mat-card-content>
                            <div class="row align-items-end">
                              <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                                <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                                <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                                <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                                <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                              </mat-select>
                            </div>
                            <div style="display: flex; justify-content: center">
                              <h3><b>Days range:</b></h3>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                padding: 1%;
                              "
                            >
                              <mat-chip-listbox aria-label="Days selection">
                                <mat-chip-option
                                  (click)="selectDateRange(day, 'cls')"
                                  style="cursor: pointer"
                                  *ngFor="let day of clsAllConfig.days"
                                  [selected]="day === clsAllConfig.selectedDays"
                                >
                                  {{ day }}
                                </mat-chip-option>
                              </mat-chip-listbox>
                            </div>
                            <div
                              *ngIf="
                                !!p75ChartOpts?.['cls']?.[selectedMatSelectValue]?.[
                                  clsAllConfig.selectedDays
                                ]
                              "
                              class="col-sm"
                            >
                              <apx-chart
                                [series]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].series
                                "
                                [chart]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].chart
                                "
                                [xaxis]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].xaxis
                                "
                                [yaxis]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].yaxis
                                "
                                [dataLabels]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].dataLabels
                                "
                                [grid]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].grid
                                "
                                [stroke]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].stroke
                                "
                                [title]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].title
                                "
                                [annotations]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].annotations
                                "
                                [grid]="
                                  p75ChartOpts['cls'][selectedMatSelectValue][
                                    clsAllConfig.selectedDays
                                  ].grid
                                "
                              >
                              </apx-chart>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>

                      <div
                        class="d-flex flex-column justify-content-center align-items-center my-2"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-primary d-flex justify-content-center align-items-center"
                          (click)="checked = !checked"
                        >
                          <mat-icon>flag</mat-icon>
                          <p class="m-0">Add annotation</p>
                        </button>
                        <div *ngIf="checked" class="add-annotation my-3 p-2">
                          <div class="graph d-flex flex-column justify-content-center align-items-center p-5">
                            <div class="d-flex justify-content-center align-content-center">
                              <mat-form-field  appearance = "outline" class="rounded p-0">
                                <mat-label>Date</mat-label>
                                <input
                                  matInput
                                  [matDatepicker]="picker"
                                  (click)="picker.open()"
                                  (dateInput)="addEvent('input', $event)"
                                  autocomplete="off"
                                  placeholder="Select date"
                                  (keydown)="(false)"
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field appearance = "outline" class="rounded p-0 mx-3">
                                <mat-label>Annotation</mat-label>
                                <input
                                  type="text"
                                  id="text"
                                  name="text"
                                  placeholder="Annotation"
                                  [(ngModel)]="text"
                                  autocomplete="off"
                                  matInput
                                  autofocus
                                />
                              </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-center align-content-center">
                              <button type="button" class="btn btn-success btn-sm" (click)="addAnnotation()">
                                Add annotation
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col ">
                        <mat-card  class="graph m-5 p-2">
                        <h2 class="m-0 mx-3">CLS over time</h2>
                        <div class="row align-items-end m-1">
                          <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                            <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                            <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                            <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                            <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                          </mat-select>
                       </div>


                        <div style="display: flex; justify-content: center">
                          <h3><b>Days range:</b></h3>
                        </div>
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            padding: 1%;
                          "
                        >
                          <mat-chip-list aria-label="Days selection">
                            <mat-chip
                              (click)="selectDateRange(day, 'cls')"
                              style="cursor: pointer"
                              *ngFor="let day of clsAllConfig.days"
                              [selected]="day === clsAllConfig.selectedDays"
                            >
                              {{ day }}
                            </mat-chip>
                          </mat-chip-list>
                        </div>

                          <apx-chart
                            *ngIf="!!clsAllConfig"
                            [series]="
                              clsAllConfig[clsAllConfig.selectedDays].series
                            "
                            [chart]="
                              clsAllConfig[clsAllConfig.selectedDays].chart
                            "
                            [dataLabels]="
                              clsAllConfig[clsAllConfig.selectedDays].dataLabels
                            "
                            [plotOptions]="
                              clsAllConfig[clsAllConfig.selectedDays].plotOptions
                            "
                            [responsive]="
                              clsAllConfig[clsAllConfig.selectedDays].responsive
                            "
                            [xaxis]="
                              clsAllConfig[clsAllConfig.selectedDays].xaxis
                            "
                            [yaxis]="
                              clsAllConfig[clsAllConfig.selectedDays].yaxis
                            "
                            [legend]="
                              clsAllConfig[clsAllConfig.selectedDays].legend
                            "
                            [fill]="clsAllConfig[clsAllConfig.selectedDays].fill"
                            [annotations]="
                              clsAllConfig[clsAllConfig.selectedDays].annotations
                            "
                            [grid]="clsAllConfig[clsAllConfig.selectedDays].grid"
                          ></apx-chart>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>

              <mat-tab label="INP">
                <ng-template matTabContent>
                  <div>
                    <div class="row">
                      <div class="col ">
                        <mat-card  class="graph m-5">
                          <mat-card-content>
                            <div class="row align-items-end">
                              <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                                <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                                <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                                <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                                <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                              </mat-select>
                            </div>
                            <div style="display: flex; justify-content: center">
                              <h3><b>Days range:</b></h3>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                padding: 1%;
                              "
                            >
                              <mat-chip-listbox aria-label="Days selection">
                                <mat-chip-option
                                  (click)="selectDateRange(day, 'inp')"
                                  style="cursor: pointer"
                                  *ngFor="let day of inpAllConfig.days"
                                  [selected]="day === inpAllConfig.selectedDays"
                                >
                                  {{ day }}
                                </mat-chip-option>
                              </mat-chip-listbox>
                            </div>
                            <div
                              *ngIf="
                                !!p75ChartOpts?.['inp']?.[selectedMatSelectValue]?.[
                                  inpAllConfig.selectedDays
                                ]
                              "
                              class="col-sm"
                            >
                              <apx-chart
                                [series]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].series
                                "
                                [chart]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].chart
                                "
                                [xaxis]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].xaxis
                                "
                                [yaxis]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].yaxis
                                "
                                [dataLabels]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].dataLabels
                                "
                                [grid]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].grid
                                "
                                [stroke]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].stroke
                                "
                                [title]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].title
                                "
                                [annotations]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].annotations
                                "
                                [grid]="
                                  p75ChartOpts['inp'][selectedMatSelectValue][
                                    inpAllConfig.selectedDays
                                  ].grid
                                "
                              >
                              </apx-chart>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>

                      <div
                        class="d-flex flex-column justify-content-center align-items-center my-2"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-primary d-flex justify-content-center align-items-center"
                          (click)="checked = !checked"
                        >
                          <mat-icon>flag</mat-icon>
                          <p class="m-0">Add annotation</p>
                        </button>
                        <div *ngIf="checked" class="add-annotation my-3 p-2">
                          <div class="graph d-flex flex-column justify-content-center align-items-center p-5">
                            <div class="d-flex justify-content-center align-content-center">
                              <mat-form-field  appearance = "outline" class="rounded p-0">
                                <mat-label>Date</mat-label>
                                <input
                                  matInput
                                  [matDatepicker]="picker"
                                  (click)="picker.open()"
                                  (dateInput)="addEvent('input', $event)"
                                  autocomplete="off"
                                  placeholder="Select date"
                                  (keydown)="(false)"
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field appearance = "outline" class="rounded p-0 mx-3">
                                <mat-label>Annotation</mat-label>
                                <input
                                  type="text"
                                  id="text"
                                  name="text"
                                  placeholder="Annotation"
                                  [(ngModel)]="text"
                                  autocomplete="off"
                                  matInput
                                  autofocus
                                />
                              </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-center align-content-center">
                              <button type="button" class="btn btn-success btn-sm" (click)="addAnnotation()">
                                Add annotation
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col ">
                        <mat-card  class="graph m-5 p-2">
                        <h2 class="m-0 mx-3">INP over time</h2>

                        <div class="row align-items-end m-1">
                          <mat-select class="chart-select rounded" [(ngModel)]="selectedMatSelectValue" (selectionChange)="changedMatSelectionValue()">
                            <mat-option [value]="matSelectFields._7days">7 Days</mat-option>
                            <mat-option [value]="matSelectFields._30days">30 Days</mat-option>
                            <mat-option [value]="matSelectFields._60days">60 Days</mat-option>
                            <mat-option [value]="matSelectFields._90days" >90 Days</mat-option>
                          </mat-select>
                       </div>

                        <div style="display: flex; justify-content: center">
                          <h3><b>Days range:</b></h3>
                        </div>
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            padding: 1%;
                          "
                        >
                          <mat-chip-list aria-label="Days selection">
                            <mat-chip
                              (click)="selectDateRange(day, 'inp')"
                              style="cursor: pointer"
                              *ngFor="let day of inpAllConfig.days"
                              [selected]="day === inpAllConfig.selectedDays"
                            >
                              {{ day }}
                            </mat-chip>
                          </mat-chip-list>
                        </div>

                          <apx-chart
                            [series]="
                              inpAllConfig[inpAllConfig.selectedDays].series
                            "
                            [chart]="
                              inpAllConfig[inpAllConfig.selectedDays].chart
                            "
                            [dataLabels]="
                              inpAllConfig[inpAllConfig.selectedDays].dataLabels
                            "
                            [plotOptions]="
                              inpAllConfig[inpAllConfig.selectedDays].plotOptions
                            "
                            [responsive]="
                              inpAllConfig[inpAllConfig.selectedDays].responsive
                            "
                            [xaxis]="
                              inpAllConfig[inpAllConfig.selectedDays].xaxis
                            "
                            [yaxis]="
                              inpAllConfig[inpAllConfig.selectedDays].yaxis
                            "
                            [legend]="
                              inpAllConfig[inpAllConfig.selectedDays].legend
                            "
                            [fill]="inpAllConfig[inpAllConfig.selectedDays].fill"
                            [annotations]="
                              inpAllConfig[inpAllConfig.selectedDays].annotations
                            "
                            [grid]="inpAllConfig[inpAllConfig.selectedDays].grid"
                          ></apx-chart>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>

            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</body>
