import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { LoginregisterRoutingModule } from '../loginregister/loginregister-routing.module';
import { MaterialModule } from 'src/app/shared/module/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NewPageComponent } from './new-page/new-page.component';
import {
  MatChipsModule,
  MAT_CHIPS_DEFAULT_OPTIONS,
} from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { PageCompareComponent } from './page-compare/page-compare.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PageInterneComponent } from './page-interne/page-interne.component';
import { ListWebsitesComponent } from './list-websites/list-websites.component';
import { OverviewComponent } from './overview/overview.component';
import { LcpComponent } from './lcp/lcp.component';
import { ClsComponent } from './cls/cls.component';
import { FcpComponent } from './fcp/fcp.component';
import { TtfpComponent } from './ttfp/ttfp.component';
import { SharedModule } from '../../shared/shared.module';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { BuyPlanComponent } from '../buy-plan/buy-plan.component';
import { InternalPageDetailComponent } from './internal-page-detail/internal-page-detail.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { DeviceButtonComponent } from './device-button/device-button.component';

import { AnnotationsComponent } from './annotations/annotations.component';
import { MetricPageComponent } from './metric-page/metric-page.component';
import { InpComponent } from './inp/inp.component';
import { DialogComponent } from './dialog/dialog.component';
import { SharedWithDialogComponent } from './shared-with-dialog/shared-with-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { MyAccountPageComponent } from './my-account-page/my-account-page.component';
import { HistogramDialogComponent } from './histogram-dialog/histogram-dialog.component';
import { UxrDialogComponent } from './uxr-dialog/uxr-dialog.component';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { ListWebsitesFirstPartyDataComponent } from './list-websites-first-party-data/list-websites-first-party-data.component';
import { FirstPartyDataHomeComponent } from './first-party-data/first-party-data-home/first-party-data-home.component';
import { FirstPartyDataOverviewComponent } from './first-party-data/first-party-data-overview/first-party-data-overview.component';
import { FirstPartyDataLcpComponent } from './first-party-data/first-party-data-lcp/first-party-data-lcp.component';
import { FirstPartyDataClsComponent } from './first-party-data/first-party-data-cls/first-party-data-cls.component';
import { FirstMetricDataComponent } from './first-party-data/first-metric-data/first-metric-data.component';
import { FirstPartyInternalPagesComponent } from './first-party-internal-pages/first-party-internal-pages.component';
import { MonitorSiteComponent } from './monitor-site/monitor-site.component';
import { AlertDiffComponent } from 'src/app/alert-diff/alert-diff.component';
import { FirstPartyDataFcpComponent } from './first-party-data/first-party-data-fcp/first-party-data-fcp.component';
import { FirstPartyDataInpComponent } from './first-party-data/first-party-data-inp/first-party-data-inp.component';
import { FirstPartyDataTtfbComponent } from './first-party-data/first-party-data-ttfb/first-party-data-ttfb.component';
import { SelectWebSiteComponent } from './select-web-site/select-web-site.component';
import { WhatIsUxrComponent } from './what-is-uxr/what-is-uxr.component';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { MatSortModule } from '@angular/material/sort';
import { PagesByPerformanceComponent } from './first-party-data/pages-by-performance/pages-by-performance.component';
import { PageViewOverTimeComponent } from './page-view-over-time/page-view-over-time.component';
import { DaysRangeSelectorComponent } from './first-party-data/days-range-selector/days-range-selector.component';
import { NavTypeComponent } from './nav-type/nav-type.component';
import { CompareInternalPagesComponent } from './compare-internal-pages/compare-internal-pages.component';
import { UrlBySegmentSettingsComponent } from './url-by-segment-settings/url-by-segment-settings.component';
import { FirstPartyDataCompareComponent } from './first-party-data/first-party-data-compare/first-party-data-compare.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RequestOfferComponent } from './request-offer/request-offer.component';
import { SuccessComponent } from './success/success.component';
import { CreateWorkflowDialogComponent } from './create-workflow-dialog/create-workflow-dialog.component';
import { ShowDetailsWorkflowComponent } from './show-details-workflow/show-details-workflow.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { InsightsViewerComponent } from './insights-viewer/insights-viewer.component';
import { MatSelectCountryComponent } from '../../shared/components/mat-select-country/mat-select-country.component';


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost', // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#f1d600',
    },
  },
  theme: 'edgeless',
  type: 'opt-out',
};

@NgModule({
  declarations: [
    DashboardComponent,
    NewPageComponent,
    PageCompareComponent,
    PageInterneComponent,
    CompareInternalPagesComponent,
    ListWebsitesComponent,
    OverviewComponent,
    LcpComponent,
    ClsComponent,
    FcpComponent,
    TtfpComponent,
    BuyPlanComponent,
    InternalPageDetailComponent,
    LoadingPageComponent,
    DeviceButtonComponent,
    AnnotationsComponent,
    MetricPageComponent,
    InpComponent,
    DialogComponent,
    SharedWithDialogComponent,
    DeleteDialogComponent,
    MyAccountPageComponent,
    HistogramDialogComponent,
    UxrDialogComponent,
    ListWebsitesFirstPartyDataComponent,
    FirstPartyDataClsComponent,
    FirstPartyDataOverviewComponent,
    FirstPartyDataHomeComponent,
    FirstPartyDataLcpComponent,
    FirstMetricDataComponent,
    FirstPartyInternalPagesComponent,
    AlertDiffComponent,
    MonitorSiteComponent,
    FirstPartyDataFcpComponent,
    FirstPartyDataInpComponent,
    FirstPartyDataTtfbComponent,
    FirstPartyDataCompareComponent,
    SelectWebSiteComponent,
    WhatIsUxrComponent,
    PagesByPerformanceComponent,
    PageViewOverTimeComponent,
    NavTypeComponent,
    DaysRangeSelectorComponent,
    UrlBySegmentSettingsComponent,
    RequestOfferComponent,
    SuccessComponent,
    CreateWorkflowDialogComponent,
    ShowDetailsWorkflowComponent,
    InsightsViewerComponent,
  ],
  imports: [
    DragDropModule,
    NgApexchartsModule,
    CommonModule,
    SharedModule,
    HomeRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    LoginregisterRoutingModule,
    NgApexchartsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MatDialogModule,
    MatChipsModule,
    MatSortModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgCircleProgressModule.forRoot({
      radius: 32,
      space: -10,
      outerStrokeGradient: true,
      outerStrokeWidth: 8,
      outerStrokeColor: '#2fbba5',
      outerStrokeGradientStopColor: '#53a9ff',
      innerStrokeColor: '#e7e8ea',
      innerStrokeWidth: 8,
      animateTitle: false,
      animationDuration: 1000,
      showUnits: false,
      showTitle: true,
      showBackground: false,
      clockwise: false,
      showImage: false,
      subtitleFontSize: '12',
      showSubtitle: true,
      startFromZero: false,
      lazy: true,
    }),
    MatExpansionModule,
    MatSelectCountryComponent,
  ],
  exports: [DeviceButtonComponent],
  bootstrap: [DashboardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },
  ],
})
export class HomeModule {}
