<div>
      <app-help-widget
      [currentURL]="currentUrl"
      [userId]="userId"
      [projectID]="projectId"
      [Url] = "Url"
      >
    </app-help-widget>
    <div class="mx-4">
      <nav class="mx-4 mt-3" aria-label="breadcrumb">
        <ol
          style="height: 50px; width: 52px"
          class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center"
        >
          @defer{ @if (estAlloue(this.selectedCountry)) {
          <span class="mx-1">
            <mat-icon [svgIcon]="getCountryCode(this.selectedCountry)"></mat-icon>
          </span>
          }@else{
          <mat-icon class="mx-1">language</mat-icon>
          } }
          <li>
            <span class="fw-bold">RUM > </span>
          </li>
          <li class="breadcrumb-item">
            <a
              class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center"
              [routerLink]="overViewPage()"
            >
              <mat-icon class="mx-2">desktop_windows</mat-icon>
              <span>{{ siteTitle }} Overview</span>
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ metric | uppercase }}
          </li>
          <li>
            @if(sharedMode) {
            <span>-</span>
            <span style="color: coral"> (Shared)</span>
            }
          </li>
        </ol>
      </nav>
    </div>

  <div *ngIf="loading" class="d-flex justify-content-center align-items-center m-4" style="height: -1000px">
    <mat-spinner class="spinner" diameter="150"></mat-spinner>
  </div>
  <div class="d-flex justify-content-center align-items-center w-100 mt-5">
    <div class="w-75">
        <div *ngIf="!loading"  class="d-flex justify-content-start align-items-center">
          <button class="btn btn-primary"[matMenuTriggerFor]="menu" #menuContacts="matMenuTrigger" >
            <div class="d-flex justify-content-center align-items-center w-100">
              <mat-icon>filter_list</mat-icon>
              <span>Filter</span>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
          </button>
          <mat-menu #menu="matMenu">
              <section mat-menu-item class="example-section">
                <mat-form-field appearance="outline" style=" margin-bottom: -10px; margin-top: 10px;" >
                  <mat-label>Select Dimension</mat-label>
                  <mat-select [value]="selectedDimension" (click)="$event.stopPropagation();$event.preventDefault" (selectionChange)="closeMe(menuContacts)">
                    <mat-optgroup [label]="'Select Dimension'">
                      <mat-option [value]="'all'" (click)="setDimension('all', 'all')">
                        All
                      </mat-option>
                    </mat-optgroup>
                    <mat-optgroup
                      *ngFor="let group of dimensionGroups?.[device]"
                      [label]="group.name"
                    >
                      <mat-option
                        *ngFor="let dimension of group.dimensions"
                        (click)="setDimension(dimension, group.id)"
                        [value]="dimension"
                        ><p *ngIf="dimension === selectedDimension">
                          {{ dimension }} ({{ group.id }})
                        </p>
                        <p *ngIf="dimension !== selectedDimension">
                          {{ dimension }}
                        </p>
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </section>
              <div mat-menu-item>
                <mat-form-field appearance="outline" style=" margin-bottom: -10px; margin-top: 10px;">
                  <mat-label>Select Location</mat-label>
                  <mat-select [value]="selectedCountry" (click)="$event.stopPropagation();$event.preventDefault" (selectionChange)="closeMe(menuContacts)">
                    <mat-option [value]="'Global'" (click)="onCountrySelect('Global')"
                      >Global</mat-option
                    >
                    @for(country of monitoringLocations; track country) {
                    <mat-option [value]="country" (click)="onCountrySelect(country)">
                      {{ country }}
                    </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
          </mat-menu>
          <div style="display: flex; gap: 10px; align-items: center; width: 100%;">
            <div style="font-weight: bold" class="mx-2">Filters:</div>
            <div><mat-chip>Location: {{selectedCountry}}</mat-chip></div>
            <div>
              <mat-chip>
                @if(selectedDimGroup == 'all') {
                  Dimension
                }
                @if(selectedDimGroup !== 'all') {
                  {{ selectedDimGroup | slice:0:1 | uppercase }}{{ selectedDimGroup | slice:1 }}
                }
                : {{ selectedDimension | slice:0:1 | uppercase }}{{ selectedDimension | slice:1 }}
              </mat-chip></div>
              @if(selectedDimGroup !== 'all' || selectedCountry !== 'Global') {
                <mat-icon matTooltip="Reset filters" style="cursor: pointer;" (click)="clearCurrentFilters()">cancel</mat-icon>
              }
          </div>
        </div>
        <div *ngIf="!loading">
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div class="radio-inputs mb-1">
              <label class="radio" *ngFor="let deviceT of deviceTypes">
                <input
                  [value]="deviceT"
                  type="radio"
                  name="deviceRadio"
                  [(ngModel)]="device"
                  [checked]="device === deviceT"
                  (change)="setDevice(deviceT)"
                />
                <span class="name"> {{ deviceT | uppercase }}</span>
              </label>
            </div>
            <div class="radio-inputs mb-1">
              <label class="radio">
                <input
                  type="radio"
                  name="periodRadio"
                  value="monthly"
                  [(ngModel)]="queryPeriod"
                  (change)="setQueryPeriod($event)"
                />
                <span class="name">monthly</span>
              </label>
              <label class="radio">
                <input
                  type="radio"
                  name="periodRadio"
                  value="weekly"
                  [(ngModel)]="queryPeriod"
                  (change)="setQueryPeriod($event)"
                />
                <span class="name">weekly</span>
              </label>
              <label class="radio">
                <input
                  type="radio"
                  name="periodRadio"
                  value="daily"
                  [(ngModel)]="queryPeriod"
                  (change)="setQueryPeriod($event)"
                />
                <span class="name">daily</span>
              </label>
            </div>
          </div>

          <div *ngIf="!loading"  class="graph p-2 mt-3">
            <div class="container my-4">
                  <div class="row align-items-center">
                    <!-- Titre centré -->
                    <div class="col text-center">
                      <h2 class="m-0">
                        Domain-Level <b>{{ metric | uppercase }}</b> evolution
                      </h2>
                      <small class="d-block mt-1">
                        <i class="bi bi-speedometer2 me-2"></i>
                        {{ metric | uppercase }} 75th percentile:
                        <span class="fw-bolder text-primary">{{p75value}}{{ metric === 'cls' ? '' : 'ms' }}</span>

                        <mat-icon
                          style="font-size: 18px; opacity: 0.8; vertical-align: middle;"
                          [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                          matTooltipPosition="left">
                          help
                        </mat-icon>
                      </small>
                    </div>
                  </div>


                  <!-- Boutons d'actions -->
                  <div class="row my-3 justify-content-center">
                    <div class="col-auto">
                      <button (click)="openDialog()" class="btn btn-sm btn-outline-primary d-flex align-items-center">
                        <mat-icon>timeline</mat-icon>
                        <span class="ml-2">Show 75th percentile progress</span>
                      </button>
                    </div>
                    @if((metric === 'lcp') || (metric === 'ttfb')) {
                    <div class="col-auto">
                      <button (click)="openDialogSubParts()" class="btn btn-sm btn-outline-primary d-flex align-items-center">
                        <mat-icon>timeline</mat-icon>
                        <span class="ml-2">Show 75th Sub Parts progress</span>
                      </button>
                    </div>
                  }
                  </div>
                  @if((metric === 'lcp') || (metric === 'ttfb')) {
                    @if(isAllP75Empty()){
                      <div class="alert alert-warning text-center" role="alert">
                        All 4 sub-attributes are either not available or equal to 0. No data to display.
                      </div>

                    }@else {
                      <!-- Graphique -->
                      <div class="mb-3">
                        <apx-chart

                          [series]="p75subPartsLastValue.series"
                          [chart]="p75subPartsLastValue.chart"
                          [dataLabels]="p75subPartsLastValue.dataLabels"
                          [plotOptions]="p75subPartsLastValue.plotOptions"
                          [responsive]="p75subPartsLastValue.responsive"
                          [xaxis]="p75subPartsLastValue.xaxis"
                          [yaxis]="p75subPartsLastValue.yaxis"
                          [legend]="p75subPartsLastValue.legend"
                          [fill]="p75subPartsLastValue.fill"
                          [grid]="p75subPartsLastValue.grid"
                          ></apx-chart>

                      </div>
                    }
                }
                  <!-- Informations complémentaires -->
                  <div *ngIf="queryPeriod === 'daily' && startDate" class="mb-3 text-center text-muted">
                    <p class="mb-0"><strong>Days range selected:</strong> {{ startDate | date }} to {{ endDate | date }}</p>
                  </div>

                  <div class="d-flex justify-content-end  align-items-center">
                    <div style="display: flex; align-items: end">
                      @if(queryPeriod === 'daily') {
                      <mat-select [(ngModel)]="daysRangeSelected" class="chart-select rounded" (selectionChange)="executeSelectionChange()">
                        <mat-option value="7">7 Days</mat-option>
                        <mat-option value="30">30 Days</mat-option>
                        <mat-option value="60">60 Days</mat-option>
                        <mat-option value="90">90 Days</mat-option>
                        <mat-option value="custom" (click)="openDaysSelectorDialog()">
                          Custom range
                        </mat-option>
                      </mat-select>
                      }
                    </div>
                  </div>
            </div>

            @if(queryPeriod === 'daily') {
            <div style="display: flex; margin-left: 8%">
              <mat-chip-listbox
                *ngIf="!!barchartOvertime && barchartOvertime?.dateGroups?.length > 1"
                aria-label="Days selection"
              >
                <mat-chip-option
                  style="cursor: pointer"
                  *ngFor="let day of barchartOvertime.dateGroups"
                  [selected]="selectedDatesRange === day"
                  (click)="setSelectedDatesRange(day)"
                >
                  {{ day }}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            }
            <div
              *ngIf="!loading && !!chartOptionsFPD"
              class="m-3"
            >
              <apx-chart
                [series]="chartOptionsFPD.series"
                [chart]="chartOptionsFPD.chart"
                [dataLabels]="chartOptionsFPD.dataLabels"
                [plotOptions]="chartOptionsFPD.plotOptions"
                [responsive]="chartOptionsFPD.responsive"
                [xaxis]="chartOptionsFPD.xaxis"
                [yaxis]="chartOptionsFPD.yaxis"
                [legend]="chartOptionsFPD.legend"
                [fill]="chartOptionsFPD.fill"
                [grid]="chartOptionsFPD.grid"
                ></apx-chart
              >
            </div>
            <div
              *ngIf="!loading && !!barchartOvertime && queryPeriod === 'daily'"
              class="mt-3"
            >
              <apx-chart
                [series]="barchartOvertime[selectedDatesRange].series"
                [chart]="barchartOvertime[selectedDatesRange].chart"
                [dataLabels]="barchartOvertime[selectedDatesRange].dataLabels"
                [plotOptions]="barchartOvertime[selectedDatesRange].plotOptions"
                [responsive]="barchartOvertime[selectedDatesRange].responsive"
                [xaxis]="barchartOvertime[selectedDatesRange].xaxis"
                [yaxis]="barchartOvertime[selectedDatesRange].yaxis"
                [legend]="barchartOvertime[selectedDatesRange].legend"
                [fill]="barchartOvertime[selectedDatesRange].fill"
                [grid]="barchartOvertime[selectedDatesRange].grid"
                >></apx-chart
              >
            </div>
          </div>
          @defer(on timer(2s)) {
          <app-pages-by-performance
            [metric]="metric"
            [userId]="userId"
            [queryPeriod]="queryPeriod"
            [sharedMode]="sharedMode"
            [projectId]="projectId"
            [selectedCountry]="selectedCountry"
            [device]="device"
            [selectedDimGroup]="selectedDimGroup"
            [selectedDimension]="selectedDimension"
            [attributionData]="attributionData"
          ></app-pages-by-performance>
          } @placeholder {
          <div class="container d-flex justify-content-center align-items-center">
            <div class="loading m-3">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          }
        </div>
    </div>
  </div>


</div>
