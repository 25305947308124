
<app-help-widget
  [currentURL]="currentUrl"
  [userId]="userId"
  [projectID]="projectID"
  [Url] = "Url"
  >
</app-help-widget>
<nav class="mx-4 mt-3" aria-label="breadcrumb">
  <ol style="height: 50px; width: 52px;" class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center">
    @defer{
      @if (estAlloue(Country)) {
        <span class="mx-1">
            <mat-icon [svgIcon]="Country"></mat-icon>
        </span>
       }@else{
        <mat-icon  class="mx-1">language</mat-icon>
       }
    }
    <li>
      <span class="fw-bold">CRUX > </span>
    </li>
    <li class="breadcrumb-item">
      <a class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center " [href]="'/home/page/' + this.senderVarService.id + '/overview'">
        <mat-icon class="mx-2 ">desktop_windows</mat-icon>
       <span>{{title}} Overview</span>
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      Nav-type
    </li>
  </ol>
</nav>
@if (loading) {
  <div class="d" style="height: -1000px">
    <mat-spinner class="spinner" diameter="150"></mat-spinner>
  </div>
}

@if(!loading){
  <div class="text-center">
    <h1 class="">Domain-level Navigation types evolution</h1>
    <div>
      <span class="text-muted small ms-3 ps-3  mt-2 ">
        See how your domain-aggregated Navigation types are evolving
      over time
      </span>

      <button class="cta flex-grow-1" (click)="openLink()">
        <span class="hover-underline-animation">What are Navigation types?</span>
        <svg viewBox="0 0 46 16" height="10" width="30" xmlns="http://www.w3.org/2000/svg" id="arrow-horizontal">
          <path transform="translate(30)" d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z" data-name="Path 10" id="Path_10"></path>
        </svg>
      </button>
    </div>
  </div>
 <div class="d-flex justify-content-center align-items-center w-100">
      <div class="graph m-4 w-75">
      <div class="m-3 d-flex justify-content-between align-items-center">
        <app-device-button (deviceEvent)="setDevice($event)"></app-device-button>
      </div>
      <div class="domain-level m-4 p-2">
        <div id="lcpOverTime">
          <apx-chart
          [series]="ChartOptionsNavType?.series"
          [chart]="ChartOptionsNavType?.chart"
          [dataLabels]="ChartOptionsNavType?.dataLabels"
          [plotOptions]="ChartOptionsNavType?.plotOptions"
          [responsive]="ChartOptionsNavType?.responsive"
          [xaxis]="ChartOptionsNavType?.xaxis"
          [yaxis]="ChartOptionsNavType?.yaxis"
          [legend]="ChartOptionsNavType?.legend"
          [fill]="ChartOptionsNavType?.fill"
          [annotations]="ChartOptionsNavType?.annotations"
          [grid]="ChartOptionsNavType?.grid">>
        </apx-chart >
        </div>
      </div>
    </div>
 </div>

}

