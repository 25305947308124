<app-layout>
    <div class="d-flex flex-column flex-lg-row justify-content-center align-items-center flex-wrap m-4">
      <!-- Première section -->
      <div class="graph col-12 col-lg-6 d-flex flex-column justify-content-between align-items-start p-3">
        <div class="text-section w-100">
          <h3 class="fw-bold text-dark" style="font-size: 1.5rem;">
            RUM Data
          </h3>
          <p class="text-muted" style="font-size: 1rem;">
            Analyze your website's performance with Real User Monitoring to better understand what your users are facing and optimize what matters.
          </p>
        </div>
      </div>

      <!-- Deuxième section -->
      <div class="graph header-banner col-12 col-lg-6 d-flex flex-column justify-content-between align-items-start p-3">
        <div class="text-section w-100 mb-3">
          <h3 class="fw-bold text-dark" style="font-size: 1.5rem;">
            How to use Speetals
          </h3>
          <p class="text-muted" style="font-size: 1rem;">
            Watch this short video to learn how to interpret the data and make the most of it.
          </p>
        </div>
        <div class="video-section d-flex justify-content-center">
          <button type="button" class="btn btn-primary d-flex align-items-center" (click)="openVideoModal()">
            <i class="material-icons me-2">play_circle</i>
            Watch Video
          </button>
        </div>
      </div>

      <!-- Modale -->
      <div class="modal-backdrop" *ngIf="isModalOpen" style="background-color: rgba(0, 0, 0, 0.295);">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <iframe
                *ngIf="videoUrl"
                id="videoIframe"
                width="800"
                height="400"
                [src]="videoUrl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="rounded-3"
              ></iframe>
            </div>
            <div class="modal-header d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-danger"
                aria-label="Close"
                (click)="closeVideoModal()"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-4">
      @if (!userHasRum) {
      <div class="notifications-container m-4">
        <div class="alert m-0">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 alert-svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div class="alert-prompt-wrap">
              <p class="text-sm text-yellow-700">
                Your current plan does not have RUM features. You can only see
                <b>shared</b> projects with you and make it appear in the list
                of websites below. For RUM Monitoring
                <a
                  class="alert-prompt-link"
                  [routerLink]="'/buyPlan'"
                  target="_blank"
                  >Upgrade now</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      }
        @if(!isValidated){
          <div class="d-flex justify-content-center align-items-center">
            <app-check-list
              class="w-md-50"
              [noSitesCreated]="noSitesCreated"
              [hasCreatedWebsite]="SitesCreated"
              [hasSharedWebsite]="hasSharedSites"
              [hasRUM]="userHasRum"
              [areWeReceivingData]="areWeReceivingData"
              [isRUM]="isCRUX"
              [checklistId]="checklistId"

            ></app-check-list>
          </div>
        }
        <div class="w-100 mt-4 d-flex">
          <div class="large-screen d-flex flex-column graph m-3 flex-shrink-1 d-none d-lg-block w-auto p-2" style="max-width: 200px;">
            <div>
              <h4 class="text-secondary mb-2">Search</h4>
              <div class="d-flex justify-content-center align-items-center my-2 w-100">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="group mx-2">
                    <svg viewBox="0 0 24 24" aria-hidden="true" class="icon">
                      <g>
                        <path
                          d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                        ></path>
                      </g>
                    </svg>
                    <input
                      [value]="searchKeyword"
                      class="input"
                      type="search"
                      placeholder="Search"
                      (keyup)="onSearchInput($event)"
                    />
                  </div>
                </div>

              </div>
            </div>

            <!-- Device Selection Section -->
            <div class="mb-3">
              <h4 class="text-secondary mb-2">Device Selection</h4>
              <div class="d-flex justify-content-center w-100">
                <div class="radio-inputs mb-1">
                  <label class="radio" *ngFor="let deviceT of deviceTypes">
                    <input
                      [value]="deviceT"
                      type="radio"
                      name="deviceRadio"
                      [(ngModel)]="device"
                      [checked]="device === deviceT"
                      (change)="setDevice(deviceT)"
                    />
                    <span class="name"> {{ deviceT | titlecase }}</span>
                  </label>
                </div>
              </div>
            </div>

            <!-- Period Selection Section -->
            <div class="mb-3">
              <h4 class="text-secondary mb-2">Period Selection</h4>
              <div class="d-flex justify-content-center w-100">
                    <div class="radio-inputs mb-1">
                  <label class="radio">
                    <input type="radio" name="periodRadio" value="monthly" [(ngModel)]="queryPeriod" (change)="setQueryPeriod($event)" />
                    <span class="name">monthly</span>
                  </label>
                  <label class="radio">
                    <input type="radio" name="periodRadio" value="weekly" [(ngModel)]="queryPeriod" (change)="setQueryPeriod($event)" />
                    <span class="name">weekly</span>
                  </label>
                  <label class="radio">
                    <input type="radio" name="periodRadio" value="daily" [(ngModel)]="queryPeriod" (change)="setQueryPeriod($event)" />
                    <span class="name">daily</span>
                  </label>
                </div>
              </div>

            </div>
              <!-- Filters Section -->
              <div class="mb-3">
                <h4 class="text-secondary mb-2">Filters</h4>
                <div class="d-flex flex-column justify-content-between align-items-center mx-0">
                    <!-- Dimension Filter -->
                    <button mat-menu-item style="width: 100%; padding: 0;">
                    <mat-form-field appearance="outline" class="w-100 m-1" style="font-size: 0.85rem; line-height: 1.2; max-width: 180px;">
                      <mat-label>Select Dimension</mat-label>
                      <mat-select
                        [value]="selectedDimension"
                        (selectionChange)="closeMe(menuContacts)"
                        panelClass="small-select-panel"
                      >
                        <mat-optgroup [label]="'Select Dimension'">
                          <mat-option
                            [value]="'all'"
                            (click)="setDimension('all', 'all')"
                          >
                            All
                          </mat-option>
                        </mat-optgroup>
                        <mat-optgroup
                          *ngFor="let group of dimensionGroups?.[device]"
                          [label]="group.name"
                        >
                          <mat-option
                            *ngFor="let dimension of group.dimensions"
                            (click)="setDimension(dimension, group.id)"
                            [value]="dimension"
                          >
                            <p *ngIf="dimension === selectedDimension">
                              {{ dimension }} ({{ group.id }})
                            </p>
                            <p *ngIf="dimension !== selectedDimension">
                              {{ dimension }}
                            </p>
                          </mat-option>
                        </mat-optgroup>
                      </mat-select>
                    </mat-form-field>
                  </button>

                  <!-- Location Filter -->
                  <button mat-menu-item style="width: 100%; padding: 0;">
                    <mat-form-field appearance="outline" class="w-100 m-1" style="font-size: 0.85rem; line-height: 1.2; max-width: 180px;">
                      <mat-label>Select Location</mat-label>
                      <mat-select
                        [value]="selectedCountry"
                        (selectionChange)="closeMe(menuContacts)"
                        panelClass="small-select-panel"
                      >
                        <mat-option [value]="'All'" (click)="onCountrySelect('All')">
                          All
                        </mat-option>
                        <mat-option [value]="'Global'" (click)="onCountrySelect('Global')">
                          Global
                        </mat-option>
                        <mat-option *ngFor="let country of monitoringLocations" [value]="country" (click)="onCountrySelect(country)">
                          {{ country }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </button>
                </div>
                
                <!-- Filter Chips - Moved to separate div with more space -->
                <div class="d-flex flex-wrap align-items-center mt-2" style="font-size: 0.75rem; max-width: 180px;">
                  <div class="me-1 mb-1">
                    <mat-chip size="small" style="height: 22px; font-size: 0.75rem; padding: 0 6px;">{{ selectedCountry }}</mat-chip>
                  </div>
                  <div class="me-1 mb-1">
                    <mat-chip size="small" style="height: 22px; font-size: 0.75rem; padding: 0 6px;">
                      @if(selectedDimGroup == 'all') { Dim }
                      @if(selectedDimGroup !== 'all') {
                      {{ selectedDimGroup | slice : 0 : 1 | uppercase }}:
                      {{ selectedDimension | slice : 0 : 3 }}
                      }
                    </mat-chip>
                  </div>
                  @if(selectedDimGroup !== 'all' || selectedCountry !== 'All') {
                  <mat-icon
                    matTooltip="Reset filters"
                    style="cursor: pointer; font-size: 16px;"
                    (click)="clearCurrentFilters()"
                    >cancel</mat-icon
                  >
                  }
                </div>
              </div>
            <!-- Shared Section -->
            <div class="mb-3">
              <h4 class="text-secondary mb-2">Visibility</h4>
              <div class="d-flex justify-content-center w-100">
                <div class="button1 d-flex justify-content-center align-items-center rounded-3" style="padding-right: 5px; width: 150px; height: 40px">
                  <input
                    checked="true"
                    type="checkbox"
                    class="switch mx-1"
                    (change)="onChangeSlideShared($event)"
                    [(ngModel)]="showShared"
                  />
                  <span class="text-dark shared-text text-center">Shared with me</span>
                </div>
              </div>
              
              <!-- All Metrics Toggle -->
              <div class="d-flex justify-content-center w-100 mt-2">
                <div class="button1 d-flex justify-content-center align-items-center rounded-3" style="padding-right: 5px; width: 150px; height: 40px">
                  <input
                    type="checkbox"
                    class="switch mx-1"
                    (change)="onToggleAllMetrics($event)"
                    [(ngModel)]="showAllMetrics"
                  />
                  <span class="text-dark shared-text text-center">Show all metrics</span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column p-0 w-75 flex-fill" style="min-width: 0;">
            <div class="medium-screen d-lg-none d-md-block d-none ">
              <div class="mx-4 mt-3 d-flex justify-content-between align-items-center">
                  <div class="group">
                    <svg viewBox="0 0 24 24" aria-hidden="true" class="icon">
                      <g>
                        <path
                          d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                        ></path>
                      </g>
                    </svg>
                    <input
                      [value]="searchKeyword"
                      class="input"
                      type="search"
                      placeholder="Search"
                      (keyup)="onSearchInput($event)"
                    />
                  </div>
                  <div class="d-flex align-items-center mx-2">
                    <button
                      class="btn btn-primary"
                      [matMenuTriggerFor]="menu"
                      #menuContacts="matMenuTrigger"
                    >
                      <div class="d-flex justify-content-center align-items-center w-100">
                        <mat-icon>filter_list</mat-icon>
                        <span>Filter</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </div>
                    </button>
                    <mat-menu #menu="matMenu">
                      <section mat-menu-item class="example-section">
                        <mat-form-field
                          appearance="outline"
                          style="margin-bottom: -10px; margin-top: 10px"
                        >
                          <mat-label>Select Dimension</mat-label>
                          <mat-select
                            [value]="selectedDimension"
                            (click)="$event.stopPropagation(); ($event.preventDefault)"
                            (selectionChange)="closeMe(menuContacts)"
                          >
                            <mat-optgroup [label]="'Select Dimension'">
                              <mat-option
                                [value]="'all'"
                                (click)="setDimension('all', 'all')"
                              >
                                All
                              </mat-option>
                            </mat-optgroup>
                            <mat-optgroup
                              *ngFor="let group of dimensionGroups?.[device]"
                              [label]="group.name"
                            >
                              <mat-option
                                *ngFor="let dimension of group.dimensions"
                                (click)="setDimension(dimension, group.id)"
                                [value]="dimension"
                              >
                                <p *ngIf="dimension === selectedDimension">
                                  {{ dimension }} ({{ group.id }})
                                </p>
                                <p *ngIf="dimension !== selectedDimension">
                                  {{ dimension }}
                                </p>
                              </mat-option>
                            </mat-optgroup>
                          </mat-select>
                        </mat-form-field>
                      </section>
                      <div mat-menu-item>
                        <mat-form-field
                          appearance="outline"
                          style="margin-bottom: -10px; margin-top: 10px"
                        >
                          <mat-label>Select Location</mat-label>
                          <mat-select
                            [value]="selectedCountry"
                            (click)="$event.stopPropagation(); ($event.preventDefault)"
                            (selectionChange)="closeMe(menuContacts)"
                          >
                            <mat-option [value]="'All'" (click)="onCountrySelect('All')"
                              >All</mat-option
                            >
                            <mat-option
                              [value]="'Global'"
                              (click)="onCountrySelect('Global')"
                              >Global</mat-option
                            >
                            @for(country of monitoringLocations; track country) {
                            <mat-option
                              [value]="country"
                              (click)="onCountrySelect(country)"
                            >
                              {{ country }}
                            </mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </mat-menu>
                    <div
                      style="
                        margin-left: 2%;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        width: 100%;
                      "
                    >
                      <div style="font-weight: bold">Filters:</div>
                      <div class="d-flex flex-wrap align-items-center" style="font-size: 0.75rem;">
                        <div class="me-1 mb-1">
                          <mat-chip size="small" style="height: 22px; font-size: 0.75rem; padding: 0 6px;">{{ selectedCountry }}</mat-chip>
                        </div>
                        <div class="me-1 mb-1">
                          <mat-chip size="small" style="height: 22px; font-size: 0.75rem; padding: 0 6px;">
                            @if(selectedDimGroup == 'all') { Dim }
                            @if(selectedDimGroup !== 'all') {
                            {{ selectedDimGroup | slice : 0 : 1 | uppercase }}:
                            {{ selectedDimension | slice : 0 : 3 }}
                            }
                          </mat-chip>
                        </div>
                        @if(selectedDimGroup !== 'all' || selectedCountry !== 'All') {
                        <mat-icon
                          matTooltip="Reset filters"
                          style="cursor: pointer; font-size: 16px;"
                          (click)="clearCurrentFilters()"
                          >cancel</mat-icon
                        >
                        }
                      </div>
                    </div>
                  </div>
              </div>
              <div class="d-flex justify-content-between align-items-center mx-4 my-3">
                  <div class="button1 d-flex justify-content-center align-items-center rounded-3" style="padding-right: 5px; max-width: 130px; height: 40px" >
                    <input
                      checked="true"
                      type="checkbox"
                      class="switch mx-1"
                      (change)="onChangeSlideShared($event)"
                      [(ngModel)]="showShared"
                    />
                    <span class="text-dark shared-text text-center"
                      >Shared</span
                    >
                  </div>
                  
                  <!-- All Metrics Toggle for medium screen -->
                  <div class="button1 d-flex justify-content-center align-items-center rounded-3" style="padding-right: 5px; max-width: 150px; height: 40px">
                    <input
                      type="checkbox"
                      class="switch mx-1"
                      (change)="onToggleAllMetrics($event)"
                      [(ngModel)]="showAllMetrics"
                    />
                    <span class="text-dark shared-text text-center">All metrics</span>
                  </div>
                  
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="radio-inputs mb-1 mx-2">
                      <label class="radio" *ngFor="let deviceT of deviceTypes">
                        <input
                          [value]="deviceT"
                          type="radio"
                          name="deviceRadio1"
                          [(ngModel)]="device"
                          [checked]="device === deviceT"
                          (change)="setDevice(deviceT)"
                        />
                        <span class="name"> {{ deviceT | titlecase }}</span>
                      </label>
                    </div>
                    <div class="radio-inputs mb-1">
                      <label class="radio">
                        <input
                          type="radio"
                          name="periodRadi1"
                          value="monthly"
                          [(ngModel)]="queryPeriod"
                          (change)="setQueryPeriod($event)"
                        />
                        <span class="name">monthly</span>
                      </label>
                      <label class="radio">
                        <input
                          type="radio"
                          name="periodRadi1"
                          value="weekly"
                          [(ngModel)]="queryPeriod"
                          (change)="setQueryPeriod($event)"
                        />
                        <span class="name">weekly</span>
                      </label>
                      <label class="radio">
                        <input
                          type="radio"
                          name="periodRadi1"
                          value="daily"
                          [(ngModel)]="queryPeriod"
                          (change)="setQueryPeriod($event)"
                        />
                        <span class="name">daily</span>
                      </label>
                    </div>
                  </div>
              </div>
            </div>
            <div class="small-screen d-block d-md-none d-flex justify-content-between align-items-center">
              <div>
                <!-- Bouton déclencheur du mat-menu -->
                <button class="btn btn-primary" [matMenuTriggerFor]="menuFilter">
                  <div class="d-flex justify-content-center align-items-center w-100">
                    <mat-icon>filter_list</mat-icon>
                    <span>Filters</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </div>
                </button>
                <!-- Contenu du mat-menu -->
                <mat-menu #menuFilter="matMenu">
                    <!-- Dimension Filter -->
                    <button mat-menu-item style="width: 100%; padding: 0 8px;">
                      <mat-form-field appearance="outline" class="w-100" style="font-size: 0.85rem; line-height: 1.2;">
                        <mat-label>Select Dimension</mat-label>
                        <mat-select
                          [value]="selectedDimension"
                          (selectionChange)="closeMe(menuContacts)"
                          panelClass="small-select-panel"
                        >
                          <mat-optgroup [label]="'Select Dimension'">
                            <mat-option
                              [value]="'all'"
                              (click)="setDimension('all', 'all')"
                            >
                              All
                            </mat-option>
                          </mat-optgroup>
                          <mat-optgroup
                            *ngFor="let group of dimensionGroups?.[device]"
                            [label]="group.name"
                          >
                            <mat-option
                              *ngFor="let dimension of group.dimensions"
                              (click)="setDimension(dimension, group.id)"
                              [value]="dimension"
                            >
                              <p *ngIf="dimension === selectedDimension">
                                {{ dimension }} ({{ group.id }})
                              </p>
                              <p *ngIf="dimension !== selectedDimension">
                                {{ dimension }}
                              </p>
                            </mat-option>
                          </mat-optgroup>
                        </mat-select>
                      </mat-form-field>
                    </button>

                    <!-- Location Filter -->
                    <button mat-menu-item style="width: 100%; padding: 0 8px;">
                      <mat-form-field appearance="outline" class="w-100" style="font-size: 0.85rem; line-height: 1.2;">
                        <mat-label>Select Location</mat-label>
                        <mat-select
                          [value]="selectedCountry"
                          (selectionChange)="closeMe(menuContacts)"
                          panelClass="small-select-panel"
                        >
                          <mat-option [value]="'All'" (click)="onCountrySelect('All')">
                            All
                          </mat-option>
                          <mat-option [value]="'Global'" (click)="onCountrySelect('Global')">
                            Global
                          </mat-option>
                          <mat-option *ngFor="let country of monitoringLocations" [value]="country" (click)="onCountrySelect(country)">
                            {{ country }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </button>

                    <!-- Shared Checkbox -->
                    <button mat-menu-item>
                      <div
                        class="button1 d-flex justify-content-center align-items-center rounded-3 m-2"
                        style="padding-right: 5px; max-width: 130px; height: 40px"
                      >
                        <input
                          checked="true"
                          type="checkbox"
                          class="switch mx-1"
                          (change)="onChangeSlideShared($event)"
                          [(ngModel)]="showShared"
                        />
                        <span class="text-dark shared-text text-center">Shared</span>
                      </div>
                    </button>
                    
                    <!-- All Metrics Toggle for small screen -->
                    <button mat-menu-item>
                      <div
                        class="button1 d-flex justify-content-center align-items-center rounded-3 m-2"
                        style="padding-right: 5px; max-width: 150px; height: 40px"
                      >
                        <input
                          type="checkbox"
                          class="switch mx-1"
                          (change)="onToggleAllMetrics($event)"
                          [(ngModel)]="showAllMetrics"
                        />
                        <span class="text-dark shared-text text-center">All metrics</span>
                      </div>
                    </button>

                    <!-- Device Radio Buttons -->
                    <button mat-menu-item>
                      <div class="radio-inputs m-2">
                        <label class="radio" *ngFor="let deviceT of deviceTypes">
                          <input
                            [value]="deviceT"
                            type="radio"
                            name="deviceRadio1"
                            [(ngModel)]="device"
                            [checked]="device === deviceT"
                            (change)="setDevice(deviceT)"
                          />
                          <span class="name">{{ deviceT | titlecase }}</span>
                        </label>
                      </div>
                    </button>

                    <!-- Period Radio Buttons -->
                    <button mat-menu-item>
                      <div class="radio-inputs m-2">
                        <label class="radio">
                          <input
                            type="radio"
                            name="periodRadi1"
                            value="monthly"
                            [(ngModel)]="queryPeriod"
                            (change)="setQueryPeriod($event)"
                          />
                          <span class="name">Monthly</span>
                        </label>
                        <label class="radio">
                          <input
                            type="radio"
                            name="periodRadi1"
                            value="weekly"
                            [(ngModel)]="queryPeriod"
                            (change)="setQueryPeriod($event)"
                          />
                          <span class="name">Weekly</span>
                        </label>
                        <label class="radio">
                          <input
                            type="radio"
                            name="periodRadi1"
                            value="daily"
                            [(ngModel)]="queryPeriod"
                            (change)="setQueryPeriod($event)"
                          />
                          <span class="name">Daily</span>
                        </label>
                      </div>
                    </button>
                  </mat-menu>
              </div>
              <button mat-menu-item>
                  <div class="p-2">
                    <div class="group">
                      <svg viewBox="0 0 24 24" aria-hidden="true" class="icon">
                        <g>
                          <path
                            d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                          ></path>
                        </g>
                      </svg>
                      <input
                        [value]="searchKeyword"
                        class="input"
                        type="search"
                        placeholder="Search"
                        (keyup)="onSearchInput($event)"
                      />
                    </div>
                  </div>
              </button>
            </div>
            <mat-card appearance="outline" class="graph">
              <mat-card-content>
                <div
                  *ngIf="loading"
                  class="d-flex justify-content-center align-items-center"
                  style="height: -1000px"
                >
                  <mat-spinner class="spinner" diameter="150"></mat-spinner>
                </div>
                @if(!loading && !!apiResponseMessage && !currentComputedData?.length && !addedToday){
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="m-4 fs-5">{{ apiResponseMessage }}</div>
                  </div>
                }@else if(!loading && !!currentComputedData?.length){
                  <div>
                    <!-- Header row with column titles -->
                    <mat-grid-list [cols]="showAllMetrics ? 6 : 4" rowHeight="50px">
                      <mat-grid-tile><b>Website</b></mat-grid-tile>
                      <!-- Show TTFB and FCP only when showAllMetrics is true -->
                      <mat-grid-tile *ngIf="showAllMetrics"><b>TTFB</b></mat-grid-tile>
                      <mat-grid-tile *ngIf="showAllMetrics"><b>FCP</b></mat-grid-tile>
                      <mat-grid-tile><b>LCP</b></mat-grid-tile>
                      <mat-grid-tile><b>CLS</b></mat-grid-tile>
                      <mat-grid-tile><b>INP</b></mat-grid-tile>
                    </mat-grid-list>
                    <mat-divider></mat-divider>
                    <!-- Data rows -->
                    <mat-grid-list
                      *ngFor="let entry of currentComputedData"
                      [cols]="showAllMetrics ? 6 : 4"
                      rowHeight="250px"
                    >
                      <mat-grid-tile>
                        <!-- Website info tile content remains the same -->
                        <div appearance="outline" class="p-3">
                          <div
                            class="title d-flex justify-content-center align-items-center p-2"
                            #tooltip="matTooltip"
                            matTooltip="{{ entry.homeUrl }}"
                            matTooltipHideDelay="100"
                            matTooltipPosition="above"
                            aria-label="Button that displays a tooltip that hides when scrolled out of the container"
                          >
                            @if (!estAlloue(entry.location)) {
                            <mat-icon class="mx-1">language</mat-icon>
                            } @if (estAlloue(entry.location)) {
                            <span class="mx-1">
                              <mat-icon [svgIcon]="getCountryCode(entry.location)">
                              </mat-icon>
                            </span>
                            }
                            <P class="m-0 fs-5">{{ entry.title }}</P>
                            <i class="flag flag-france"></i>
                          </div>
                          <div
                            class="d-flex justify-content-center align-items-center p-0"
                          >
                            <button
                              class="custom-mat-button1 mx-1"
                              mat-button
                              #tooltip="matTooltip"
                              matTooltip="Access to Overview"
                              matTooltipHideDelay="100"
                              aria-label="Button that displays a tooltip that hides when scrolled out of the container"
                              (click)="goToOverview(entry)"
                            >
                              <mat-icon class="m-0">visibility</mat-icon>
                            </button>
                            <button
                              class="custom-mat-button1 mx-1"
                              [disabled]="showShared"
                              (click)="openDialog(entry)"
                              mat-button
                              #tooltip="matTooltip"
                              matTooltip="Share website with a team mate"
                              matTooltipHideDelay="100"
                              aria-label="Button that displays a tooltip that hides when scrolled out of the container"
                            >
                              <mat-icon class="m-0">share</mat-icon>
                            </button>
                          </div>
                        </div>
                      </mat-grid-tile>
                      
                      <!-- TTFB Metric (only shown when showAllMetrics is true) -->
                      <mat-grid-tile *ngIf="showAllMetrics" class="my-2">
                        <div *ngIf="!!!entry.ttfb" style="margin: 5%; width: 100%">
                          <h4 style="text-align: center">No TTFB data</h4>
                        </div>
                        <div
                          *ngIf="!!entry.ttfb"
                          class="d-flex flex-column justify-content-center align-items-center"
                        >
                          <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                            <span class="mx-1">
                            75th percentile: {{entry.ttfb.p75 | number : "1.2-2"}}
                            </span>
                          <mat-icon
                            style="font-size: 18px; opacity: 0.7;"
                            [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                            matTooltipPosition="left">
                            help
                          </mat-icon>
                        </div>
                          <apx-chart
                            [series]="entry.ttfb.series"
                            [chart]="entry.ttfb.chart"
                            [dataLabels]="entry.ttfb.dataLabels"
                            [plotOptions]="entry.ttfb.plotOptions"
                            [xaxis]="entry.ttfb.xaxis"
                            [yaxis]="entry.ttfb.yaxis"
                            [stroke]="entry.ttfb.stroke"
                            [fill]="entry.ttfb.fill"
                            [tooltip]="entry.ttfb.tooltip"
                            [legend]="entry.ttfb.legend"
                          >
                          </apx-chart>
                        </div>
                      </mat-grid-tile>
                      
                      <!-- FCP Metric (only shown when showAllMetrics is true) -->
                      <mat-grid-tile *ngIf="showAllMetrics" class="my-2">
                        <div *ngIf="!!!entry.fcp" style="margin: 5%; width: 100%">
                          <h4 style="text-align: center">No FCP data</h4>
                        </div>
                        <div
                          *ngIf="!!entry.fcp"
                          class="d-flex flex-column justify-content-center align-items-center"
                        >
                          <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                            <span class="mx-1">
                            75th percentile: {{entry.fcp.p75 | number : "1.2-2"}}
                            </span>
                          <mat-icon
                            style="font-size: 18px; opacity: 0.7;"
                            [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                            matTooltipPosition="left">
                            help
                          </mat-icon>
                        </div>
                          <apx-chart
                            [series]="entry.fcp.series"
                            [chart]="entry.fcp.chart"
                            [dataLabels]="entry.fcp.dataLabels"
                            [plotOptions]="entry.fcp.plotOptions"
                            [xaxis]="entry.fcp.xaxis"
                            [yaxis]="entry.fcp.yaxis"
                            [stroke]="entry.fcp.stroke"
                            [fill]="entry.fcp.fill"
                            [tooltip]="entry.fcp.tooltip"
                            [legend]="entry.fcp.legend"
                          >
                          </apx-chart>
                        </div>
                      </mat-grid-tile>
                      
                      <!-- LCP Metric (always shown) -->
                      <mat-grid-tile class="my-2">
                        <div *ngIf="!!!entry.lcp" style="margin: 5%; width: 100%">
                          <h4 style="text-align: center">No LCP data</h4>
                        </div>
                        <div
                          *ngIf="!!entry.lcp"
                          class="d-flex flex-column justify-content-center align-items-center"
                        >
                          <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                            <span class="mx-1">
                            75th percentile: {{entry.lcp.p75 | number : "1.2-2"}}
                            </span>
                          <mat-icon
                            style="font-size: 18px; opacity: 0.7;"
                            [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                            matTooltipPosition="left">
                            help
                          </mat-icon>
                        </div>
                          <apx-chart
                            [series]="entry.lcp.series"
                            [chart]="entry.lcp.chart"
                            [dataLabels]="entry.lcp.dataLabels"
                            [plotOptions]="entry.lcp.plotOptions"
                            [xaxis]="entry.lcp.xaxis"
                            [yaxis]="entry.lcp.yaxis"
                            [stroke]="entry.lcp.stroke"
                            [fill]="entry.lcp.fill"
                            [tooltip]="entry.lcp.tooltip"
                            [legend]="entry.lcp.legend"
                          >
                          </apx-chart>
                        </div>
                      </mat-grid-tile>
                      
                      <!-- CLS Metric (always shown) -->
                      <mat-grid-tile class="my-2">
                        <div *ngIf="!!!entry.cls" style="margin: 5%; width: 100%">
                          <h4 style="text-align: center">No CLS data</h4>
                        </div>
                        <div
                          *ngIf="!!entry.cls"
                          class="d-flex flex-column justify-content-center align-items-center"
                        >
                          <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                            <span class="mx-1">
                            75th percentile: {{entry.cls.p75 | number : "1.2-2"}}
                            </span>
                          <mat-icon
                            style="font-size: 18px; opacity: 0.7;"
                            [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                            matTooltipPosition="left">
                            help
                          </mat-icon>
                        </div>
                          <apx-chart
                            [series]="entry.cls.series"
                            [chart]="entry.cls.chart"
                            [dataLabels]="entry.cls.dataLabels"
                            [plotOptions]="entry.cls.plotOptions"
                            [xaxis]="entry.cls.xaxis"
                            [yaxis]="entry.cls.yaxis"
                            [stroke]="entry.cls.stroke"
                            [fill]="entry.cls.fill"
                            [tooltip]="entry.cls.tooltip"
                            [legend]="entry.cls.legend"
                          >
                          </apx-chart>
                        </div>
                      </mat-grid-tile>
                      
                      <!-- INP Metric (always shown) -->
                      <mat-grid-tile class="my-2">
                        <div *ngIf="!!!entry.inp" style="margin: 5%; width: 100%">
                          <h4 style="text-align: center">No INP data</h4>
                        </div>
                        <div
                          *ngIf="!!entry.inp"
                          class="d-flex flex-column justify-content-center align-items-center"
                        >
                          <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                            <span class="mx-1">
                            75th percentile: {{entry.inp.p75 | number : "1.2-2"}}
                            </span>
                          <mat-icon
                            style="font-size: 18px; opacity: 0.7;"
                            [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                            matTooltipPosition="left">
                            help
                          </mat-icon>
                        </div>
                          <apx-chart
                            [series]="entry.inp.series"
                            [chart]="entry.inp.chart"
                            [dataLabels]="entry.inp.dataLabels"
                            [plotOptions]="entry.inp.plotOptions"
                            [xaxis]="entry.inp.xaxis"
                            [yaxis]="entry.inp.yaxis"
                            [stroke]="entry.inp.stroke"
                            [fill]="entry.inp.fill"
                            [tooltip]="entry.inp.tooltip"
                            [legend]="entry.inp.legend"
                          >
                          </apx-chart>
                        </div>
                      </mat-grid-tile>
                    </mat-grid-list>
                  </div>
                }@else{
                  <app-oops-no-data [message]="noAddedWebSite"></app-oops-no-data>
                }


              </mat-card-content>
            </mat-card>
          </div>
        </div>
      @if(!loading && addedToday) {
      <div style="display: flex; justify-content: center; margin-top: 4%">
        <div style="display: flex; flex-direction: column; align-items: center">
          <h2>
            The RUM data for your recently added website will show here in the
            comming morning, make sure to add the rum snippet into your site!
          </h2>
        </div>
      </div>
      }


    </div>
</app-layout>
