<app-layout>
  <!-- <div class="d-flex justify-content-end align-items-center" style="margin-right: 25px; margin-top: 20px; padding-bottom: 0">
    <app-select-web-site></app-select-web-site>
  </div> -->
  @if (userId === null) {
    <div style="margin-left: 80%; margin-top: 5%">
      <button mat-raised-button color="primary" [routerLink]="['/login']" routerLinkActive="router-link-active" >
        Add my website<mat-icon>add</mat-icon>
      </button>
    </div>
  }
  <router-outlet></router-outlet>
</app-layout>
