import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services/common/user/user.service';
import { WebsiteAnalyzeService } from '../services/common/website-analyze/website-analyze.service';
import { ConstantService } from '../shared/constant/constant.service';

@Component({
    selector: 'app-add-internal-pages-only',
    templateUrl: './add-internal-pages-only.component.html',
    styleUrls: ['./add-internal-pages-only.component.scss'],
    standalone: false
})
export class AddInternalPagesOnlyComponent implements OnInit {
  internalPages: UntypedFormGroup[] = [];
  allowed = 0;
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private router: Router,
    private userService: UserService
  ) {}

  async ngOnInit(): Promise<void> {
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    const plan = await this.userService.getPlan(userId).toPromise();
    const internalOnlyWebsites = await this.websiteAnalyzeService
      .getWebsiteAnalyze(userId, true)
      .toPromise();
    this.allowed =
      (Number(plan.details.nbInternalPage.value) -
        internalOnlyWebsites.length)
    this.addRow();
  }
  addRow() {
    if (this.allowed - this.internalPages.length > 0) {
      this.internalPages.push(
        this._formBuilder.group({
          title: [''],
          pageUrl: [''],
          deviceDesktop: true,
          deviceMobile: true
        })
      );
    }
  }

  deleteRow() {
    if (this.internalPages.length > 1) {
      this.internalPages.pop();
    }
  }

  save() {
    const internalPages = this.internalPages.map((fv) => fv.value);
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    const payload = {
      userId,
      internalPages,
    };
    this.websiteAnalyzeService
      .analyzeInternalPages(payload)
      .subscribe((res) => {});
    this.router.navigate(['/home/pageLoad/loading-po'], {
      queryParams: { t: 'po' },
    });
  }
}
