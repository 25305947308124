
<nav class="mx-4 mt-3" aria-label="breadcrumb">
  <ol
    style="height: 50px; width: 52px"
    class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center"
  >
    @defer{ @if (estAlloue(this.selectedCountry)) {
    <span class="mx-1">
      <mat-icon [svgIcon]="getCountryCode(this.selectedCountry)"></mat-icon>
    </span>
    }@else{
    <mat-icon class="mx-1">language</mat-icon>
    } }
    <li>
      <span class="fw-bold">RUM > </span>
    </li>
    <li class="breadcrumb-item">
      <a
        class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center"
        [routerLink]="getPages().overview"
      >
        <mat-icon class="mx-2">desktop_windows</mat-icon>
        <span>{{ SiteTitle }} Overview</span>
      </a>
    </li>
    <li class="breadcrumb-item">
      <a
        class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center"
        [routerLink]="getPages().internal"
      >
        <span>Internal Pages</span>
      </a>
    </li>
    <li class="breadcrumb-item active"></li>
    <span
      matTooltip="{{ site1 }}"
      class="d-inline-block text-truncate"
      style="max-width: 150px"
    >
      {{ siteTitle1 }}
    </span>
    <span class="mx-1">  vs  </span>
    <span
    matTooltip="{{ site2 }}"
    class="d-inline-block text-truncate"
    style="max-width: 150px"
    >
      {{ siteTitle2 }}
    </span>
    <!-- <mat-icon
      matTooltip="Copy Url"
      (click)="copyUrlToClipboard(detailUrlDecoded)"
      class="mx-1"
      style="cursor: pointer"
      >content_copy</mat-icon
    > -->
    @if(sharedMode) {
    <span>-</span>
    <span style="color: coral"> (Shared)</span>
    }
  </ol>
</nav>
@if (loading) {
  <div
    class="d-flex justify-content-center align-items-center my-3"
    style="height: -1000px"
  >
    <mat-spinner class="spinner" diameter="150"></mat-spinner>
  </div>
  }@else{
   <div class="m-3 d-flex justify-content-between align-content-center">
      <div class="radio-inputs mb-1">
        <label class="radio" *ngFor="let deviceT of deviceTypes">
          <input
            [value]="deviceT"
            type="radio"
            name="deviceRadio"
            [(ngModel)]="device"
            [checked]="device === deviceT"
            (change)="setDevice(deviceT)"
          />
          <span class="name"> {{ deviceT | uppercase }}</span>
        </label>
      </div>
      <div class="radio-inputs mb-1">
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="monthly"
            [(ngModel)]="queryPeriod"
            (change)="setQueryPeriod($event)"
          />
          <span class="name">monthly</span>
        </label>
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="weekly"
            [(ngModel)]="queryPeriod"
            (change)="setQueryPeriod($event)"
          />
          <span class="name">weekly</span>
        </label>
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="daily"
            [(ngModel)]="queryPeriod"
            (change)="setQueryPeriod($event)"
          />
          <span class="name">daily</span>
        </label>
      </div>
    </div>
    <div id="tab-metric">
        <mat-tab-group mat-align-tabs="center" class="m-3">
            <mat-tab label="The last measurement" class="header-table">
                <div class="" style="overflow: hidden">
                  <div class="row">
                    @for (metric of metrics; track $index) {
                    <div class="col-sm-12 col-md-6 col-4">
                      <mat-card class="graph m-3 p-2">
                        <mat-card-content>
                          <div class="chart">
                            <strong>{{ metric.toUpperCase() }} </strong>
                          </div>
                          <div class="col-sm">
                            @if(!metricsData[metric]){
                              <div>
                                  <h4 style="text-align: center">
                                    No {{ metric.toUpperCase() }} data
                                  </h4>
                              </div>

                            }@else{
                              <div class="d-flex flex-column justify-content-center align-items-center">
                                <span class="rounded-pill bg-light py-1 px-2">
                                  <div>
                                    75th for {{siteTitle1}} : {{ metricsData[metric]?.p75[0] | number : "1.2-2" }} ms
                                  </div>
                                  <div>
                                    75th for {{siteTitle2}} : {{ metricsData[metric]?.p75[1] | number : "1.2-2" }} ms
                                  </div>
                            

                                </span>
                                <apx-chart style="width: 100%;"
                                  [series]="metricsData[metric]?.series"
                                  [chart]="metricsData[metric]?.chart"
                                  [dataLabels]="metricsData[metric]?.dataLabels"
                                  [plotOptions]="metricsData[metric]?.plotOptions"
                                  [xaxis]="metricsData[metric]?.xaxis"
                                  [yaxis]="metricsData[metric]?.yaxis"
                                  [stroke]="metricsData[metric]?.stroke"
                                  [fill]="metricsData[metric]?.fill"
                                  [title]="metricsData[metric]?.title"
                                  [tooltip]="metricsData[metric]?.tooltip"
                                  [legend]="metricsData[metric]?.legend"
                                >
                                </apx-chart> 
                              </div>
                            }  
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    }
                  </div>
                </div>
              </mat-tab>
              @for (metric of metrics; track $index) {
                <mat-tab label="{{ metric.toUpperCase() }}">
                  <ng-template matTabContent>
              
              
                    <!-- <ng-container *ngIf="queryPeriod === 'daily'">
                      <div class="mx-4 mt-3 d-flex justify-content-between align-items-center">
                        <div></div>
                        <div style="width: 15%">
                          <div style="display: flex; align-items: end">
                            <mat-select
                              [(ngModel)]="daysRangeSelected"
                              class="chart-select rounded"
                              (selectionChange)="executeSelectionChange()"
                            >
                              <mat-option value="7">7 Days</mat-option>
                              <mat-option value="30">30 Days</mat-option>
                              <mat-option value="60">60 Days</mat-option>
                              <mat-option value="90">90 Days</mat-option>
                              <mat-option value="custom" (click)="openDaysSelectorDialog()"
                                >Custom range</mat-option
                              >
                            </mat-select>
                          </div>
                        </div>
                      </div>
                    </ng-container> -->
              
                    <mat-card class="graph m-4 p-2">
                        <mat-card-title class="visits-chart__header"></mat-card-title>
                        <mat-card-content>
                          <div class="col-sm">
                            <apx-chart
                              [series]="overTimeP75Data[metric]?.series"
                              [chart]="overTimeP75Data[metric]?.chart"
                              [xaxis]="overTimeP75Data[metric]?.xaxis"
                              [yaxis]="overTimeP75Data[metric]?.yaxis"
                              [dataLabels]="overTimeP75Data[metric]?.dataLabels"
                              [grid]="overTimeP75Data[metric]?.grid"
                              [stroke]="overTimeP75Data[metric]?.stroke"
                              [title]="overTimeP75Data[metric]?.title"
                              [grid]="overTimeP75Data[metric]?.grid"
                            >
                            </apx-chart>
                          </div>
                        </mat-card-content>
                    </mat-card>
              
                    <mat-card class="graph m-4 p-2">
                        <mat-card-title>{{ metric.toUpperCase() }} over time</mat-card-title>
                        <mat-card-content>
                          <apx-chart
                            [series]="overTimeBarchartData[metric]?.series"
                            [chart]="overTimeBarchartData[metric]?.chart"
                            [dataLabels]="overTimeBarchartData[metric]?.dataLabels"
                            [plotOptions]="overTimeBarchartData[metric]?.plotOptions"
                            [responsive]="overTimeBarchartData[metric]?.responsive"
                            [xaxis]="overTimeBarchartData[metric]?.xaxis"
                            [yaxis]="overTimeBarchartData[metric]?.yaxis"
                            [legend]="overTimeBarchartData[metric]?.legend"
                            [fill]="overTimeBarchartData[metric]?.fill"
                            [grid]="overTimeBarchartData[metric]?.grid"
                          >
                          </apx-chart>
                        </mat-card-content>
                    </mat-card>
                  </ng-template>
                </mat-tab>
              }   
        </mat-tab-group>
    </div>
  }