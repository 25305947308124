import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CompilerService } from 'src/app/shared/compiler/compiler.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import {
  ChartComponent,
} from 'ng-apexcharts';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../../services/core/auth/auth.service';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { BarUrl } from 'src/app/shared/models/user.models';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { DataService } from 'src/app/services/common/sidabar/Data.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: false
})
export class DashboardComponent implements OnInit {

  selectedValue: String[] = ['Mobile'];
  Device: 'mobile' | 'desktop' = 'desktop';
  @ViewChild('chart') chart: ChartComponent;
  public chartOptionsFCP: Partial<any>;
  public chartOptionsFID: Partial<any>;
  public chartOptionsCLS: Partial<any>;
  public chartOptionsLCP: Partial<any>;
  public chartOptionsscoreGraph: Partial<any>;
  public chartOptionCruxLCP: Partial<any>;
  public chartOptionCruxFID: Partial<any>;
  public chartOptionCruxCLS: Partial<any>;
  public chartOptionCruxInp: Partial<any>;

  status = 'passe';
  //selected: any;
  originDomainUrl: any;
  URLSMobile: any;
  URLSDesktop: any;
  dataCruxMobile: any;
  dataCruxDesktop: any;
  BarURL: Array<BarUrl>;
  BarURL1: any;

  BarURLNewUser: any;
  BarURLDesktop: any;
  BarURLSMobile: any;
  URL: any;
  URLS1: any;
  loading: string;
  selected: any;
  liste: any;
  show: boolean = true;
  i = 0;
  id: string;
  Overview_show: boolean = true;
  Lcp_show: boolean = false;
  Fid_show: boolean = false;
  Cls_show: boolean = false;
  Device_show: boolean = true;
  titleLcp: any;
  titleCls: any;
  titleInp: any;
  userId: string;

  mobileShow: boolean;
  dataBar: BarUrl[];
  data: BarUrl[];
  country: string;

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private route: ActivatedRoute,
    private title: Title,
    public senderVarService: SenderVarService
  ) {}
  async ngOnInit(): Promise<void> {
    this.title.setTitle('Speetals Web Performance Vitals');
    this.senderVarService.id = this.route.snapshot.paramMap.get('id');
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);

    this.BarURL = await this.websiteAnalyzeService
      .getWebsiteBar(this.userId)
      .toPromise();

    let all : BarUrl = {id : null, originDomain : null, country:null, title : "All"}

    this.BarURL.unshift(all);
    let i=0;
    this.BarURL.map(((data : BarUrl)=>{
      if (data.id===this.senderVarService.id){
        this.selected = this.BarURL[i];
        this.country=this.BarURL[i].country;
      }

      i++;


    }));
    if (this.senderVarService.id === undefined) {

      this.senderVarService.id = this.route.snapshot.paramMap.get('id');
    }

    if (this.userId === 'null')
      localStorage.setItem(
        ConstantService.loadingUrl.Url,
        'https://www.amazon.com/'
      );
   // localStorage.setItem(ConstantService.loadingUrl.device, 'desktop');
   this.dataService.setSharedData({ WebSiteTitle: this.selected?.title,
                                    country: this.selected.country,
                                    url: this.selected.originDomain,
                                    projectID : this.selected.id});
    localStorage.setItem(ConstantService.loadingUrl.Url, this.selected.originDomain);
  }
  ngAfterViewInit() {
  }

  clearData() {
    this.authService.logoutUser();
  }
  /**
   * this to get url data from database
   */
  getUrl() {}

  getWebsiteValue(Website : BarUrl){
    return Website.title + ' ' + Website.country
  }

  estAlloue(code): boolean{
    if (code == "NotAllowed" || code == undefined)
      return false
    return true
  }

  /**
   * this to change website from listbar
   * @param value
   */
  changeValue(value: any) {

    if (value.title === "All"){
      this.router.navigate(['/home/']);
    }
    else {
    this.country="US";
      let i0= this.BarURL.findIndex((x) => x === value);
      this.country=this.BarURL[i0].country;


    if (value != 'undefined') {
      if (i0 != -1) {
        this.senderVarService.id = this.BarURL[i0].id;
        let route = this.router.url.substring(
          this.router.url.lastIndexOf("/"),
          this.router.url.length
      );


        if (this.senderVarService.id != this.route.snapshot.paramMap.get('id')) {

        switch(route){
          case '/internalpages' :
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/internalpages+']);
            break;
          case '/internalpages%2B' :
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/internalpages']);
            break;
          case  '/overview%2B':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/overview']);
            break;
          case  '/overview':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/overview+']);
            break;
          case  '/lcp%2B':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/lcp']);
            break;
          case  '/lcp':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/lcp+']);
            break;
          case  '/fid%2B':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/fid']);
            break;
          case  '/fid':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/fid+']);
            break;
          case  '/cls':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/cls+']);
            break;
          case  '/cls%2B':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/cls']);
            break;
          case  '/ttfb':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/ttfb+']);
            break;
          case  '/ttfb%2B':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/ttfb']);
            break;
          case  '/inp':
              this.router.navigate(['/home/page/' + this.senderVarService.id + '/inp+']);
              break;
          case  '/inp%2B':
              this.router.navigate(['/home/page/' + this.senderVarService.id + '/inp']);
              break;
          case  '/competitor':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/competitor+']);
            break;
          case  '/competitor%2B':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/competitor']);
            break;
          case  '/fcp':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/fcp+']);
            break;
          case '/fcp%2B':
            this.router.navigate(['/home/page/' + this.senderVarService.id + '/fcp']);
            break;
          default :
            this.router.navigate(['/home/page/' + this.senderVarService.id + route]);
        }

        }
      }
    }
  }
}
}
