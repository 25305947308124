import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { colors } from 'src/app/consts';
import { AnnotationService } from 'src/app/services/common/annotation/annotation.service';
import { ChartOptionsService } from 'src/app/services/common/chart/chart-options.service';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { UserService } from 'src/app/services/common/user/user.service';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { Annotation } from 'src/app/shared/models/annotation.model';
import { legend } from 'src/app/shared/models/legend';
import { InternalPageDetail } from 'src/app/shared/models/user.models';
import { HistogramDialogComponent } from '../histogram-dialog/histogram-dialog.component';
import { DataService } from 'src/app/services/common/sidabar/Data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

enum matSelectedFields {
  _7days = '_7days',
  _30days = '_30days',
  _60days = '_60days',
  _90days = '_90days',
}
@Component({
    selector: 'app-internal-page-detail',
    templateUrl: './internal-page-detail.component.html',
    styleUrls: ['./internal-page-detail.component.scss'],
    standalone: false
})
export class InternalPageDetailComponent implements OnInit {
  deviceOptions: Array<String> = ['Mobile', 'Desktop'];
  device: string;
  text: string;
  userId: string;
  checked: boolean = false;

  metrics = ['lcp', 'cls', 'fcp', 'inp', 'ttfb'];
  lastValues = {};

  annotationData = new Annotation();

  public colors: typeof colors = colors;
  public chartOptions: Partial<any>;
  public chartOptions2: Partial<any>;

  public chartOptionsLcpP75: Partial<any>;
  public chartOptionsLcpGPN: Partial<any>;
  public chartOptionsLcpGPNBarOT: Partial<any>;

  //
  public chartOptionsClsP75: Partial<any>;
  public chartOptionsClsGPN: Partial<any>;
  public chartOptionsClsGPNBarOT: Partial<any>;

  //²
  public chartOptionsFcpP75: Partial<any>;
  public chartOptionsFcpGPN: Partial<any>;
  public chartOptionsFcpGPNBarOT: Partial<any>;

  public chartOptionsInpP75: Partial<any>;
  public chartOptionsInpGPN: Partial<any>;
  public chartOptionsInpGPNBarOT: Partial<any>;

  public chartOptionsTtfbP75: Partial<any>;
  public chartOptionsTtfbGPN: Partial<any>;
  public chartOptionsTtfbGPNBarOT: Partial<any>;

  public chartOptionsCo2Emmision: any;
  public chartOptionsPageSize: any;
  public planName: string;
  public chartOptionsUxr: Partial<any>;
  public ttfbAllConfig: any = null;
  public lcpAllConfig: any = null;
  public clsAllConfig: any = null;
  public fcpAllConfig: any = null;
  public inpAllConfig: any = null;
  //
  titleLcp: string;
  diffLcp: number;
  //
  titleCls: string;
  diffCls: number;
  //
  titleFcp: string;
  diffFcp: number;
  //
  titleInp: string;
  diffInp: number;
  //
  titleTtfb: string;
  diffTtfb: number;

  data: InternalPageDetail;
  title: string;
  img: string;
  loading: boolean = true;
  zero = false;
  datas = [];
  dailyLineChartData: any;
  events: string;
  annotationBarChart: any;

  plan: any;
  freq: string = 'daily';
  upgrade: boolean = false;
  pageOnly = false;

  public selectedMatSelectValue = matSelectedFields._30days;

  public matSelectFields: typeof matSelectedFields = matSelectedFields;
  annotationLineChart: object[];
  passOrNot: boolean;
  needImprovement: string;
  arrayNeedImprovements: any[];

  diffDates = [
    { id: 'most_recent', name: 'Most Recent' },
    { id: 'last_week', name: 'Previous week' },
    { id: 'last_month', name: 'Previous month' },
  ];
  selectedDiffDate = '';
  diff_date: string;
  isFreeAccount = true;
  perthouthandVisits = 0;
  p75ChartOpts = {
    lcp: {},
    fcp: {},
    cls: {},
    inp: {},
    ttfb: {},
  };
  websites: any;
  WebSiteTitle: any;
  result: any;
  url: string;
  country: any;
  originDomain: any;
  projectID: any;
  currentUrl: any;
  Url: string;

  public chartOptionsLcpBreakdown: Partial<any>;
  public lcpImageMetrics = {
    p75LcpImageRenderDelay: 0,
    p75LcpImageLoadDelay: 0,
    p75LcpImageLoadDuration: 0,
    p75LcpImageTtfb: 0
  };

  constructor(
    private speetalsDatabaseService: SpeetalsDatabaseService,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    private route: ActivatedRoute,
    public senderVarService: SenderVarService,
    private annotationService: AnnotationService,
    private userService: UserService,
    private dialog: MatDialog,
    private chartOptionsService: ChartOptionsService,
    private currentRoute: Router,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.device = this.device === "tablet" ? "mobile" : this.device
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);

    this.pageOnly = this.currentRoute.url.includes('-po');


    const planName = localStorage.getItem(
      ConstantService.localStorageKeys.plan_name,
    );
    if (planName) {
      this.isFreeAccount = planName === 'Free';
    }
    this.selectedDiffDate = this.selectedDiffDate || 'most_recent';
    this.data = await this.speetalsDatabaseService
      .getInternalPageDetail(
        this.userId,
        this.route.snapshot.paramMap.get('id'),
        this.route.snapshot.paramMap.get('url'),
        this.device,
        this.route.snapshot.queryParamMap.get('type'),
        this.selectedDiffDate,
      )
      .toPromise();

    this.WebSiteTitle = this.dataService.getSharedData()?.WebSiteTitle;
    this.country = this.dataService.getSharedData()?.country;
    this.currentUrl = this.dataService.getSharedData()?.url;
    this.projectID = this.dataService.getSharedData()?.projectID;
    this.Url = this.router.url;
    this.plan = await this.userService.getPlan(this.userId).toPromise();
    if (this.plan.name.toLowerCase() === 'free') {
      this.upgrade = true;
      this.freq = 'weekly';
    }

     let annotation = await this.annotationService
        .getAnnotation(
          this.route.snapshot.paramMap.get('id'),
          'internalPage',
           this.data.title,
        )
        .toPromise();
      this.annotationBarChart = this.annotationService.constructAnnotation(
        annotation,
        'barChart',
      );
      this.annotationLineChart = this.annotationService.constructAnnotation(
        annotation,
        'lineChart',
      );
    this.chartOptionsUxr = this.pageSpeedInsigthService.chartOptionsUXROverTime(
      [{ data: this.data.arrayUxr }],
      this.data.date,
      false,
    );

    const dates7 = this.splitTable(matSelectedFields._30days, this.data.date);
    // TTFB config
    const ttfbGood7days = this.splitTable(
      matSelectedFields._30days,
      this.data.ttfb.prop.good,
    );

    const ttfbNeedsImprovement7days = this.splitTable(
      matSelectedFields._30days,
      this.data.ttfb.prop.needImprovement,
    );
    const ttfbPoor7days = this.splitTable(
      matSelectedFields._30days,
      this.data.ttfb.prop.poor,
    );
    const ttfb7data = this.splitTablePages(
      dates7,
      ttfbGood7days,
      ttfbNeedsImprovement7days,
      ttfbPoor7days,
      legend.ttfb,
    );
    const ttfb7Days = Object.keys(ttfb7data);
    this.ttfbAllConfig = {
      selectedDays: ttfb7Days[ttfb7Days.length - 1],
      days: ttfb7Days,
      ...ttfb7data,
    };


    //LCP config
    const lcpGood = this.splitTable(
      matSelectedFields._30days,
      this.data.lcp.prop.good,
    );
    const lcpNeedsImprovement = this.splitTable(
      matSelectedFields._30days,
      this.data.lcp.prop.needImprovement,
    );
    const lcpPoor = this.splitTable(
      matSelectedFields._30days,
      this.data.lcp.prop.poor,
    );
    const lcpData = this.splitTablePages(
      dates7,
      lcpGood,
      lcpNeedsImprovement,
      lcpPoor,
      legend.lcp,
    );
    const lcp7Days = Object.keys(lcpData);
    this.lcpAllConfig = {
      selectedDays: lcp7Days[lcp7Days.length - 1],
      days: lcp7Days,
      ...lcpData,
    };

    this.chartOptionsLcpP75 =
      this.pageSpeedInsigthService.chartOptionsmetricOverTime(
        'P75 (ms)',
        this.data.lcp.p75,
        this.data.date,
        this.annotationLineChart,
      );
    this.chartOptionsLcpGPN = this.pageSpeedInsigthService.chartOptionsGPN(
      this.data.lcp.prop.good[this.data.lcp.prop.good.length - 1],
      this.data.lcp.prop.needImprovement[
        this.data.lcp.prop.needImprovement.length - 1
      ],
      this.data.lcp.prop.poor[this.data.lcp.prop.poor.length - 1],
    );
    this.titleLcp = this.pageSpeedInsigthService.getIcon(
      this.data.lcp.prop.good[this.data.lcp.prop.good.length - 1],
    );
    this.diffLcp = Math.round(this.data.lcp.diff * 10) / 10 || 0;



    this.metrics.forEach(metric => {
        if (this.data[metric] && this.data[metric].p75 && this.data[metric].p75.length > 0) {
            this.lastValues[metric] = this.data[metric].p75[this.data[metric].p75.length - 1];
        } else {
            this.lastValues[metric] = null;
        }
    });

    //CLS config
    const clsGood = this.splitTable(
      matSelectedFields._30days,
      this.data.cls.prop.good,
    );
    const clsNeedsImprovement = this.splitTable(
      matSelectedFields._30days,
      this.data.cls.prop.needImprovement,
    );
    const clsPoor = this.splitTable(
      matSelectedFields._30days,
      this.data.cls.prop.poor,
    );
    const clsData = this.splitTablePages(
      dates7,
      clsGood,
      clsNeedsImprovement,
      clsPoor,
      legend.cls,
    );
    const cls7Days = Object.keys(clsData);
    this.clsAllConfig = {
      selectedDays: cls7Days[cls7Days.length - 1],
      days: cls7Days,
      ...clsData,
    };
    this.chartOptionsClsP75 =
      this.pageSpeedInsigthService.chartOptionsmetricOverTime(
        'P75',
        this.data.cls.p75,
        this.data.date,
        this.annotationLineChart,
      );
    this.chartOptionsClsGPN = this.pageSpeedInsigthService.chartOptionsGPN(
      this.data.cls.prop.good[this.data.cls.prop.good.length - 1],
      this.data.cls.prop.needImprovement[
        this.data.cls.prop.needImprovement.length - 1
      ],
      this.data.cls.prop.poor[this.data.cls.prop.poor.length - 1],
    );
    this.titleCls = this.pageSpeedInsigthService.getIcon(
      this.data.cls.prop.good[this.data.cls.prop.good.length - 1],
    );
    this.diffCls = Math.round(this.data.cls.diff * 10) / 10 || 0;

    //FCP config
    const fcpGood = this.splitTable(
      matSelectedFields._30days,
      this.data.fcp.prop.good,
    );
    const fcpNeedsImprovement = this.splitTable(
      matSelectedFields._30days,
      this.data.fcp.prop.needImprovement,
    );
    const fcpPoor = this.splitTable(
      matSelectedFields._30days,
      this.data.fcp.prop.poor,
    );
    const fcpData = this.splitTablePages(
      dates7,
      fcpGood,
      fcpNeedsImprovement,
      fcpPoor,
      legend.fcp,
    );
    const fcp7Days = Object.keys(fcpData);
    this.fcpAllConfig = {
      selectedDays: fcp7Days[fcp7Days.length - 1],
      days: fcp7Days,
      ...fcpData,
    };

    this.chartOptionsFcpP75 =
      this.pageSpeedInsigthService.chartOptionsmetricOverTime(
        'P75 (ms)',
        this.data.fcp.p75,
        this.data.date,
        this.annotationLineChart,
      );

    this.chartOptionsFcpGPN = this.pageSpeedInsigthService.chartOptionsGPN(
      this.data.fcp.prop.good[this.data.fcp.prop.good.length - 1],
      this.data.fcp.prop.needImprovement[
        this.data.fcp.prop.needImprovement.length - 1
      ],
      this.data.fcp.prop.poor[this.data.fcp.prop.poor.length - 1],
    );
    this.titleFcp = this.pageSpeedInsigthService.getIcon(
      this.data.fcp.prop.good[this.data.fcp.prop.good.length - 1],
    );
    this.diffFcp = Math.round(this.data.fcp.diff * 10) / 10 || 0;
    //
    this.img = this.data.img;
    this.title = this.data.title;
    this.url = this.data.url;
    this.loading = false;

    //INP config
    const inpGood = this.splitTable(
      matSelectedFields._30days,
      this.data.inp.prop.good,
    );
    const inpNeedsImprovement = this.splitTable(
      matSelectedFields._30days,
      this.data.inp.prop.needImprovement,
    );
    const inpPoor = this.splitTable(
      matSelectedFields._30days,
      this.data.inp.prop.poor,
    );
    const inpData = this.splitTablePages(
      dates7,
      inpGood,
      inpNeedsImprovement,
      inpPoor,
      legend.inp,
    );
    const inp7Days = Object.keys(inpData);
    this.inpAllConfig = {
      selectedDays: inp7Days[inp7Days.length - 1],
      days: inp7Days,
      ...inpData,
    };

    this.chartOptionsInpP75 =
      this.pageSpeedInsigthService.chartOptionsmetricOverTime(
        'P75 (ms)',
        this.data.inp.p75,
        this.data.date,
        this.annotationLineChart,
      );
    this.chartOptionsInpGPN = this.pageSpeedInsigthService.chartOptionsGPN(
      this.data.inp.prop.good[this.data.inp.prop.good.length - 1],
      this.data.inp.prop.needImprovement[
        this.data.inp.prop.needImprovement.length - 1
      ],
      this.data.inp.prop.poor[this.data.inp.prop.poor.length - 1],
    );
    this.titleInp = this.pageSpeedInsigthService.getIcon(
      this.data.inp.prop.good[this.data.inp.prop.good.length - 1],
    );
    this.diffInp = Math.round(this.data.inp.diff * 10) / 10 || 0;

    //TTFB details

    this.chartOptionsTtfbP75 =
      this.pageSpeedInsigthService.chartOptionsmetricOverTime(
        'P75 (ms)',
        this.data.ttfb.p75,
        this.data.date,
        this.annotationLineChart,
      );
    this.chartOptionsTtfbGPN = this.pageSpeedInsigthService.chartOptionsGPN(
      this.data.ttfb.prop.good[this.data.ttfb.prop.good.length - 1],
      this.data.ttfb.prop.needImprovement[
        this.data.ttfb.prop.needImprovement.length - 1
      ],
      this.data.ttfb.prop.poor[this.data.ttfb.prop.poor.length - 1],
    );
    this.titleTtfb = this.pageSpeedInsigthService.getIcon(
      this.data.ttfb.prop.good[this.data.ttfb.prop.good.length - 1],
    );
    this.diffTtfb = Math.round(this.data.ttfb.diff * 10) / 10 || 0;

    this.arrayNeedImprovements = [];
    let array = [];
    for (const metric of this.metrics) {
      let good =
        this.data[metric].prop.good[this.data[metric].prop.good.length - 1];
      if (['cls', 'lcp' , 'inp'].includes(metric)) array.push(good);

      if (good < 75) {
        this.arrayNeedImprovements.push({
          metric: metric,
          value: good,
        });
      }
    }

    this.planName = localStorage.getItem(
      ConstantService.localStorageKeys.plan_name,
    );

    this.passOrNot = this.getAverage(array) === 'FAST' ? true : false;
    if (!this.passOrNot && this.arrayNeedImprovements.length > 0) {
      let text = '';
      this.arrayNeedImprovements
        .sort((a: any, b: any) => {
          return a.value - b.value;
        })
        .map((elt) => {
          let ptVirgule =
            elt ===
            this.arrayNeedImprovements[this.arrayNeedImprovements.length - 1]
              ? ' '
              : ';';
          text +=
            elt.metric.toUpperCase() +
            ' (' +
            elt.value +
            '%)' +
            ptVirgule +
            ' ';
        });
      this.needImprovement = text;
    }
    ['_7days', '_30days', '_60days', '_90days'].forEach((days) => {
      this.processP75ChartOption('', days, 'co2');
      this.processP75ChartOption('', days, 'pageSize');
    });
    ['lcp', 'fcp', 'inp', 'ttfb', 'cls'].forEach((metric) => {
      ['_7days', '_30days', '_60days', '_90days'].forEach((days) => {
        this.processP75ChartOption(metric, days);
      });
    });

    this.chartOptionsLcpBreakdown = this.pageSpeedInsigthService.getChartOptionsLcpBreakdown(this.data);

    this.loading = false;

  }

  getBadgeColor(x: number): string {
    return x < 0 ? 'warn' : 'accent';
  }
  getAverage(array: number[]) {
    //if(array.length == 0 || array === undefined || ) return
    if (
      array.length == 0 ||
      array === undefined ||
      array.some((elem) => isNaN(elem))
    )
      return 'N/A';
    if (array.every((elem) => elem >= 50)) {
      if (array.some((elem) => elem < 75)) return 'AVERAGE';
      return 'FAST';
    }
    if (array.some((elem) => elem < 50 && elem > 0)) return 'POOR';
    return 'N/A';
  }
  setDevice($event: string) {
    this.device = $event;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);
    this.datas.length = 0;
    this.needImprovement = '';
    this.ngOnInit();
  }

  onDiffDateChanged(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const newValue = selectElement.value;
    this.selectedDiffDate = newValue;
    this.loading = true;
    this.datas.length = 0;
    this.ngOnInit();
  }

  getlink() {
    return this.speetalsDatabaseService.getlink(
      this.route.snapshot.paramMap.get('id'),
    );
  }
  description(name: string) {
    return 'Day to day change of Good ' + name + ' %';
  }

  printUxr(uxr: number) {
    return uxr !== 0;
  }
  getColorUxr(uxr: number) {
    if (uxr >= 90) return 'green';
    if (uxr > 50 && uxr < 90) return 'orange';
    return 'red';
  }

  public changedMatSelectionValue() {
    let lcpGood = [];
    let lcpNeedsImprovement = [];
    let lcpPoor = [];
    let lcpData: any = {};
    let clsGood = [];
    let clsNeedsImprovement = [];
    let clsPoor = [];
    let clsData: any = {};
    let fcpGood = [];
    let fcpNeedsImprovement = [];
    let fcpPoor = [];
    let fcpData: any = {};
    let inpGood = [];
    let inpNeedsImprovement = [];
    let inpPoor = [];
    let inpData: any = {};

    switch (this.selectedMatSelectValue) {
      case matSelectedFields._7days:
        // TTFB
        const dates7 = this.splitTable('_7days', this.data.date);
        const ttfbGood7days = this.splitTable(
          '_7days',
          this.data.ttfb.prop.good,
        );
        const ttfbNeedsImprovement7days = this.splitTable(
          '_7days',
          this.data.ttfb.prop.needImprovement,
        );
        const ttfbPoor7days = this.splitTable(
          '_7days',
          this.data.ttfb.prop.poor,
        );
        const ttfb7data = this.splitTablePages(
          dates7,
          ttfbGood7days,
          ttfbNeedsImprovement7days,
          ttfbPoor7days,
          legend.ttfb,
        );
        const ttfb7Days = Object.keys(ttfb7data);

        this.ttfbAllConfig = {
          selectedDays: ttfb7Days[ttfb7Days.length - 1],
          days: ttfb7Days,
          ...ttfb7data,
        };
        // FCP
        fcpGood = this.splitTable('_7days', this.data.fcp.prop.good);
        fcpNeedsImprovement = this.splitTable(
          '_7days',
          this.data.fcp.prop.needImprovement,
        );
        fcpPoor = this.splitTable('_7days', this.data.fcp.prop.poor);
        fcpData = this.splitTablePages(
          dates7,
          fcpGood,
          fcpNeedsImprovement,
          fcpPoor,
          legend.fcp,
        );
        const fcp7Days = Object.keys(fcpData);
        this.fcpAllConfig = {
          selectedDays: fcp7Days[fcp7Days.length - 1],
          days: fcp7Days,
          ...fcpData,
        };
        //CLS
        clsGood = this.splitTable('_7days', this.data.cls.prop.good);
        clsNeedsImprovement = this.splitTable(
          '_7days',
          this.data.cls.prop.needImprovement,
        );
        clsPoor = this.splitTable('_7days', this.data.cls.prop.poor);
        clsData = this.splitTablePages(
          dates7,
          clsGood,
          clsNeedsImprovement,
          clsPoor,
          legend.cls,
        );
        const cls7Days = Object.keys(clsData);
        this.clsAllConfig = {
          selectedDays: cls7Days[cls7Days.length - 1],
          days: cls7Days,
          ...clsData,
        };
        //LCP
        lcpGood = this.splitTable('_7days', this.data.lcp.prop.good);
        lcpNeedsImprovement = this.splitTable(
          '_7days',
          this.data.lcp.prop.needImprovement,
        );
        lcpPoor = this.splitTable('_7days', this.data.lcp.prop.poor);
        lcpData = this.splitTablePages(
          dates7,
          lcpGood,
          lcpNeedsImprovement,
          lcpPoor,
          legend.lcp,
        );
        const lcp7Days = Object.keys(lcpData);
        this.lcpAllConfig = {
          selectedDays: lcp7Days[lcp7Days.length - 1],
          days: lcp7Days,
          ...lcpData,
        };
        //INP
        inpGood = this.splitTable('_7days', this.data.inp.prop.good);
        inpNeedsImprovement = this.splitTable(
          '_7days',
          this.data.inp.prop.needImprovement,
        );
        inpPoor = this.splitTable('_7days', this.data.inp.prop.poor);
        inpData = this.splitTablePages(
          dates7,
          inpGood,
          inpNeedsImprovement,
          inpPoor,
          legend.inp,
        );
        const inp7Days = Object.keys(inpData);
        this.inpAllConfig = {
          selectedDays: inp7Days[inp7Days.length - 1],
          days: inp7Days,
          ...inpData,
        };
        break;
      case matSelectedFields._30days:
        const dates30 = this.splitTable('_30days', this.data.date);
        //TTFB
        const ttfbGood30days = this.splitTable(
          '_30days',
          this.data.ttfb.prop.good,
        );
        const ttfbNeedsImprovement30days = this.splitTable(
          '_30days',
          this.data.ttfb.prop.needImprovement,
        );
        const ttfbPoor30days = this.splitTable(
          '_30days',
          this.data.ttfb.prop.poor,
        );
        const ttf30pdata = this.splitTablePages(
          dates30,
          ttfbGood30days,
          ttfbNeedsImprovement30days,
          ttfbPoor30days,
          legend.ttfb,
        );
        const ttfbDays = Object.keys(ttf30pdata);

        this.ttfbAllConfig = {
          selectedDays: ttfbDays[ttfbDays.length - 1],
          days: ttfbDays,
          ...ttf30pdata,
        };
        //FCP
        fcpGood = this.splitTable('_30days', this.data.fcp.prop.good);
        fcpNeedsImprovement = this.splitTable(
          '_30days',
          this.data.fcp.prop.needImprovement,
        );
        fcpPoor = this.splitTable('_30days', this.data.fcp.prop.poor);
        fcpData = this.splitTablePages(
          dates30,
          fcpGood,
          fcpNeedsImprovement,
          fcpPoor,
          legend.fcp,
        );
        const fcp30Days = Object.keys(fcpData);
        this.fcpAllConfig = {
          selectedDays: fcp30Days[fcp30Days.length - 1],
          days: fcp30Days,
          ...fcpData,
        };
        //CLS
        clsGood = this.splitTable('_30days', this.data.cls.prop.good);
        clsNeedsImprovement = this.splitTable(
          '_30days',
          this.data.cls.prop.needImprovement,
        );
        clsPoor = this.splitTable('_30days', this.data.cls.prop.poor);
        clsData = this.splitTablePages(
          dates30,
          clsGood,
          clsNeedsImprovement,
          clsPoor,
          legend.cls,
        );
        const cls30Days = Object.keys(clsData);
        this.clsAllConfig = {
          selectedDays: cls30Days[cls30Days.length - 1],
          days: cls30Days,
          ...clsData,
        };
        //LCP
        lcpGood = this.splitTable('_30days', this.data.lcp.prop.good);
        lcpNeedsImprovement = this.splitTable(
          '_30days',
          this.data.lcp.prop.needImprovement,
        );
        lcpPoor = this.splitTable('_30days', this.data.lcp.prop.poor);
        lcpData = this.splitTablePages(
          dates30,
          lcpGood,
          lcpNeedsImprovement,
          lcpPoor,
          legend.lcp,
        );
        const lcp30Days = Object.keys(lcpData);
        this.lcpAllConfig = {
          selectedDays: lcp30Days[lcp30Days.length - 1],
          days: lcp30Days,
          ...lcpData,
        };

        //INP
        inpGood = this.splitTable('_30days', this.data.inp.prop.good);
        inpNeedsImprovement = this.splitTable(
          '_30days',
          this.data.inp.prop.needImprovement,
        );
        inpPoor = this.splitTable('_30days', this.data.inp.prop.poor);
        inpData = this.splitTablePages(
          dates30,
          inpGood,
          inpNeedsImprovement,
          inpPoor,
          legend.inp,
        );
        const inp30Days = Object.keys(inpData);
        this.inpAllConfig = {
          selectedDays: inp30Days[inp30Days.length - 1],
          days: inp30Days,
          ...inpData,
        };
        break;
      case matSelectedFields._60days:
        const dates60 = this.splitTable('_60days', this.data.date);
        //TTFB
        const ttfbGood60days = this.splitTable(
          '_60days',
          this.data.ttfb.prop.good,
        );
        const ttfbNeedsImprovement60days = this.splitTable(
          '_60days',
          this.data.ttfb.prop.needImprovement,
        );
        const ttfbPoor60days = this.splitTable(
          '_60days',
          this.data.ttfb.prop.poor,
        );
        const ttf60pdata = this.splitTablePages(
          dates60,
          ttfbGood60days,
          ttfbNeedsImprovement60days,
          ttfbPoor60days,
          legend.ttfb,
        );
        const ttf60Days = Object.keys(ttf60pdata);

        this.ttfbAllConfig = {
          selectedDays: ttf60Days[ttf60Days.length - 1],
          days: ttf60Days,
          ...ttf60pdata,
        };
        // FCP
        fcpGood = this.splitTable('_60days', this.data.fcp.prop.good);
        fcpNeedsImprovement = this.splitTable(
          '_60days',
          this.data.fcp.prop.needImprovement,
        );
        fcpPoor = this.splitTable('_60days', this.data.fcp.prop.poor);
        fcpData = this.splitTablePages(
          dates60,
          fcpGood,
          fcpNeedsImprovement,
          fcpPoor,
          legend.fcp,
        );
        const fcp60Days = Object.keys(fcpData);
        this.fcpAllConfig = {
          selectedDays: fcp60Days[fcp60Days.length - 1],
          days: fcp60Days,
          ...fcpData,
        };
        //CLS
        clsGood = this.splitTable('_60days', this.data.cls.prop.good);
        clsNeedsImprovement = this.splitTable(
          '_60days',
          this.data.cls.prop.needImprovement,
        );
        clsPoor = this.splitTable('_60days', this.data.cls.prop.poor);
        clsData = this.splitTablePages(
          dates60,
          clsGood,
          clsNeedsImprovement,
          clsPoor,
          legend.cls,
        );
        const cls60Days = Object.keys(clsData);
        this.clsAllConfig = {
          selectedDays: cls60Days[cls60Days.length - 1],
          days: cls60Days,
          ...clsData,
        };
        //LCP
        lcpGood = this.splitTable('_60days', this.data.lcp.prop.good);
        lcpNeedsImprovement = this.splitTable(
          '_60days',
          this.data.lcp.prop.needImprovement,
        );
        lcpPoor = this.splitTable('_60days', this.data.lcp.prop.poor);
        lcpData = this.splitTablePages(
          dates60,
          lcpGood,
          lcpNeedsImprovement,
          lcpPoor,
          legend.lcp,
        );
        const lcp60Days = Object.keys(lcpData);
        this.lcpAllConfig = {
          selectedDays: lcp60Days[lcp60Days.length - 1],
          days: lcp60Days,
          ...lcpData,
        };
        //INP
        inpGood = this.splitTable('_60days', this.data.inp.prop.good);
        inpNeedsImprovement = this.splitTable(
          '_60days',
          this.data.inp.prop.needImprovement,
        );
        inpPoor = this.splitTable('_60days', this.data.inp.prop.poor);
        inpData = this.splitTablePages(
          dates60,
          inpGood,
          inpNeedsImprovement,
          inpPoor,
          legend.inp,
        );
        const inp60Days = Object.keys(inpData);
        this.inpAllConfig = {
          selectedDays: inp60Days[inp60Days.length - 1],
          days: inp60Days,
          ...inpData,
        };
        break;
      case matSelectedFields._90days:
        const dates90 = this.splitTable('_90days', this.data.date);
        //TTFB
        const ttfbGood90days = this.splitTable(
          '_90days',
          this.data.ttfb.prop.good,
        );
        const ttfbNeedsImprovement90days = this.splitTable(
          '_90days',
          this.data.ttfb.prop.needImprovement,
        );
        const ttfbPoor90days = this.splitTable(
          '_90days',
          this.data.ttfb.prop.poor,
        );
        const ttf90pdata = this.splitTablePages(
          dates90,
          ttfbGood90days,
          ttfbNeedsImprovement90days,
          ttfbPoor90days,
          legend.ttfb,
        );
        const ttf90Days = Object.keys(ttf90pdata);

        this.ttfbAllConfig = {
          selectedDays: ttf90Days[ttf90Days.length - 1],
          days: ttf90Days,
          ...ttf90pdata,
        };
        // FCP
        fcpGood = this.splitTable('_90days', this.data.fcp.prop.good);
        fcpNeedsImprovement = this.splitTable(
          '_90days',
          this.data.fcp.prop.needImprovement,
        );
        fcpPoor = this.splitTable('_90days', this.data.fcp.prop.poor);
        fcpData = this.splitTablePages(
          dates90,
          fcpGood,
          fcpNeedsImprovement,
          fcpPoor,
          legend.fcp,
        );
        const fcp90Days = Object.keys(fcpData);
        this.fcpAllConfig = {
          selectedDays: fcp90Days[fcp90Days.length - 1],
          days: fcp90Days,
          ...fcpData,
        };
        //CLS
        clsGood = this.splitTable('_90days', this.data.cls.prop.good);
        clsNeedsImprovement = this.splitTable(
          '_90days',
          this.data.cls.prop.needImprovement,
        );
        clsPoor = this.splitTable('_90days', this.data.cls.prop.poor);
        clsData = this.splitTablePages(
          dates90,
          clsGood,
          clsNeedsImprovement,
          clsPoor,
          legend.cls,
        );
        const cls90Days = Object.keys(clsData);
        this.clsAllConfig = {
          selectedDays: cls90Days[cls90Days.length - 1],
          days: cls90Days,
          ...clsData,
        };
        //LCP
        lcpGood = this.splitTable('_90days', this.data.lcp.prop.good);
        lcpNeedsImprovement = this.splitTable(
          '_90days',
          this.data.lcp.prop.needImprovement,
        );
        lcpPoor = this.splitTable('_90days', this.data.lcp.prop.poor);
        lcpData = this.splitTablePages(
          dates90,
          lcpGood,
          lcpNeedsImprovement,
          lcpPoor,
          legend.lcp,
        );
        const lcp90Days = Object.keys(lcpData);
        this.lcpAllConfig = {
          selectedDays: lcp90Days[lcp90Days.length - 1],
          days: lcp90Days,
          ...lcpData,
        };

        //INP
        inpGood = this.splitTable('_90days', this.data.inp.prop.good);
        inpNeedsImprovement = this.splitTable(
          '_90days',
          this.data.inp.prop.needImprovement,
        );
        inpPoor = this.splitTable('_90days', this.data.inp.prop.poor);
        inpData = this.splitTablePages(
          dates90,
          inpGood,
          inpNeedsImprovement,
          inpPoor,
          legend.inp,
        );
        const inp90Days = Object.keys(inpData);
        this.inpAllConfig = {
          selectedDays: inp90Days[inp90Days.length - 1],
          days: inp90Days,
          ...inpData,
        };
        break;

      default:
    }
  }

  splitTable(dateType, tab) {
    let lengthTab = tab.length;
    let k;
    let newTab = [];
    switch (dateType) {
      case '_7days':
        if (lengthTab - 7 < 0) return tab;
        k = lengthTab - 7;
        break;
      case '_30days':
        if (lengthTab - 30 < 0) return tab;
        k = lengthTab - 30;
        break;
      case '_60days':
        if (lengthTab - 60 < 0) return tab;
        k = lengthTab - 60;
        break;
      case '_90days':
        if (lengthTab - 90 < 0) return tab;
        k = lengthTab - 90;
        break;
      default:
    }

    for (let i = k; i < lengthTab; i++) {
      newTab.push(tab[i]);
    }
    return newTab;
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events = `${type}: ${event.value}`;
  }
  async addAnnotation() {
    const datepipe: DatePipe = new DatePipe('en-US');
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    this.annotationData.date = datepipe.transform(this.events, 'yyyy/MM/dd');

    this.annotationData.nameOfGarph = 'internalPage';
    this.annotationData.internalUrlTitle = this.data.title;
    this.annotationData.text = this.text;
    this.annotationData.userId = this.userId;
    this.annotationData.projectId = this.route.snapshot.paramMap
      .get('id')
      .toString();
    await this.annotationService
      .postAnnotation(this.annotationData)
      .toPromise();
    this.events = '';
    this.text = '';
    let annotation:any =[];
     annotation = await this.annotationService
       .getAnnotation(
         this.route.snapshot.paramMap.get('id'),
         'internalPage',
         this.data.title,
       )
       .toPromise();
    this.annotationBarChart = this.annotationService.constructAnnotation(
      annotation,
      'barChart',
    );
    this.annotationLineChart = this.annotationService.constructAnnotation(
      annotation,
      'lineChart',
    );
    // FCP
    this.chartOptionsFcpGPNBarOT =
      this.pageSpeedInsigthService.chartOptionMonthGPN(
        'Good < 1.8s',
        'Moderate',
        'Poor > 3.0s',
        this.splitTable('_7days', this.data.fcp.prop.good),
        this.splitTable('_7days', this.data.fcp.prop.poor),
        this.splitTable('_7days', this.data.fcp.prop.needImprovement),
        this.splitTable('_7days', this.data.date),
        this.annotationBarChart,
      );
    //CLS
    this.chartOptionsClsGPNBarOT =
      this.pageSpeedInsigthService.chartOptionMonthGPN(
        'Good < 0.1',
        'Moderate',
        'Poor > 0.25',
        this.splitTable('_7days', this.data.cls.prop.good),
        this.splitTable('_7days', this.data.cls.prop.poor),
        this.splitTable('_7days', this.data.cls.prop.needImprovement),
        this.splitTable('_7days', this.data.date),
        this.annotationBarChart,
      );
    //LCP
    this.chartOptionsLcpGPNBarOT =
      this.pageSpeedInsigthService.chartOptionMonthGPN(
        'Good < 2.5s',
        'Moderate',
        'Poor > 4.0s',
        this.splitTable('_7days', this.data.lcp.prop.good),
        this.splitTable('_7days', this.data.lcp.prop.poor),
        this.splitTable('_7days', this.data.lcp.prop.needImprovement),
        this.splitTable('_7days', this.data.date),
        this.annotationBarChart,
      );

    //INP
    this.chartOptionsInpGPNBarOT =
      this.pageSpeedInsigthService.chartOptionMonthGPN(
        'Good < 200ms',
        'Moderate',
        'Poor > 500ms',
        this.splitTable('_7days', this.data.inp.prop.good),
        this.splitTable('_7days', this.data.inp.prop.poor),
        this.splitTable('_7days', this.data.inp.prop.needImprovement),
        this.splitTable('_7days', this.data.date),
        this.annotationBarChart,
      );
    //lineChart
    this.chartOptionsLcpP75 =
      this.pageSpeedInsigthService.chartOptionsmetricOverTime(
        'P75 (ms)',
        this.data.lcp.p75,
        this.data.date,
        this.annotationLineChart,
      );
    this.chartOptionsClsP75 =
      this.pageSpeedInsigthService.chartOptionsmetricOverTime(
        'P75',
        this.data.cls.p75,
        this.data.date,
        this.annotationLineChart,
      );

    this.chartOptionsFcpP75 =
      this.pageSpeedInsigthService.chartOptionsmetricOverTime(
        'P75 (ms)',
        this.data.fcp.p75,
        this.data.date,
        this.annotationLineChart,
      );
    this.chartOptionsInpP75 =
      this.pageSpeedInsigthService.chartOptionsmetricOverTime(
        'P75 (ms)',
        this.data.inp.p75,
        this.data.date,
        this.annotationLineChart,
      );
  }

  openDialog(isUXR?:boolean) {
    const dialogRef = this.dialog.open(HistogramDialogComponent, {
      data: {
        chartOptions: this.chartOptionsUxr,
        isUXRovertime : isUXR,
        buttonText: {
          cancel: 'Close',
          upgrade: 'Upgrade',
        },
      },
      maxWidth: '200vw',
      maxHeight: '100vh',
      height: '70%',
      width: '80%',
    });
  }

  splitTablePages(
    dates,
    good,
    needsIprovement,
    poor,
    legendData?: { good: string; moderate: string; poor: string },
  ) {
    const configObj: any = {};
    const rounds = Math.floor(good.length / 15);
    const reminder = good.length % 15;
    if (rounds > 0) {
      for (let i = 0; i < rounds; i++) {
        const currentRange = [15 * i, 15 * (i + 1)];
        const key = `${dates[currentRange[0]]} to ${
          dates[currentRange[1] - 1]
        }`;
        configObj[key] = this.pageSpeedInsigthService.chartOptionMonthGPN(
          legendData.good,
          legendData.moderate,
          legendData.poor,
          good.slice(currentRange[0], currentRange[1]),
          poor.slice(currentRange[0], currentRange[1]),
          needsIprovement.slice(currentRange[0], currentRange[1]),
          dates.slice(currentRange[0], currentRange[1]),
          this.annotationBarChart,
        );
      }
      if (reminder > 0) {
        const startIndex = rounds * 15;
        const endIndex = good.length;
        const key = `${dates[startIndex]} to ${dates[endIndex - 1]}`;
        configObj[key] = this.pageSpeedInsigthService.chartOptionMonthGPN(
          legendData.good,
          legendData.moderate,
          legendData.poor,
          good.slice(startIndex, endIndex),
          poor.slice(startIndex, endIndex),
          needsIprovement.slice(startIndex, endIndex),
          dates.slice(startIndex, endIndex),
          this.annotationBarChart,
        );
      }
    }
    if (!rounds && !!reminder) {
      const key = `${dates[0]} to ${dates[reminder - 1]}`;
      configObj[key] = this.pageSpeedInsigthService.chartOptionMonthGPN(
        legendData.good,
        legendData.moderate,
        legendData.poor,
        good.slice(0, reminder),
        poor.slice(0, reminder),
        needsIprovement.slice(0, reminder),
        dates.slice(0, reminder),
        this.annotationBarChart,
      );
    }

    return configObj;
  }

  selectDateRange(selectedDateRange, metric) {
    switch (metric) {
      case 'ttfb':
        this.ttfbAllConfig.selectedDays = selectedDateRange;
        break;
      case 'lcp':
        this.lcpAllConfig.selectedDays = selectedDateRange;
        break;
      case 'cls':
        this.clsAllConfig.selectedDays = selectedDateRange;
        break;
      case 'fcp':
        this.fcpAllConfig.selectedDays = selectedDateRange;
        break;
      case 'inp':
        this.inpAllConfig.selectedDays = selectedDateRange;
    }
  }

  processP75ChartOption(metric, selected, type = 'p75') {
    let currentData;
    if (type === 'p75') {
      currentData = this.data?.[metric]?.p75 || [];
    } else {
      currentData = this.data.arrayTransferSize;
    }
    const dates = this.data?.date || [];

    switch (selected) {
      case '_7days':
        const data7Days = this.splitTable('_7days', currentData);
        const days7 = this.splitTable('_7days', dates);
        const partionedD7 = this.partitionData(data7Days, days7, type);
        if (type === 'p75') {
          this.p75ChartOpts[metric]['_7days'] = partionedD7;
        }

        break;
      case '_30days':
        const data30Days = this.splitTable('_30days', currentData);
        const days30 = this.splitTable('_30days', dates);
        const partitionedD30 = this.partitionData(data30Days, days30, type);
        if (type === 'p75') {
          this.p75ChartOpts[metric]['_30days'] = partitionedD30;
        }

        break;
      case '_60days':
        const data60Days = this.splitTable('_60days', currentData);
        const days60 = this.splitTable('_60days', dates);
        const partitionedD60 = this.partitionData(data60Days, days60, type);
        if (type === 'p75') {
          this.p75ChartOpts[metric]['_60days'] = partitionedD60;
        }

        break;
      case '_90days':
        const data90Days = this.splitTable('_90days', currentData);
        const days90 = this.splitTable('_90days', dates);
        const partitionedD90 = this.partitionData(data90Days, days90, type);
        if (type === 'p75') {
          this.p75ChartOpts[metric]['_90days'] = partitionedD90;
        }

        break;
    }
  }

  partitionData(data, dates, type) {
    if (!dates.length) return;

    const rounds = Math.floor(dates.length / 15);
    const reminder = dates.length % 15;
    const dateGroups = [];
    const dataByRange = {};

    if (rounds > 0) {
      for (let i = 0; i < rounds; i++) {
        const currentRange = [15 * i, 15 * (i + 1)];
        const dateGroup = `${dates[currentRange[0]]} to ${
          dates[currentRange[1] - 1]
        }`;
        dateGroups.push(dateGroup);
        if (type === 'p75') {
          dataByRange[dateGroup] =
            this.pageSpeedInsigthService.chartOptionsmetricOverTime(
              'P75 (ms)',
              data.slice(currentRange[0], currentRange[1]),
              dates.slice(currentRange[0], currentRange[1]),
              this.annotationLineChart,
            );
        }

        if (reminder > 0) {
          const startIndex = rounds * 15;
          const endIndex = dates.length;
          const dateGroup = `${dates[startIndex]} to ${dates[endIndex - 1]}`;
          dateGroups.push(dateGroup);
          if (type === 'p75') {
            dataByRange[dateGroup] =
              this.pageSpeedInsigthService.chartOptionsmetricOverTime(
                'P75 (ms)',
                data.slice(startIndex, endIndex),
                dates.slice(startIndex, endIndex),
                this.annotationLineChart,
              );
          }
        }
      }
    }
    if (!rounds && !!reminder) {
      const dateGroup = `${dates[0]} to ${dates[reminder - 1]}`;
      if (type === 'p75') {
        dataByRange[dateGroup] =
          this.pageSpeedInsigthService.chartOptionsmetricOverTime(
            'P75 (ms)',
            data.slice(0, reminder),
            dates.slice(0, reminder),
            this.annotationLineChart,
          );
      }
    }
    return {
      dateGroups,
      ...dataByRange,
    };
  }
  copyUrlToClipboard(): void {
    navigator.clipboard.writeText(this.url).then(() => {
      this.snackBar.open('URL copied!' , 'Close');
    }).catch(err => {
      this.snackBar.open('Error copying URL!' , 'Close');
    });
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined) return false;
    return true;
  }
  getDiffStatus(diff: number | null): string {
    if (diff === null || diff === 0) return 'stabilizing';
    return diff > 0 ? 'improving' : 'regressing';
  }
}
