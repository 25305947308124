import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/common/user/user.service';
import { StripeService } from 'src/app/services/stripe.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
@Component({
    selector: 'app-buy-plan',
    templateUrl: './buy-plan.component.html',
    styleUrls: ['./buy-plan.component.scss'],
    standalone: false
})
export class BuyPlanComponent implements OnInit {
  public plans: any;
  name: Array<String> = [];
  text: Array<String> = [];
  // value:Array<String> = [];
  public isChecked = false;
  value: any = [];
  dataValue = [];
  tooltib = [];
  typeMA = 'yearly';
  price = [];
  link = [];
  featured: boolean[] = [];
  currentPlan
  httpClient: any;
  user: Observable<any>;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private stripeService: StripeService,
  ) {}

  async ngOnInit() {
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    const user = await firstValueFrom(this.userService.getUserDetail(userId));
  
    this.userService.getAllPlan().subscribe(
      (resp) => {
        this.plans = resp;
        this.plans = this.sortByPrice(this.plans);
        
        if (user.isTrialActive !== undefined && user.isTrialActive !== null) {
          this.plans = this.plans.filter(plan => plan.name !== 'Trial');
        }

        this.price = [];
        this.link = [];
        this.name = [];
        this.text = [];
        this.tooltib = [];
        this.dataValue = [];
        this.featured = [];

        this.plans.map((data) => {
          this.price.push(data.price[this.typeMA]);
          this.link.push(data.link[this.typeMA]);
          this.name.push(data.name);
          for (const key in data.details) {
            this.text.push(data.details[key].text);
            this.tooltib.push(data.details[key].tooltip);
            this.text = [...new Set(this.text)];
            this.dataValue.push(data.details[key].value);
          }
          this.featured.push(data.featured); 
        });
        this.text.map((txt) => {
          this.dataValue = [];
          this.plans.map((data) => {
            for (const key in data.details) {
              if (data.details[key].text === txt.trim()) {
                this.dataValue.push(data.details[key].value);
              }
            }
          });
          this.value.push(this.dataValue);
        });
        this.plans.forEach(plan => {
          plan.details = this.sortObjectsByOrder(Object.values(plan.details),this.text);
        });
      },
      (err) => {
        if (err.status === 401) {
          // localStorage.clear();
          // this.router.navigate(['/auth/login']) ;
        }
      }
    );

  }


  async redirectToCheckout(priceID: string, mode?: string){
    var model = {
       priceId: priceID,
       userID:localStorage.getItem(ConstantService.localStorageKeys.userId),
       mode: mode
    }
    await this.stripeService.redirectToCheckout(model);
  }

  changeBillingType(event: any) {
    this.typeMA = event.target.checked ? 'yearly' : 'monthly';
    
    // Réinitialiser les tableaux de prix et de liens
    this.price = [];
    this.link = [];
  
    // Mettre à jour les prix et les liens selon le type de facturation sélectionné
    this.plans.forEach(data => {
      this.price.push(data.price[this.typeMA]);
      this.link.push(data.link[this.typeMA]);
    });
  
    
  }
  
  sortByPrice(arr: any) {
    arr.sort(function (a: any, b: any) {
      return a.price.monthly - b.price.monthly;
    });
    return arr;
  }
  roundPrice(price: number): number {
    return Math.round(price);
  }
  isValueBoolean(val) {
    return typeof val === 'boolean';
  }

  updateFeatureTexts(txt) {
    let msg = '';
    if (
      txt.trim() == 'Domain data retention' ||
      txt.trim() == 'Internal pages data retention'
    ) {
      msg = 'Month(s)';
    }
    return msg;
  }
    sortObjectsByOrder(objects, order) {
      const orderMap = new Map(order.map((text, index) => [text, index]));
  
      objects.sort((a, b) => {
          const indexA = orderMap.has(a.text) ? orderMap.get(a.text) : order.length;
          const indexB = orderMap.has(b.text) ? orderMap.get(b.text) : order.length;
  
          return indexA - indexB;
      });
  
      return objects;
  }
}
