<div>
  <app-help-widget
  [currentURL]="projectUrl"
  [userId]="userId"
  [projectID]="projectID"
  [Url] = "Url"
  >
</app-help-widget>
  <nav class="mx-4 mt-3" aria-label="breadcrumb">
    <ol
      style="height: 50px; width: 52px"
      class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center"
    >
    @defer{
      @if (estAlloue(this.selectedCountry)) {
        <span class="mx-1">
            <mat-icon [svgIcon]="getCountryCode(this.selectedCountry)"></mat-icon>
        </span>
       }@else{
        <mat-icon  class="mx-1">language</mat-icon>
       }
    }
      <li>
        <span class="fw-bold">RUM > </span>
      </li>
      <li class="breadcrumb-item">
        <a
          class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center"
          [routerLink]=""
        >
          <mat-icon class="mx-2">desktop_windows</mat-icon>
          <span>{{ projectTitle }} Overview</span>
          @if(sharedMode) {
          <span>-</span>
          <span style="color: coral"> (Shared)</span>
          }
        </a>
      </li>
    </ol>
  </nav>
  <div *ngIf="loading" class="d-flex justify-content-center align-items-center"  style="height: -1000px">
    <mat-spinner class="spinner" diameter="150"></mat-spinner>
  </div>
  <div *ngIf="!loading"  class="d-flex justify-content-start align-items-center mx-4">
    <button class="btn mx-4 btn-primary"[matMenuTriggerFor]="menu" #menuContacts="matMenuTrigger">
      <div class="d-flex justify-content-center align-items-center w-100">
        <mat-icon>filter_list</mat-icon>
        <span>Filter</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </button>
    <mat-menu #menu="matMenu">
        <section mat-menu-item class="example-section">
          <mat-form-field appearance="outline" style=" margin-bottom: -10px; margin-top: 10px;">
            <mat-label>Select Dimension</mat-label>
            <mat-select [value]="selectedDimension"  (click)="$event.stopPropagation();$event.preventDefault" (selectionChange)="closeMe(menuContacts)">
              <mat-optgroup [label]="'Select Dimension'">
                <mat-option [value]="'all'" (click)="setDimension('all', 'all')">
                  All
                </mat-option>
              </mat-optgroup>
              <mat-optgroup
                *ngFor="let group of dimensionGroups?.[device]"
                [label]="group.name"
              >
                <mat-option
                  *ngFor="let dimension of group.dimensions"
                  (click)="setDimension(dimension, group.id)"
                  [value]="dimension"
                  ><p *ngIf="dimension === selectedDimension">
                    {{ dimension }} ({{ group.id }})
                  </p>
                  <p *ngIf="dimension !== selectedDimension">
                    {{ dimension }}
                  </p>
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </section>
        <div mat-menu-item>
          <mat-form-field appearance="outline" style=" margin-bottom: -10px; margin-top: 10px;">
            <mat-label>Select Location</mat-label>
            <mat-select [value]="selectedCountry"  (click)="$event.stopPropagation();$event.preventDefault" (selectionChange)="closeMe(menuContacts)">
              <mat-option [value]="'Global'" (click)="onCountrySelect('Global')"
                >Global</mat-option
              >
              @for(country of monitoringLocations; track country) {
              <mat-option [value]="country" (click)="onCountrySelect(country)">
                {{ country }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
    </mat-menu>
    <div style="display: flex; gap: 10px; align-items: center; width: 100%;">
      <div style="font-weight: bold">Filters:</div>
      <div><mat-chip>Location: {{selectedCountry}}</mat-chip></div>
      <div>
        <mat-chip>
          @if(selectedDimGroup == 'all') {
            Dimension
          }
          @if(selectedDimGroup !== 'all') {
            {{ selectedDimGroup | slice:0:1 | uppercase }}{{ selectedDimGroup | slice:1 }}
          }
          : {{ selectedDimension | slice:0:1 | uppercase }}{{ selectedDimension | slice:1 }}
          </mat-chip></div>
          @if(selectedDimGroup !== 'all' || selectedCountry !== 'Global') {
             <mat-icon style="cursor: pointer;" (click)="clearCurrentFilters()">cancel</mat-icon>
          }
    </div>
  </div>

  <div *ngIf="!loading"  class="m-4">
    <div class="mx-4 mt-3 d-flex justify-content-between align-items-center">
      <div class="radio-inputs mb-1">
        <label class="radio" *ngFor="let deviceT of deviceTypes">
          <input
            [value]="deviceT"
            type="radio"
            name="deviceRadio"
            [(ngModel)]="device"
            [checked]="device === deviceT"
            (change)="setDevice(deviceT)"
          />
          <span class="name"> {{ deviceT | uppercase }}</span>
        </label>
      </div>
      <div>
        <button type="button" class="btn btn-sm btn-outline-primary mx-3" (click)="showScoreProgressChart(true)">
          UXR score and status progress
        </button>
      </div>
      <div class="radio-inputs mb-1">
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="monthly"
            [(ngModel)]="queryPeriod"
            (change)="setQueryPeriod($event)"
          />
          <span class="name">monthly</span>
        </label>
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="weekly"
            [(ngModel)]="queryPeriod"
            (change)="setQueryPeriod($event)"
          />
          <span class="name">weekly</span>
        </label>
        <label class="radio">
          <input
            type="radio"
            name="periodRadio"
            value="daily"
            [(ngModel)]="queryPeriod"
            (change)="setQueryPeriod($event)"
          />
          <span class="name">daily</span>
        </label>
      </div>
    </div>

    <div *ngIf="!loading" class="d-flex justify-content-center align-items-center">
      <div class="row w-75">
        <!-- Loop through each group of metrics -->
        @for (group of ['speed', 'layoutStability', 'interactivity']; track $index) {
          <!-- Section Title -->
       <!-- Display the group title -->
       <div class="d-flex align-items-center mt-4 py-1 px-4 border-bottom mb-3">
        <h1 class="mb-0  text-dark">
          {{ $index +1 }}.{{ (group === 'layoutStability' ? 'Layout Stability' : group) | titlecase }}
        </h1>
        <span class="text-muted small ms-3 ps-3 border-start">
          ({{ getTooltipText(group) }})
        </span>
      </div>

          <!-- Loop through each metric within the group -->
          @for (metric of metricsGrouped[group]; track $index) {
            <div class="col-sm-12 col-md-6 col-lg-4 hover-zoom">
              <div class="graph p-1">
                <div class="title">
                  <div class="d-flex flex-column justify-content-center align-items-center mt-3">
                    <strong class=" m-0">{{getMetricName(metric)}}</strong>


                  <div class="diff-indicator d-flex justify-content-center align-items-center mt-2">
                    <ng-container [ngSwitch]="getDiffStatus(metricsData[metric]?.diff)">
                      <span *ngSwitchCase="'improving'" class="text-success">
                         Improving (+{{ metricsData[metric]?.diff }}%)<mat-icon>arrow_upward</mat-icon>
                      </span>
                      <span *ngSwitchCase="'regressing'" class="text-danger">
                        Regressing ({{ metricsData[metric]?.diff }}%)<mat-icon>arrow_downward</mat-icon>
                      </span>
                      <span *ngSwitchCase="'stabilizing'" class="text-muted">
                        Stabilizing ({{ metricsData[metric]?.diff }}%)<mat-icon>arrow_forward</mat-icon>
                      </span>
                    </ng-container>
                  </div>

                  </div>

                  <!-- No Data Message -->
                  @if (!metricsData?.[metric]) {
                    <p>No data available for <strong>{{ metric }}</strong></p>
                  }

                    <!-- Display the 75th percentile value -->
                    <div class="d-flex justify-content-center align-items-center mt-1 text-muted small">
                        <span class="mx-1">
                          75th percentile: {{ formatP75Display(metricsData?.[metric]?.p75)  }}{{ metric === 'cls' ? '' : 'ms' }}
                        </span>
                      <mat-icon
                        style="font-size: 18px; opacity: 0.7;"
                        [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                        matTooltipPosition="left">
                        help
                      </mat-icon>
                    </div>
                </div>


                <!-- Track Button and Chart if data is available -->
                @if (metricsData?.[metric]) {

                  <div class="d-flex justify-content-center align-items-center my-3">
                    <button type="button"
                            class="btn btn-track d-flex align-items-center"
                            [routerLink]="['/home/rum', siteId, metric]">
                      <mat-icon class="me-2">timeline</mat-icon>
                      <span>Track</span>
                    </button>
                  </div>

                  <apx-chart
                    [series]="metricsData[metric]?.series"
                    [chart]="metricsData[metric]?.chart"
                    [dataLabels]="metricsData[metric]?.dataLabels"
                    [plotOptions]="metricsData[metric]?.plotOptions"
                    [xaxis]="metricsData[metric]?.xaxis"
                    [yaxis]="metricsData[metric]?.yaxis"
                    [stroke]="metricsData[metric]?.stroke"
                    [fill]="metricsData[metric]?.fill"
                    [tooltip]="metricsData[metric]?.tooltip"
                    [legend]="metricsData[metric]?.legend">
                  </apx-chart>
                }
              </div>
            </div>
          }
        }
      </div>
    </div>


  </div>
</div>
