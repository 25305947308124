import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { flatten, groupBy, isNumber, keys, sortBy } from 'lodash';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { FirstPartyDataServiceService } from 'src/app/services/first-party-data-service.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { legend } from 'src/app/shared/models/legend';
import { HistogramDialogComponent } from '../../histogram-dialog/histogram-dialog.component';
import { CountryCodes } from '../../../../consts/countryCode';
import { DaysRangeSelectorComponent } from '../days-range-selector/days-range-selector.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { RumService } from 'src/app/services/rum.service';

@Component({
    selector: 'app-first-metric-data',
    templateUrl: './first-metric-data.component.html',
    styleUrls: ['./first-metric-data.component.scss'],
    standalone: false
})
export class FirstMetricDataComponent implements OnInit {
  @Output() public deviceEvent = new EventEmitter<string>();
  @ViewChild('menuContacts') menuContacts;
  @Input() metric: string;
  projectId: string = null;
  metricData = null;
  loading = false;
  metricsBreakdown: any = {};
  rawResult: any = {};
  p75ChartOptions: any = {};
  p75value;
  barchartOvertime;
  selectedDatesRange;

  queryLevel: string = 'domain';
  queryPeriod: string = 'daily';
  device: string = 'desktop';
  chartOptionsFPD = null;
  currentComputedData = [];
  apiResponseMessage: string = '';
  deviceTypes = ConstantService.defaultDevices;
  dimensionGroups = ConstantService.defaultDimensionGroup;
  selectedDimension: string = 'all';
  selectedDimGroup: string = 'all';
  daysRangeSelected = '7';
  numberOfDataPoints = 30;
  startDate;
  endDate;
  dataList: any[];
  sites: any;
  siteTitle: any;
  site: any;
  p25value: number;
  p50value: number;
  p95value: number;
  sharedMode = false;
  monitoringLocations = [];
  selectedCountry = 'Global';
  attributionData = {};
  userId;
  timeToFirstByte_p75: any;
  resourceLoadDuration_p75: any;
  resourceLoadDelay_p75: any;
  elementRenderDelay_p75: any;
  p75subParts: any;
  p75subPartsLastValue: any;
  requestDuration_p75: number;
  connectionDuration_p75: number;
  dnsDuration_p75: number;
  cacheDuration_p75: number;
  waitingDuration_p75: number;
  Url: string;
  currentUrl: any;
  constructor(
    public webAnalyzeService: WebsiteAnalyzeService,
    public webAnalizeService: WebsiteAnalyzeService,
    private route: Router,
    private pageSpeedInsideService: PageSpeedInsigthService,
    private dialog: MatDialog,
    private fpdService: FirstPartyDataServiceService,
    private rumService: RumService,
    private router: Router
  ) {
    this.projectId = this.route.url.split('/')[3];
  }

  async ngOnInit() {
    this.Url = this.router.url;
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    this.userId = userId;
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.selectedDimension =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimension) || 'all';
    this.selectedDimGroup =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimGroup) || 'all';
    this.queryPeriod =
      localStorage.getItem(ConstantService.loadingUrl.fpdQueryPeriod) ||
      'daily';
    this.sharedMode =
      localStorage.getItem(ConstantService.localStorageKeys.sharedMode) ===
      'on';
    this.selectedCountry =
      localStorage.getItem(
        ConstantService.localStorageKeys.selectedRUMCountry
      ) || 'Global';
    if (!this.sharedMode) {
      this.sites = await this.webAnalizeService
        .getWebsiteAnalyze(userId)
        .toPromise();
      this.site = this.sites.find((site) => site.id === this.projectId);
      this.siteTitle = this.site?.title;
    }
    this.fetchVitalData();
  }

  onCountrySelect(country) {
    this.selectedCountry = country;
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      country
    );
    this.updateTableData();
  }

  setDevice(selectedDevice: string) {
    this.device = selectedDevice;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);
    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';

    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimension,
      this.selectedDimension
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    this.updateTableData();
  }

  updateTableData() {
    const result = this.dataList;
    const dates = result.map((r) => r.date);
    const computed = this.rumService.getComputedMetric(
      result,
      this.selectedDimGroup,
      this.selectedDimension,
      this.device,
      this.selectedCountry
    );
    const p25Data = computed.map((r) =>
      Math.round(r?.metricData?.calculated?.[0]?.percentiles?.[0] || 0)
    );
    const p50Data = computed.map((r) =>
      Math.round(r?.metricData?.calculated?.[0]?.percentiles?.[1] || 0)
    );
    const p75Data = computed.map((r) =>
      Math.round(r?.metricData?.calculated?.[0]?.percentiles?.[2] || 0)
    );
    const p95Data = computed.map((r) =>
      Math.round(r?.metricData?.calculated?.[0]?.percentiles?.[3] || 0)
    );

    const timeToFirstByte_p75_Data = computed.map((r) =>
      Math.round(r?.attributionData[this.device]?.lcp[0]?.subParts?.timeToFirstByte || 0)
    );
    const resourceLoadDuration_p75_Data = computed.map((r) =>
      Math.round(r?.attributionData[this.device]?.lcp[0]?.subParts?.resourceLoadDuration|| 0)
    );
    const resourceLoadDelay_p75_Data = computed.map((r) =>
      Math.round(r?.attributionData[this.device]?.lcp[0]?.subParts?.resourceLoadDelay || 0)
    );
    const elementRenderDelay_p75_Data = computed.map((r) =>
      Math.round(r?.attributionData[this.device]?.lcp[0]?.subParts?.elementRenderDelay || 0)
    );

    const waitingDuration_p75_Data = computed.map((r) =>
      Math.round(r?.attributionData[this.device]?.ttfb?.[0]?.subParts?.waitingDuration || 0)
    );
    const cacheDuration_p75_Data = computed.map((r) =>
      Math.round(r?.attributionData[this.device]?.ttfb?.[0]?.subParts?.cacheDuration || 0)
    );
    const dnsDuration_p75_Data = computed.map((r) =>
      Math.round(r?.attributionData[this.device]?.ttfb?.[0]?.subParts?.dnsDuration || 0)
    );
    const connectionDuration_p75_Data = computed.map((r) =>
      Math.round(r?.attributionData[this.device]?.ttfb?.[0]?.subParts?.connectionDuration || 0)
    );
    const requestDuration_p75_Data = computed.map((r) =>
      Math.round(r?.attributionData[this.device]?.ttfb?.[0]?.subParts?.requestDuration || 0)
    );



    this.p25value = p25Data[p25Data.length - 1];
    this.p50value = p50Data[p50Data.length - 1];
    this.p75value = p75Data[p75Data.length - 1];
    this.p95value = p95Data[p95Data.length - 1];


    this.timeToFirstByte_p75 = timeToFirstByte_p75_Data[timeToFirstByte_p75_Data.length - 1] / 1000;
    this.resourceLoadDuration_p75 = resourceLoadDuration_p75_Data[resourceLoadDuration_p75_Data.length - 1] / 1000;
    this.resourceLoadDelay_p75 = resourceLoadDelay_p75_Data[resourceLoadDelay_p75_Data.length - 1] / 1000;
    this.elementRenderDelay_p75 = elementRenderDelay_p75_Data[elementRenderDelay_p75_Data.length - 1] / 1000;

    this.waitingDuration_p75 = waitingDuration_p75_Data[waitingDuration_p75_Data.length - 1] / 1000;
    this.cacheDuration_p75 = cacheDuration_p75_Data[cacheDuration_p75_Data.length - 1] / 1000;
    this.dnsDuration_p75 = dnsDuration_p75_Data[dnsDuration_p75_Data.length - 1] / 1000;
    this.connectionDuration_p75 = connectionDuration_p75_Data[connectionDuration_p75_Data.length - 1] / 1000;
    this.requestDuration_p75 = requestDuration_p75_Data[requestDuration_p75_Data.length - 1] / 1000;




    if(this.metric === 'lcp'){
    this.p75subPartsLastValue = this.pageSpeedInsideService.chartOptionP75SubPartsLastValue(
      [this.timeToFirstByte_p75],
      [dates[dates.length - 1]],
      this.metric,
      [this.resourceLoadDuration_p75],
      [this.resourceLoadDelay_p75],
      [this.elementRenderDelay_p75]
    );

    this.p75subParts = this.pageSpeedInsideService.chartOptionP75SubParts(
      timeToFirstByte_p75_Data,
      dates,
      this.metric,
      resourceLoadDuration_p75_Data,
      resourceLoadDelay_p75_Data,
      elementRenderDelay_p75_Data
    );
    }else if(this.metric === 'ttfb'){
      this.p75subPartsLastValue = this.pageSpeedInsideService.chartOptionP75SubPartsLastValueTTFB(
        [this.waitingDuration_p75],
        [dates[dates.length - 1]],
        this.metric,
        [this.cacheDuration_p75],
        [this.dnsDuration_p75],
        [this.connectionDuration_p75],
        [this.requestDuration_p75]
      );

      this.p75subParts = this.pageSpeedInsideService.chartOptionP75SubPartsTTFB(
        waitingDuration_p75_Data,
        dates,
        this.metric,
        cacheDuration_p75_Data,
        dnsDuration_p75_Data,
        connectionDuration_p75_Data,
        requestDuration_p75_Data
      );
    }


    this.p75ChartOptions = this.pageSpeedInsideService.chartOptionCruxP75(
      p75Data,
      dates,
      this.metric,
      p25Data,
      p50Data,
      p95Data
    );


    const good = computed.map((r) =>
      (r?.metricData?.calculated?.[0]?.pctGood * 100 || 0).toFixed(2)
    );
    const needsImprovement = computed.map((r) =>
      (r?.metricData?.calculated?.[0]?.pctNeedsImprovement * 100 || 0).toFixed(
        2
      )
    );
    const poor = computed.map((r) =>
      (r?.metricData?.calculated?.[0]?.pctPoor * 100 || 0).toFixed(2)
    );
    const legendData = legend[this.metric];
    const barchartData = {
      good,
      needsImprovement,
      poor,
      p75: p75Data,
      month: dates,
      date: dates,
      nameGood: legendData.good,
      nameNeedsImprovement: legendData.moderate,
      namePoor: legendData.poor,
    };
    this.chartOptionsFPD = this.pageSpeedInsideService.chartOptionCrux(
      barchartData,
      {},
      localStorage.getItem(ConstantService.loadingUrl.fpdQueryPeriod)
    );
  }

  trimURL(url) {
    const joint = url.split('/');
    return [joint.pop(), joint.pop()].reverse().join('/');
  }

  setQueryPeriod(event: Event) {
    const target = event.target as HTMLInputElement;
    const QueryPeriod = target.value;

    this.deviceEvent.emit(QueryPeriod);
    this.queryPeriod = QueryPeriod;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdQueryPeriod,
      this.queryPeriod
    );

    this.fetchVitalData();
  }

  setDimension(dimension, dimensionGroup) {
    this.selectedDimension = dimension;
    this.selectedDimGroup = dimensionGroup;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      dimensionGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, dimension);
    this.updateTableData();
  }

  clearCurrentFilters() {
    this.selectedCountry = 'Global';
    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      this.selectedCountry
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, 'all');
    this.updateTableData();
  }

  async fetchVitalData() {
    this.loading = true;

    let { data: result, locations } = await this.webAnalyzeService
      .getRumMetric(
        this.projectId,
        this.metric,
        this.queryPeriod,
        this.daysRangeSelected
      )
      .toPromise();
    this.monitoringLocations = locations;
    if (!result?.length) {
      this.loading = false;
      this, (this.chartOptionsFPD = null);
      return;
    }
    this.dataList = result;
    this.currentUrl = this.dataList[0]?.url;
    this.attributionData = result?.[result?.length - 1].attributionData;
    this.updateTableData();
    this.loading = false;
  }

  getMetric(): string {
    return this.metric === 'cls' ? this.metric : this.metric + 's';
  }

  executeSelectionChange() {
    if (this.daysRangeSelected == 'custom') return;
    this.startDate = '';
    this.endDate = '';
    this.fetchVitalData();
  }

  openDialog() {
    const dialogRef = this.dialog.open(HistogramDialogComponent, {
      data: {
        chartOptions: this.p75ChartOptions,
        buttonText: {
          cancel: 'Close',
        },
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '70%',
      width: '70%',
      //panelClass: 'full-screen-modal'
    });
  }
  openDialogSubParts() {
    const dialogRef = this.dialog.open(HistogramDialogComponent, {
      data: {
        chartOptions: this.p75subParts,
        buttonText: {
          cancel: 'Close',
        },
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '70%',
      width: '70%',
      //panelClass: 'full-screen-modal'
    });
  }


  openDaysSelectorDialog() {
    const dialogRef = this.dialog.open(DaysRangeSelectorComponent, {
      height: '300px',
      width: '600px',
    });
    dialogRef.componentInstance.selectionChange.subscribe((val) => {
      this.startDate = val.startDate;
      this.endDate = val.endDate;
      this.daysRangeSelected = `${this.startDate}_${this.endDate}`;
      this.fetchVitalData();
    });
    dialogRef
      .afterClosed()
      .subscribe(() =>
        dialogRef.componentInstance.selectionChange.unsubscribe()
      );
  }

  overViewPage() {
    return '/home/rum/' + this.projectId + '/overview';
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined || code == 'Global')
      return false;
    return true;
  }

  partitionData(barchartData) {
    const {
      good,
      needsImprovement,
      poor,
      p75,
      date: dates,
      nameGood,
      nameNeedsImprovement,
      namePoor,
    } = barchartData;
    if (!dates.length) return;
    try {
      const rounds = Math.floor(dates.length / this.numberOfDataPoints);
      const reminder = dates.length % this.numberOfDataPoints;
      const dateGroups = [];
      const dataByRange = {};
      let partitionedDates = [];

      if (rounds > 0) {
        for (let i = 0; i < rounds; i++) {
          const currentRange = [
            this.numberOfDataPoints * i,
            this.numberOfDataPoints * (i + 1),
          ];
          const dateGroup = `${dates[currentRange[0]]} to ${
            dates[currentRange[1] - 1]
          }`;
          dateGroups.push(dateGroup);

          partitionedDates = dates.slice(currentRange[0], currentRange[1]);
          dataByRange[dateGroup] = this.pageSpeedInsideService.chartOptionCrux(
            {
              good: good.slice(currentRange[0], currentRange[1]),
              needsImprovement: needsImprovement.slice(
                currentRange[0],
                currentRange[1]
              ),
              poor: poor.slice(currentRange[0], currentRange[1]),
              p75: p75.slice(currentRange[0], currentRange[1]),
              month: partitionedDates,
              date: partitionedDates,
              nameGood,
              nameNeedsImprovement,
              namePoor,
            },
            {}
          );

          if (reminder > 0) {
            const startIndex = rounds * this.numberOfDataPoints;
            const endIndex = dates.length;
            const dateGroup = `${dates[startIndex]} to ${dates[endIndex - 1]}`;
            dateGroups.push(dateGroup);
            partitionedDates = dates.slice(startIndex, endIndex);
            dataByRange[dateGroup] =
              this.pageSpeedInsideService.chartOptionCrux(
                {
                  good: good.slice(startIndex, endIndex),
                  needsImprovement: needsImprovement.slice(
                    startIndex,
                    endIndex
                  ),
                  poor: poor.slice(startIndex, endIndex),
                  p75: p75.slice(startIndex, endIndex),
                  month: partitionedDates,
                  date: partitionedDates,
                  nameGood,
                  nameNeedsImprovement,
                  namePoor,
                },
                {}
              );
          }
        }
      }
      if (!rounds && !!reminder) {
        const dateGroup = `${dates[0]} to ${dates[reminder - 1]}`;
        dateGroups.push(dateGroup);
        partitionedDates = dates.slice(0, reminder);

        dataByRange[dateGroup] = this.pageSpeedInsideService.chartOptionCrux(
          {
            good: good.slice(0, reminder),
            needsImprovement: needsImprovement.slice(0, reminder),
            poor: poor.slice(0, reminder),
            p75: p75.slice(0, reminder),
            month: partitionedDates,
            date: partitionedDates,
            nameGood,
            nameNeedsImprovement,
            namePoor,
          },
          {}
        );
      }
      return {
        dateGroups,
        ...dataByRange,
      };
    } catch (error) {
      console.log(error);
    }
  }

  setSelectedDatesRange(val) {
    this.selectedDatesRange = val;
  }

  getCountryCode(countryName: string): string | undefined {
    return CountryCodes[countryName];
  }
  closeMe(menuTrigger: MatMenuTrigger) {
    menuTrigger.closeMenu();
  }

  isAllP75Empty(): boolean {
    if (!this.p75subPartsLastValue?.series) {
      return true;
    }

    for (const singleSeries of this.p75subPartsLastValue.series) {
      if (Array.isArray(singleSeries.data)) {
        const hasNonZero = singleSeries.data.some(
          value => value !== 0 && value !== null && value !== undefined
        );
        if (hasNonZero) {
          return false;
        }
      }
    }
    return true;
  }
}
