<div>
  <app-help-widget
  [currentURL]="''"
  [userId]="userId"
  [projectID]="projectId"
  [Url] = "Url"
  >
</app-help-widget>
  <div class="mx-4">
  <nav class="mx-4 mt-3" aria-label="breadcrumb">
    <ol style="height: 50px; width: 52px"
        class="breadcrumb breadcrumb-custom overflow-hidden text-center border rounded-3 w-100 d-flex justify-content-center align-items-center">
    @defer{
      @if (estAlloue(this.selectedCountry)) {
        <span class="mx-1">
            <mat-icon [svgIcon]="getCountryCode(this.selectedCountry)"></mat-icon>
        </span>
       }@else{
        <mat-icon  class="mx-1">language</mat-icon>
       }
    }
      <li>
        <span class="fw-bold">RUM > </span>
      </li>
      <li class="breadcrumb-item">
        <a
          class="fw-semibold text-decoration-none d-flex justify-content-center align-items-center"
          [routerLink]="overViewPage()"
        >
          <mat-icon class="mx-2">desktop_windows</mat-icon>
          <span>{{ sitetitle }} Overview</span>
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Internal Pages</li>
      @if(sharedMode) {
      <span>-</span>
      <span style="color: coral"> (Shared)</span>
      }
    </ol>
  </nav>
  </div>

  @if (loading) {
  <div class="d-flex justify-content-center align-items-center my-3"style="height: -1000px">
    <mat-spinner class="spinner" diameter="150"></mat-spinner>
  </div>
 }@else{

  <div class="d-flex justify-content-center align-items-center w-100 mt-5">
    <div class="w-100">
      <div class="d-flex justify-content-between align-items-center mx-2">

        <button class="btn mx-4 btn-primary"[matMenuTriggerFor]="menu" #menuContacts="matMenuTrigger">
          <div class="d-flex justify-content-center align-items-center w-100">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
        </button>
        <mat-menu #menu="matMenu">
          <section mat-menu-item>
            <mat-form-field appearance="outline"  style=" margin-bottom: -10px; margin-top: 10px;">
              <mat-label>Select Dimension</mat-label>
              <mat-select [value]="selectedDimension" (click)="$event.stopPropagation();$event.preventDefault" (selectionChange)="closeMe(menuContacts)">
                  <mat-optgroup [label]="'Select Dimension'">
                    <mat-option [value]="'all'" (click)="setDimension('all', 'all')">
                      All
                    </mat-option>
                  </mat-optgroup>
                  <mat-optgroup *ngFor="let group of dimensionGroups?.[device]" [label]="group.name">
                    <mat-option
                      *ngFor="let dimension of group.dimensions"
                      (click)="setDimension(dimension, group.id)"
                      [value]="dimension"
                      ><p *ngIf="dimension === selectedDimension">
                        {{ dimension }} ({{ group.id }})
                      </p>
                      <p *ngIf="dimension !== selectedDimension">
                        {{ dimension }}
                      </p>
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
            </mat-form-field>
          </section>
          <div mat-menu-item>
            <mat-form-field appearance="outline" style=" margin-bottom: -10px; margin-top: 10px;">
              <mat-label>Select Location</mat-label>
              <mat-select [value]="selectedCountry" (click)="$event.stopPropagation();$event.preventDefault" (selectionChange)="closeMe(menuContacts)">
                <mat-option [value]="'Global'" (click)="onCountrySelect('Global')"
                  >Global</mat-option
                >
                @for(country of monitoringLocations; track country) {
                <mat-option [value]="country" (click)="onCountrySelect(country)">
                  {{ country }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </mat-menu>
        <div style="display: flex; gap: 10px; align-items: center; width: 100%;">
          <div style="font-weight: bold">Filters:</div>
          <div><mat-chip>Location: {{selectedCountry}}</mat-chip></div>
          <div>
            <mat-chip>
              @if(selectedDimGroup == 'all') {
                Dimension
              }
              @if(selectedDimGroup !== 'all') {
                {{ selectedDimGroup | slice:0:1 | uppercase }}{{ selectedDimGroup | slice:1 }}
              }
              : {{ selectedDimension | slice:0:1 | uppercase }}{{ selectedDimension | slice:1 }}
            </mat-chip></div>
            @if(selectedDimGroup !== 'all' || selectedCountry !== 'Global') {
              <mat-icon matTooltip="Reset filters" style="cursor: pointer;" (click)="clearCurrentFilters()">cancel</mat-icon>
            }
        </div>
        <div class="group">
          <svg viewBox="0 0 24 24" aria-hidden="true" class="icon">
            <g>
              <path
                d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
              ></path>
            </g>
          </svg>
          <input [value]="searchKeyword" class="input w-100" type="search" placeholder="Search an URL" autocomplete="off" (keyup)="search($event)" />
        </div>
      </div>
      <div>

          <div class="d-flex flex-column">
              <div class="d-flex justify-content-between align-items-center m-3">
                <div class="radio-inputs mb-3">
                  <label class="radio" *ngFor="let deviceT of deviceTypes">
                    <input
                      [value]="deviceT"
                      type="radio"
                      name="deviceRadio"
                      [(ngModel)]="device"
                      [checked]="device === deviceT"
                      (change)="setDevice(deviceT)"
                    />
                    <span class="name"> {{ deviceT | uppercase }}</span>
                  </label>
                </div>
                <div class="d-flex flex-column justify-content-center align-content-center">
                  <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" (click)="refreshbutton()">
                    Compare Two URLs
                  </button>
                  <mat-checkbox (change)="onUrlsBySegmentChange($event)">
                    Show URLs by segment
                  </mat-checkbox>
                </div>
                <div class="radio-inputs1 mb-3">
                  <label class="radio1">
                    <input
                      type="radio"
                      name="periodRadio"
                      value="monthly"
                      [(ngModel)]="queryPeriod"
                      (change)="setQueryPeriod($event)"
                    />
                    <span class="name1">monthly</span>
                  </label>
                  <label class="radio1">
                    <input
                      type="radio"
                      name="periodRadio"
                      value="weekly"
                      [(ngModel)]="queryPeriod"
                      (change)="setQueryPeriod($event)"
                    />
                    <span class="name1">weekly</span>
                  </label>
                  <label class="radio1">
                    <input
                      type="radio"
                      name="periodRadio"
                      value="daily"
                      [(ngModel)]="queryPeriod"
                      (change)="setQueryPeriod($event)"
                    />
                    <span class="name1">daily</span>
                  </label>
                </div>
              </div>
              <div class="collapse" id="collapseExample">
                <div class="card card-body mx-5 mb-4 p-4">
                  <span class="fs-5 text-center mb-3 me-3 fw-semibold">Select two URLs to compare</span>
                  <div class="d-flex justify-content-center align-content-center">
                    <div class="input-group w-25">
                      <select class="form-select" id="page1Select" [(ngModel)]="selectedPage1" (change)="onFirstSelectChange()">
                        <option value="" disabled selected>Select the first page</option>
                        <ng-container *ngFor="let group of transformedPages">
                          <optgroup [label]="group.title">
                            <option *ngFor="let page of group.urls" [value]="page.title">{{ page.title }}</option>
                          </optgroup>
                        </ng-container>
                      </select>
                    </div>
                    <div class="input-group mx-4 w-25">
                      <select class="form-select" id="page2Select" [(ngModel)]="selectedPage2">
                        <option value="" disabled selected>Select the second page</option>
                        <ng-container *ngFor="let group of filteredPages">
                          <optgroup [label]="group.title">
                            <option *ngFor="let page of group.urls" [value]="page.title">{{ page.title }}</option>
                          </optgroup>
                        </ng-container>
                      </select>
                    </div>
                    <button class="btn btn-primary" (click)="comparePages()">Compare</button>
                  </div>
                </div>
              </div>
            </div>
        <mat-paginator [length]="totalDataSize"
              [pageIndex]="pageIndex"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              [showFirstLastButtons]="showFirstLastButtons"
              aria-label="Select page"
              class="header-table mx-4"
              (page)="paginationChange($event)">
        </mat-paginator>



        <mat-card *ngIf="!dataSource.data.length" appearance="outline" class="card_no_borderRadiusTop mx-4 mb-4" >
            <div class="d-flex justify-content-center align-items-center">
              <h3 class="m-3">{{ apiResponseMessage }}</h3>
            </div>
        </mat-card>

        <div *ngIf="dataSource.data.length">
            <mat-table [dataSource]="dataSource" matSort (matSortChange)="onMatSortChange($event)" class="mat-elevation-z14 mx-4 mb-4"  >
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  <b>Page</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let entry">
                  <mat-card class="graph card-size d-flex flex-column justify-content-center align-items-center p-2" >
                    <mat-card-title
                      class="title"
                      #tooltip="matTooltip"
                      matTooltip="{{ entry.website }}"
                    >
                      {{ entry.title }}
                    </mat-card-title>
                    <mat-card-body class="d-flex justify-content-center align-items-center">
                      <span matTooltip="{{ formatUrl(entry.website) }}"
                            matTooltipPosition="above"
                            class="d-inline-block text-truncate"
                            style="max-width: 140px;">
                        {{ formatUrl(entry.website) }}
                      </span>
                      <mat-icon matTooltip="Copy Url" (click)="copyUrlToClipboard(entry.website)" class="mx-1" style="cursor: pointer;">content_copy</mat-icon>
                    </mat-card-body>

                    <mat-card-actions>
                      <button (click)="goToOverview(entry)" class="btn btn-primary">
                        Monitor
                      </button>
                    </mat-card-actions>
                  </mat-card>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="pageVisits">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  <b>Page views</b>
                </mat-header-cell>
                <mat-cell *matCellDef="let entry">
                  <b> {{ entry.total }}</b>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LCP">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  ><b>LCP</b></mat-header-cell
                >
                <mat-cell *matCellDef="let entry">
                  <div *ngIf="!!!entry.lcp" style="margin: 5%; width: 100%">
                    <h4 style="text-align: center">No LCP data</h4>
                  </div>
                  <div   *ngIf="!!entry.lcp" class="d-flex flex-column justify-content-center align-items-center ">
                    <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                      <span class="mx-1">
                       75th percentile: {{entry.lcp.p75 | number : "1.2-2"}}
                      </span>
                    <mat-icon
                      style="font-size: 18px; opacity: 0.7;"
                      [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                      matTooltipPosition="left">
                      help
                    </mat-icon>
                  </div>
                    <apx-chart
                      [series]="entry.lcp.series"
                      [chart]="entry.lcp.chart"
                      [dataLabels]="entry.lcp.dataLabels"
                      [plotOptions]="entry.lcp.plotOptions"
                      [xaxis]="entry.lcp.xaxis"
                      [yaxis]="entry.lcp.yaxis"
                      [stroke]="entry.lcp.stroke"
                      [fill]="entry.lcp.fill"
                      [tooltip]="entry.lcp.tooltip"
                      [legend]="entry.lcp.legend"
                    >
                    </apx-chart>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="CLS">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  ><b>CLS</b></mat-header-cell
                >
                <mat-cell *matCellDef="let entry">
                  <div *ngIf="!!!entry.cls" style="margin: 5%; width: 100%">
                    <h4 style="text-align: center">No CLS data</h4>
                  </div>
                  <div   *ngIf="!!entry.cls" class="d-flex flex-column justify-content-center align-items-center">
                    <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                      <span class="mx-1">
                       75th percentile: {{entry.cls.p75 | number : "1.2-2"}}
                      </span>
                    <mat-icon
                      style="font-size: 18px; opacity: 0.7;"
                      [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                      matTooltipPosition="left">
                      help
                    </mat-icon>
                  </div>
                  <apx-chart

                    [series]="entry.cls.series"
                    [chart]="entry.cls.chart"
                    [dataLabels]="entry.cls.dataLabels"
                    [plotOptions]="entry.cls.plotOptions"
                    [xaxis]="entry.cls.xaxis"
                    [yaxis]="entry.cls.yaxis"
                    [stroke]="entry.cls.stroke"
                    [fill]="entry.cls.fill"
                    [tooltip]="entry.cls.tooltip"
                    [legend]="entry.cls.legend"
                  >
                  </apx-chart>
                </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="INP">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  ><b>INP</b></mat-header-cell
                >
                <mat-cell *matCellDef="let entry"
                  ><div *ngIf="!!!entry.inp" style="margin: 5%; width: 100%">
                    <h4 style="text-align: center">No INP data</h4>
                  </div>
                  <div   *ngIf="!!entry.inp" class="d-flex flex-column justify-content-center align-items-center">
                    <div class="d-flex justify-content-center align-items-center mt-1 text-muted">
                      <span class="mx-1">
                       75th percentile: {{entry.inp.p75 | number : "1.2-2"}}
                      </span>
                    <mat-icon
                      style="font-size: 18px; opacity: 0.7;"
                      [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
                      matTooltipPosition="left">
                      help
                    </mat-icon>
                  </div>
                  <apx-chart
                    [series]="entry.inp.series"
                    [chart]="entry.inp.chart"
                    [dataLabels]="entry.inp.dataLabels"
                    [plotOptions]="entry.inp.plotOptions"
                    [xaxis]="entry.inp.xaxis"
                    [yaxis]="entry.inp.yaxis"
                    [stroke]="entry.inp.stroke"
                    [fill]="entry.inp.fill"
                    [tooltip]="entry.inp.tooltip"
                    [legend]="entry.inp.legend"
                  >
                  </apx-chart>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
              </mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns" class="mt-4"> </mat-row>
            </mat-table>
        </div>
      </div>
    </div>
  </div>

 }

</div>
