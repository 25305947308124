import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Plan, WebsiteAnalyze } from 'src/app/shared/models/user.models';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { HelperService } from 'src/app/shared/helper/helper.service';
import { countries } from 'src/app/shared/models/country.model';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../../services/core/auth/auth.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { MatSelect } from '@angular/material/select';
import { requireCheckboxesToBeCheckedValidator } from './check-Validators';
import { Router } from '@angular/router';
import {
  belongOriginDomainValidator,
  diffFromOriginDomainValidator,
} from './check-url-validators';
import { tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/common/user/user.service';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { MatSnackBar } from '@angular/material/snack-bar';

interface Page {
  title: string;
  url: string;
}

@Component({
    selector: 'app-new-page',
    templateUrl: './new-page.component.html',
    styleUrls: ['./new-page.component.scss'],
    standalone: false
})
export class NewPageComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  //
  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  //
  public websiteAnalyze = new WebsiteAnalyze();
  public countries: any = countries;
  Country: any;
  username: any;
  urlVerfied: false;
  b = true;
  removable = true;
  addOnBlur = true;
  allSelected = true;
  selectedValue: String[] = ['Mobile', 'Desktop'];
  loading: string = 'true';
  submitted = false;
  public newPageFormGroup: UntypedFormGroup;
  //public competitorsForm: FormGroup;
  public competitors: UntypedFormArray;
  public internalUrls: UntypedFormArray;
  competitionBouton = true;
  internalButtom = true;
  validatorInternal = true;
  locationButton: boolean;
  elementduplicates: any;
  regex: string;
  device: string;
  chipInput: any;
  superUserId: string;
  urlCtrl: any;
  x: any;
  apiURL: string;
  country1: any;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  intUrl: Page;
  compet: Page;
  test: Array<Page> = [];
  plan: Plan;
  planView = false;
  websitesRestantes = 0;
  cptCompet = 0;
  cptInter = 0;

  groupValidators: any;
  edit: boolean;
  editWebsite: WebsiteAnalyze;
  editDataLoading = true;
  validIn: boolean = false;
  validComp: boolean = false;
  text: string;
  intUrlBack: any;
  userId: string;
  titreessai = 'titletest';
  firstFormGroupEdit: UntypedFormGroup;
  domainButtom: boolean = false;
  subBut: boolean = true;
  planHasRum;
  enableRum = false;
  rumSelectedCountries = [];
  currentSelectedRUMCountry = null;
  isRUM: boolean;
  messageFromParent: string = 'to take adventage of the RUM';
  scriptSnippet: string;
  isClientAsync: boolean = false;
  isClientDefer: boolean = false;
  clientSideSamplingRate: number = 100;
  clientSideScriptSnippet: string = '';

  isAsync: boolean = false;
  isDefer: boolean = false;
  userData: any;
  rumSettings = { samplingRate: 100 };

  deviceMobile: boolean = true;
  deviceDesktop: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private SpeetalsDatabaseService: SpeetalsDatabaseService,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private title: Title,
    private helperService: HelperService,
    private snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,

    private router: Router,
    private senderVarService: SenderVarService,
  ) {}
  removeTrailing(string) {
    return string.replace(/\/+$/g, '');
  }

  ngOnInit() {
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    this.userService.getUserDetail(this.userId).subscribe(
      (data) => {
        this.userData = data;
        this.rumSettings = this.userData.rumSettings || this.rumSettings;
      },
      (error) => {
        console.error('Erreur : ', error);
      }
    );
    this.edit = this.senderVarService.isEdit;
    if (this.edit) {
      this.websiteAnalyzeService
        .getWebsiteAnalyzeById(this.userId, this.senderVarService.editId)
        .subscribe((response) => {
          this.editWebsite = response;
          this.editDataLoading = false;
          this.editWebsite.internalURLs.map((result) => {
            this.intUrl = result;
          });
          this.editWebsite.competitors.map((result) => {
            this.compet = result;
          });
          this.deviceMobile = response.deviceMobile;
          this.deviceDesktop = response.deviceDesktop;
        });
    }

    this.title.setTitle('Monitor new website - Speetals');
    this.regex = '^(https?://)?([a-zA-Z0-9][a-zA-Z0-9.-]*[a-zA-Z0-9])\\.([a-zA-Z]{2,})(/)?$';
    this.firstFormGroup = this._formBuilder.group({
      title: ['', [Validators.required]],
      domain: ['', [Validators.required, Validators.pattern(this.regex)]],
      device: new UntypedFormGroup(
        {
          myCheckbox1: new UntypedFormControl(true),
          myCheckbox2: new UntypedFormControl(true),
        },
        requireCheckboxesToBeCheckedValidator(),
      ),
    });
    this.firstFormGroupEdit = this._formBuilder.group({
      title: [''],
      domain: [''],
    });
    this.secondFormGroup = this._formBuilder.group({
      internalUrls: this.formBuilder.array([this.createInternalUrl()]),
    });
    this.thirdFormGroup = this._formBuilder.group({
      competitors: this.formBuilder.array([this.createCompetitor()]),
    });
    // string to boolean function
    const stringToBoolean = (string) => (string === 'false' ? false : !!string);

    this.userService.getPlan(this.userId).subscribe((data: Plan) => {
      this.plan = data;
      this.planHasRum = this.plan.details?.realUserMonitoring?.value;
      this.planView = true;
      this.locationButton = stringToBoolean(
        this.plan.details.locationSelectionAllowed.value,
      );

      this.websiteAnalyzeService
        .getWebsiteAnalyze(this.userId)
        .subscribe((website) => {
          let nb = parseInt(this.plan.details.nbWebsite.value) - website.length;
          if (nb == 0) {
            this.domainButtom = false;
          } else {
            this.domainButtom = true;
          }
        });
    });

    this.firstFormGroup.get('domain').valueChanges.subscribe((event) => {
      this.firstFormGroup.get('domain').setValue(event.toLowerCase(), {
        emitEvent: false,
      });
    });
  }
  validInternal() {
    this.validIn = true;
  }
  validCompetitor() {
    this.validComp = true;
    this.logValue();
  }

  onCountrySelectedRUM(country) {
    if (this.rumSelectedCountries.length === 5 || !country) return;
    const exists = this.rumSelectedCountries.find((e) => e === country.name);

    if (!exists) {
      this.rumSelectedCountries.push(country.name);
    }
    this.currentSelectedRUMCountry = {};
  }

  deleteSelectedCountry(country) {
    this.rumSelectedCountries = this.rumSelectedCountries.filter(
      (c) => country != c,
    );
  }

  urlValidators(regex: string) {
    return [
      Validators.pattern(regex),
      Validators.required,
      RxwebValidators.unique(),
      diffFromOriginDomainValidator(this.firstFormGroup.controls['domain']),
    ];
  }

  urlTitleValidators() {
    return [Validators.required, RxwebValidators.unique()];
  }

  conditionalRequired(condition) {
    return condition ? Validators.required : Validators.nullValidator;
  }

  addUrlValidatorsRequired(fb: UntypedFormGroup, validators) {
    fb.get('title')
      .valueChanges.pipe(
        tap((titleValue: boolean) => {
          fb.get('url').setValidators(
            validators.concat(this.conditionalRequired(titleValue)),
          );
          fb.get('url').updateValueAndValidity();
        }),
      )
      .subscribe();
  }
  addTitleValidatorsRequired(fb: UntypedFormGroup) {
    fb.get('url')
      .valueChanges.pipe(
        tap((value: boolean) => {
          fb.get('title').setValidators(this.conditionalRequired(value));
          fb.get('title').updateValueAndValidity();
        }),
      )
      .subscribe();
  }

  get competitorControls() {
    return this.thirdFormGroup.get('competitors')['controls'];
  }

  createCompetitor(): UntypedFormGroup {
    let validators = this.urlValidators(this.regex);
    let fb = this.formBuilder.group({
      title: ['', Validators.required],
      url: ['', validators],
    });
    return fb;
  }

  addCompetitor(): void {
    console.log('cpt', this.cptCompet);
    console.log('mode control', this.competitorControls[this.cptCompet]);
    if (this.competitorControls[this.cptCompet].invalid) {
      return;
    }
    if (this.cptCompet + 1 >= Number(this.plan.details.nbCompetitor.value))
      this.competitionBouton = false;
    else {
      this.competitors = this.thirdFormGroup.get(
        'competitors',
      ) as UntypedFormArray;
      this.competitors.push(this.createCompetitor());
      this.cptCompet++;
    }
  }

  removeCompetitor(i: number) {
    if (i > 0) {
      this.competitors.removeAt(i);
      this.competitionBouton = true;
      this.cptCompet--;
    }
  }

  get internalUrlControls() {
    return this.secondFormGroup.get('internalUrls')['controls'];
  }

  createInternalUrl(): UntypedFormGroup {
    //'^(http(s)?://)([\\da-z.-]+)\\.([a-z.]{2,6})'
    //let regexIntUrl = '^(http(s)?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    let regexIntUrl =
      '[(http(s)?)://(www\\.)?a-z-A-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)';
    let validators = this.urlValidators(regexIntUrl).concat(
      belongOriginDomainValidator(this.firstFormGroup.controls['domain']),
    );
    let fb = this.formBuilder.group({
      title: ['', this.urlTitleValidators()],
      url: ['', validators],
    });

    return fb;
  }

  addCount(i) {
    if (this.edit && i === 0) {
      i++;
      return true;
    } else {
      return false;
    }
  }

  addInternalUrl(): void {
    if (this.internalUrlControls[this.cptInter].invalid) {
      return;
    }
    if (this.cptInter + 1 >= Number(this.plan.details.nbInternalPage.value))
      this.internalButtom = false;
    else {
      this.internalUrls = this.secondFormGroup.get(
        'internalUrls',
      ) as UntypedFormArray;
      this.internalUrls.push(this.createInternalUrl());
      this.cptInter++;
    }
  }

  removeInternalUrl(i: number) {
    if (i > 0) {
      this.internalUrls.removeAt(i);

      this.internalButtom = true;
      this.cptInter--;
    }
  }

  getValidity(i) {
    this.validatorInternal = !this.internalUrlControls[i].invalid;

    return !this.internalUrlControls[i].invalid;
  }

  logValue() {
    this.websiteAnalyze.internalURLs = [];
    this.websiteAnalyze.competitors = [];
    this.websiteAnalyze.originDomain = this.normalizeDomain(
      this.firstFormGroup.value.domain,
    );

    //Add WSA internal title

    this.websiteAnalyze.title = this.firstFormGroup.value.title;

    //Add WSA internal Url
    this.internalUrlControls.map((element) => {
      if (element.status === 'VALID') {
        const internalPage = element.value;
        // Appliquer la normalisation spécifique aux internal pages
        internalPage.url = this.normalizeInternalUrl(internalPage.url);
        this.websiteAnalyze.internalURLs.push(internalPage);
      }
    });


    this.competitorControls.map((element) => {
      if (element.status === 'VALID') {
        const competitor = element.value;
        competitor.url = this.normalizeDomain(competitor.url);
        this.websiteAnalyze.competitors.push(competitor);
      }
    });

    //Add WSA Country

    this.websiteAnalyze.country =
      this.country1 != undefined
        ? this.country1.alpha2Code.toLowerCase()
        : 'NotAllowed';

    //wsa Device

    this.websiteAnalyze.deviceDesktop = false;
    this.websiteAnalyze.deviceMobile = false;

    if (this.firstFormGroup.value.device.myCheckbox1)
      this.websiteAnalyze.deviceMobile = true;
    if (this.firstFormGroup.value.device.myCheckbox2)
      this.websiteAnalyze.deviceDesktop = true;
    if (this.firstFormGroup.value.enableRum) {
      this.websiteAnalyze.isFPDEnabled = true;
    }

    //wsa userId
    this.websiteAnalyze.userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId,
    );

    if (this.plan.name.toLowerCase() === 'free') {
      this.websiteAnalyze.free = true;
      this.websiteAnalyze.editable = true;
    }
  }
  normalizeInternalUrl (url: string): string  {
    if (!url) return '';
    url = url.trim();
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    return url;
  };


  normalizeDomain (domain: string): string  {
    if (!domain) return '';
    domain = domain.trim();
    if (!/^https?:\/\//i.test(domain)) {
      domain = 'https://' + domain;
    }
    domain = domain.replace(/\/+$/, '');
    return domain;
  };


  selectChangeHandler(event: any) {
    this.country1 = event.target.value;
  }

  onCountrySelected(country: any) {
    this.country1 = country;
    this.websiteAnalyze.country = country.code.toLowerCase();
  }
  newPage() {
    this.subBut = false;
    
    // Validate device selection
    if (!this.validateDeviceSelection()) {
      this.helperService.createSnackBar(
        'Please select at least one device type (Mobile or Desktop)'
      );
      return;
    }
    
    // Make sure all required fields are set
    if (this.country1) {
      this.websiteAnalyze.country = this.country1.code.toLowerCase();
    }
    
    // Make sure userId is set
    this.websiteAnalyze.userId = this.userId;
    
    // Make sure device settings are set
    this.websiteAnalyze.deviceMobile = this.deviceMobile;
    this.websiteAnalyze.deviceDesktop = this.deviceDesktop;
    
    // RUM settings
    this.websiteAnalyze.isFPDEnabled = this.enableRum;
    this.websiteAnalyze.rumMonitoringLocations = this.rumSelectedCountries;
    

    
    if (this.edit) {
      this.websiteAnalyzeService
        .putWebsiteAnalyze(this.senderVarService.editId, this.websiteAnalyze)
        .subscribe((_) => {});
    } else {
      this.websiteAnalyzeService
        .postWebsiteAnalyze(this.websiteAnalyze)
        .subscribe((response) => {
          if (response.error.statusCode !== 200) {
            this.helperService.createSnackBar(
              ConstantService.errorMessage.websiteExists,
            );
          }
        });
    }
    this.authService.updateUserLoggedNbWebsites();
    this.SpeetalsDatabaseService.invalidateListwebistes();
    this.router.navigate(['/home/pageLoad/loading']);
  }

  selectionChanged(item) {
    this.device = item.value;
  }
  onCheckRumEnabled(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.enableRum = inputElement.checked;
    const asyncAttr = this.isClientAsync ? ' async' : '';
    const deferAttr = this.isClientDefer ? ' defer' : '';
    const baseUrl = 'https://cdn.speetals.com/rumagent/sp-rum-v2.1.js';

    if (this.enableRum) {
      this.scriptSnippet = "<script src=\"https://cdn.speetals.com/rumagent/sp-rum-v2.1.js?apiKey=" + this.userData?.apiKey + "\"></script>";
      this.clientSideScriptSnippet =
      `<script src="${baseUrl}?apiKey=${this.userData?.apiKey}&sampling=${this.clientSideSamplingRate}"${asyncAttr}${deferAttr}></script>`;

    } else {
      this.scriptSnippet = '';
    }
  }

  updateScriptSnippet(): void {
    this.scriptSnippet = `<script src="https://cdn.speetals.com/rumagent/sp-rum-v2.1.js?apiKey=${this.userData?.apiKey}"`;
    if (this.isAsync) {
      this.scriptSnippet += ' async';
    }
    if (this.isDefer) {
      this.scriptSnippet += ' defer';
    }
    this.scriptSnippet += '></script>';
  }

  copyRumSnippet(): void {
    const code = this.scriptSnippet;
    const textarea = document.createElement('textarea');
    textarea.value = code;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.snackBar.open('RUM snippet copied! : ' + code, 'close', {
      duration: 3000,
    });
  }

  updateClientSideSnippet(): void {
    const asyncAttr = this.isClientAsync ? ' async' : '';
    const deferAttr = this.isClientDefer ? ' defer' : '';
    const baseUrl = 'https://cdn.speetals.com/rumagent/sp-rum-v2.1.js';
    this.clientSideScriptSnippet =
      `<script src="${baseUrl}?apiKey=${this.userData?.apiKey}&sampling=${this.clientSideSamplingRate}"${asyncAttr}${deferAttr}></script>`;
  }


  copyClientSideSnippet(): void {
    navigator.clipboard.writeText(this.clientSideScriptSnippet);
    this.snackBar.open('Snippet copied to clipboard!', 'Close', {
      duration: 3000,
    });
  }

  async updateRumSettings() {
    try {
      if (
        this.rumSettings.samplingRate < 1 ||
        this.rumSettings.samplingRate > 100
      ) {
        this.snackBar.open('RUM sampling rate not updated!', 'close', {
          duration: 1000,
        });
      } else {
        await this.userService
          .updateRumSettings(this.userId, this.rumSettings)
          .toPromise();
        this.snackBar.open('RUM sampling rate updated!', 'close', {
          duration: 1000,
        });
      }
    } catch (error) {}
  }

  clearData() {
    this.authService.logoutUser();
  }

  onRumCountriesChange(countryNames: string[]) {
    this.rumSelectedCountries = countryNames;
  }

  // Add this method to check if at least one device is selected
  validateDeviceSelection(): boolean {
    return this.deviceMobile || this.deviceDesktop;
  }
}
