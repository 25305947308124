import { Component, OnInit } from '@angular/core';
import { DeviceButtonComponent } from '../device-button/device-button.component';
import { MetricPageComponent } from '../metric-page/metric-page.component';

@Component({
    selector: 'app-inp',
    templateUrl: './inp.component.html',
    styleUrls: ['./inp.component.scss'],
    standalone: false
})
export class InpComponent implements OnInit {

  metric : string = 'inp'

  constructor() {}
  ngOnInit(): void {}

}
